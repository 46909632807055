import React, { useState, useEffect,useRef} from "react";
import axios from "../../api/axios";
import { useNavigate,Link } from 'react-router-dom';
import Swal from "sweetalert2";


function AjouterCommandeFr() {
   
    const navigate = useNavigate();

    const [fournisseurs, setfournisseurs] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedFr, setselectedFr] = useState("");
    const [date_commande, setDateFr] = useState("");
    const [selectedArticle, setSelectedArticle] = useState("");
    const [categories, setCategories] = useState([]);
    const [article_commandes, setArticleCommande] = useState([]);
    const [qte, setQte] = useState("");
    const [price, setPrice] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
  



    useEffect(() => {
        const fetchFournisseurs = async () => {
            try {
                const response = await axios.get("/fournisseurs");
                setfournisseurs(response.data);
            } catch (error) {
                console.error("Error fetching fournisseurs:", error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get("/categories"); 
                setCategories(response.data.categories);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
    
        const fetchArticles = async () => {
            try {
                const response = await axios.get("/articles");
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };
        fetchFournisseurs();
        fetchCategories();
        fetchArticles();
    }, []);
    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
      };
    
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleSelectArticle = (id, nom_article) => {
        setSelectedArticle(id);
        setSearchTerm(nom_article);
        setDropdownOpen(false);
      };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const commandeResponse = await axios.post("/commandeFr", {
                id_fournisseur: selectedFr,
                date_commande: date_commande,
            });

            if (commandeResponse.status === 201) {
                const commandeId = commandeResponse.data.id;

                await Promise.all(
                    article_commandes.map(async (article) => {
                        await axios.post("/ligne-commande-fors", {
                            id_commande_fr: commandeId,
                            id_article: article.id,
                            quantite: article.quantity,
                            pu_vente: article.price,
                            total_tva:article.total_tva,
                            prix_tcc: article.prix_tcc,
                        });
                    })
                );

                handleSuccess();
            } else {
                handleError();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Commande fournisseur ajoutée avec succès!',
        });
        navigate('/commande-fournisseur/commandes');
    };

    const handleError = (error) => {
        console.error('Erreur lors de l\'ajout de la commande fournisseur:', error);
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Échec de l\'ajout de la commande fournisseur. Veuillez réessayer plus tard.',
        });
    };

    const handleAddRow = () => {
        const selectedArticleObj = articles.find(
          (article) => article.id === parseInt(selectedArticle, 10)
        );
      
        if (!selectedArticleObj) {
          console.error("L'article sélectionné n'a pas été trouvé");
          return;
        }
      
        const quantite = parseFloat(qte);
        const pu_vente = parseFloat(price);
        const total_tva = pu_vente * quantite * 0.20;
        const prix_tcc = pu_vente * quantite + total_tva;
      
        const newCommande = {
          id: selectedArticle, 
          nom_article: selectedArticleObj.nom_article, 
          quantity: quantite,
          price: pu_vente,
          total_tva: total_tva,
          prix_tcc: prix_tcc,
        };
        setArticleCommande([...article_commandes, newCommande]); 
        setSelectedArticle('')
        setSearchTerm("");

        setPrice('')
        setQte('')
      };

    const handleChange = (field, value) => {
        switch (field) {
            case "selectedFr":
                setselectedFr(value);
                break;
            case "dateCommande":
                setDateFr(value);
                break;
            case "selectedArticle":
                setSelectedArticle(value);
                break;
            case "qte":
                setQte(value);
                break;
            case "price":
                setPrice(value);
                break;
            default:
                console.warn("Champ inconnu:", field);
        }
    };

    const deleteArticle = (index) => {
        const updatedArticleCommandes = [...article_commandes];
        updatedArticleCommandes.splice(index, 1);
        setArticleCommande(updatedArticleCommandes);
    };
    
    return (
        <>
		<div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter commande fournisseur</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-3">Fournisseur</label>
                                                <select
                                                    className="form-control"
                                                    value={selectedFr}
                                                    onChange={(e) => handleChange("selectedFr", e.target.value)}
                                                    >
                                                    <option value="" />
                                                        {fournisseurs.map((fournisseur) => (
                                                    <option key={fournisseur.id} value={fournisseur.id}>
                                                        {fournisseur.nom_fournisseur}
                                                    </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-3">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="adresse_magasin"
                                                    value={date_commande}
                                                    onChange={(e) => setDateFr(e.target.value)}
                                                />
                                            
                                            </div>
                                           
                                        </div>
                                        <div className="form-row">
                                        
                                            <React.Fragment >
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-4 col-md-4">Article</label>
                                                <div className="custom-select-wrapper" ref={dropdownRef}>
                                                <div className="form-control" onClick={handleDropdownClick}>
                                                    {searchTerm}
                                                </div>
                                                {dropdownOpen && (
                                                    <div className="autocom-box">
                                                    <input
                                                        type="search"
                                                        aria-label="Search"
                                                        className="search-box form-control"
                                                        placeholder="Rechercher..."
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                        onClick={() => setDropdownOpen(true)}
                                                    />
                                                        <ul>
                                                    {categories
                                                    .filter(category => 
                                                        articles.some(article => 
                                                        article.categorie_id === category.id && 
                                                        article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                        )
                                                    )
                                                    .map(category => (
                                                        <React.Fragment key={category.id}>
                                                        <li className="category-label text-bold">{category.nom_categorie}</li>
                                                        {articles
                                                            .filter(article => 
                                                            article.categorie_id === category.id && 
                                                            article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                            )
                                                            .map(filteredArticle => (
                                                            <li
                                                                key={filteredArticle.id}
                                                                className="article-item"
                                                                onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                                            >
                                                                {filteredArticle.nom_article}
                                                            </li>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>

                                                </div>
                                                )}
                                                </div>
                                            </div>
                                           
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Quantité</label>
                                                <input
                                               type="number"
                                               className="form-control"
                                               value={qte}
                                               onChange={(e) => handleChange( 'qte', e.target.value)}
                                               min={0}
                                                />
                                            </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Prix</label>
                                                <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                value={price}
                                                onChange={(e) => handleChange( 'price', e.target.value)}
                                                />
                                            </div>
                                            
                       
                                        </React.Fragment>
                                        <div className="col-md-12 mb-3">
                                            <div className="row g-3">
                                                    <div className="col-md-4 offset-md-8 text-end ">
                                                     <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                                   </div>
                                            </div>
                                    </div>
                                         </div>
                                         <table className="display table table-striped table-bordered">
                                           <thead>
                                             <tr>
                                               <th>Article</th>
                                               <th>Quantité</th>
                                               <th>P.U</th>
                                               <th>Total</th>
                                               <th className="d-md-flex justify-content-md-end">Action</th>
                                             </tr>
                                           </thead>
                                           <tbody>
                                           {article_commandes.map((commande, index) => (
                                             <tr key={index}>
                                               <td>{commande.nom_article}</td>
                                               <td>{commande.quantity}</td>
                                               <td>{commande.price}</td>
                                               <td>{commande.quantity * commande.price}</td>
                                               <td className="d-md-flex justify-content-md-end">
                                                 <i
                                                   className="feather icon-trash btn btn-danger-rgba btn-round"
                                                   style={{ fontSize: "small" }}
                                                   onClick={() => deleteArticle(index)}
                                                 ></i>
                                               </td>
                                             </tr>
                   
                   
                                             
                                             ))}
                                           </tbody>
                                         </table>
                                       
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/commande-fournisseur/commandes">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                          
                     </form>
                </div>
             </div>
        </div>
                        
    </div>
       </div>


</>

    );
}

export default AjouterCommandeFr;
