import React from "react";
import SideBar from "./SideBar";
import Header from "./Header";

function MainLayout({ children }) {
  return (
    <div id="containerbar">
      <SideBar />
      <div className="rightbar">
        <Header />
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
