import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from 'sweetalert2';

function Verrouiller() {
    const [verrouille, setVerrouille] = useState(false);


    const handleVerrouillage = async () => {
        try {
            if (verrouille) {
                await axios.post('/update-all-users-status');
                Swal.fire({
                    icon: 'success',
                    title: 'Mise à jour réussie',
                    text: 'Statut de tous les utilisateurs mis à jour avec succès'
                });
            } else {
                await axios.post('/reactivate-all-users-status');
                Swal.fire({
                    icon: 'success',
                    title: 'Réactivation réussie',
                    text: 'Statut de tous les utilisateurs réactivé avec succès'
                });
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut des utilisateurs :", error);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la mise à jour du statut des utilisateurs. Veuillez réessayer.'
            });
        }
    };
    
    

    const handleRadioChange = (e) => {
        setVerrouille(e.target.value === "oui");
    };

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="row justify-content-center"> 
                            <div className="col-lg-12 m-b-50"> 
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Verrouiller l'accès </h5>
                                    </div>
                                    <div className="card-body">
                                        <form className="needs-validation" onSubmit={(e) => { e.preventDefault(); handleVerrouillage(); }}>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <h6 className="form-check-label md-5">
                                                        Verrouiller l'accès aux autres utilisateurs
                                                    </h6>
                                                </div>
                                                <div className="col-md-4 d-flex align-items-center mb-3 custom-radio">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="oui"
                                                            name="verrouillage"
                                                            value="oui"
                                                            checked={verrouille === true}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="oui">Oui</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="non"
                                                            name="verrouillage"
                                                            value="non"
                                                            checked={verrouille === false}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor="non">Non</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                                                <button className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden w-90" type="submit">Enregistrer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
          </div>
              
        </>
    );
}

export default Verrouiller;
