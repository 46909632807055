import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useQuery } from "react-query";
import { fetchClients } from '../../../services/clientService';
import { fetchFournisseurs } from '../../../services/fournisseurService';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

const CompteParType = () => {
  const { t } = useTranslation();

  const { data: clientsData, error: clientsError } = useQuery(['clients'], fetchClients);
  const { data: fournisseursData, error: fournisseursError } = useQuery(['fournisseurs'], fetchFournisseurs);

  if (clientsError || fournisseursError) {
    console.error('Error fetching data', clientsError || fournisseursError);
    return <div>Error fetching data</div>;
  }

  const clientCount = clientsData?.length || 0;
  const fournisseurCount = fournisseursData?.length || 0;

  const data = {
    labels: [t('Clients'), t('Fournisseurs')],
    datasets: [
      {
        data: [clientCount, fournisseurCount],
        backgroundColor: ['#36A2EB', '#FF7F50'],
      },
    ],
  };

  return (
    <div className="container my-4">
      <div className="chart-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <Pie data={data} />
      </div>
      <table className="table table-striped table-bordered w-100 mt-4  centered-table">
        <thead>
          <tr>
            <th>{t('Type')}</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label={t('Type')}>{t('Clients')}</td>
            <td data-label="#">{clientCount}</td>
          </tr>
          <tr>
            <td data-label={t('Type')}>{t('Fournisseurs')}</td>
            <td data-label="#">{fournisseurCount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompteParType;
