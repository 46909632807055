import axios from '../api/axios';

export const SuiviVente = async () => {
    try {
        const { data: factures } = await axios.get('/factures');
        const { data: avoirs } = await axios.get('/avoirs');
        
        const totalFactures = factures.reduce((total, facture) => total + facture.montant, 0);
        const totalAvoirs = avoirs.reduce((total, avoir) => total + (avoir.quantite * avoir.montant), 0);
        const CA = totalFactures - totalAvoirs;
        
        return {
            totalFactures,
            totalAvoirs,
            CA
        };
        
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
