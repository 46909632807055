import React, { useState, useRef, useEffect } from "react";
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from '../../../api/axios';

const PersonaliserFacture = () => {
  const { t } = useTranslation();
  const [selectedMainCase, setSelectedMainCase] = useState('Agenda');
  const [selectedSubCaseIndex, setSelectedSubCaseIndex] = useState(0);
  const [personnelId, setPersonnelId] = useState('');
  const [title, setTitle] = useState('');
  const [durationHours, setDurationHours] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');
  const [message, setMessage] = useState('');
  const [personnels, setPersonnels] = useState([]);
  const printRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const mainCases = [
    { id: 'Factures', label: 'Factures' },
  ];

  const subCases = {
    Factures: [
      { label: 'EnTete', path: 'enTete' },
      { label: 'EnPied', path: 'enPied' },
  
    ],

    
  };
  

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const mainCaseId = Object.keys(subCases).find((key) =>
      subCases[key].some((subCase) => subCase.path === path)
    );
    if (mainCaseId) {
      setSelectedMainCase(mainCaseId);
      setSelectedSubCaseIndex(
        subCases[mainCaseId].findIndex((subCase) => subCase.path === path)
      );
    }
  }, [location]);

  const handleMainCaseClick = (id) => {
    if (id !== selectedMainCase) {
      setSelectedMainCase(id);
      setSelectedSubCaseIndex(0);
      navigate(`/PersonaliserFacture/${subCases[id][0].path}`);
    }
  };

  const handlePreviousSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex - 1 + subCaseList.length) % subCaseList.length;
      navigate(`/PersonaliserFacture/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const handleNextSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex + 1) % subCaseList.length;
      navigate(`/PersonaliserFacture/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const selectedSubCase = subCases[selectedMainCase]?.[selectedSubCaseIndex];

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/taches', {
        personnel_id: personnelId,
        titre: title,
        duree: `${durationHours}h${durationMinutes}m`
      });

      setMessage('Tâche ajoutée avec succès');
      setPersonnelId('');
      setTitle('');
      setDurationHours('');
      setDurationMinutes('');
    } catch (error) {
      setMessage('Erreur lors de l\'ajout de la tâche');
    }
  };

  const fetchPersonnels = async () => {
    try {
      const response = await axios.get('/personnel');
      setPersonnels(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des personnes:', error);
    }
  };

  useEffect(() => {
    fetchPersonnels();
  }, []);

  return (
    <>
     
          <div className="breadcrumbbar">
            <div className="row align-items-center mb-5">
              <div className="col-md-8 col-lg-12 d-md-flex align-items-center justify-content-between mb-3">
                <div className="breadcrumb-list mb-3">
                  <ol className="breadcrumb d-flex align-items-center">
                    <li className="breadcrumb-item">
                      <Link to="/home">
                        <i className="fa fa-home img-fluid" style={{ fontSize: "15px", width: "20px", marginRight: "3px" }}></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/home" style={{ textDecoration: 'none' }}>{t('Configuration')}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Champs Personalisés')}</li>
                  </ol>
                </div>
                <div className="widgetbar">
                  <Link className="btn btn-danger-rgba mr-2" onClick={handlePreviousSubCase}>
                    <i className="fa fa-chevron-left"></i>
                  </Link>
                  <Link className="btn btn-success-rgba mr-2" onClick={handleNextSubCase}>
                    <i className="fa fa-chevron-right"></i>
                  </Link>
                  {selectedSubCase === 'Mes Tâches' && (
                    <button className="btn btn-primary-rgba" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <i className="feather icon-plus mr-2"></i>{t('Ajouter une tâche')}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-12">
              <div className="content-container">
                <div className="right-panel" ref={printRef}>
                  <div className="subcase-container m-b-50">
                    <div className="card m-b-50">
                      <div className="card-header text-center mt-2">
                        <h6>{mainCases.find((mc) => mc.id === selectedMainCase)?.label} {'  -->  '} {selectedSubCase?.label}</h6>
                      </div>
                      <div className="card-body text-center chart-container" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
       
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Nouveau tâche</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="col-sm-4 col-form-label">Personnel 
                  <span className="required" aria-required="true"> * </span>: </label>
                  <select
                    required=""
                    name="departmentid"
                    className="form-control"
                    aria-required="true"
                    value={personnelId}
                    onChange={(e) => setPersonnelId(e.target.value)}
                  >
                    <option value="">---</option>
                    {personnels.map(personnel => (
                      <option key={personnel.id} value={personnel.id}>
                        {personnel.nom_complet}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 col-form-label">Titre
                  <span className="required" aria-required="true"> * </span>: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="col-sm-4 col-form-label">Durée
                  <span className="required" aria-required="true"> * </span>: </label>
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control mr-2"
                      id="durationHours"
                      name="durationHours"
                      value={durationHours}
                      onChange={(e) => setDurationHours(e.target.value)}
                      placeholder="Heures"
                      required
                    />
                    <input
                      type="number"
                      className="form-control"
                      id="durationMinutes"
                      name="durationMinutes"
                      value={durationMinutes}
                      onChange={(e) => setDurationMinutes(e.target.value)}
                      placeholder="Minutes"
                      required
                    />
                  </div>
                </div>
                {message && <div className="alert alert-info">{message}</div>}
                <div className="modal-footer mr-0">
                <div className="d-md-flex ">
                  <button type="button" className="btn btn-secondary mr-2" data-bs-dismiss="modal">Fermer</button>
                  <button type="submit" className="btn btn-primary">Enregistrer</button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PersonaliserFacture;
