
import React from 'react';
import '../assets/css/footer.css'; 
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footerbar">
      <footer className="footer">
        <p className="mb-0">{t('footer')}</p>
      </footer>
    </div>
  );
};

export default Footer;
