import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";



function ModiferCategorie() {

    const { categoryId } = useParams();
    const [categories, setCategories] = useState([]);

    const navigate = useNavigate();
  
    const [categorie, setCategorie] = useState({
      nom_categorie: '',
      pu_achat: '',
      pu_vente: '',
      parent_id: 0
  });
  useEffect(() => {
    const fetchCategorie = async () => {
        try {
            const response = await axios.get(`/categories/categories/${categoryId}`);
            setCategorie(response.data.categorie);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching categorie:", error);
        }
    };

    fetchCategorie();
}, [categoryId]); 


  useEffect(() => {
      const fetchCategories = async () => {
          try {
              const response = await axios.get('/categories');
              setCategories(response.data.categories);
          } catch (error) {
              console.error("Error fetching categories:", error);
          }
      };

      fetchCategories();
  }, [categoryId]);

  const { nom_categorie, pu_achat, pu_vente, parent_id } = categorie;

  const onInputChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    // Check if the input is parent_id and value is empty
    const newValue = name === "parent_id" && value === "" ? 0 : value;

    setCategorie({ ...categorie, [name]: newValue });
};


  
const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        if (nom_categorie.trim() === "") {
            Swal.fire({
                title: "",
                text: "Veuillez saisir les données svp",
                icon: "error"
            });
            return;
        }

        const parentIdToSend = parent_id === "" ? 0 : parent_id;
        console.log(parentIdToSend);
        
        let requestData = {
            nom_categorie,
            pu_achat,
            pu_vente,
        };
        
        if (parentIdToSend !== 0) {
            requestData.parent_id = parentIdToSend;
        } else {
            requestData.parent_id = 0;
        }
        
        console.log(requestData.parent_id)
        await axios.put(`/categories/${categoryId}`, requestData);

        Swal.fire({
            title: "",
            text: "Les modifications ont été enregistrées avec succès",
            icon: "success"
        });

        navigate('/articles/categorie');
    } catch (error) {
        console.error('Erreur lors de la modification de la catégorie :', error);
        alert('Erreur lors de la modification de la catégorie. Veuillez réessayer.');
    }
};


  
    return (
        <>
		<div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header" >
                                    <h5 className="card-title">Modifier la catégorie</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                          <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">Nom de Catégorie</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="nom_categorie"
                                            value={nom_categorie}
                                            onChange={onInputChange}
                                            />
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">Catégorie parent</label>
                                            <select
                                            data-hide-search="true"
                                            className="form-control"
                                            name="parent_id"
                                            value={parent_id}
                                            onChange={onInputChange}
                                            >
                                            <option value=""></option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                {category.nom_categorie}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                        </div>
                                        <div className="form-row">
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">PU Achat (DH)</label>
                                            <input
                                            type="number"
                                            name="pu_achat"
                                            className="form-control"
                                            value={pu_achat}
                                            onChange={onInputChange}
                                            />
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">PU Vente (DH)</label>
                                            <input
                                            type="number"
                                            className="form-control"
                                            name="pu_vente"
                                            value={pu_vente}
                                            onChange={onInputChange}
                                            />
                                        </div>
                                        </div>

                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Link className="btn btn-primary-rgba my-1" to="/articles/categorie">Annuler</Link>
                                        <button className="btn btn-primary-rgba my-1">
                                            <i className="feather icon-save mr-2"></i>Modifier
                                        </button>
                                        </div>
                                    </form>
                                    </div>

             </div>
        </div>
                        
    </div>
      </div>

    </>

  );
}

export default ModiferCategorie;
