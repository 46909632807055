import React from 'react';
import StatCard from './StatCard';

const SuiviVenteDashboard = ({ facturesTotal, avoirsTotal, chiffreAffaires }) => {
  return (
    <div className="SuiviVente">
      <StatCard
        title="Factures"
        value={`${facturesTotal} MAD`}
        trendDirection="up"
      />
      <StatCard
        title="Avoirs"
        value={`${avoirsTotal} MAD`}
        trendDirection="up"
      />
      <StatCard
        title="Chiffre d'affaire"
        value={`${chiffreAffaires} MAD`}
        trendDirection="up"
      />
    </div>
  );
};

export default SuiviVenteDashboard;
