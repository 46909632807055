import React, { useState, useEffect, useMemo } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from "react-i18next";

function ListerCharge() {
  const{t} = useTranslation();
  const queryClient = useQueryClient();
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [chargeIdToDelete, setChargeIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch charges with related data
  const { data: charges = [], refetch } = useQuery(
    ['charges'],
    async () => {
      const [chargesResponse, magasinsResponse, typeChargesResponse] = await Promise.all([
        axios.get("/charges"),
        axios.get("/magasins"),
        axios.get("/type-charges")
      ]);

      return chargesResponse.data.map(charge => {
        const magasin = magasinsResponse.data.find(magasin => magasin.id === charge.id_magasin);
        const typeCharge = typeChargesResponse.data.find(typeCharge => typeCharge.id === charge.id_type_charge);

        return {
          ...charge,
          nom_magasin: magasin ? magasin.nom_magasin : "",
          type_charge: typeCharge ? typeCharge.type_charge : ""
        };
      });
    }
  );

  // Mutation for deleting a charge
  const deleteChargeMutation = useMutation(
    async (chargeId) => {
      await axios.delete(`/charges/${chargeId}`);
    },
    {
      onSuccess: () => {
        refetch(); // Refetch charges after deletion
        closeConfirmBox();
      },
      onError: (error) => {
        console.error("Erreur lors de la suppression de la charge :", error);
      },
    }
  );

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const filteredcharges = useMemo(() => {
    return charges.filter((charge) => {
      // Convertir tous les champs de la charge en minuscules pour une comparaison insensible à la casse
      const lowercaseCharge = Object.fromEntries(
        Object.entries(charge).map(([key, value]) => [
          key,
          typeof value === "string" ? value.toLowerCase() : (value && value.toString().toLowerCase()) || '',
        ])
      );

      // Vérifier si le terme de recherche est inclus dans l'une des valeurs des champs de la charge
      return Object.values(lowercaseCharge).some((value) =>
        typeof value === "string" && value.includes(searchTerm.toLowerCase())
      );
    });
  }, [charges, searchTerm]);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredcharges.slice(offset, offset + itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const openDelete = (chargeId) => {
    setChargeIdToDelete(chargeId);
    setShowConfirmBox(true);
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false);
    setChargeIdToDelete(null);
  };

  const handleDeleteCharge = () => {
    if (chargeIdToDelete) {
      deleteChargeMutation.mutate(chargeIdToDelete);
    }
  };

  const columns = useMemo(() => [
    { key: 'magasin', label: 'Magasin', sortable: true },
    { key: 'charge', label: 'Charge', sortable: false },
    { key: 'montant', label: 'Montant', sortable: true },
    { key: 'date', label: 'Date', sortable: true },
    { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
  ], []);

  return (
    <>
      
          <div className="breadcrumbbar">
            <div className="row align-items-center">
              <div className="col-md-8 col-lg-8">
                <h4 className="page-title">{t('Liste des charges')}</h4>
                    <div className="breadcrumb-list mb-3">
                         <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                </Link>
                            </li>
                                  <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('charges')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('charges')}</li>
                                </ol>
                    </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                  <div className="row align-items-center">
                              <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                    <div className="col-5 col-md-5 d-flex justify-content-center">
                                       <Search 
                                          placeholder={t('Search')} 
                                          onSearchChange={handleSearchChange} 
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                     <div className="widgetbar">
                                          <Link to="/ajouter-charge" className="btn btn-primary-rgba">
                                              <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                          </Link>
                                      </div>
                                </div>
                        </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <SortingTable
                        data={currentItems.map((charge) => ({
                          id: charge.id,
                          magasin: charge.nom_magasin,
                          charge: charge.type_charge,
                          montant: Number(charge.montant).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                          date: charge.date,
                          actions: (
                            <div className="text-center">
                              <Link
                                to={`/modifier-charge/${charge.id}`}
                                className="btn btn-success-rgba btn-round mb-1"
                                title="Edit"
                                data-toggle="tooltip"
                                style={{ marginRight: '10px' }}
                              >
                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                              </Link>
                              <Link
                                onClick={() => openDelete(charge.id)}
                                className="btn btn-danger-rgba btn-round"
                                style={{ marginRight: '10px' }}
                              >
                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                              </Link>
                            </div>
                          ),
                        }))}
                        columns={columns}
                      />
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <select
                            className="form-control"
                            id="items-per-page"
                            onChange={handleItemsPerPageChange}
                            value={itemsPerPage}
                          >
                            <option value={2}>2</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </select>
                        </div>
                        <Pagination
                          items={filteredcharges}
                          itemsPerPage={itemsPerPage}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmBox
            open={showConfirmBox}
            closeDialog={closeConfirmBox}
            title="Supprimer Charge"
            deleteFunction={handleDeleteCharge}
          />
       
    </>
  );
}

export default ListerCharge;
