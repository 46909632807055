import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InfoSociete() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [societe, setSociete] = useState({
        societe: "",
        contact: "",
        adresse: "",
        telephone1: "",
        telephone2: "",
        fax: "",
        email: "",
        patente: "",
        if: "",
        tva: 0,
        rc: "",
        cnss: "",
        ice: ""
    });
    const [logo, setLogo] = useState(null);
    const [preview, setPreview] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchSociete = async () => {
            try {
                const response = await axios.get(`/societe`);
                setSociete(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données de la société:", error);
                Swal.fire({
                    title: "Erreur",
                    text: "Erreur lors de la récupération des données de la société",
                    icon: "error"
                });
            }
        };

        fetchSociete();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSociete({ ...societe, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            Object.keys(societe).forEach(key => formData.append(key, societe[key]));
            if (logo) {
                formData.append('logo', logo);
            }

            await axios.post(`/societe`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            Swal.fire({
                title: "Succès",
                text: "La société a été modifiée avec succès",
                icon: "success",
            });
            navigate('/infos-societe');
        } catch (error) {
            console.error("Erreur lors de la modification de la société:", error);
            Swal.fire({
                title: "Erreur",
                text: `Une erreur s'est produite lors de la modification de la société: ${error.response?.data?.message || error.message}`,
                icon: "error"
            });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemove = () => {
        setLogo(null);
        setPreview(null);
    };

    return (
        <>
            <div className="breadcrumbbar">
                <div className="col-md-8 col-lg-8">
                    <h4 className="page-title">{t('Informations de société')}</h4>
                    <div className="breadcrumb-list mb-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{ fontSize: "15px", width: "20px", marginRight: "3px" }}></i>
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/home" style={{ textDecoration: 'none' }}>{t('parametre')}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{t('Informations de société')}</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header">
                                <h5 className="card-title">{t('Informations de société')}</h5>
                            </div>
                            <div className="card-body">
                                <form className="needs-validation" onSubmit={handleSubmit}>
                                    <div className="form-row">
                                        {[
                                            { label: 'Nom de Société', name: 'societe' },
                                            { label: 'Contact', name: 'contact' },
                                            { label: 'Adresse', name: 'adresse' },
                                            { label: 'Téléphone 1', name: 'telephone1' },
                                            { label: 'Téléphone 2', name: 'telephone2' },
                                            { label: 'Fax', name: 'fax' },
                                            { label: 'Email', name: 'email', type: 'email' },
                                            { label: 'Patente', name: 'patente' },
                                            { label: 'IF', name: 'if' },
                                            { label: 'TVA', name: 'tva', type: 'number' },
                                            { label: 'RC', name: 'rc' },
                                            { label: 'CNSS', name: 'cnss' },
                                            { label: 'ICE', name: 'ice' }
                                          ].map((field, index) => (
                                            <div className="col-md-4 d-flex align-items-center mb-3" key={index}>
                                                <label className="me-2 col-md-5">{t(field.label)}</label>
                                                <input
                                                    type={field.type || 'text'}
                                                    className="form-control"
                                                    name={field.name}
                                                    value={societe[field.name]}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="custom-logo my-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="logo-upload d-flex flex-column align-items-center">
                                                    <label className="form-label">{t('Logo (maximum 800px de largeur) ')}</label>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        className="input-file form-control btn btn-primary mb-3"
                                                    />
                                                    {preview ? (
                                                        <div className="logo-preview d-flex flex-column align-items-center">
                                                            <img
                                                                src={preview}
                                                                alt="Logo Preview"
                                                                style={{ maxWidth: '100%', maxHeight: '300px' }}
                                                                className="mb-3"
                                                            />
                                                            <button
                                                                onClick={handleRemove}
                                                                className="btn btn-primary"
                                                            >
                                                                {t('Supprimer')}
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="no-image">
                                                            {societe.logo ?(
                                                            <img
                                                            src={`http://127.0.0.1:8000/${societe.logo}`}
                                                            alt="articles"
                                                            className="image-preview"
                                                            style={{ maxWidth: "100px", marginLeft: "40px" }}
                                                            />
                                                        ) :  (
                                                            <div>{t('Aucune image trouvée.')}</div>
                                                        )}
                                                            
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="btn btn-primary-rgba my-1">
                                            <i className="feather icon-save mr-2"></i>{t('Modifier')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoSociete;
