import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEntity } from './entitySlice';
import { useOfflineOperations } from '../useOfflineOperations';

const useEntityData = (entityName, fetchFunction) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.entities[entityName] || []);
  const { saveOperationOffline } = useOfflineOperations();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (navigator.onLine) {
          const result = await fetchFunction();
          dispatch(setEntity({ entity: entityName, data: result }));
        }
      } catch (error) {
        console.error(`Error fetching ${entityName}:`, error);
      }
    };
    
    fetchData();
  }, [dispatch, entityName, fetchFunction]);

  const handleDelete = async (id, deleteFunction) => {
    try {
      if (navigator.onLine) {
        await deleteFunction(id);
        dispatch(setEntity({
          entity: entityName,
          data: data.filter(item => item.id !== id),
        }));
      } else {
        saveOperationOffline({
          type: 'DELETE',
          endpoint: `/${entityName}/${id}`, 
        });
      }
    } catch (error) {
      console.error(`Error deleting ${entityName}:`, error);
    }
  };

  return { data, handleDelete };
};

export default useEntityData;
