import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function AjouterPaiCl() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [bonLivraison, setBonLivraison] = useState(null); 
    const [date_paiement, setDatePaiement] = useState(""); 
    const [montant, setMontant] = useState("");
    const [mode_paiement, setModePaiement] = useState("");
    const [date_echeance, setDateEcheance] = useState("");

    useEffect(() => {
        const fetchBonLivraison = async () => {
            try {
                const response = await axios.get(`/bonslivraisons/${id}`);
                const bonLivraisonData = response.data;
    
                const lignesResponse = await axios.get(`/ligne-bon-liv-cls?bonLivraisonId=${id}`);
                const lignesData = lignesResponse.data;
    
                const montantTotal = lignesData.reduce(
                    (total, ligne) => total + (ligne.pu_achat * ligne.quantite),
                    0
                );
    
                const montantPaye = 0; 
                const montantReste = montantTotal - montantPaye;
    
                setBonLivraison({
                    id_client: bonLivraisonData.id_client, 
                    code: bonLivraisonData.code,
                    montant: montantTotal.toFixed(2),
                    montant_paye: montantPaye.toFixed(2), 
                    reste: montantReste.toFixed(2), 
                });
            } catch (error) {
                console.error("Erreur lors de la récupération du bon de livraison:", error);
            }
        };
    
        fetchBonLivraison(); 
    }, [id]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const checkResponse = await axios.get(`/Paiementclient?livraison_code=${bonLivraison.code}`);
            
            if (checkResponse.data.length > 0) {
                const existingRecordId = checkResponse.data[0].id; 
                const updateResponse = await axios.put(`/Paiementclient/${existingRecordId}`, {
                    date_paiement: date_paiement,
                    id_client: bonLivraison.id_client,
                    montant: montant,
                    mode_paiement: mode_paiement,
                    date_echeance: date_echeance,
                });
    
                if (updateResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Paiement mis à jour avec succès!',
                    });
                    navigate('/paiements-clients');
                } else {
                    throw new Error('Erreur lors de la mise à jour du paiement.');
                }
            } else {
                const createResponse = await axios.post("/Paiementclient", {
                    livraison_code: bonLivraison.code,
                    date_paiement: date_paiement,
                    id_client: bonLivraison.id_client,
                    montant: montant,
                    mode_paiement: mode_paiement,
                    date_echeance: date_echeance,
                });
    
                if (createResponse.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Paiement ajouté avec succès!',
                    });
                    navigate('/paiements-clients');
                } else {
                    throw new Error('Erreur lors de l\'ajout du paiement.');
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Erreur lors du traitement du paiement. Veuillez réessayer plus tard.',
            });
        }
    };
    

    return (
    
                    <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Ajouter un paiement client</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Code BL</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonLivraison ? bonLivraison.code : ""}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Total TTC</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonLivraison ? bonLivraison.montant : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Payé</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonLivraison ? bonLivraison.montant_paye : ""}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Reste</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonLivraison ? bonLivraison.reste : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                      
                                             <div  className="form-row">
                                               <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Payé le</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={date_paiement}
                                                        onChange={(e) => setDatePaiement(e.target.value)}
                                                    />
                                                    
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Montant</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={montant}
                                                        onChange={(e) => setMontant(e.target.value)}
                                                        maxLength={undefined}
                                                    />
                                                </div>
                                            
                                                </div>
                                                <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Mode paiement</label>
                                                     <select
                                                        className="form-control"
                                                        value={mode_paiement}
                                                        onChange={(e) => setModePaiement(e.target.value)}
                                                    >
                                                        <option value=""></option>
                                                        <option value="espèces">Espèces</option>
                                                        <option value="chéque">Chèque</option>
                                                        <option value="tpe">TPE</option>
                                                    </select>
                                                </div>
                                                {mode_paiement === "chéque" && (
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Date d'échéance</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date_echeance}
                                                    onChange={(e) => setDateEcheance(e.target.value)}
                                                />
                                            </div>
                                             )}
                                              </div>
                                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-warning my-1" to="/magasins">Annuler</Link>
                                                <button className="btn btn-success my-1" type="submit">Ajouter</button>
                                             </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
        
    );
}

export default AjouterPaiCl;
