import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate,Link } from 'react-router-dom';





function AjouterFournisseur() {

    const navigate = useNavigate();
    const [telephone, setTelephone] = useState("");
    const [error, setError]=useState('');
    const [formData, setFormData] = useState({
        nom_fournisseur: "",
        email: "",
        telephone: "",
        fax: "",
        societe: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setTelephone(value);
    
        const phonePattern = /^[0-9\s()+-]*$/; 
        if (!phonePattern.test(value)) {
          setError('Veuillez entrer un numéro de téléphone valide.');
        } else {
          setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/fournisseurs", formData);
            if (response.status === 201) {
                Swal.fire("Success", "Fournisseur ajouté avec succès", "success");
                navigate('/fournisseurs');
            }
        } catch (error) {
            Swal.fire("Error", "Une erreur s'est produite lors de l'ajout du fournisseur", "error");
        }
    };

    return (
        <>
		
           <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter un fournisseur</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-4">Nom de fournisseur</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="nom_fournisseur"
                                                value={formData.nom_fournisseur}
                                                onChange={handleInputChange}
                                            />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Email</label>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            
                                            </div>
                                        </div>
                                   
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Téléphone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="telephone"
                                                    value={formData.telephone}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Fax</label>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    name="fax"
                                                    value={formData.fax}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            {error && <span className="text-danger mt-2 d-block">{error}</span>}

                                        </div>
                                        
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                               <label className="me-2 col-md-4" >Societe</label>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    name="societe"
                                                    value={formData.societe}
                                                    onChange={handleInputChange}
                                                />
                                        
                                            </div>

                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/fournisseurs">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
           </div>


</>

  );
}

export default AjouterFournisseur;
