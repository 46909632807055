
import axios from "../api/axios";

export const getMagasins = async () => {
    const response = await axios.get("/magasins");
    return response.data;
};

export const deleteMagasin = async (magasinId) => {
    await axios.delete(`/magasins/${magasinId}`);
};
