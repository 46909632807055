import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";

function AjouterTypeCharge() {
  const [typeCharge, setTypeCharge] = useState(""); 
  const [parentId, setParentId] = useState("");
  const [charges, setCharges] = useState([]);
  const navigate = useNavigate(); 

  const onInputChange = (e) => {
    setTypeCharge(e.target.value); 
  };

  useEffect(() => {
    const fetchTypeCharges = async () => {
          const response = await axios.get("/type-charges"); 
        setCharges(response.data); 
      
       
    };
  
    fetchTypeCharges(); 
  }, []);
  

  const onSubmit = async (e) => {
    e.preventDefault(); 
  
    try {
      if (typeCharge.trim() === "") {
        console.error("Le champ Type Charge est vide.");
        return;
      }
        const requestData = {
        type_charge: typeCharge,
      };
        if (parentId !== "") {
        requestData.parent_id = parentId;
      }
        const response = await axios.post("/type-charges", requestData);
  
      if (response.status === 201) { 
        navigate("/types-charges"); 
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du type de charge :", error); 
    }
  };
  

  return (
    <>
      
          <div className="breadcrumbbar">
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                    <h5 className="card-title">Ajouter Type Charge</h5>
                  </div>
                  <div className="card-body">
                    <form className="needs-validation" onSubmit={onSubmit}>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Type Charge</label>
                          <input
                            type="text"
                            className="form-control"
                            name="type_charge"
                            value={typeCharge}
                            onChange={onInputChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Charge Parent</label>
                          <select
                            className="form-select"
                            value={parentId} 
                            onChange={(e) => setParentId(e.target.value)} 
                          >
                            <option value=""></option> 
                            {charges.map((charge) => (
                              <option key={charge.id} value={charge.id}>
                                {charge.type_charge} 
                              </option>
                            ))}
                          </select>
                        </div>

                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Link className="btn btn-primary-rgba my-1" to="/type-charges">
                          Annuler
                        </Link>
                        <button className="btn btn-primary-rgba my-1" type="submit">
                          Ajouter
                          <i className="feather icon-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </>
  );
}

export default AjouterTypeCharge;
