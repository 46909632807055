import axios from '../api/axios';

 export const fetchClients = async ()=>{
   try {
        const response = await axios.get('/clients');
        return response.data;

      }
    catch(error){
     console.error("error feching clients:",error);
     throw error;
 }
 }
 export const fetchCommandeCl = async ()=>{
  try {
       const response = await axios.get('/commandeclient');
       return response.data;

     }
   catch(error){
    console.error("error feching commandeclient:",error);
    throw error;
}
}

export const fetchDevis = async ()=>{
  try {
    const response = await axios.get('/article-devis');
    return response.data;
  }
  catch(error){
    console.erroe("error fetching devis :", error);
    throw error;

  }
}