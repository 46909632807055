import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; 
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";

function ListerAvoirs() {
    const {t}=useTranslation();
    const [avoirs, setAvoirs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredavoirs, setFilteredavoirs] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage,setItemsPerPage] = useState(1);
  
    //sorting
    const columns = [
        { key: 'Avoir', label: 'Avoir', sortable: true },
        { key: 'Facture', label: 'Facture', sortable: false },
        { key: 'Date', label: 'Date', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
    ];
  
   //logique recherche 
   const handleSearchChange = (term) => {
    setSearchTerm(term);
  
    const filtered = avoirs.filter((commande) => {
        return Object.keys(commande).some((key) => {
            if (typeof commande[key] === 'string') {
                return commande[key].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
    });
  
    setFilteredavoirs(filtered);
  };
  
  useEffect(() => {
    setFilteredavoirs(avoirs); 
  }, [avoirs]);
  
    //pagination debut
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex); 
      };
      
      const offset = currentPage * itemsPerPage; 
      const currentItems = filteredavoirs.slice(offset, offset + itemsPerPage); 
      console.log('Nombre de lignes affichées:', currentItems.length);
    
    //end pagination
     //temsPerPage 
     const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value)); 
        setCurrentPage(0); 
      };
      
      
    useEffect(() => {
        const fetchAvoirs = async () => {
            try {
                const response = await axios.get("/avoirs");
                const avoirsData = response.data;
                const avoirsWithFactureCode = await Promise.all(avoirsData.map(async (avoir) => {
                    const factureId = avoir.facture_id;
                    const factureResponse = await axios.get(`/factures/${factureId}`);
                    const factureData = factureResponse.data;
                    return { ...avoir, facture_code: factureData.code };
                }));
                return avoirsWithFactureCode;
            } catch (error) {
                console.error("Erreur lors de la récupération des avoirs :", error);
                return [];
            }
        };
    
        const fetchData = async () => {
            const avoirsData = await fetchAvoirs();
            setAvoirs(avoirsData);
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="breadcrumbbar">
                        <div className="row align-items-center">
                            <div className="col-md-8 col-lg-8">
                                <h4 className="page-title">{t('Avoirs')}</h4>
                                <div className="breadcrumb-list mb-3">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/home">
                                            <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                            </Link>
                                        </li>
                                            <li className="breadcrumb-item">
                                                    <Link to="/home" style={{textDecoration:'none'}}>{t('Avoirs')}</Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page"> {t('Liste')} {t('Avoirs')}</li>
                                            </ol>
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                           <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>                                            
                                         </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                        <SortingTable
                                               data={currentItems.map((avoir) => ({
                                                id:avoir.id,
                                                Avoir:avoir.code,
                                                Facture: avoir.facture_code,
                                                Date: avoir.date_avoir,
                                                actions: (
                                                    <div className="text-center">
                                                        <Link
                                                                to={`https://admin.mansoft.ma/api/avoirs/pdf/${avoir.id}`}
                                                                title="Consulter"
                                                                data-toggle="tooltip"
                                                                className="btn btn-danger-rgba btn-round"
                                                                style={{ marginRight: '10px' }}
                                                                target="_blank"
                                                            >
                                                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "18px" }} />
                                                            </Link>
                                            </div>
                                            ),
                                        }))}
                                    columns={columns}
                                    />
                                    <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <select
                                        className="form-control"
                                        id="items-per-page"
                                        onChange={handleItemsPerPageChange}
                                        value={itemsPerPage}
                                            >
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            </select>
                                        </div>
                                        <Pagination
                                                items={filteredavoirs} 
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageChange}
                                       />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            
        </>
    );
}

export default ListerAvoirs;
