import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";

function ModifierCharge() {
  const { id } = useParams();
  const [magasins, setMagasins] = useState([]);
  const [nomMagasin, setNomMagasin] = useState("");
  const [typeCharge, setTypeCharge] = useState("");
  const [date, setDate] = useState("");
  const [montant, setMontant] = useState("");
  const [description, setDescription] = useState("");
  const [typesCharge, setTypesCharge] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMagasins = async () => {
      try {
        const response = await axios.get("/magasins");
        setMagasins(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des magasins :", error);
      }
    };

    const fetchTypes = async () => {
      try {
        const response = await axios.get("/type-charges");
        setTypesCharge(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des types de charge :", error);
      }
    };

    fetchMagasins();
    fetchTypes();
  }, []);

  useEffect(() => {
    const fetchCharge = async () => {
      try {
        const response = await axios.get(`/charges/${id}`);
        const charge = response.data;
        console.log("dfgh",charge)
        setNomMagasin(charge.id_magasin);
        setTypeCharge(charge.id_type_charge);
        setDate(charge.date);
        setMontant(charge.montant);
        setDescription(charge.description);
      } catch (error) {
        console.error("Erreur lors de la récupération de la charge :", error);
      }
    };

    fetchCharge();
  }, [id]); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      id_magasin: nomMagasin,
      id_type_charge: typeCharge,
      date: date,
      montant: montant,
      description: description,
    };

    try {
      await axios.put(`/charges/${id}`, formData); 
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Charge mise à jour avec succès!",
      });
      navigate("/charges");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.response?.data?.message || "Une erreur est survenue",
      });
    }
  };

  return (
    
        <div className="breadcrumbbar">
          <div className="row">
            <div className="col-lg-12 m-b-50">
              <div className="card m-b-50">
                <div className="card-header">
                  <h5 className="card-title">Modifier la charge</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Magasin</label>
                        <select
                          className="form-select"
                          value={nomMagasin}
                          onChange={(e) => setNomMagasin(e.target.value)}
                        >
                          <option value="">Choisir un magasin</option>
                          {magasins.map((magasin) => (
                            <option key={magasin.id} value={magasin.id}>
                              {magasin.nom_magasin}
                            </option>
                          ))}
                        </select>
                      </div>
                     <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Type de charge</label>
                        <select
                          className="form-select"
                          value={typeCharge}
                          onChange={(e) => setTypeCharge(e.target.value)}
                        >
                          <option value="">Choisir un type de charge</option>
                          {typesCharge.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.type_charge}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Montant</label>
                        <input
                          type="number"
                          name="montant"
                          className="form-control"
                          value={montant}
                          onChange={(e) => setMontant(e.target.value)}
                        />
                      </div>

                      <div className=" col-md-12 d-flex align-items-center mb-3">
                        <label className="me-0 col-md-2">Description</label>
                        <textarea
                          className="form-control"
                          rows="1"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Link className="btn btn-primary-rgba my-1" to="/charges">
                        Annuler
                      </Link>
                      <button className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
     
  );
}

export default ModifierCharge;
