import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import Search from "../../layouts/Search";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';

// Fonction pour récupérer les commandes
const fetchCommandes = async () => {
    const response = await axios.get("/commandeFr");
    const commandes = response.data;
    const commandesWithData = await Promise.all(commandes.map(async commande => {
        const clientResponse = await axios.get(`/fournisseurs/${commande.id_fournisseur}`);
        const clientData = clientResponse.data;
        return {
            ...commande,
            fournisseur: clientData.nom_fournisseur,
            societe: clientData.societe
        };
    }));
    return commandesWithData;
};

function ListerCommandeFr() {

    const {t} =useTranslation();

    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [commandeIdToDelete, seCommandeIdToDelete] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredcommandes, setFilteredcommandes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { data: commandes, error, isLoading } = useQuery({
        queryKey: ['commandes'],
        queryFn: fetchCommandes,
        staleTime: 60000, 
    });

    useEffect(() => {
        if (commandes) {
            setFilteredcommandes(commandes);
        }
    }, [commandes]);
    const handleSearchChange = (term) => {
        setSearchTerm(term);
    
        if (commandes) {
            const filtered = commandes.filter((commande) => {
                return columns.some((col) => {
                    if (col.sortable && typeof commande[col.key] === 'string') {
                        return commande[col.key].toLowerCase().includes(term.toLowerCase());
                    }
                    return false;
                });
            });
    
            setFilteredcommandes(filtered);
        }
    };
    
    useEffect(() => {
        if (commandes) {
            const filtered = commandes.filter((commande) => {
                return columns.some((col) => {
                    if (typeof commande[col.key] === 'string') {
                        return commande[col.key].toLowerCase().includes(searchTerm.toLowerCase());
                    }
                    return false;
                });
            });
    
            setFilteredcommandes(filtered);
            setCurrentPage(0);
        }
    }, [searchTerm, commandes]);
    

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredcommandes.slice(offset, offset + itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const openDelete = (Id) => {
        seCommandeIdToDelete(Id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        seCommandeIdToDelete(null);
    };

    const fetchCommandeDetails = async (id) => {
        try {
            const allLignesCommandeResponse = await axios.get(`/ligne-commande-fors?id_commande_fr=${id}`);
            const allLignesCommandeData = allLignesCommandeResponse.data;
            return allLignesCommandeData.filter(ligne => ligne.id_commande_fr === id).map(ligne => ligne.id);
        } catch (error) {
            console.error('Error fetching commande details:', error);
            throw error;
        }
    };

    const handleDeleteCommande = async () => {
        if (commandeIdToDelete === undefined || commandeIdToDelete === null) {
            console.error("No commande ID to delete");
            return;
        }

        try {
            const lignesCommandeIds = await fetchCommandeDetails(commandeIdToDelete);

            await Promise.all(lignesCommandeIds.map(async ligneId => {
                await axios.delete(`/ligne-commande-fors/${ligneId}`);
            }));

            await axios.delete(`/commandeFr/${commandeIdToDelete}`);

            setFilteredcommandes(filteredcommandes.filter(commande => commande.id !== commandeIdToDelete));
            setShowConfirmBox(false);
        } catch (error) {
            console.error("Error deleting commande:", error);
        }
    };

    const columns = [
        { key: 'commande', label: 'commande', sortable: true },
        { key: 'fournisseur', label: 'fournisseur', sortable: false },
        { key: 'societe', label: 'societe', sortable: true },
        { key: 'date', label: 'date', sortable: true },
        { key: 'actions', label: 'actions', sortable: false },
    ];

    // if (isLoading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="row align-items-center">
                            <div className="col-md-8 col-lg-8">
                                <h4 className="page-title">{t('Commandes Fournisseurs')}</h4>
                                <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Fournisseurs')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('Liste')} {t(' Commandes Fournisseurs')}</li>
                                </ol>
                                </div>
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                       <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/commande-fournisseur" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                            <div className="table-responsive">
                                                <SortingTable
                                                    data={currentItems.map((commande) => ({
                                                        id: commande.id,
                                                        commande: commande.code,
                                                        fournisseur: commande.fournisseur,
                                                        societe: commande.societe,
                                                        date: commande.date_commande,
                                                        actions: (
                                                            <div className="text-center">
                                                                <Link to={`/modifier-commande-fournisseur/${commande.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip" style={{ marginLeft: "10px" }}>
                                                                    <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                                </Link>
                                                                <Link onClick={() => openDelete(commande.id)} className="btn btn-danger-rgba btn-round mb-1" style={{ marginLeft: "10px" }}>
                                                                    <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                                </Link>
                                                                <Link to={`/fournisseur/creer-bon-entree/${commande.id}`} className="btn btn-primary-rgba btn-round mb-1" title="ajouter bon entree" style={{ marginLeft: "10px" }}>
                                                                    <i className="feather icon-log-in" style={{ fontSize: "small" }}></i>
                                                                </Link>
                                                                <Link to={`http://127.0.0.1:8000/api/commandeFr/pdf/${commande.id}`} title="Consulter" data-toggle="tooltip" className="btn btn-danger-rgba btn-round " 
                                                                style={{ marginLeft: "10px" }}  target="_blank">
                                                                    <i className="fa fa-file-pdf-o" style={{ fontSize: "small" }}></i>
                                                                </Link>
                                                            </div>
                                                        ),
                                                    }))}
                                                    columns={columns}
                                                />
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div>
                                                        <select
                                                            className="form-control"
                                                            id="items-per-page"
                                                            onChange={handleItemsPerPageChange}
                                                            value={itemsPerPage}
                                                        >
                                                            <option value={2}>2</option>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                        </select>
                                                    </div>
                                                    <Pagination
                                                        items={filteredcommandes}
                                                        itemsPerPage={itemsPerPage}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                            )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
           </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="Commande fournisseur"
                        deleteFunction={handleDeleteCommande}
                    />
               
        </>
    );
}

export default ListerCommandeFr;
