import React from 'react';

function Lightbox({ isOpen, onClose, imageUrl }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}> 
        <img src={imageUrl} alt="Lightbox" style={{ width: "80%" }} />
        <button onClick={onClose}>Fermer</button> 
      </div>
    </div>
  );
}

export default Lightbox;
