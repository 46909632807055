import React, { useState, useEffect, useMemo } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Lightbox from "../../layouts/Lightbox";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import Search from "../../layouts/Search";
import { useTranslation } from "react-i18next";

function ListeUtilisateur() {
    const {t}=useTranslation();
    const [users, setUsers] = useState([]);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [UserIdToDelete, setUserIdToDelete] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredusers, setFilteredusers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = useMemo(() => [
        { key: 'photo', label: 'Photo', sortable: false },
        { key: 'nom_complet', label: 'Nom Complet', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'magasin', label: 'Magasin', sortable: true },
        { key: 'telephone', label: 'Téléphone', sortable: true },
        { key: 'CIN', label: 'CIN', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
    ], []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get("/users");
            return response.data;
        } catch (error) {
            console.error("Erreur lors de la récupération des utilisateurs:", error);
            return [];
        }
    };

    const fetchPersonnelData = async (personnelId) => {
        try {
            const response = await axios.get(`/personnel/${personnelId}`);
            return response.data;
        } catch (error) {
            console.error(`Erreur lors de la récupération des données du personnel pour l'ID ${personnelId}:`, error);
            return null;
        }
    };

    const fetchUserMagasinData = async (userId) => {
        try {
            const response = await axios.get(`/utilisateur-magasin/${userId}`);
            return response.data;
        } catch (error) {
            console.error(`Erreur lors de la récupération des magasins pour l'utilisateur ID ${userId}:`, error);
            return [];
        }
    };

    const fetchMagasinData = async (magasinId) => {
        try {
            const response = await axios.get(`/magasins?id=${magasinId}`);
            const magasinData = response.data;
            return magasinData.find(m => m.id === magasinId);
        } catch (error) {
            console.error(`Erreur lors de la récupération des données du magasin pour l'ID ${magasinId}:`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const users = await fetchUsers();

            const usersWithPersonnelAndMagasinData = await Promise.all(users.map(async user => {
                const personnelData = await fetchPersonnelData(user.personnel_id);
                const userMagasinData = await fetchUserMagasinData(user.id);
                let magasin = '';

                if (userMagasinData.length > 0) {
                    const magasinData = await fetchMagasinData(userMagasinData[0].id_magasin);
                    magasin = magasinData ? magasinData.nom_magasin : '';
                }

                return {
                    ...user,
                    personnel: personnelData || {}, 
                    magasin: magasin
                };
            }));

            setUsers(usersWithPersonnelAndMagasinData);
        };

        fetchData();
    }, []);

    const openDelete = (userId) => {
        setUserIdToDelete(userId);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setUserIdToDelete(null);
    };

    const handleDeleteUser = async () => {
        try {
            await axios.delete(`/users/${UserIdToDelete}`);
            setUsers(users.filter(user => user.id !== UserIdToDelete));
            setShowConfirmBox(false);
        } catch (error) {
            console.error("Erreur lors de la suppression de l'utilisateur :", error);
        }
    };

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredusers.slice(offset, offset + itemsPerPage);

    const openLightbox = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        const filtered = users.filter((user) => {
            return columns.some((col) => {
                if (typeof user[col.key] === 'string') {
                    return user[col.key].toLowerCase().includes(searchTerm.toLowerCase());
                }
                if (col.key === 'nom_complet') {
                    return user.personnel.nom_complet.toLowerCase().includes(searchTerm.toLowerCase());
                }
                if (col.key === 'telephone') {
                    return user.personnel.telephone.toLowerCase().includes(searchTerm.toLowerCase());
                }
                if (col.key === 'CIN') {
                    return user.personnel.CIN.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });
        });

        setFilteredusers(filtered);
        setCurrentPage(0);
    }, [searchTerm, users, columns]);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    return (
        <>
           
                    <div className="breadcrumbbar">
                        <div className="row align-items-center">
                            <h4 className="page-title">{t('Utilisateurs')}</h4>
                            <div className="breadcrumb-list mb-3">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/home">
                                            <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                            </Link>
                                        </li>
                                            <li className="breadcrumb-item">
                                                    <Link to="/home" style={{textDecoration:'none'}}>{t('Utilisateurs')}</Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page"> {t('Liste')} {t('Utilisateurs')}</li>
                                            </ol>
                                    </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                    <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajoute-utilisateur" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                   </div>  
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((user) => ({
                                                    key :user.id,
                                                    id: user.id,
                                                    photo: (
                                                        <img
                                                            src={`https://admin.mansoft.ma/${user.personnel.photo}`}
                                                            alt={user.personnel.nom_complet}
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                                borderRadius: '50%',
                                                                overflow: 'hidden',
                                                            }}
                                                            onClick={() => openLightbox(`https://admin.mansoft.ma/${user.personnel.photo}`)}
                                                        />
                                                    ),
                                                    nom_complet: user.personnel.nom_complet,
                                                    role: user.role,
                                                    magasin: user.magasin,
                                                    telephone: user.personnel.telephone,
                                                    CIN: user.personnel.CIN,
                                                    actions: (
                                                        <div className="text-center">
                                                            <Link to={`/modifier-utilisateur/${user.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(user.id)} className="btn btn-danger-rgba btn-round" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredusers}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                            <Lightbox
                                                isOpen={lightboxOpen}
                                                onClose={closeLightbox}
                                                imageUrl={currentImageUrl}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="utilisateur"
                        deleteFunction={handleDeleteUser}
                    />
                
        </>
    );
}

export default ListeUtilisateur;
