import React, { useState, useTransition } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate,Link } from 'react-router-dom';
import { useOfflineOperations } from '../../layouts/useOfflineOperations'; 
import { useTranslation } from "react-i18next";

function AjouterMagasin() {
    const {t} =useTranslation();
    const navigate = useNavigate();
    const [nomMagasin, setNomMagasin] = useState("");
    const [adresseMagasin, setAdresseMagasin] = useState("");
    const [telephone, setTelephone] = useState("");
    const [ville, setVille] = useState("");
    const [depot, setDepot] = useState(false); 
    const { saveOperationOffline } = useOfflineOperations(); 
    const [error,setError]=useState("");

    const handleChange = (e) => {
        const value = e.target.value;
        setTelephone(value);
    
        const phonePattern = /^[0-9\s()+-]*$/; 
        if (!phonePattern.test(value)) {
          setError('Veuillez entrer un numéro de téléphone valide.');
        } else {
          setError('');
        }
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData ={
            nom_magasin: nomMagasin,
            adresse_magasin: adresseMagasin,
            telephone: telephone,
            ville: ville,
            depot: depot
        }
        try {
            if(navigator.onLine){
            const response = await axios.post("/magasins", formData);
            if (response.status === 201) {
                Swal.fire({
                    title: "Succès",
                    text: "Le magasin a été ajouté avec succès",
                    icon: "success",
                });
                navigate('/magasins');
            }
            
            else {
                Swal.fire({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de l'ajout du magasin",
                    icon: "error",
                });
            }
        } else{
            saveOperationOffline({
                type: 'CREATE',
                endpoint: '/magasins', 
                data: formData, 
              });
              Swal.fire({
                icon: "success",
                title: "Succès",
                text: "magasins sauvegardé localement ! Il sera envoyé lorsque vous serez en ligne.",
              });
        }

        } catch (error) {
            console.error("Erreur lors de l'ajout du magasin :", error);
            Swal.fire({
                title: "Erreur",
                text: "Une erreur s'est produite lors de l'ajout du magasin",
                icon: "error"
            });
        }
    };

    const handleRadioChange = (event) => {
        setDepot(event.target.checked);
    };


    return (
        <>
		   <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header">
                                <h5 className="card-title">Ajouter magasin</h5>
                            </div>
                           <div className="card-body">
                                <form className="needs-validation" onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6 mb-3 d-flex align-items-center">
                                                <label className="me-2 col-md-4" htmlFor="nomMagasin">Nom de Magasin</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="nomMagasin"
                                                    name="nom_magasin"
                                                    value={nomMagasin}
                                                    onChange={(e) => setNomMagasin(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3 d-flex align-items-center">
                                                <label className="me-2 col-md-4" >Adresse</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="adresseMagasin"
                                                    name="adresse_magasin"
                                                    value={adresseMagasin}
                                                    onChange={(e) => setAdresseMagasin(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                         <div className="col-md-6 mb-3 d-flex align-items-center">
                                            <label className="me-2 col-md-4" htmlFor="telephone">Téléphone</label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                id="telephone"
                                                name="telephone"
                                                value={telephone}
                                                onChange={handleChange}
                                                required
                                            />
                                            </div>

                                            <div className="col-md-6 mb-3 d-flex align-items-center">
                                                <label className="me-2 col-md-4" >Ville</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="ville"
                                                    name="ville"
                                                    value={ville}
                                                    onChange={(e) => setVille(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        {error && <span className="text-danger mt-2 d-block">{error}</span>}

                                        <div className="form-row">
                                            <div className="form-check col-6 d-flex d-md-block flex-row-reverse flex-md-row align-items-center">
                                                <label className="me-4 col-md-4" >Dépôt</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="depotCheckbox"
                                                    checked={depot}
                                                    onChange={handleRadioChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-warning my-1" to="/magasins">Annuler</Link>
                                            <button className="btn btn-success my-1" type="submit">Ajouter</button>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

  );
}

export default AjouterMagasin;
