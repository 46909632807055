import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import Swal from "sweetalert2";
import { TailSpin } from 'react-loader-spinner';
import { useTranslation } from "react-i18next";
import {fetchBonRetour} from '../../services/apiService';


function ListerBonR() {
    const {t}= useTranslation();
    const { data: bonretour = [], isLoading, error } = useQuery('bonretour', fetchBonRetour);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [BRIdToDelete, seBRIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredbonretour, setFilteredbonretour] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(2);

   // Ensure that useEffect runs only when `bonretour` changes
useEffect(() => {
    setFilteredbonretour(bonretour);
}, [bonretour]);

// Handle Search Change
const handleSearchChange = (term) => {
    setSearchTerm(term);

    // Filter the data based on search term
    const filtered = bonretour.filter((commande) => {
        return Object.keys(commande).some((key) => {
            if (typeof commande[key] === 'string') {
                return commande[key].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
    });

    setFilteredbonretour(filtered);
};

// Ensure Pagination and Items Per Page changes are managed properly
const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
};

const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page on items per page change
};


    const openDelete = (Id) => {
        seBRIdToDelete(Id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        seBRIdToDelete(null);
    };

    const handleDeleteBR = async () => {
        if (BRIdToDelete === undefined || BRIdToDelete === null) {
            console.error("No bon de retour ID to delete");
            return;
        }

        try {
            const lignesCommandeResponse = await axios.get(`/article-commandes?id_commande_cl=${BRIdToDelete}`);
            const lignesCommandeIds = lignesCommandeResponse.data.map(ligne => ligne.id);

            await Promise.all(lignesCommandeIds.map(async ligneId => {
                await axios.delete(`/article-commandes/${ligneId}`);
            }));

            await axios.delete(`/commandeclient/${BRIdToDelete}`);

            setFilteredbonretour(filteredbonretour.filter(bonretour => bonretour.id !== BRIdToDelete));
            
            setShowConfirmBox(false);

            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Bon de retour supprimé avec succès!',
            });

        } catch (error) {
            console.error("Error deleting bon de retour:", error);

            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'La suppression du bon de retour a échoué. Veuillez réessayer.',
            });
        }
    };

    // Sorting
    const columns = [
        { key: 'Code', label: t('Code'), sortable: true },
        { key: 'Client', label: t('Client'), sortable: false },
        { key: 'Personnel', label: t('Personnel'), sortable: true },
        { key: 'Magasin', label: t('Magasin'), sortable: true },
        { key: 'Montant', label: t('Montant'), sortable: true },
        { key: 'actions', label: t('Actions'), sortable: false }
      ];
    
    // Search logic
  
    const offset = currentPage * itemsPerPage;
    const currentItems = filteredbonretour.slice(offset, offset + itemsPerPage);

  


    return (
        <>
           <div className="breadcrumbbar">   
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('Bons de retours de clients')} </h4>
                            <div className="breadcrumb-list mb-3">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                 </li>
                                <li className="breadcrumb-item">
                                    <Link to="/home" style={{textDecoration:'none'}}>{t('clients')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('List Bons de retours')}</li>
                            </ol>
                        </div>
                        </div>             
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">                                
                                        <div className="row align-items-center">
                                        <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((bonretour) => ({
                                                    id: bonretour.id,
                                                    Code: bonretour.code,
                                                    Client: bonretour.client,
                                                    Date: bonretour.date_br,
                                                    Personnel: bonretour.personnel,
                                                    Magasin: bonretour.magasin,
                                                    Montant:bonretour.montant,

                                                    actions: (
                                                        <div className="text-center">
                                                            <Link to={`/clients/modifer-bon-retour/${bonretour.id}`} className="btn btn-success-rgba btn-round mb-1 " title="Edit"  style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(bonretour.id)} className="btn btn-danger-rgba btn-round" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredbonretour}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                         )}
                                         </>
                                     )}
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
          
                <ConfirmBox
                    open={showConfirmBox}
                    closeDialog={closeConfirmBox}
                    title="Bon retour Client"
                    deleteFunction={handleDeleteBR}
                />
            
        </>
    );
}

export default ListerBonR;
