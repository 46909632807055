import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchForCommandes } from '../../../services/fournisseurService'; 
import * as XLSX from 'xlsx';

const BC = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data: ligneCommandes, error: ligneCommandesError, isLoading: ligneCommandesLoading } = useQuery('ligneCommandes', fetchForCommandes); // Update query key and function

  const filteredData = React.useMemo(() => {
    if (!ligneCommandes) return [];

    const filteredLigneCommandes = ligneCommandes.filter(cmd => {
      const createdAt = new Date(cmd.created_at);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || createdAt >= start) && (!end || createdAt <= end);
    });

    const monthlyTotals = filteredLigneCommandes.reduce((acc, cmd) => {
      const month = new Date(cmd.created_at).toISOString().slice(0, 7); 
      if (!acc[month]) {
        acc[month] = 0;
      }
      acc[month] += cmd.quantite * cmd.pu_vente; 
      return acc;
    }, {});

    return Object.entries(monthlyTotals).map(([month, totalAmount]) => ({
      month,
      totalAmount
    }));
  }, [ligneCommandes, startDate, endDate]);

  const noData = filteredData.length === 0;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Commande_Fournisseur_par_Mois');
    XLSX.writeFile(workbook, 'Commande_Fournisseur_par_Mois.xlsx');
  };

  if (ligneCommandesLoading) return <p>Loading...</p>;
  if (ligneCommandesError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
    <div className="row mb-4 align-items-center">
      <div className="col-md-5 col-sm-12 mb-4">
        <label className="form-label">{t('Date de début')} :</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-md-5 col-sm-12 mb-4">
        <label className="form-label">{t('Date de fin')} :</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-md-2 col-sm-12 d-flex justify-content-end">
        <Link className="btn btn-primary" onClick={exportToExcel}>
          <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
        </Link>
      </div>
    </div>
    {noData ? (
      <div className="m-alert m-alert--outline alert alert-dismissible fade show alert-warning">
        {t('Aucune donnée disponible pour la période sélectionnée')}
      </div>
    ) : (
      <div className='table-responsive'>
          <table className="table table-striped table-bordered w-100 mt-4  centered-table">
            <thead>
              <tr>
                <th>{t('Mois')}</th>
                <th>{t('Montant Total')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(({ month, totalAmount }) => (
                <tr key={month}>
                  <td>{month}</td>
                  <td>{totalAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                </tr>
               ))}
               </tbody>
             </table>
             </div>
           )}
         </div>
       );
     };
export default BC;
