import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import axios from '../../../api/axios';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const fetchFactures = async () => {
  const response = await axios.get('/factures');
  return response.data;
};

const fetchAvoirs = async () => {
  const response = await axios.get('/avoirs');
  return response.data;
};

const Facture = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const chartRef = useRef(null);
  const [data, setData] = useState({ labels: [], datasets: [] });

  const { data: Factures, error: FacturesError, isLoading: FacturesLoading } = useQuery('Factures', fetchFactures);
  const { data: Avoirs, error: AvoirsError, isLoading: AvoirsLoading } = useQuery('Avoirs', fetchAvoirs);

  useEffect(() => {
    if (Factures && Avoirs) {
      updateChartData(Factures, Avoirs);
    }
  }, [Factures, Avoirs]);

  useEffect(() => {
    if (Factures && Avoirs) {
      const filtered = filterData(Factures, Avoirs, startDate, endDate);
      setFilteredData(filtered);
    }
  }, [startDate, endDate, Factures, Avoirs]);

  const extractMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}`;
  };

  const aggregateDataByMonth = (data) => {
    return data.reduce((acc, item) => {
      const monthYear = extractMonthYear(item.created_at);
      if (!acc[monthYear]) {
        acc[monthYear] = 0;
      }
      acc[monthYear] += item.montant;
      return acc;
    }, {});
  };

  const updateChartData = (Factures, Avoirs) => {
    const FacturesDataByMonth = aggregateDataByMonth(Factures);
    const AvoirsDataByMonth = aggregateDataByMonth(Avoirs);

    const months = [...new Set([...Object.keys(FacturesDataByMonth), ...Object.keys(AvoirsDataByMonth)])];
    const facturesAmounts = months.map(month => FacturesDataByMonth[month] || 0);
    const avoirsAmounts = months.map(month => AvoirsDataByMonth[month] || 0);

    setData({
      labels: months,
      datasets: [
        {
          label: 'Factures',
          data: facturesAmounts,
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: false,
        },
        {
          label: 'Avoirs',
          data: avoirsAmounts,
          borderColor: '#FF6384',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          fill: false,
        },
      ],
    });
  };

  const filterData = (Factures, Avoirs, startDate, endDate) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filteredFactures = Factures.filter(facture => {
      const factureDate = new Date(facture.created_at);
      return (!start || factureDate >= start) && (!end || factureDate <= end);
    });

    const filteredAvoirs = Avoirs.filter(avoir => {
      const avoirDate = new Date(avoir.created_at);
      return (!start || avoirDate >= start) && (!end || avoirDate <= end);
    });

    const months = [...new Set([...filteredFactures.map(facture => extractMonthYear(facture.created_at)), ...filteredAvoirs.map(avoir => extractMonthYear(avoir.created_at))])];

    const aggregatedFactures = aggregateFilteredData(filteredFactures, months);
    const aggregatedAvoirs = aggregateFilteredData(filteredAvoirs, months);

    return months.map(month => ({
      mois: month,
      facture: Number(aggregatedFactures[month]) || 0,
      avoir: Number(aggregatedAvoirs[month]) || 0,
      totalNet: (Number(aggregatedFactures[month]) || 0) - (Number(aggregatedAvoirs[month]) || 0),
    }));
  };

  const aggregateFilteredData = (data, months) => {
    return data.reduce((acc, item) => {
      const monthYear = extractMonthYear(item.created_at);
      if (!acc[monthYear]) {
        acc[monthYear] = 0;
      }
      acc[monthYear] += item.montant;
      return acc;
    }, {});
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mois',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Montant',
        },
      },
    },
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Facture');
    XLSX.writeFile(workbook, 'Facture.xlsx');
  };

  if (FacturesLoading || AvoirsLoading) return <p>Loading...</p>;
  if (FacturesError || AvoirsError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="row mb-4 align-items-center">
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de début')} :</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="form-control"
          />
        </div>
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de fin')} :</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="form-control"
          />
        </div>
        <div className="col-md-2 col-sm-12 d-flex justify-content-end">
          <Link className="btn btn-primary" onClick={exportToExcel}>
            <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
          </Link>
        </div>
      </div>
      <div className="chart-container" style={{ width: '80%', height: '300px' }}>
        <Line data={data} options={options} ref={chartRef} />
      </div>
      <div className="table-responsive mt-4">
        <table className="table table-striped table-bordered w-100 mt-4">
          <thead>
            <tr>
              <th>{t('Mois')}</th>
              <th>{t('Factures')}</th>
              <th>{t('Avoirs')}</th>
              <th>{t('Total Net')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row.mois}</td>
                <td>{row.facture.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                <td>{row.avoir.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                <td>{row.totalNet.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Facture;
