import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function AjouterPaiPer() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [personnel, setPersonnel] = useState({});
    const [frequence_paiement, setfrequence_paiement] = useState("");
    const [montant_paiement, setmontant_paiement] = useState("");
    const [date_paiement, setdate_paiement] = useState("");

    useEffect(() => {
        const fetchPersonnel = async () => {
            try {
                const response = await axios.get(`/personnel/${id}`);
                setPersonnel(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations du personnel :", error);
            }
        };

        fetchPersonnel();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const paiementData = {
            personnel_id: id,
            frequence_paiement,
            montant_paiement,
            date_paiement
        };

        try {
            await axios.post('/paiementPersonnel', paiementData);
            Swal.fire({
                icon: 'success',
                title: 'Paiement ajouté avec succès!',
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/paiement/personnel");
        } catch (error) {
            console.error("Erreur lors de l'ajout du paiement :", error);
        }
    };

    return (
        <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter un paiement personnel</h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Nom & Prenom</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={personnel.nom_complet || ""}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Responsabilité</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={personnel.responsabilite || ""}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Contact</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={personnel.telephone || ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Fréquence :</label>
                                                <select
                                                    className="form-control"
                                                    value={frequence_paiement}
                                                    onChange={(e) => setfrequence_paiement(e.target.value)}
                                                >
                                                    <option value=""></option>
                                                    <option value="jour">Par Jour</option>
                                                    <option value="quinzaine">Par 15 Jours</option>
                                                    <option value="mois">Par Mois</option>
                                                </select>
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">montant </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={montant_paiement}
                                                    onChange={(e) => setmontant_paiement(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">date_paiement </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date_paiement}
                                                    onChange={(e) => setdate_paiement(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/paiement/personnel">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1" type="submit">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
    );
}

export default AjouterPaiPer;
