import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function ModifierUtilisateur() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [personnels, setPersonnels] = useState([]);
    const [magasins, setMagasins] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        role: "",
        password: "",
        password_confirmation: "", 
        id_magasin: "",
        id_personnel: ""
    });

    useEffect(() => {
        
        const fetchUserData = async () => {
            const magasinsResponse = await axios.get("/magasins");
            setMagasins(magasinsResponse.data);

            const persResponse = await axios.get("/personnel");
            setPersonnels(persResponse.data);

            try {
                const response = await axios.get(`/users/${id}`);
                const userData = response.data;
                setFormData(prevData => ({
                    ...prevData,
                    name: userData.name || "",
                    email: userData.email || "",
                    role: userData.role || "",
                    id_personnel: userData.personnel_id || "",
                }));

                const utilisateurMagasinResponse = await axios.get(`/utilisateur-magasin/user/${userData.id}`);
                const utilisateurMagasinData = utilisateurMagasinResponse.data[0];
                if (utilisateurMagasinData && utilisateurMagasinData.id_magasin) {
                    setFormData(prevData => ({
                        ...prevData,
                        id_magasin: utilisateurMagasinData.id_magasin
                    }));
                }
             }catch (error) {
                console.error("Erreur lors de la récupération des données utilisateur:", error);
             }
        };
        fetchUserData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.password_confirmation) {
            Swal.fire("Error", "Les mots de passe ne correspondent pas", "error");
            return;
        }
        try {
            const userData = { ...formData, personnel_id: formData.id_personnel };
            await axios.put(`/users/${id}`, userData);
    
            const utilisateurMagasinResponse = await axios.get(`/utilisateur-magasin/user/${id}`);
            const utilisateurMagasinData = utilisateurMagasinResponse.data[0];
    
            if (utilisateurMagasinData) {
                await axios.put(`/utilisateur-magasin/${utilisateurMagasinData.id}`, { id_magasin: formData.id_magasin });
            } else {
                await axios.post(`/utilisateur-magasin`, { id_utilisateur: id, id_magasin: formData.id_magasin });
            }
    
            console.log("magasin after", formData.id_magasin);
    
            Swal.fire("Success", "Utilisateur modifié avec succès", "success");
            navigate("/utilisateurs");
        } catch (error) {
            console.error("Erreur lors de la modification de l'utilisateur:", error);
            Swal.fire("Error", "Une erreur s'est produite lors de la modification de l'utilisateur", "error");
        }
    };
    
    

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
};

 return (
        <>
           <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Modifier un utilisateur</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} className="needs-validation">
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Personnel</label>
                                                    <select
                                                        className="form-control"
                                                        name="id_personnel"
                                                        value={formData.id_personnel}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=""></option>
                                                        {personnels.map(personnel => (
                                                            <option key={personnel.id} value={personnel.id}>
                                                                {personnel.nom_complet}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Magasin</label>
                                                    <select
                                                        className="form-control"
                                                        name="id_magasin"
                                                        value={formData.id_magasin}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=""></option>
                                                        {magasins.map(magasin => (
                                                            <option key={magasin.id} value={magasin.id}>
                                                                {magasin.nom_magasin}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Nom utilisateur</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Nouveau password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Confirmation</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password_confirmation"
                                                        value={formData.password_confirmation}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Role</label>
                                                    <select
                                                        className="form-control"
                                                        name="role"
                                                        value={formData.role}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=""></option>
                                                        <option value="chef personnel">chef personnel</option>
                                                        <option value="Super Admin">Super Admin</option>
                                                        <option value="Comptable admin">Comptable admin</option>
                                                        <option value="Controleur">Controleur</option>
                                                        <option value="livraison admin">Livraison admin</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-primary-rgba my-1" to="/utilisateurs">Annuler</Link>
                                                <button type="submit" className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        </>
    );
}

export default ModifierUtilisateur;
