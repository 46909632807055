import $ from 'jquery';

const dropdownMenu = (triggerSelector, menuSelector) => {
    const showMenu = (e) => {
        const $menu = $(menuSelector);

        if ($menu.is(':visible')) {
            $menu.hide();
        } else {
            $('.dropdown-menu:visible').hide();
            $menu.show();
        }

        e.stopPropagation();
    };

    const hideMenu = () => {
        $(menuSelector).hide();
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    $(document).on('click', triggerSelector, showMenu);
    $(document).on('click', hideMenu);
    $(document).on('click', menuSelector, stopPropagation);

    return () => {
        $(document).off('click', triggerSelector, showMenu);
        $(document).off('click', hideMenu);
        $(document).off('click', menuSelector, stopPropagation);
    };
};

export default dropdownMenu;
