import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchFournisseurs, fetchBonEntree, fetchLigneBonEntree } from '../../../services/fournisseurService';
import * as XLSX from 'xlsx';

const AchatParFournisseur = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data: fournisseurs, error: fournisseursError, isLoading: fournisseursLoading } = useQuery('fournisseurs', fetchFournisseurs);
  const { data: bonEntree, error: bonEntreeError, isLoading: bonEntreeLoading } = useQuery('bonEntree', fetchBonEntree);
  const { data: ligneBonEntree, error: ligneBonEntreeError, isLoading: ligneBonEntreeLoading } = useQuery('ligneBonEntree', fetchLigneBonEntree);

  const filteredData = React.useMemo(() => {
    if (!bonEntree || !ligneBonEntree || !fournisseurs) return [];

    const filteredBonEntrees = bonEntree.filter(be => {
      const createdAt = new Date(be.created_at);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || createdAt >= start) && (!end || createdAt <= end);
    });

    const fournisseurMap = fournisseurs.reduce((acc, fournisseur) => {
      acc[fournisseur.id] = fournisseur.nom_fournisseur;
      return acc;
    }, {});

    const ligneBonEntreeMap = ligneBonEntree.reduce((acc, lbe) => {
      if (!acc[lbe.bon_entree_id]) {
        acc[lbe.bon_entree_id] = [];
      }
      acc[lbe.bon_entree_id].push(lbe);
      return acc;
    }, {});

    const fournisseurTotals = filteredBonEntrees.reduce((acc, be) => {
      const fournisseurId = be.id_fournisseur;
      if (!acc[fournisseurId]) {
        acc[fournisseurId] = { totalQuantite: 0, totalMontant: 0 };
      }

      const lignes = ligneBonEntreeMap[be.id] || [];
      lignes.forEach(ligne => {
        acc[fournisseurId].totalQuantite += ligne.quantite;
        acc[fournisseurId].totalMontant += ligne.quantite * ligne.montant;
      });

      return acc;
    }, {});

    return Object.entries(fournisseurTotals).map(([fournisseurId, totals]) => ({
      fournisseur: fournisseurMap[fournisseurId],
      totalQuantite: totals.totalQuantite,
      totalMontant: totals.totalMontant,
    }));
  }, [bonEntree, ligneBonEntree, fournisseurs, startDate, endDate]);

  const noData = filteredData.length === 0;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Achat_par_fournisseur');
    XLSX.writeFile(workbook, 'Achat_par_fournisseur.xlsx');
  };

  if (fournisseursLoading || bonEntreeLoading || ligneBonEntreeLoading) return <p>Loading...</p>;
  if (fournisseursError || bonEntreeError || ligneBonEntreeError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="row mb-4 align-items-center">
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de début')} :</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de fin')} :</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-2 col-sm-12 d-flex justify-content-end">
          <Link className="btn btn-primary" onClick={exportToExcel}>
            <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
          </Link>
        </div>
      </div>
      {noData ? (
        <div className="m-alert m-alert--outline alert alert-dismissible fade show alert-warning">
          {t('Aucune donnée disponible pour la période sélectionnée')}
        </div>
      ) : (
        <div className='table-responsive'>
        <table className="table table-striped table-bordered w-100 mt-4  centered-table">
          <thead>
            <tr>
              <th>{t('Fournisseurs')}</th>
              <th>{t('Quantité Totale')}</th>
              <th>{t('Montant Total')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(({ fournisseur, totalQuantite, totalMontant }) => (
              <tr key={fournisseur}>
                <td>{fournisseur}</td>
                <td>{totalQuantite}</td>
                <td>{totalMontant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
  );
};

export default AchatParFournisseur;
