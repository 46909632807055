import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";

function ListerPaimentPer() {
    const {t}= useTranslation();
    const [paiements, setPaiements] = useState([]);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [paiementIdToDelete, setPaiementIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredpaiements, setFilteredpaiements] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage,setItemsPerPage] = useState(1);
 
    //sorting
    const columns = [
        { key: 'personnel', label: 'personnel', sortable: true },
        { key: 'montant', label: 'montant', sortable: true },
        { key: 'mode_paiement', label: 'mode_paiement', sortable: false },
        { key: 'date_paiement', label: 'date_paiement', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
    ];

   //logique recherche 
   const handleSearchChange = (term) => {
    setSearchTerm(term);

    const filtered = paiements.filter((commande) => {
        return Object.keys(commande).some((key) => {
            if (typeof commande[key] === 'string') {
                return commande[key].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
    });

    setFilteredpaiements(filtered);
};

useEffect(() => {
    setFilteredpaiements(paiements); 
}, [paiements]);

    //pagination debut
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex); 
      };
      
      const offset = currentPage * itemsPerPage; 
      const currentItems = filteredpaiements.slice(offset, offset + itemsPerPage); 
      console.log('Nombre de lignes affichées:', currentItems.length);
    
    //end pagination
     //temsPerPage 
     const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value)); 
        setCurrentPage(0); 
      };

    useEffect(() => {
        const fetchPaiements = async () => {
            try {
                const response = await axios.get('/paiementPersonnel');
                const paiementsData = response.data;
    
                const fetchPersonnelPromises = paiementsData.map(paiement => fetchPersonnelName(paiement.personnel_id));
                const personnelNames = await Promise.all(fetchPersonnelPromises);
    
                const updatedPaiements = paiementsData.map((paiement, index) => ({
                    ...paiement,
                    nom_complet: personnelNames[index]
                }));
                setPaiements(updatedPaiements);
            } catch (error) {
                console.error("Erreur lors de la récupération des paiements:", error);
            }
        };
    
        fetchPaiements();
    }, []);
    
    const fetchPersonnelName = async (personnelId) => {
        try {
            const response = await axios.get(`/personnel/${personnelId}`);
            return response.data.nom_complet;
        } catch (error) {
            console.error("Erreur lors de la récupération du nom complet du personnel:", error);
            return '';
        }
    };

    const openDelete = (id) => {
        setPaiementIdToDelete(id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setPaiementIdToDelete(null);
    };

    const handleDeletepaiement = async () => {
        if (!paiementIdToDelete) {
            console.error("No paiement ID to delete");
            return;
        }
    
        try {
            await axios.delete(`/paiementPersonnel/${paiementIdToDelete}`);
            
            const updatedPaiements = paiements.filter(paiement => paiement.id !== paiementIdToDelete);
            setPaiements(updatedPaiements);
    
            const updatedFilteredPaiements = filteredpaiements.filter(paiement => paiement.id !== paiementIdToDelete);
            setFilteredpaiements(updatedFilteredPaiements);
    
            setShowConfirmBox(false);
            setPaiementIdToDelete(null);
        } catch (error) {
            console.error("Error deleting paiement:", error);
        }
    };
    

    return (
        <>
          <div className="breadcrumbbar">
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('Liste paiement personnel')}</h4>
                            <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Paiment')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('paiement personnel')}</li>
                                </ol>
                    </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                    <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>                                        
                                     <div className="col-6 d-md-flex justify-content-md-end">
                                       
                                    </div>
                                    </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">

                                        <SortingTable
                                       data={currentItems.map((paiement) => ({
                                        id:paiement.id,
                                        personnel:paiement.nom_complet,
                                        montant: Number(paiement.montant_paiement).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                                        mode_paiement: paiement.frequence_paiement,
                                        date_paiement: paiement.date_paiement,
                                        actions: (
                                            <div className="text-center">
                                                   
                                                    <Link  to={`/modifier-paiement-personnel/${paiement.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit"  style={{ marginRight: '10px' }}>
                                                        <i className="feather icon-edit-2" style={{fontSize: "small"}}></i>
                                                    </Link>
                                                    <Link onClick={() => openDelete(paiement.id)}  className="btn btn-danger-rgba btn-round"style={{ marginRight: '10px' }}>
                                                        <i className="feather icon-trash" style={{fontSize: "small"}} ></i>
                                                    </Link>
                                            </div>
                                            ),
                                        }))}
                                    columns={columns}
                                    />
                                    <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <select
                                        className="form-control"
                                        id="items-per-page"
                                        onChange={handleItemsPerPageChange}
                                        value={itemsPerPage}
                                            >
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            </select>
                                        </div>
                                        <Pagination
                                                items={filteredpaiements} 
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageChange}
                                        />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="Confirmation de suppression"
                        deleteFunction={handleDeletepaiement}
                    />
                </div>
           
        </>
    );
}

export default ListerPaimentPer;
