import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from '../../../api/axios';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

const Agenda = () => {
  const { t } = useTranslation();
  const [selectedMainCase, setSelectedMainCase] = useState('Agenda');
  const [selectedSubCaseIndex, setSelectedSubCaseIndex] = useState(0);
  const [personnelId, setPersonnelId] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [durationHours, setDurationHours] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');
  const [message, setMessage] = useState('');
  const [personnels, setPersonnels] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const mainCases = [
    { id: 'Agenda', label: 'Agenda' },
  ];

  const subCases = {
    Agenda: [
      { label: 'Mes Tâches', path: 'taches' },
      { label: 'Gérer mes tâches', path: 'gerer-taches' },
    ],
  };

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const mainCaseId = Object.keys(subCases).find((key) =>
      subCases[key].some((subCase) => subCase.path === path)
    );
    if (mainCaseId) {
      setSelectedMainCase(mainCaseId);
      setSelectedSubCaseIndex(
        subCases[mainCaseId].findIndex((subCase) => subCase.path === path)
      );
    }
  }, [location]);

  const handleMainCaseClick = (id) => {
    if (id !== selectedMainCase) {
      setSelectedMainCase(id);
      setSelectedSubCaseIndex(0);
      navigate(`/agenda/${subCases[id][0].path}`);
    }
  };

  const handlePreviousSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex - 1 + subCaseList.length) % subCaseList.length;
      navigate(`/agenda/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const handleNextSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex + 1) % subCaseList.length;
      navigate(`/agenda/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const selectedSubCase = subCases[selectedMainCase]?.[selectedSubCaseIndex];

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/taches', {
        personnel_id: personnelId,
        titre: title,
        date: date,
        duree: `${durationHours}h${durationMinutes}m`
      });

      setMessage('Tâche ajoutée avec succès');
      setPersonnelId('');
      setTitle('');
      setDate('');
      setDurationMinutes('');
      setDurationHours('');
    } catch (error) {
      setMessage('Erreur lors de l\'ajout de la tâche');
    }
  };

  const fetchPersonnels = async () => {
    try {
      const response = await axios.get('/personnel');
      setPersonnels(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des personnes:', error);
    }
  };

  useEffect(() => {
    fetchPersonnels();
  }, []);

  return (
    <>
      <div className="breadcrumbbar">
        <div className="row align-items-center mb-5">
          <div className="col-md-8 col-lg-12 d-md-flex align-items-center justify-content-between mb-3">
            <div className="breadcrumb-list mb-3">
              <ol className="breadcrumb d-flex align-items-center">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <i className="fa fa-home img-fluid" style={{ fontSize: "15px", width: "20px", marginRight: "3px" }}></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/home" style={{ textDecoration: 'none' }}>{t('Configuration')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">{t('Agenda')}</li>
              </ol>
            </div>
            <div className="widgetbar">
              <Link className="btn btn-danger-rgba mr-2" onClick={handlePreviousSubCase}>
                <i className="fa fa-chevron-left"></i>
              </Link>
              <Link className="btn btn-success-rgba mr-2" onClick={handleNextSubCase}>
                <i className="fa fa-chevron-right"></i>
              </Link>
              {selectedSubCase?.label === 'Mes Tâches' && (
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  <i className="feather icon-plus mr-2"></i>{t('Ajouter une tâche')}
                </Button>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="content-container">
              <div className="right-panel">
                <div className="subcase-container m-b-50">
                  <div className="card m-b-50">
                    <div className="card-header text-center mt-2">
                      <h6>{mainCases.find((mc) => mc.id === selectedMainCase)?.label} {'  -->  '} {selectedSubCase?.label}</h6>
                    </div>
                    <div className="card-body text-center chart-container" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Nouveau tâche')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formPersonnel">
              <Form.Label>
                {t('Personnel')} 
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                as="select"
                required
                name="departmentid"
                value={personnelId}
                onChange={(e) => setPersonnelId(e.target.value)}
              >
                <option value="">---</option>
                {personnels.map(personnel => (
                  <option key={personnel.id} value={personnel.id}>
                    {personnel.nom_complet}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>
                {t('Titre')}
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDate">
              <Form.Label>
                {t('Date')}
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDuration">
              <Form.Label>
                Durée
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="heures"
                  value={durationHours}
                  onChange={(e) => setDurationHours(e.target.value)}
                  placeholder="Heures"
                  required
                />
                <InputGroup.Text>h</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="minutes"
                  value={durationMinutes}
                  onChange={(e) => setDurationMinutes(e.target.value)}
                  placeholder="Minutes"
                  step="1"
                  required
                />
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {message && <div className="alert alert-info">{message}</div>}
            <Modal.Footer>
              <Button variant="success" type="submit">{t('Enregistrer')}</Button>
              <Button variant="danger" onClick={() => setShowModal(false)}>{t('Fermer')}</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Agenda;
