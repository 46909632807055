import React, { useState, useEffect,useRef } from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Quagga from 'quagga';


function AjouterBonEntr() {
   
    const { id } = useParams();
    const navigate = useNavigate();
  
    const [fournisseurs, setFournisseurs] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedFournisseur, setSelectedFournisseur] = useState("");
    const [dateEntree, setDateEntree] = useState("");
    const [description, setDescription] = useState("");
    const [ categories,setCategories] = useState([]);
    const [selectedCode, setSelectedCode] = useState("");
    const [article_commandes, setArticleCommande] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(""); 
    const [price, setPrice] = useState(""); 
    const [qte, setQte] = useState("");
    const [choixMode, setChoixMode] = useState("manuel");
    const scannerRef = useRef(null);
    const [selectedArticleId, setSelectedArticleId] = useState("");
    const [selectedArticleName, setSelectedArticleName] = useState("");
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredArticles, setFilteredArticles] = useState([]);


    const handleDropdownClick = () => {
      setDropdownOpen(!dropdownOpen);
    };
    const handleSearchChange = (e) => {
      const searchTerm = e.target.value.toLowerCase();
      setSearchTerm(searchTerm);
  
      const filtered = articles.filter(article =>
        article.nom_article.toLowerCase().includes(searchTerm)
      );
      setFilteredArticles(filtered);
    };
    const handleSelectArticle = (id, nom) => {
      console.log("Article sélectionné :", id, nom);
      setSelectedArticleId(id);
      setSelectedArticleName(nom);
      setDropdownOpen(false);
    };
    

    /*   scanner */
    useEffect(() => {
      if (choixMode === "scanner" && scannerRef.current) {
        
        const isMobile = window.innerWidth < 768;
      
        const width = isMobile ? 440 : 440;  
        const height = isMobile ? 230 : 230;
       
        Quagga.init(
          {
            inputStream: {
              type: "LiveStream",
              target: scannerRef.current,
              constraints: {
                width: width,
                height: height,
              },
            },
            locator: {
              patchSize: 'medium',
              halfSample: true,
            },
            locate: true,
            decoder: {
              readers: ["ean_reader", "code_128_reader"],
            },
          },
          (err) => {
            if (err) {
              console.error("Erreur lors de l'initialisation de Quagga:", err);
              return;
            }
            console.log("Quagga initialisé avec succès.");
            Quagga.start();
          }
        );
    
        Quagga.onDetected((data) => {
          if (data && data.codeResult && data.codeResult.code) {
            handleScanResult(data.codeResult.code);
          } else {
            console.error("Données de détection invalides :", data);
          }
        });
    
      return () => {
        if (typeof Quagga !== "undefined" && typeof Quagga.stop === "function") {
          Quagga.stop();  
          console.log("Quagga stopped.");
        }
      };
      }
    }, [choixMode, scannerRef])
    const handleScanResult = (scanResult) => {
      console.log("Scanned Code:", scanResult); // Log the scanned code
    
      const foundArticle = articles.find(
        (article) => article.code_barre === scanResult
      );
    
      if (foundArticle) {
        Swal.fire({
          icon: "success",
          title: "Article scanné",
          text: `L'article "${foundArticle.nom_article}" a été scanné avec succès.`,
        });
    
        setSelectedArticleId(foundArticle.id);
        setSelectedArticleName(foundArticle.nom_article);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Article non trouvé",
          text: "L'article scanné n'existe pas dans la base de données.",
        });
      }
    };

    useEffect(() => {
      const fetchFournisseurs = async () => {
        try {
          const response = await axios.get("/fournisseurs");
          setFournisseurs(response.data);
        } catch (error) {
          console.error("Erreur lors du chargement des fournisseurs :", error);
        }
      };
  
      const fetchCategories = async () => {
        try {
          const response = await axios.get("/categories");
          setCategories(response.data.categories);
        } catch (error) {
          console.error("Erreur lors du chargement des catégories :", error);
        }
      };
  
      
        const fetchArticles = async () => {
          try {
            const response = await axios.get("/articles");
            if (response && response.data) {
              setArticles(response.data);
              setFilteredArticles(response.data);
            } else {
              console.error("Réponse API invalide pour les articles :", response);
            }
          } catch (error) {
            console.error("Erreur lors de la récupération des articles :", error);
          }
        }
  
      
      fetchFournisseurs();
      fetchCategories();
      fetchArticles();
    }, [id,setCategories]); 
   
    useEffect(() => {
      const fetchCommandeFrDetails = async () => {
        try {
          const commandeFrResponse = await axios.get(`/commandeFr/${id}`);
          
          // Vérifier si la commande fournisseur existe
          if (commandeFrResponse.data) {
            const { code, id_fournisseur } = commandeFrResponse.data;
      
            setSelectedCode(code); 
            setSelectedFournisseur(id_fournisseur);
      
            const ligneCommandeFrResponse = await axios.get(`/ligne-commande-fors?id_commande_fr=${id}`);
            const commandeFr = ligneCommandeFrResponse.data;
            
            const articlesResponse = await axios.get("/articles");
            const allArticles = articlesResponse.data;
      
            const articlesDevis = commandeFr.map(ligne => {
              const article = allArticles.find(a => a.id === ligne.id_article);
              return {
                id: ligne.id_article,
                nom_article: article.nom_article,
                quantity: ligne.quantite,
                price: ligne.pu_vente,
              };
            });
      
            setArticleCommande(articlesDevis);
          } else {
            console.log("La commande fournisseur n'existe pas.");
          }
      
        } catch (error) {
          console.error("Erreur lors du chargement des détails de la commande fournisseur :", error);
        }
      };
    
      fetchCommandeFrDetails();
    }, [id]);
    
    
    
    const handleRowChange = (field, value) => {
      switch (field) {
        case 'selectedArticle':
          setSelectedArticle(value);
          break;
        case 'price':
          setPrice(value);
          break;
        case 'quantity':
          setQte(value);
          break;
        default:
          console.error("Field not recognized");
          break;
      }
    };
    
const handleAddRow = () => {
  if (!selectedArticle || !qte || !price) {
    console.error("Tous les champs doivent être remplis pour ajouter un article");
    return;
  }

  const articleExist = article_commandes.some(
    (article) => article.id === selectedArticle
  );

  if (articleExist) {
    console.error("Cet article a déjà été ajouté");
    return;
  }

  const selectedArticleObj = articles.find(
    (article) => article.id === parseInt(selectedArticle, 10)
  );

  if (!selectedArticleObj) {
    console.error("L'article sélectionné n'a pas été trouvé");
    return;
  }

  const newCommande = {
    id: selectedArticle,
    nom_article: selectedArticleObj.nom_article,
    quantity: parseFloat(qte), 
    price: parseFloat(price),
  };

  setArticleCommande([...article_commandes, newCommande]);

  setSelectedArticle("");
  setPrice("");
  setQte("");
};

    
const handleSubmit = async (e) => {
  e.preventDefault();

  try {
      const requestData = {
          id_fournisseur: selectedFournisseur,
          date_entree: dateEntree,
          description: description,
      };

      if (id) {
          requestData.id_cf = id;
      }
       console.log("orjfr",requestData);
      const bonEResponse = await axios.post("/bon-entrees", requestData);

      if (bonEResponse.status === 201) {
          const bonEResponseId = bonEResponse.data.id;

          await Promise.all(
              article_commandes.map(async (row) => {
                  await axios.post("/ligne-bon-entree-fr", {
                      bon_entree_id: bonEResponseId,
                      id_article: row.id,
                      quantite: row.quantity,
                      montant: row.price * row.quantity,
                  });
              })
          );

          handleSuccess();
      } else {
          handleError();
      }
  } catch (error) {
      handleError(error);
  }
};

   const handleSuccess = () => {
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Bon d\'entrée ajouté avec succès!',
      });
      navigate('/fournisseur/bon-entree');
    };
  
    const handleError = (error) => {
      console.error("Erreur lors de l'ajout du bon d'entrée :", error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Échec de l'ajout du bon d'entrée. Veuillez réessayer plus tard.",
      });
    };
  

  
    const deleteArticle = (index) => {
      const updatedRows = [...article_commandes];
      updatedRows.splice(index, 1);
      setArticleCommande(updatedRows);
    };
  
  
    return (
        <>
		
         <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter bon d'entree de fournisseur</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                            <div className="form-row">
                                                  <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-4 col-md-4">Fournisseur</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedFournisseur}
                                                        onChange={(e) => setSelectedFournisseur(e.target.value)} 
                                                        >
                                                        <option key="" value=""></option>
                                                        {fournisseurs.map((fournisseur) => (
                                                            <option key={fournisseur.id} value={fournisseur.id}>
                                                            {fournisseur.nom_fournisseur}
                                                            </option>
                                                        ))}
                                                        </select>
                                                  </div>
                                                  <div className="col-md-4 d-flex align-items-center mb-3">
                                                        <label className="me-2 col-md-4">Date</label>
                                                        <input
                                                          type="date"
                                                            className="form-control"
                                                            value={dateEntree}
                                                           onChange={(e) => setDateEntree(e.target.value)}
                                                        />
                                                  </div>
                                                    
                                                   <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-6">Code Commande</label>
                                                    <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={selectedCode}
                                                    readOnly
                                                    />
                                                 </div>
                                                </div>
                                                <div className="form-row">
                                                
                                                    <React.Fragment>
                                                    <div className="col-md-4 mb-3 d-flex align-items-center">
                                                      <label className="me-2 col-md-4">Mode</label>
                                                      <select
                                                        className="form-select"
                                                        value={choixMode}
                                                        onChange={(e) => setChoixMode(e.target.value)}
                                                      >
                                                        <option value="manuel">Choix manuel</option>
                                                        <option value="scanner">Scan automatique</option>
                                                      </select>
                                                    </div>
                                                    <div className="col-md-4 mb-3 d-flex align-items-center">
                                                      <label className="me-2 col-md-4">Article</label>
                                                      <div className="custom-select-wrapper" ref={dropdownRef}>
                                                        <div className="form-control" onClick={handleDropdownClick}>
                                                          {selectedArticleName ? selectedArticleName : ""}
                                                        </div>
                                                        {dropdownOpen && (
                                                          <div className="autocom-box">
                                                            <input
                                                              type="search"
                                                              aria-label="Search"
                                                              className="search-box form-control"
                                                              placeholder="Rechercher..."
                                                              value={searchTerm}
                                                              onChange={handleSearchChange}
                                                              onClick={() => setDropdownOpen(true)}
                                                            />
                                                            <ul>
                                                              {filteredArticles.map((article) => (
                                                                <li
                                                                  key={article.id}
                                                                  value={article.id}
                                                                  onClick={() => handleSelectArticle(article.id, article.nom_article)}
                                                                >
                                                                  {article.nom_article}
                                                                </li>
                                                              ))}
                                                            </ul>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div  className="col-md-4 d-flex align-items-center mb-3">
                                                        <label className="me-2 col-md-4">Quantité</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={qte}
                                                            onChange={(e) => handleRowChange( 'quantity', e.target.value)}
                                                            min={0}
                                                    />
                                                        
                                                    </div>
                                                    <div  className="col-md-4 d-flex align-items-center mb-3">
                                                        <label className="me-2 col-md-6">Prix</label>
                                                        <input
                                                        type="number"
                                                        className="form-control form-control-solid"
                                                        value={price}
                                                        onChange={(e) => handleRowChange('price', e.target.value)}
                                                        />
                                                    </div>
                                                    
                                            </React.Fragment>
                                                <div className="col-md-12 mb-3">
                                                    <div className="row g-3">
                                                        <div className="col-md-4 offset-md-8 text-end ">
                                                            <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                                          </div>
                                                    </div>
                                                </div>
                                                                
                                                </div>
                                                <table className="display table table-striped table-bordered">
                                                  <thead>
                                                    <tr>
                                                      <th>Article</th>
                                                      <th>Quantité</th>
                                                      <th>P.U</th>
                                                      <th>Total</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {article_commandes.map((commande, index) => (
                                                      <tr key={index}>
                                                        <td>{commande.nom_article}</td>
                                                        <td>{commande.quantity}</td>
                                                        <td>{commande.price}</td>
                                                        <td>{commande.quantity * commande.price}</td>
                                                        <td className="d-md-flex justify-content-md-end">
                                                          <i
                                                            className="feather icon-trash btn btn-danger-rgba btn-round"
                                                            style={{ fontSize: "small" }}
                                                            onClick={() => deleteArticle(index)}
                                                          ></i>
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>


                                        
                                        <div className="form-row">
                                            <div className=" col-md-12 d-flex align-items-center mb-3">

                                            <label className="me-0 col-md-2">Description</label>
                                                <textarea className="form-control " rows="1" name="description" 
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}

                                                >

                                                </textarea>
                                            </div>
                                        </div>
                                     
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/fournisseur/bon-entree">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
       </div>


</>

    );
}

export default AjouterBonEntr;
