import React, { useState, useEffect,useRef } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function ModifierInventaire() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [articles, setArticles] = useState([]);
  const [magasins, setMagasins] = useState([]);
  const [selectedMagasin, setSelectedMagasin] = useState("");
  const [dateInventaire, setDateInventaire] = useState("");
  const [description, setDescription] = useState("");
  const [articleCommandes, setArticleCommande] = useState([]);
  const [quantite, setQuantite] = useState("");
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState("");
  const [selectedArticleName, setSelectedArticleName] = useState("");

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get("/articles");
        setArticles(response.data); 
        setFilteredArticles(response.data); 

      } catch (error) {
        console.error("Erreur lors de la récupération des articles :", error);
      }
    };
  
    const fetchMagasins = async () => {
      try {
        const response = await axios.get("/magasins");
        setMagasins(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des magasins :", error);
      }
    };
  
    fetchArticles(); 
    fetchMagasins();
  }, []); 
  
  useEffect(() => {
    const fetchInventaire = async () => {
      try {
        const inventaireResponse = await axios.get(`/inventaires/${id}`); 
        const inventaire = inventaireResponse.data;
  
        setDateInventaire(inventaire.date);
        setDescription(inventaire.observation);
        setSelectedMagasin(inventaire.magasin_id);
  
        const articlesResponse = await axios.get(`/inventaire-articles?inventaire_id=${id}`);
        const articleCommandesWithNames = articlesResponse.data.map((articleCommande) => {
          const article = articles.find((a) => a.id === articleCommande.article_id);
          return {
            ...articleCommande,
            nom_article: article ? article.nom_article : "Inconnu",
          };
        });
  
        setArticleCommande(articleCommandesWithNames);
  
      } catch (error) {
        console.error("Erreur lors de la récupération de l'inventaire :", error);
      }
    };
  
    if (articles.length > 0) { 
      fetchInventaire();
    }
  }, [id, articles]); 
  

  const handleMagasinChange = (e) => {
    const magasinId = e.target.value;
    setSelectedMagasin(magasinId); 
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectArticle = (articleId, ArticleName) => {
    setSelectedArticleId(articleId);
    setSelectedArticleName(ArticleName);
    setSearchTerm(ArticleName);
    setDropdownOpen(false);

  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleAddRow = () => {
    if (!selectedArticleId || !quantite) {
      console.error("Tous les champs doivent être remplis");
      return;
    }
  
    const selectedArticleObj = articles.find(
      (article) => article.id === parseInt(selectedArticleId, 10)
    );
  
    if (!selectedArticleObj) {
      console.error("L'article sélectionné n'a pas été trouvé");
      return;
    }
  
    const newArticleCommande = {
      id_article: selectedArticleId,
      nom_article: selectedArticleObj.nom_article,
      quantite: quantite,
    };
  
    setArticleCommande([...articleCommandes, newArticleCommande]);
    setSelectedArticleId("");
    setQuantite("");
  };
  
  const deleteArticle = async (index) => {
    const articleToDelete = articleCommandes[index]; 
    const confirmation = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Non, annuler",
    });
  
    if (confirmation.isConfirmed) {
      try {
        if (articleToDelete.id) {
          await axios.delete(`/inventaire-articles/${articleToDelete.id}`);
        }
  
        const updatedRows = [...articleCommandes];
        updatedRows.splice(index, 1); 
        setArticleCommande(updatedRows);
  
        Swal.fire({
          icon: "success",
          title: "Supprimé!",
          text: "L'article a été supprimé avec succès.",
        });
      } catch (error) {
        console.error("Erreur lors de la suppression de l'article :", error);
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Échec de la suppression de l'article. Veuillez réessayer plus tard.",
        });
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault(); 
  
    try {
      const inventaireResponse = await axios.put(`/inventaires/${id}`, {
        date: dateInventaire,
        observation: description,
        magasin_id: selectedMagasin,
      });
  
      if (inventaireResponse.status === 200) {
        await Promise.all(
          articleCommandes.map(async (commande) => {
            if (commande.id) {
              await axios.put(`/inventaire-articles/${commande.id}`, {
                inventaire_id: inventaireResponse.data.id,
                article_id: commande.id_article,
                quantite: commande.quantite,
              });
            } else {
              await axios.post("/inventaire-articles", {
                inventaire_id: inventaireResponse.data.id,
                article_id: commande.id_article,
                quantite: commande.quantite,
              });
            }
          })
        );
  
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Inventaire mis à jour avec succès!",
        });
        navigate("/inventaires");
      } else {
        handleError(); 
      }
    } catch (error) {
      handleError(error); 
    }
  };
  
  const handleError = (error) => {
    console.error("Erreur lors de la mise à jour de l'inventaire :", error);
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Échec de la mise à jour de l'inventaire. Veuillez réessayer plus tard.",
    });
  };
  
  return (
    
        <div className="breadcrumbbar">
          <div className="row">
            <div className="col-lg-12 m-b-50">
              <div className="card m-b-50">
                <div className="card-header">
                  <h5 className="card-title">Modifier Inventaire</h5>
                </div>
                <div className="card-body">
                  <form className="needs-validation" onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-4 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={dateInventaire}
                          onChange={(e) => setDateInventaire(e.target.value)}
                        />
                      </div>

                      <div className="col-md-4 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Magasin</label>
                        <select
                          className="form-select"
                          value={selectedMagasin} 
                          onChange={handleMagasinChange}
                        >
                          <option value=""></option>
                          {magasins.map((magasin) => (
                            <option key={magasin.id} value={magasin.id}>
                              {magasin.nom_magasin}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-4 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Description</label>
                        <textarea
                          className="form-control"
                          rows="1"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                    <div className="col-md-4 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Article</label>
                         <div className="custom-select-wrapper" ref={dropdownRef}>
                          <div className="form-control" onClick={handleDropdownClick}>
                            {selectedArticleName ? selectedArticleName : ""}
                          </div>
                        {dropdownOpen && (
                          <div className="autocom-box">
                            <input
                              type="search"
                              aria-label="Search"
                              className="search-box form-control"
                              placeholder="Rechercher..."
                              value={searchTerm}
                              onChange={handleSearchChange}
                              onClick={() => setDropdownOpen(true)}
                            />
                            <ul>
                              {filteredArticles.map((article) => (
                                <li
                                  key={article.id} value={article.id}
                                  onClick={() => handleSelectArticle(article.id, article.nom_article)}
                                >
                                {article.nom_article}                                   
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                     </div>
                    <div className="col-md-4 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Quantité</label>
                        <input
                        type="number"
                        className="form-control"
                        value={quantite}
                        onChange={(e) => setQuantite(e.target.value)}
                        min={1}
                        />
                    </div>

                    <div className="col-md-4 d-flex justify-content-end align-items-center mb-3">
                        <i
                        className="feather icon-plus-circle btn btn-primary-rgba"
                        onClick={handleAddRow}
                        style={{ cursor: 'pointer' }}
                        ></i>
                    </div>
                    </div>

                    <table className="display table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Article</th>
                          <th>Quantité</th>
                          <th className="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {articleCommandes.map((commande, index) => (
                          <tr key={index}>
                            <td>{commande.nom_article}</td>
                            <td>
                            {commande.quantite}
                            </td>
                            <td className="text-end">
                              <i
                                className="feather icon-trash btn btn-danger-rgba btn-round"
                                style={{ fontSize: 'small' }}
                                onClick={() => deleteArticle(index)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Link className="btn btn-primary-rgba" to="/inventaires">
                        Annuler
                      </Link>
                      <button className="btn btn-primary-rgba" type="submit">
                      <i className="feather icon-save mr-2"></i>

                        Mettre à jour
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      
  );
}

export default ModifierInventaire;
