import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/Logo_mansoft.png';
import crmIcon from '../assets/images/svg-icon/customers.svg';
import frIcon from '../assets/images/svg-icon/crm.svg';
import ecommerceIcon from '../assets/images/svg-icon/ecommerce.svg';
import magasinIcon from '../assets/images/svg-icon/frontend.svg';
import dashIcon from '../assets/images/svg-icon/dashboard.svg';
import settingIcon from '../assets/images/svg-icon/settings.svg';
import reportIcon from '../assets/images/svg-icon/pages.svg';
import '../assets/js/vertical-menu';
import $ from 'jquery';
import { useAuth } from '../../src/layouts/AuthContext';
import logOut from '../assets/images/svg-icon/logout.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faCircleDollarToSlot, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'; 

const SideBar  = React.memo(() => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('article'); 
    const { logout } = useAuth();


    useEffect(() => {
       
        $.sidebarMenu($('.vertical-menu'));

        return () => {
            $('.vertical-menu').off('click', 'li > a, li > Link'); 
        };
    }, []);

    useEffect(() => {
        if (location.pathname.includes('/infos-societe') || location.pathname.includes('/verrouiller-acces')) {
            setActiveTab('Parametre');
        } else if (location.pathname.includes('/rapport')) {
            setActiveTab('rapport');
        } else if (location.pathname.includes('/profil')) {
            setActiveTab('profile');
        } else {
            setActiveTab('article');
        }
    }, [location]);
    const handleLogout = () => {
        logout();
        window.location.href = "/";
      };
    

    return (
        <div className="leftbar">
            <div className="sidebar">
                <div className="navigationbar">
                    <div className="vertical-menu-icon">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="logobar"/>
                            <Link 
                                className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                to="#v-pills-profile"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected={activeTab === 'profile'}
                                onClick={() => setActiveTab('profile')}
                            >
                                <img 
                                    src={crmIcon} 
                                    className="img-fluid" 
                                    alt="Profile"  
                                    title="Profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                />
                            </Link>
                            <Link
                                className={`nav-link ${activeTab === 'article' ? 'active' : ''}`}
                                id="v-pills-article-tab"
                                data-bs-toggle="pill"
                                to="#v-pills-article"
                                role="tab"
                                aria-controls="v-pills-article"
                                aria-selected={activeTab === 'article'}
                                onClick={() => setActiveTab('article')}
                            >
                                <img
                                    src={ecommerceIcon}
                                    className="img-fluid"
                                    alt="Article"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Article"
                                />
                            </Link>
                            <Link
                                className={`nav-link ${activeTab === 'Parametre' ? 'active' : ''}`}
                                id="v-pills-parametre-tab" 
                                data-bs-toggle="pill" 
                                to="#v-pills-parametre"
                                role="tab" 
                                aria-controls="v-pills-parametre" 
                                aria-selected={activeTab === 'Parametre'}
                                onClick={() => setActiveTab('Parametre')}
                            >
                              <img src={settingIcon} 
                               className="img-fluid"
                               alt="Parametre" 
                               data-bs-toggle="tooltip" 
                               data-bs-placement="top" 
                               title="Parametre" />
                            </Link>
                            <Link  
                                className={`nav-link ${activeTab === 'rapport' ? 'active' : ''}`} 
                                id="v-pills-rapport-tab" 
                                data-bs-toggle="pill" 
                                to="#v-pills-rapport" 
                                role="tab" 
                                aria-controls="v-pills-rapport" 
                                aria-selected={activeTab === 'rapport'}
                                onClick={() => setActiveTab('rapport')}
                            >
                                <img src={reportIcon}
                                className="img-fluid" 
                                alt="rapport"
                                data-bs-toggle="tooltip" 
                                data-bs-placement="top" 
                                title="rapports" 
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="vertical-menu-detail">
                        <div className="logobar">
                            <Link to="#" className="logo logo-large"><img src={logo} alt="logo" /></Link>
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className={`tab-pane fade ${activeTab === 'article' ? 'show active' : ''}`} id="v-pills-article" role="tabpanel" aria-labelledby="v-pills-article-tab">
                            <ul className="vertical-menu">
                                            <li>
                                                <Link to="/home">
                                                    <img src={dashIcon} className="img-fluid" alt="frontend" />Dashboard
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <img src={ecommerceIcon} className="img-fluid" alt="frontend" />Articles<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/articles">Articles</Link></li>
                                                    <li><Link to="/articles/categorie">Categorie</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/magasins">
                                                    <img src={magasinIcon} className="img-fluid" alt="frontend" />Magasins<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/magasins">Magasins</Link></li>
                                                    <li><Link to="/personnels">Personnels</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/clients">
                                                    <img src={crmIcon} className="img-fluid" alt="frontend" />Clients<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/clients">Clients</Link></li>
                                                    <li><Link to="/clients/commandes">Bons Commandes</Link></li>
                                                    <li><Link to="/clients/bon-livraison">Bons Livraisons</Link></li>
                                                    <li><Link to="/clients/bon-retour">Bons Retours</Link></li>
                                                    <li><Link to="/clients/devis">Devis</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/fournisseurs">
                                                    <img src={frIcon} className="img-fluid" alt="frontend" />Fournisseurs<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/fournisseurs">Fournisseurs</Link></li>
                                                    <li><Link to="/commande-fournisseur/commandes">Bons Commandes</Link></li>
                                                    <li><Link to="/fournisseur/bon-entree">Bons d'Entrees</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link>
                                                    <i className="feather icon-credit-card" />Paiments<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/paiements-clients">Paiment Client</Link></li>
                                                    <li><Link to="/paiements-fournisseurs">Paiment Fournisseur</Link></li>
                                                    <li><Link to="/paiement/personnel">Paiment Personnel</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/articles">
                                                    <FontAwesomeIcon icon={faCircleDollarToSlot} style={{ fontSize: "18px", width: "20px", marginRight: "10px" }} />Charges<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/charges">Charges</Link></li>
                                                    <li><Link to="/types-charges">Types de charges</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/articles">
                                                    <FontAwesomeIcon icon={faCalculator} style={{ fontSize: "19px", width: "20px", marginRight: "7px" }} /> Inventaires<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/inventaires">Inventaires</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="feather icon-file-text"></i>Factures<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/factures">Les factures clients</Link></li>
                                                    <li><Link to="/avoirs">Avoirs</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/articles">
                                                    <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ fontSize: "19px", width: "20px", marginRight: "7px" }} />Versements<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/liste-versement">Versements</Link></li>
                                                </ul>
                                            </li>
                                           <li>
                                                <Link to="/articles">
                                                    <i className="feather icon-users" alt="frontend" /> Utilisateurs<i className="feather icon-chevron-right"></i>
                                                </Link>
                                                <ul className="vertical-submenu">
                                                    <li><Link to="/utilisateurs">Utilisateurs</Link></li>
                                                </ul>
                                            </li>
                                </ul>                            
                            </div>

                            <div className={`tab-pane fade ${activeTab === 'Parametre' ? 'show active' : ''}`} id="v-pills-parametre" role="tabpanel" aria-labelledby="v-pills-parametre-tab">
                               <ul className="vertical-menu">
                                        <li>
                                            <Link to="/home">
                                                <img src={dashIcon} className="img-fluid" alt="frontend" />Dashboard
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/infos-societe">
                                                <img src={settingIcon} alt="" className="img-fluid" title="Parametre" />Infos Sociéte
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/verrouiller-acces">
                                           <i className="feather icon-log-in small-icon" ></i>Verouiller accées
                                            </Link>
                                        </li>
                                       
                                         <li>
                                            <Link to="">
                                            <i className="feather icon-calendar small-icon" ></i> Agenda<i className="feather icon-chevron-right"></i>
                                            </Link>
                                            <ul className="vertical-submenu">
                                                <li><Link to="/agenda/taches">Mes taches</Link></li>
                                                <li><Link to="/agenda/gerer-taches">Gérer mes taches</Link></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="">
                                            <i className="feather icon-file-text small-icon" ></i>Facture<i className="feather icon-chevron-right"></i>
                                            </Link>
                                            <ul className="vertical-submenu">
                                                <li><Link to="/PersonaliserFacture/enTete">Entête</Link></li>
                                                <li><Link to="/PersonaliserFacture/enPied">En pied</Link></li>
                                            </ul>
                                        </li>
                                </ul>                            
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'rapport' ? 'show active' : ''}`} id="v-pills-rapport" role="tabpanel" aria-labelledby="v-pills-rapport-tab">
                               <ul className="vertical-menu">
                                    <li>
                                    <Link to="/home">
                                        <img src={dashIcon} className="img-fluid" alt="frontend" />Dashboard
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="">
                                    <i className="feather icon-file-text small-icon " />Compte<i className="feather icon-chevron-right"></i>
                                    </Link>
                                    <ul className="vertical-submenu">
                                        <li><Link to="/rapport/comptes-par-type">Comptes par type</Link></li>
                                        <li><Link to="/rapport/ca-par-client">CA par client</Link></li>
                                        <li><Link to="/rapport/ca-par-categorie">CA par catégorie</Link></li>
                                        <li><Link to="/rapport/ca-par-ville">CA par ville</Link></li>
                                        <li><Link to="/rapport/evolution-des-comptes">Évolution des comptes</Link></li>
                                    </ul>
                                    </li>
                                    <li>
                                    <Link to="">
                                        <i className="feather icon-layout small-icon " />
                                        Catalogue<i className="feather icon-chevron-right"></i>
                                    </Link>
                                    <ul className="vertical-submenu">
                                        <li><Link to="/rapport/article-par-fournisseur">Article par Fournisseur</Link></li>
                                        <li><Link to="/rapport/article-par-categorie">Article par Catégorie</Link></li>
                                        <li><Link to="/rapport/vente-par-client">Vente par Client</Link></li>
                                        <li><Link to="/rapport/vente-par-article">Vente par Article</Link></li>
                                        <li><Link to="/rapport/achat-par-article">Achat par Article</Link></li>
                                    </ul>
                                    </li>
                                    <li>
                                    <Link to="">
                                    <i className="feather icon-truck small-icon " />Achats<i className="feather icon-chevron-right"></i>
                                    </Link>
                                    <ul className="vertical-submenu">
                                        <li><Link to="/rapport/factures">Factures</Link></li>
                                        <li><Link to="/rapport/bc">Bon Commande</Link></li>
                                        <li><Link to="/rapport/achat-par-fournisseur">Achat par Fournisseur</Link></li>
                                    </ul>
                                    </li>
                                    <li>
                                    <Link to="">
                                       <i className="feather icon-shopping-bag small-icon " />Ventes<i className="feather icon-chevron-right"></i>
                                    </Link>
                                    <ul className="vertical-submenu">
                                        <li><Link to="/rapport/vente-par-mois">Vente par Mois</Link></li>
                                        <li><Link to="/rapport/devis">Devis</Link></li>
                                        <li><Link to="/rapport/commandes">Commandes</Link></li>
                                        <li><Link to="/rapport/bl-par-mois">BL par Mois</Link></li>
                                    </ul>
                                    </li>
                                </ul>                           
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <ul className="vertical-menu">
                                        <li>
                                            <Link to="/home">
                                                <img src={dashIcon} className="img-fluid" alt="frontend" />Dashboard
                                            </Link>
                                        </li>
                                        <li>
                                                <Link to="/profil">
                                                    <img alt="" src={crmIcon} className="img-fluid" />Profile
                                                </Link>
                                                <Link onClick={handleLogout} >
                                                    <img alt="" src={logOut} className="img-fluid" />Se déconnecter
                                                </Link>
                                                
                                        </li>
                                    </ul>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SideBar;
