import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import { useTranslation } from "react-i18next";
import useEntityData from '../../layouts/store/useEntityData';
import { getMagasins, deleteMagasin } from "../../services/magasinService";
import Swal from "sweetalert2";

function ListerMagasins() {
  const { t } = useTranslation();
  const { data: magasins, handleDelete } = useEntityData('magasins', getMagasins);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [magasinIdToDelete, setMagasinIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [filteredmagasins, setFilteredmagasins] = useState([]);

  const columns = useMemo(() => [
    { key: 'nom_magasin', label: t('magasinName'), sortable: true },
    { key: 'adresse_magasin', label: t('address'), sortable: true },
    { key: 'telephone', label: t('phone'), sortable: true },
    { key: 'ville', label: t('city'), sortable: true },
    { key: 'depot', label: t('depot'), sortable: true },
    { key: 'actions', label: t('actions'), sortable: false },
  ], [t]);

  const openDelete = (magasinId) => {
    setMagasinIdToDelete(magasinId);
    setShowConfirmBox(true);
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false);
    setMagasinIdToDelete(null);
  };

  const handleDeleteMagasin = async () => {
    await handleDelete(magasinIdToDelete, deleteMagasin);
    setShowConfirmBox(false);
    Swal.fire({
      icon: "success",
      title: "Succès",
      text: "Magasin supprimé avec succès.",
    });
  };

  useEffect(() => {
    if (magasins) {
      const filtered = magasins.filter((magasin) =>
        columns.some((col) => {
          if (typeof magasin[col.key] === 'string') {
            return magasin[col.key].toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false;
        })
      );
      setCurrentPage(0);
      setFilteredmagasins(filtered);
    }
  }, [searchTerm, magasins, columns]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredmagasins.slice(offset, offset + itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <>
      
    <div className="breadcrumbbar">
      <div className="col-md-8 col-lg-8">
        <h4 className="page-title">{t('magasins')}</h4>
        <div className="breadcrumb-list mb-3">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/home">
                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                      <Link to="/home" style={{textDecoration:'none'}}>{t('magasins')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">{t('List magasins')}</li>
              </ol>
          </div>
      </div>
      <div className="row">
        <div className="col-lg-12 m-b-50">
          <div className="card m-b-50">
            <div className="card-header">
            <div className="row align-items-center">
                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                      <div className="col-5 col-md-5 d-flex justify-content-center">
                              <Search 
                                placeholder={t('Search')} 
                                onSearchChange={handleSearchChange} 
                              />
                      </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                          <div className="widgetbar">
                                              <Link to="/ajoute-magasin" className="btn btn-primary-rgba">
                                                  <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                              </Link>
                                          </div>
                  </div>
                </div>
             
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <SortingTable
                  data={currentItems.map((magasin) => ({
                    id: magasin.id,
                    nom_magasin: magasin.nom_magasin,
                    adresse_magasin: magasin.adresse_magasin,
                    telephone: magasin.telephone,
                    ville: magasin.ville,
                    depot: magasin.depot === 1 ? (
                      <span className="badge badge-success font-14">{t('internal')}</span>
                    ) : (
                      <span className="badge badge-info font-14">{t('external')}</span>
                    ),
                    actions: (
                      <div style={{ textAlign: "center" }}>
                        <Link to={`/modifier-magasin/${magasin.id}`} 
                          className="btn btn-success-rgba btn-round mb-1" 
                          title={t('edit')}
                          style={{ marginLeft: "10px" }}
                        >
                          <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                        </Link>
                        <Link
                          className="btn btn-danger-rgba btn-round mb-1"
                          title={t('delete')}
                          style={{ marginLeft: "10px" }}
                          onClick={() => openDelete(magasin.id)}
                        >
                          <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                        </Link>
                      </div>
                    ),
                  }))}
                  columns={columns}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    <select
                      className="form-control"
                      id="items-per-page"
                      onChange={handleItemsPerPageChange}
                      value={itemsPerPage}
                    >
                      <option value={2}>2</option>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </select>
                  </div>
                  <Pagination
                    items={filteredmagasins}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox
        open={showConfirmBox}
        closeDialog={closeConfirmBox}
        title={t('confirmDelete')}
        deleteFunction={handleDeleteMagasin}
        message={t('areYouSure')}
      />
    </div>
 
</>
  );
}

export default ListerMagasins;
