import axios from '../api/axios'; 

export const fetchArticles = async () => {
    try {
        const response = await axios.get('/articles');
        return response.data;

        } catch (error) {
        console.error("Error fetching articles:", error);
        throw error;
    }
};


export const fetchArticleById = async (id) => {
    try {
        const response = await axios.get(`/articles/${id}`);
        return response;
    } catch (error) {
        console.error("Error fetching article:", error);
        throw error;
    }
};

export const fetchArticleInfo = async (id) => {
    try {
        const response = await axios.get(`/info_articles/article/${id}`);
        return response.data[0];
    } catch (error) {
        console.error("Error fetching article info:", error);
        if (error.response?.status === 404) {
            return {}; 
        } else {
            throw error;
        }
    }
};
export const fetchCategories = async () => {
    try {
        const response = await axios.get('/categories');
        return response.data.categories;
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
};
export const fetchListCategories = async () => {
    const result = await axios.get("categories/listcategories");
    const sousCategories = result.data.sous_categories;
  
    const categoriesWithData = await Promise.all(sousCategories.map(async category => {
      const subcategoriesResult = await axios.get(`/categories/${category.id}/sous-categories`);
  
      return {
        ...category,
        sous_categories: subcategoriesResult.data
      };
    }));
    return categoriesWithData.reverse();
  };
  
  export const deleteCategory = async (categoryId) => {
    await axios.delete(`/categories/${categoryId}`);
  };

export const fetchTypes = async () => {
    try {
        const response = await axios.get('/types_articles');
        return response.data;
    } catch (error) {
        console.error("Error fetching types:", error);
        throw error;
    }
};

export const updateArticle = async (id, formData) => {
    try {
        await axios.put(`/articles/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        console.error("Error updating article:", error);
        throw error;
    }
};

export const updateArticleInfo = async (infoArticle) => {
    try {
        const response = await axios.get(`/info_articles?article_id=${infoArticle.id_article}`);
        const existingInfoArticle = response.data;

        if (existingInfoArticle.length > 0) {
            await axios.put(`/info_articles/${existingInfoArticle[0].id}`, infoArticle);
        } else {
            await axios.post('/info_articles', infoArticle);
        }
    } catch (error) {
        console.error("Error updating article info:", error);
        throw error;
    }
};

export const deleteArticle = async (id) => {
    try {
        await axios.delete(`/info_articles/${id}`);
        await axios.delete(`/articles/${id}`);
    } catch (infoError) {
        if (infoError.response && infoError.response.status === 404) {
            await axios.delete(`/articles/${id}`);
        } else {
            throw infoError;
        }
    }
};



export const fetchligneBonEntree = async () => {
    const response = await axios.get('/ligne-bon-entree-fr');
    return response.data;
  };
  export const fetchBonEntree = async () => {
    const response = await axios.get('/bon-entrees');
    return response.data;
  };

 export const fetchCommandeData = async (clientId, year) => {
    const response = await axios.get(`/commandeclient/commandeclient/${clientId}`, {
      params: { year }
    });
    return response.data;
  };
  
  export const fetchArticleCommandes = async (commandeId) => {
    const response = await axios.get(`/article-commandes?id_commande_cl=${commandeId}`);
    return response.data;
  };

  export const fetchArticlePlusVendu = async () => {
    const response = await axios.get(`/article-commandes`);
    return response.data;
  };

  export const fetchMagasin = async () => {
    const response = await axios.get(`/magasins`);
    return response.data;
  };