import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../../api/axios';
import { useTranslation } from 'react-i18next';

const EnTeteFacture = () => {
  const {t} = useTranslation();
  const [headerContent, setHeaderContent] = useState('');

  useEffect(() => {
    fetchHeaderContent();
  }, []);

  const fetchHeaderContent = async () => {
    try {
      const response = await axios.get('/parametre?cle=entete_facture');
      if (response.data && response.data.valeur) {
        setHeaderContent(response.data.valeur);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du contenu:', error);
    }
  };

  const handleContentChange = (value) => {
    setHeaderContent(value);
  };

  const handleSave = async () => {
    try {
      await axios.post('/parametre', {
        cle: 'entete_facture',
        valeur: headerContent,
      });
      alert('Contenu enregistré avec succès!');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du contenu:', error);
      alert('Une erreur est survenue lors de l\'enregistrement.');
    }
  };

  return (
    <div className="card-body text-center table-container">
      <div className="form-row">
        <div className="col-12 col-md-2 d-flex align-items-center mb-3">
          <label className="me-2">{t('Personnaliser l\'entête')} :</label>
        </div>
        <div className="col-12 col-md-10 mb-3">
          <ReactQuill 
            value={headerContent} 
            onChange={handleContentChange} 
            style={{ width: '100%' }} 
          />
        </div>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button className="btn btn-info my-1" onClick={handleSave}>
          {t('Enregistrer')}
        </button>
      </div>
    </div>
  );
};

export default EnTeteFacture;
