import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Pagination from "../../layouts/Pagination";
import SortingTable from '../../layouts/SortingTable';
import Search from "../../layouts/Search";
import Lightbox from "../../layouts/Lightbox";
import { useTranslation } from "react-i18next";
import useEntityData from '../../layouts/store/useEntityData';
import { fetchArticles, deleteArticle } from '../../services/articleService'; 

function ListerArticles() {
    const { t } = useTranslation();
    const { data: articles, handleDelete } = useEntityData('articles', fetchArticles, deleteArticle);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [articleIdToDelete, setArticleIdToDelete] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = useMemo(() => [
      { key: 'photo', label: t('Photo'), sortable: false },
      { key: 'nom_article', label: t('Article'), sortable: true },
      { key: 'code_barre', label: t('Code Barre'), sortable: true },
      { key: 'pu_achat', label: t('PU. Achat'), sortable: true },
      { key: 'pu_vente', label: t('PU. Vente'), sortable: true },
      { key: 'couleur', label: t('Couleur'), sortable: true },
      { key: 'largeur', label: t('L'), sortable: true },
      { key: 'hauteur', label: t('H'), sortable: true },
      { key: 'actions', label: t('Actions'), sortable: false, style: { textAlign: "center" } },
    ], [t]);

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const filteredArticles = articles?.filter(article => {
        return Object.values(article).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }) || [];
    const currentItems = filteredArticles.slice(offset, offset + itemsPerPage);

    const openLightbox = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    const openDelete = (articleId) => {
        setArticleIdToDelete(articleId);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setArticleIdToDelete(null);
    };

    const handleDeleteArticle = () => {
        if (articleIdToDelete) {
            handleDelete(articleIdToDelete,deleteArticle);
            closeConfirmBox();
        }
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    return (
        <>
           
           <div className="breadcrumbbar mb-15">
                <div className="row align-items-center">
                    <div className="col-md-8 col-lg-8">
                        <h4 className="page-title">{t('Liste articles')}</h4>
                        <div className="breadcrumb-list mb-3">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/home">
                                        <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/home" style={{textDecoration:'none'}}>{t('Dashboard')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('Liste articles')}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                        <div className="col-5 col-md-5 d-flex justify-content-center">
                                            <Search 
                                                placeholder={t('Search')} 
                                                onSearchChange={handleSearchChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                        <div className="widgetbar">
                                            <Link to="/ajoute_article" className="btn btn-primary-rgba">
                                                <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div className="card-body">
                               
                                            <div className="table-responsive">
                                                <SortingTable
                                                    data={currentItems.map((article) => ({
                                                        id: article.id,
                                                        photo: (
                                                            <img
                                                                src={`https://admin.mansoft.ma/${article.photo}`}
                                                                alt={article.nom_article}
                                                                style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                                                onClick={() => openLightbox(`https://admin.mansoft.ma/${article.photo}`)}
                                                            />
                                                        ),
                                                        nom_article: article.nom_article,
                                                        code_barre: article.code_barre,
                                                        pu_achat: article.pu_achat,
                                                        pu_vente: article.pu_vente,
                                                        couleur: article.infoArticle ? article.infoArticle.couleur : '',
                                                        largeur: article.infoArticle ? article.infoArticle.largeur : '',
                                                        hauteur: article.infoArticle ? article.infoArticle.hauteur : '',
                                                        actions: (
                                                            <div>
                                                                <Link
                                                                    to={`/article/detail/${article.id}`}
                                                                    style={{ marginLeft: "10px" }}
                                                                    className="btn btn-info-rgba btn-round mb-1"
                                                                >
                                                                    <i className="feather icon-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    to={`/ListerArticles/ModifierArticle/${article.id}`}
                                                                    style={{ marginLeft: "10px" }}
                                                                    className="btn btn-success-rgba btn-round mb-1"
                                                                >
                                                                    <i className="feather icon-edit-2"></i>
                                                                </Link>
                                                                <Link
                                                                    onClick={() => openDelete(article.id)}
                                                                    style={{ marginLeft: "10px" }}
                                                                    className="btn btn-danger-rgba btn-round mb-1"
                                                                >
                                                                    <i className="feather icon-trash"></i>
                                                                </Link>
                                                            </div>
                                                        ),
                                                    }))}
                                                    columns={columns}
                                                />
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div>
                                                        <select
                                                            className="form-control"
                                                            id="items-per-page"
                                                            onChange={handleItemsPerPageChange}
                                                            value={itemsPerPage}
                                                        >
                                                            <option value={2}>2</option>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                        </select>
                                                    </div>
                                                    <Pagination
                                                        items={filteredArticles}
                                                        itemsPerPage={itemsPerPage}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                                <Lightbox
                                                    isOpen={lightboxOpen}
                                                    onClose={closeLightbox}
                                                    imageUrl={currentImageUrl}
                                                />
                                            </div>
                                        
                            </div>
                        </div>
                    </div>
                </div>
        </div>
            <ConfirmBox
                open={showConfirmBox}
                closeDialog={closeConfirmBox}
                title={t('Confirm Delete')}
                deleteFunction={handleDeleteArticle}
            />
       
</>
    );
}

export default ListerArticles;
