import axios from "axios";

const axiosInstance = axios.create({
       baseURL: "https://admin.mansoft.ma/api",
       //baseURL: "http://127.0.0.1:8000/api",

  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['x-ratelimit-limit'] = 90;
    config.headers['x-ratelimit-remaining'] = 59;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
