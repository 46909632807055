import React, { useState, useEffect, useRef } from "react";
import axios from "../../api/axios";
import { useQuery } from "react-query";
import '../../assets/css/feather.css';
import Swal from "sweetalert2";
import $ from 'jquery'; 
import { useNavigate, Link } from 'react-router-dom';
import { fetchTypes,fetchCategories } from "../../services/articleService";
import { useOfflineOperations } from '../../layouts/useOfflineOperations'; 
import { useTranslation } from "react-i18next";

function AjouterArticle() {
  const {t}=useTranslation();
  const navigate = useNavigate();
  const [nom_article, setNomArticle] = useState('');
  const [code_barre, setCodeBarre] = useState('');
  const [pu_achat, setPrixAchat] = useState('');
  const [pu_vente, setPrixVente] = useState('');
  const [largeur, setLargeur] = useState('');
  const [hauteur, setHauteur] = useState('');
  const [couleur, setCouleur] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] =  useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [photo, setPhoto] = useState(null);
  const dropdownRef = useRef(null);
  const { saveOperationOffline } = useOfflineOperations(); 

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  useEffect(() => {
    (function() {
      $('.input-file').each(function() {
        var $input = $(this),
            $label = $input.next('.js-labelFile'),
            labelVal = $label.html();

        $input.on('change', function(element) {
          var fileName = '';
          if (element.target.value) fileName = element.target.value.split('\\').pop();
          fileName ? $label.addClass('has-file').find('.js-fileName').html(fileName) : $label.removeClass('has-file').html(labelVal);
        });
      });
    })();
  }, []);

  const { data: types, isLoading: typesLoading, error: typesError } = useQuery("types", fetchTypes);

  const { data: categories, isLoading: categoriesLoading, error: categoriesError } = useQuery("categories", fetchCategories);

  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

  const toggleAdditionalInputs = () => {
    setShowAdditionalInputs(!showAdditionalInputs);
  };

  useEffect(() => {
    const filtered = categories?.filter(category =>
      category.nom_categorie.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectCategory = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName(categoryName);
    setSearchTerm(categoryName);
    setDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const articleData = new FormData();
      articleData.append('nom_article', nom_article);
      articleData.append('code_barre', code_barre);
      articleData.append('pu_achat', pu_achat);
      articleData.append('pu_vente', pu_vente);
      articleData.append('id_type', selectedType);
      articleData.append('categorie_id', selectedCategoryId);
      articleData.append('photo', photo);

      let additionalData = null;

      if (largeur || hauteur || couleur) {
        additionalData = {
          largeur,
          hauteur,
          couleur
        };
      }

      if (navigator.onLine) {
        const articleResponse = await axios.post("/articles", articleData);
        const { id } = articleResponse.data;

        if (additionalData) {
          additionalData.id_article = id;
          await axios.post("/info_articles", additionalData);
        }

        Swal.fire({
          title: "",
          text: "L'article a été ajouté avec succès",
          icon: "success"
        });

        navigate('/articles');
      } else {
        saveOperationOffline({
          type: 'CREATE',
          endpoint: '/articles', 
          data: articleData, 
        });

        if (additionalData) {
          saveOperationOffline({
            type: 'CREATE',
            endpoint: '/info_articles', 
            data: additionalData, 
          });
        }

        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Article sauvegardé localement ! Il sera envoyé lorsque vous serez en ligne.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.response?.data?.message || "Une erreur est survenue lors de l'ajout de l'article.",
      });
    }
};


  const [showImagesAndIcons, setShowImagesAndIcons] = useState(false);

  const toggleImagesAndIcons = () => {
    setShowImagesAndIcons(!showImagesAndIcons);
  };

  if (typesLoading || categoriesLoading) {
    return <div>Loading...</div>;
  }

  if (typesError || categoriesError) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      
      <div className="breadcrumbbar">
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                    <h5 className="card-title"> {t('Ajouter')}{ ' '} {t('Article')}</h5>
                  </div>
                  <div className="card-body">
                  <form className="needs-validation" onSubmit={onSubmit}>
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('Nom d\'article')}:</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={nom_article}
                          onChange={(e) => setNomArticle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('Catégorie')}:</label>
                        <div className="custom-select-wrapper w-100" ref={dropdownRef}>
                          <div className="form-control" onClick={handleDropdownClick}>
                            {selectedCategoryName ? selectedCategoryName : ""}
                          </div>
                          {dropdownOpen && (
                            <div className="autocom-box">
                              <input
                                type="search"
                                aria-label="Search"
                                className="search-box form-control"
                                placeholder="Rechercher..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onClick={() => setDropdownOpen(true)}
                              />
                              <ul>
                                {filteredCategories.map((category) => (
                                  <li
                                    key={category.id}
                                    onClick={() => handleSelectCategory(category.id, category.nom_categorie)}
                                  >
                                    {category.nom_categorie}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('Code barre')}:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={code_barre}
                          onChange={(e) => setCodeBarre(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('Type')}:</label>
                        <select
                          className="form-select"
                          value={selectedType}
                          onChange={(e) => setSelectedType(e.target.value)}
                        >
                          <option value=""></option>
                          {types.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('PU Achat')} (DH):</label>
                        <input
                          type="number"
                          className="form-control"
                          value={pu_achat}
                          onChange={(e) => setPrixAchat(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('PU Vente')} (DH):</label>
                        <input
                          type="number"
                          className="form-control"
                          value={pu_vente}
                          onChange={(e) => setPrixVente(e.target.value)}
                        />
                      </div>
                    </div>
                    {showAdditionalInputs && (
                      <div className="form-row">
                        <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                          <label className="me-2 col-md-3">{t('Largeur')}:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setLargeur(e.target.value)}
                            
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                          <label className="me-2 col-md-3">{t('Hauteur')}:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setHauteur(e.target.value)}
                            
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                          <label className="me-2 col-md-3">{t('Couleur')}:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setCouleur(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12 mb-3 d-flex flex-column flex-md-row">
                        <label className="me-2 col-md-3">{t('Choisir une image')}:</label>
                        <input
                          type="file"
                          className="input-file form-control"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                        <div onClick={toggleAdditionalInputs} className="btn btn-primary-rgba ms-1 mb-3">
                          {showAdditionalInputs ? (
                            <i className="feather icon-minus-circle"></i>
                          ) : (
                            <i className="feather icon-plus-circle"></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Link className="btn btn-primary-rgba my-1" to="/articles">{t('Annuler')}</Link>
                      <button className="btn btn-primary-rgba my-1">{t('Ajouter')}</button>
                    </div>
                  </form>
                </div>


                </div>
              </div>
            </div>
          </div>
        
    </>
  );
}

export default AjouterArticle;
