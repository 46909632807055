import {
    Button,
    Dialog,
    DialogContent,
    Fade,
    Grid,
    IconButton,
    Typography,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import React, { forwardRef } from "react";
  
  const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
  });
  
  function ConfirmBox({ open, closeDialog, title, deleteFunction }) {
    return (
      <Dialog
        fullWidth
        open={open}
        maxWidth="xs" // Changer la largeur maximale de la boîte de dialogue
        onClose={closeDialog}
        onBackdropClick={closeDialog}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ px: 3, py: 2, position: "relative" }}> {/* Ajuster le padding */}
          <IconButton
            size="medium"
            onClick={closeDialog}
            sx={{ position: "absolute", right: "0.5rem", top: "0.5rem" }} // Ajuster la position du bouton de fermeture
          >
            X
          </IconButton>
  
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6"className="mb-4">Supprimer {title}</Typography> {/* Changer la taille de la typographie */}
                <Typography variant="body2" > {/* Changer la taille de la typographie */}
                Etes-vous sûr de vouloir supprimer cette {title} ?
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem" }} // Réduire l'espace entre les boutons
            >
              <Button
                onClick={closeDialog}
                size="small" // Changer la taille du bouton
                variant="contained"
                color="primary"
              >
                Annuler
              </Button>
              <Button
                onClick={deleteFunction}
                size="small" // Changer la taille du bouton
                variant="contained"
                color="error"
              >
                Supprimer
              </Button>{" "}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
  
  export default ConfirmBox;
  