import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useQuery } from 'react-query';

function ModefierArticle() {
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
  const [selectedArticleImage, setSelectedArticleImage] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [articleData, setArticleData] = useState({
    nom_article: "",
    code_barre: "",
    pu_achat: "",
    pu_vente: "",
    categorie_id: "",
    id_type: "",
    photo: "",
  });
  const [infoArticle, setInfoArticle] = useState({
    largeur: "",
    hauteur: "",
    couleur: "",
  });

  // Fetch article data
  const { data: article, error: articleError, isLoading: articleLoading } = useQuery(
    ['article', id],
    async () => {
      const { data } = await axios.get(`/articles/${id}`);
      return data;
    }
  );

  // Fetch article info data
  const { data: infoArticles, error: infoArticleError, isLoading: infoArticleLoading } = useQuery(
    ['infoArticles', id],
    async () => {
      const { data } = await axios.get(`/info_articles?id_article=${id}`);
      return data;
    },
    { enabled: !!article }
  );

  // Fetch categories
  const { data: categories, error: categoriesError, isLoading: categoriesLoading } = useQuery(
    'categories',
    async () => {
      const { data } = await axios.get("/categories");
      return data.categories;
    }
  );

  // Fetch types
  const { data: types, error: typesError, isLoading: typesLoading } = useQuery(
    'types',
    async () => {
      const { data } = await axios.get("/types_articles");
      return data;
    }
  );

  useEffect(() => {
    if (article) {
      setArticleData({
        ...article,
        photo: article.photo || ""
      });
      if (article.photo) {
        setSelectedArticleImage(`http://127.0.0.1:8000/${article.photo}`);
      }
    }
  }, [article]);

  useEffect(() => {
    if (infoArticles && infoArticles.length > 0) {
      const info = infoArticles[0];
      setInfoArticle({
        couleur: info.couleur,
        largeur: info.largeur,
        hauteur: info.hauteur,
      });
    }
  }, [infoArticles]);

  const toggleAdditionalInputs = () => {
    setShowAdditionalInputs(!showAdditionalInputs);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSelectedArticleImage(previewUrl);
      setArticleData({ ...articleData, photo: file });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["largeur", "hauteur", "couleur"].includes(name)) {
      setInfoArticle({ ...infoArticle, [name]: value });
    } else {
      setArticleData({ ...articleData, [name]: value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create FormData for article
      const formData = new FormData();
      Object.keys(articleData).forEach((key) => {
        formData.append(key, articleData[key]);
      });
      
      if (articleData.photo instanceof File) {
        formData.append('photo', articleData.photo);
      }
      
      if (articleData.photo instanceof File) {
        formData.append('photo', articleData.photo);
      }
  
      // Send article data
      await axios.put(`/articles/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      Swal.fire({
        title: "",
        text: "L'article a été modifié avec succès",
        icon: "success",
      });
  
      // Update additional article info
      const updatedInfoArticle = { ...infoArticle, id_article: id };
      Object.keys(updatedInfoArticle).forEach(
        (key) => updatedInfoArticle[key] === "" && delete updatedInfoArticle[key]
      );
  
      const response = await axios.get(`/info_articles?id_article=${id}`);
      const existingInfoArticle = response.data;
  
      if (existingInfoArticle.length > 0) {
        if (Object.keys(updatedInfoArticle).length > 1) {
          await axios.put(`/info_articles/${existingInfoArticle[0].id}`, updatedInfoArticle);
        } else {
          await axios.delete(`/info_articles/${existingInfoArticle[0].id}`);
        }
      } else if (Object.keys(updatedInfoArticle).length > 1) {
        await axios.post(`/info_articles`, updatedInfoArticle);
      }
  
      navigate("/articles");
    } catch (error) {
      console.error("Error updating article:", error);
      Swal.fire({
        title: "Error",
        text: "Erreur lors de la modification de l'article. Veuillez réessayer.",
        icon: "error",
      });
    }
  };
  
  

  // if (articleLoading || infoArticleLoading || categoriesLoading || typesLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (articleError || infoArticleError || categoriesError || typesError) {
  //   return <p>Error loading data.</p>;
  // }

  return (
    <div className="breadcrumbbar">
    <div className="row">
      <div className="col-lg-12 m-b-50">
        <div className="card m-b-50">
          <div className="card-header">
            <h5 className="card-title">Modifier l'article</h5>
          </div>
          <div className="card-body">
              <form className="needs-validation" onSubmit={onSubmit}>
                  <div className="form-row">
                     <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
                         <label className="me-2 col-md-3">Nom d'article:</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="nom_article"
                          value={articleData.nom_article}
                          onChange={handleInputChange}
                        />
                      </div>
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-2 col-md-3">Catégorie:</label>
              <select
                className="form-control"
                name="categorie_id"
                value={articleData.categorie_id}
                onChange={handleInputChange}
              >
                <option value=""></option>
                {categories && categories.length > 0 && categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.nom_categorie}
                  </option>
                ))}
              </select>
              </div>
              </div>
              <div className="form-row">
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-2 col-md-3">Code barre:</label>
              <input
                type="text"
                className="form-control"
                name="code_barre"
                value={articleData.code_barre}
                onChange={handleInputChange}
              />
              </div>
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-2 col-md-3">Type:</label>
              <select
                className="form-control"
                name="id_type"
                value={articleData.id_type || ""}
                onChange={handleInputChange}
              >
                <option value=""></option>
                {types && types.length > 0 && types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.type}
                  </option>
                ))}
              </select>
              </div>
              </div>
              <div className="form-row">
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-2 col-md-3">PU Achat (DH):</label>
              <input
                type="text"
                className="form-control"
                name="pu_achat"
                value={articleData.pu_achat}
                onChange={handleInputChange}
              />
              </div>
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-2 col-md-3">PU Vente (DH):</label>
              <input
                type="text"
                className="form-control"
                name="pu_vente"
                value={articleData.pu_vente}
                onChange={handleInputChange}
              />
              </div>
              </div>
              {showAdditionalInputs && (
              <>
              <div className="form-row">
                <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
                  <label className="me-1 col-md-3">Largeur:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="largeur"
                    value={infoArticle.largeur}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
                  <label className="me-1 col-md-3">Hauteur:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="hauteur"
                    value={infoArticle.hauteur || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
                  <label className="me-1 col-md-3">Couleur:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="couleur"
                    value={infoArticle.couleur || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              </>
              )}

              <div className="form-row">
              <div className="col-md-6 col-sm-12 d-flex flex-column flex-md-row mb-3">
              <label className="me-1 col-md-3">Image:</label>
              <input
                type="file"
                className="input-file form-control"
                onChange={handleFileChange}
              />
              {selectedArticleImage ? (
                <img
                  src={selectedArticleImage}
                  alt="Selected"
                  style={{ maxWidth: '100px', marginLeft: '7px' }}
                />
              ) : (
                articleData.photo && (
                  <img
                    src={`https://admin.mansoft.ma/${articleData.photo}`}
                    alt="articles"
                    style={{ maxWidth: '90px', marginLeft: '30px' }}
                  />
                )
              )}
              </div>
              </div>

              <div className="d-flex justify-content-end">
              <button
              type="button"
              onClick={toggleAdditionalInputs}
              className="btn btn-primary-rgba ms-1 mb-3"
              >
              {showAdditionalInputs ? (
                <i className="feather icon-minus-circle"></i>
              ) : (
                <i className="feather icon-plus-circle"></i>
              )}
              </button>
              </div>

              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Link className="btn btn-primary-rgba my-1" to="/articles">
              Annuler
              </Link>
              <button className="btn btn-primary-rgba my-1">
              <i className="feather icon-save mr-2"></i>Modifier
              </button>
              </div>
              </form>
              </div>

        </div>
      </div>
    </div>
  </div>
  );
}

export default ModefierArticle;
