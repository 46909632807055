import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useQuery } from 'react-query';
import { fetchFournisseurs } from '../../../services/fournisseurService';
import { fetchBonEntree, fetchligneBonEntree } from '../../../services/articleService';
import { useTranslation } from 'react-i18next';
ChartJS.register(ArcElement, Tooltip, Legend);

const ArticleParFournisseur = () => {
  const {t} = useTranslation();
  const { data: fournisseurs, error: fournisseursError, isLoading: fournisseursLoading } = useQuery('fournisseurs', fetchFournisseurs);
  const { data: bonEntree, error: bonEntreeError, isLoading: bonEntreeLoading } = useQuery('bonEntree', fetchBonEntree);
  const { data: ligneBonEntree, error: ligneBonEntreeError, isLoading: ligneBonEntreeLoading } = useQuery('ligneBonEntree', fetchligneBonEntree);

  const isLoading = fournisseursLoading || bonEntreeLoading || ligneBonEntreeLoading;
  const error = fournisseursError || bonEntreeError || ligneBonEntreeError;

  const articlesCount = useMemo(() => {
    if (!fournisseurs || !bonEntree || !ligneBonEntree) return {};

    return fournisseurs.reduce((acc, fournisseur) => {
      const bons = bonEntree.filter(be => be.id_fournisseur === fournisseur.id);
      const totalArticles = bons.reduce((sum, bon) => {
        const lignes = ligneBonEntree.filter(ligne => ligne.bon_entree_id === bon.id);
        return sum + lignes.reduce((ligneSum, ligne) => ligneSum + ligne.quantite, 0);
      }, 0);
      acc[fournisseur.id] = totalArticles;
      return acc;
    }, {});
  }, [fournisseurs, bonEntree, ligneBonEntree]);

  // Filter out fournisseurs with zero articles
  const filteredFournisseurs = fournisseurs?.filter(fournisseur => articlesCount[fournisseur.id] > 0) || [];

  // Generate a list of unique colors
  const generateColors = (count) => {
    const baseColors = ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0', '#9966FF'];
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(baseColors[i % baseColors.length]);
    }
    return colors;
  };

  // Prepare data for Pie chart
  const data = {
    labels: filteredFournisseurs.map(fournisseur => fournisseur.nom_fournisseur),
    datasets: [
      {
        data: filteredFournisseurs.map(fournisseur => articlesCount[fournisseur.id]),
        backgroundColor: generateColors(filteredFournisseurs.length),
        hoverBackgroundColor: generateColors(filteredFournisseurs.length),
      }
    ]
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <Pie data={data} width={300} height={300} />
      </div>
      <table className="table table-striped table-bordered w-100 mt-4 centered-table">
        <thead>
          <tr>
            <th>{t('Fournisseur')}</th>
            <th>{t('Nombre d\'articles')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredFournisseurs.length > 0 ? (
            filteredFournisseurs.map(fournisseur => (
              <tr key={fournisseur.id}>
                <td>{fournisseur.nom_fournisseur}</td>
                <td>{articlesCount[fournisseur.id] || 0}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">Aucune donnée disponible</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ArticleParFournisseur;
