import React, { useState } from "react";

function Pagination({ items, itemsPerPage, onPageChange}) { 
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event) => {
    const newPage = event.selected; 
    setCurrentPage(newPage); 


    if (onPageChange) {
      onPageChange(newPage); 
    }
  };
 
  const pageCount = Math.ceil(items.length / itemsPerPage); 
  return (
    <>
     
      <nav aria-label="Pagination">
        <ul className="pagination" style={{
            justifyContent: "flex-end",
            paddingTop:"0.5rem" 
           }}
            >
          <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageClick({ selected: currentPage - 1 })}
              disabled={currentPage === 0}
              style={{
                fontSize: "12px", 
              }}
            >
              &laquo;
            </button>
          </li>

          {Array.from({ length: pageCount }, (_, i) => (
            <li
              key={i}
              className={`page-item ${i === currentPage ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageClick({ selected: i })}
                style={{
                   
                    fontSize: "12px", 
                  }}
              >
                {i + 1}
              </button>
            </li>
          ))}

          <li
            className={`page-item ${
              currentPage === pageCount - 1 ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageClick({ selected: currentPage + 1 })}
              disabled={currentPage === pageCount - 1}
              style={{
                
                fontSize: "12px", 
              }}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Pagination;
