import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";

function AjouterCharge() {
  const [magasins, setMagasin] = useState([]);
  const [nomMagasin, setNomMagasin] = useState("");
  const [typeCharge, setTypeCharge] = useState("");
  const [date, setDate] = useState("");
  const [montant, setMontant] = useState("");
  const [description, setDescription] = useState("");
 const [ setCharges] = useState([]); 

  const [typesCharge, setTypesCharge] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMagasin = async () => {
        try {
            const response = await axios.get("/magasins");
            setMagasin(response.data);
        } catch (error) {
            console.error('Error fetching magasins:', error);
            setMagasin([]);
        }
    };
const fetchType = async () => {
    try {
      const response = await axios.get("/type-charges"); 
      const filteredTypes = response.data.filter(
        (type) => type.parent_id === 0 
      );
      setTypesCharge(filteredTypes);
    } catch (error) {
      console.error("Error fetching types of charge:", error);
      setTypesCharge([]); 
    }
  };
  
  
    fetchMagasin();
    fetchType();
}, []);

useEffect(() => {
    const fetchCharges = async () => {
      try {
        const response = await axios.get("/charges"); 
        setCharges(response.data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des charges :", error);
      }
    };
  
    fetchCharges(); 
  }, [setCharges]); 
  
   
const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
       
        id_magasin: nomMagasin, 
        id_type_charge: typeCharge, 
         date: date,
        montant: montant,
        description: description,
    };
  
    try {
      await axios.post("/charges", formData);
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Charge ajoutée avec succès !",
      });
      navigate("/charges");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.response?.data?.message || "Une erreur est survenue",
      });
    }
  };
  
  return (
    
       <div className="breadcrumbbar">
          <div className="row">
            <div className="col-lg-12 m-b-50">
              <div className="card m-b-50">
                <div className="card-header">
                  <h5 className="card-title">Ajouter une charge</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      

                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Nom de Magasin</label>
                        <select
                            name="magasins"
                            className="form-control"
                            value={nomMagasin} 
                            onChange={(e) => {
                                setNomMagasin(e.target.value); 
                            }}
                            >
                            <option value="">Choisir un magasin</option>
                            {magasins.map((magasin) => (
                                <option key={magasin.id} value={magasin.id}>
                                {magasin.nom_magasin}
                                </option>
                            ))}
                        </select>

                      </div>

                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Type de charge</label>
                        <select
                            name="type_charge"
                            className="form-select"
                            value={typeCharge} 
                            onChange={(e) => setTypeCharge(e.target.value)}
                            >
                            <option value="">Choisir un type de charge</option>
                            {typesCharge.map((type) => (
                                <option key={type.id} value={type.id}>
                                {type.type_charge} 
                                </option>
                            ))}
                            </select>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Date</label>
                        <input
                          type="date"
                          name="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          />
                      </div>

                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <label className="me-2 col-md-4">Montant</label>
                        <input
                          type="text"
                          name="montant"
                          className="form-control"
                          value={montant}
                          onChange={(e) => setMontant(e.target.value)}
                          />
                      </div>
                      <div className=" col-md-12 d-flex align-items-center mb-3">
                        <label className="me-0 col-md-2">Description</label>
                        <textarea className="form-control " rows="1" name="description" 
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        >
                        </textarea>
                        </div>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md">
                      <Link className="btn btn-primary-rgba my-1" to="/articles/categorie">
                        Annuler
                      </Link>
                      <button className="btn btn-primary-rgba my-1">
                        Ajouter
                        <i className="feather icon-arrow-right ml-2"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
    
  );
}

export default AjouterCharge;
