import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const entitySlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
        setEntity: (state, action) => {
            const { entity, data } = action.payload;
            state[entity] = data;
        },
    },
});

export const { setEntity } = entitySlice.actions;
export default entitySlice.reducer;
