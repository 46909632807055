import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";

function ListerPaimentCl() {
const {t}=useTranslation();
 const [paiements, setpaiements] = useState([]);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [paiementIdToDelete, sepaiementIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredpaiements, setFilteredpaiements] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage,setItemsPerPage] = useState(2);
 
    //sorting
    const columns = [
        { key: 'client', label: 'client', sortable: true },
        { key: 'montant', label: 'montant', sortable: true },
        { key: 'mode_paiement', label: 'mode_paiement', sortable: false },
        { key: 'date_paiement', label: 'date_paiement', sortable: true },
        { key: 'date_echeance', label: 'date_echeance', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
    ];

   //logique recherche 
   const handleSearchChange = (term) => {
    setSearchTerm(term);

    const filtered = paiements.filter((commande) => {
        return Object.keys(commande).some((key) => {
            if (typeof commande[key] === 'string') {
                return commande[key].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
    });

    setFilteredpaiements(filtered);
};

useEffect(() => {
    setFilteredpaiements(paiements); 
}, [paiements]);

    //pagination debut
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex); 
      };
      
      const offset = currentPage * itemsPerPage; 
      const currentItems = filteredpaiements.slice(offset, offset + itemsPerPage); 
      console.log('Nombre de lignes affichées:', currentItems.length);
    
    //end pagination
     //temsPerPage 
     const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value)); 
        setCurrentPage(0); 
      };
      useEffect(() => {

        const fetchPaiementCl = async () => {
            try {
              const response = await axios.get('/Paiementclient');
              const paiements = response.data; 
        
              const paiementsWithClientData = await Promise.all(
                paiements.map(async (paiement) => {
                  const clientResponse = await axios.get(`/clients/${paiement.id_client}`);
                  const clientData = clientResponse.data;
        
                  return {
                    id: paiement.id,
                    montant: paiement.montant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                    mode_paiement: paiement.mode_paiement,
                    date_paiement: paiement.date_paiement,
                    date_echeance: paiement.date_echeance,
                    nom_client: clientData.nom_client, 
                  prenom_client: clientData.prenom_client, 
                  };
                })
              );
        
              setpaiements(paiementsWithClientData);
            } catch (error) {
              console.error("Erreur lors de la récupération des paiements :", error);
            }
          };
        
            fetchPaiementCl();
          }, []); 
        
        
      
        const fetchpaiementDetails = async (id) => {
            try {
                const allLignespaiementResponse = await axios.get(`/ligne-paiement-fors?id_paiement_fr=${id}`);
                const allLignespaiementData = allLignespaiementResponse.data;
        
                const lignespaiementData = allLignespaiementData.filter(ligne => ligne.id_paiement_fr === id);
        
              
                return lignespaiementData.map(ligne => ligne.id);
            } catch (error) {
                console.error('Error fetching paiement details:', error);
                throw error; 
            }
        };
    
    const openDelete = (Id) => {
        sepaiementIdToDelete(Id);
        setShowConfirmBox(true);
    };
    
    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        sepaiementIdToDelete(null);
    };
    const handleDeletepaiement = async () => {
        if (paiementIdToDelete === undefined || paiementIdToDelete === null) {
            console.error("No comande ID to delete");
            return;
        }
    
        try {
            const lignespaiementIds = await fetchpaiementDetails(paiementIdToDelete);
    
            for (const ligneId of lignespaiementIds) {
                await axios.delete(`/ligne-paiement-fors/${ligneId}`);
            }
    
            await axios.delete(`/paiementFr/${paiementIdToDelete}`);
    
            setpaiements(paiements.filter(paiement => paiement.id !== paiementIdToDelete));
    
            setShowConfirmBox(false);
        } catch (error) {
            console.error("Error deleting paiement:", error);
        }
    }
    
    return (
        <>
		<div className="breadcrumbbar">  
               <div className="col-md-8 col-lg-8">
                  <h4 className="page-title">{t('paiement client')}</h4>
                   <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Paiment')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('paiement client')}</li>
                                </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header">                                
                            <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            
                            <div className="table-responsive">
                               <SortingTable
                                    data={currentItems.map((paiement) => ({
                                        id:paiement.id,
                                        client:paiement.nom_client +' ' +paiement.prenom_client,
                                        montant: paiement.montant,
                                        mode_paiement: paiement.mode_paiement,
                                        date_paiement: paiement.date_paiement,
                                        date_echeance: paiement.date_echeance,
                                        actions: (
                                            <div className="text-center">
                                                   
                                                    <Link  to={`/modifier-paiement-client/${paiement.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip" style={{ marginRight: '10px' }}>
                                                        <i className="feather icon-edit-2" style={{fontSize: "small"}}></i>
                                                    </Link>
                                                    <Link onClick={() => openDelete(paiement.id)}  className="btn btn-danger-rgba btn-round "style={{ marginRight: '10px' }}>
                                                        <i className="feather icon-trash" style={{fontSize: "small"}} ></i>
                                                    </Link>
                                            </div>
                                            ),
                                        }))}
                                    columns={columns}
                                    />
                                    <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <select
                                        className="form-control"
                                        id="items-per-page"
                                        onChange={handleItemsPerPageChange}
                                        value={itemsPerPage}
                                            >
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            </select>
                                        </div>
                                        <Pagination
                                                items={filteredpaiements} 
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                                 
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmBox
                open={showConfirmBox}
                closeDialog={closeConfirmBox}
                title="paiement fournisseur"
                deleteFunction={handleDeletepaiement}
           />
   
   

</>

  );
}

export default ListerPaimentCl;
