import axios from '../api/axios';

// Save operation to localStorage
export function saveOfflineOperation(operation) {
    const offlineOperations = JSON.parse(localStorage.getItem('offlineOperations')) || [];
    offlineOperations.push(operation);
    localStorage.setItem('offlineOperations', JSON.stringify(offlineOperations));
}

export async function sendOfflineOperations() {
    const offlineOperations = JSON.parse(localStorage.getItem('offlineOperations')) || [];

    if (offlineOperations.length > 0) {
        for (const operation of offlineOperations) {
            try {
                const { type, data, endpoint } = operation;

                if (type === 'CREATE') {
                    await axios.post(endpoint, data); 
                } else if (type === 'UPDATE') {
                    await axios.put(endpoint, data); 
                } else if (type === 'DELETE') {
                    await axios.delete(endpoint); 
                } else {
                    console.error('Type d\'opération non pris en charge:', type);
                    continue;
                }

                console.log('Opération envoyée avec succès:', operation);
                removeOperationFromStorage(operation);
            } catch (error) {
                console.error('Erreur lors de l\'envoi des opérations:', error);
            }
        }
    }
}

// Remove an operation from localStorage
export function removeOperationFromStorage(operationToRemove) {
    let offlineOperations = JSON.parse(localStorage.getItem('offlineOperations')) || [];
    offlineOperations = offlineOperations.filter(operation => operation.id !== operationToRemove.id);
    localStorage.setItem('offlineOperations', JSON.stringify(offlineOperations));
}

// Provide access to the functions
export function useOfflineOperations() {
    return {
        saveOperationOffline: saveOfflineOperation,
        sendOperations: sendOfflineOperations,
        removeOperation: removeOperationFromStorage,
    };
}
