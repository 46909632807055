import React, { useState, useEffect ,useMemo} from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import { useTranslation } from "react-i18next";

function ListerTypeCharge() {
  const {t}= useTranslation();
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [charges, setCharges] = useState([]); 
    const [chargeIdToDelete, setChargeIdToDelete] = useState(null); 
    const [filteredcharges, setFilteredcharges] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
  
    //sorting
    const columns = useMemo(() => [
      { key: 'charge', label: 'Charge', sortable: true },
      { key: 'sous_charge', label: 'Sous_Charge', sortable: true },
      { key: 'actions', label: 'Actions', sortable: false, style: { textAlign: "center" } },
    ], []);
   
  
    const handleSearchChange = (term) => {
      setSearchTerm(term);
    };
  
    useEffect(() => {
      const filtered = charges.filter((charge) => {
        return charge.type_charge.toLowerCase().includes(searchTerm.toLowerCase()) ||
               (charge.sous_charges && charge.sous_charges.some(subCharge => subCharge.type_charge.toLowerCase().includes(searchTerm.toLowerCase())));
      });
      setFilteredcharges(filtered);
      setCurrentPage(0);
    }, [searchTerm, charges]);
    
  
    useEffect(() => {
      setFilteredcharges(charges);
    }, [charges]);
  
    const handlePageChange = (pageIndex) => {
      setCurrentPage(pageIndex);
    };
  
    const offset = currentPage * itemsPerPage;
    const currentItems = filteredcharges.slice(offset, offset + itemsPerPage);
  
    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(parseInt(event.target.value));
      setCurrentPage(0);
    };
    
      
    useEffect(() => {
        const fetchTypeCharges = async () => {
          try {
            const response = await axios.get("/type-charges");
      
            const organizedCharges = response.data.reduce((acc, charge) => {
              if (charge.parent_id) {
                const parent = acc.find(c => c.id === charge.parent_id);
                if (parent) {
                  parent.sous_charges = parent.sous_charges || [];
                  parent.sous_charges.push(charge);
                }
              } else {
                acc.push(charge);
              }
              return acc;
            }, []);
      
            setCharges(organizedCharges);
          } catch (error) {
            console.error("Erreur lors de la récupération des types de charges :", error);
          }
        };
      
        fetchTypeCharges();
      }, []);
      
      
      const openDeleteConfirmBox = (chargeId) => {
        setChargeIdToDelete(chargeId);
        setShowConfirmBox(true); 
      };
    
      const closeConfirmBox = () => {
        setShowConfirmBox(false); 
        setChargeIdToDelete(null); 
      };
    
      const handleDeleteCharge = async () => {
        if (chargeIdToDelete === undefined || chargeIdToDelete === null) {
          console.error("Aucun ID de charge à supprimer");
          return;
        }
    
        try {
          await axios.delete(`/type-charges/${chargeIdToDelete}`);
    
          setCharges(charges.filter(charge => charge.id !== chargeIdToDelete));
    
          setShowConfirmBox(false);
        } catch (error) {
          console.error("Erreur lors de la suppression du type de charge :", error);
        }
      };
    return (
        <>
		 <div className="breadcrumbbar"> 
            <div className="row align-items-center">
                    <div className="col-md-8 col-lg-8">
                        <h4 className="page-title">{t(' types charges')} </h4>
                        <div className="breadcrumb-list mb-3">
                         <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                </Link>
                            </li>
                                  <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t(' types charges')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t(' types charges')}</li>
                                </ol>
                    </div>
                
                    </div>
                   
                </div>              
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header"> 
                              <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajouter-type-charges" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                              </div>                               
                               
                            </div>
                            <div className="card-body">
                            <div className="table-responsive">
                           

                                <table id="default-datatable" className="display table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Charge</th>
                                        <th>Sous-Charge</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((charge, index) => (
                                        <React.Fragment key={index}>
                                        <tr>
                                            <td>{charge.type_charge}</td>
                                            <td className="text-center"></td> 
                                            <td style={{ textAlign: "center" }}>
                                            <Link to={`/modifier-type-charge/${charge.id}`} className="btn btn-success-rgba btn-round mb-1" style={{ marginRight: '10px'}}>
                                                <i className="feather icon-edit-2 " />
                                            </Link>
                                            <button
                                                onClick={() => openDeleteConfirmBox(charge.id)}
                                                className="btn btn-danger-rgba btn-round "
                                                style={{ marginRight: '10px'}}
                                            >
                                                <i className="feather icon-trash " />
                                            </button>
                                            </td>
                                        </tr>

                                        {charge.sous_charges?.map((subCharge, subIndex) => (
                                            <tr key={`${index}-${subIndex}`} className="subcharge-row">
                                            <td></td>
                                            <td>{subCharge.type_charge}</td>
                                            
                                            <td style={{ textAlign: "center" }}>
                                                <Link to={`/modifier-type-charge/${subCharge.id}`} className="btn btn-success-rgba  btn-round mb-1" style={{ marginRight: '10px'}}>
                                                <i className="feather icon-edit-2" />
                                                </Link>
                                                <button
                                                    onClick={() => openDeleteConfirmBox(subCharge.id)}                                             
                                                    className="btn btn-danger-rgba btn-round"
                                                    style={{ marginRight: '10px'}}
                                                >
                                                <i className="feather icon-trash" />
                                                </button>
                                            </td>
                                            </tr>
                                        ))}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between mb-3">
                        <div>
                          <select
                            className="form-control"
                            id="items-per-page"
                            onChange={handleItemsPerPageChange}
                            value={itemsPerPage}
                          >
                            <option value={2}>2</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </select>
                        </div>
                        <Pagination
                          items={filteredcharges}
                          itemsPerPage={itemsPerPage}
                          onPageChange={handlePageChange}
                        />
                      </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmBox
            open={showConfirmBox}
            closeDialog={closeConfirmBox}
            title="Confirmation de suppression"
            deleteFunction={handleDeleteCharge}
          />
    
   

</>

  );
}

export default ListerTypeCharge;
