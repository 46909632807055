import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: 'area-chart',
                    type: 'area',
                    height: 250,
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
                },
                yaxis: {
                    labels: {
                        show: true 
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        show: true 
                    },
                    y: {
                        formatter: function (val) {
                            return val; 
                        }
                    }
                },
                series: [
                    {
                        name: 'Ventes',
                        data: [130, 90, 150, 150, 109, 60, 90, 91, 125, 130, 95, 150],
                        color: '#00ff00'
                    },
                    {
                        name: 'Retours',
                        data: [3, 2, 1, 10, 5, 0, 12, 0, 15, 20, 3, 6],
                        color: '#FFB4A3'
                    }
                ]
            }
        };
    }

    render() {
        return (
            <ReactApexChart 
                options={this.state.options} 
                series={this.state.options.series} 
                type="area" 
                height="250" 
            />
        );
    }
}

export default AreaChart;


  /* import React, { Component } from 'react';
import axios from "../../../api/axios"; // Make sure the path to axios is correct
import ReactApexChart from 'react-apexcharts';

class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: 'area-chart',
                    type: 'area',
                    height: 300,
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
                },
                yaxis: {
                    labels: {
                        show: true 
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        show: true 
                    },
                    y: {
                        formatter: function (val) {
                            return val; 
                        }
                    }
                },
                series: [
                    {
                        name: 'Ventes',
                        data: [],
                        color: '#00ff00'
                    },
                    {
                        name: 'Retours',
                        data: [],
                        color: '#FFB4A3'
                    }
                ]
            }
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            // Récupération des données de ventes
            const salesResponse = await axios.get('/bonslivraisons'); // Endpoint pour les ventes
            const salesData = salesResponse.data; // Supposons que les données de ventes sont au format approprié

            // Récupération des données de retours
            const returnsResponse = await axios.get('/bon_retour_cls'); // Endpoint pour les retours
            const returnsData = returnsResponse.data; // Supposons que les données de retours sont au format approprié

            // Process data if necessary
            const processedSalesData = salesData.map(item => item.value); // Modify this line as needed
            const processedReturnsData = returnsData.map(item => item.value); // Modify this line as needed

            this.setState({
                options: {
                    ...this.state.options,
                    series: [
                        { name: 'Ventes', data: processedSalesData, color: '#00ff00' },
                        { name: 'Retours', data: processedReturnsData, color: '#FFB4A3' }
                    ]
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    render() {
        return (
            <ReactApexChart 
                options={this.state.options} 
                series={this.state.options.series} 
                type="area" 
                height="300" 
            />
        );
    }
}

export default AreaChart;

*/