import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useQuery } from 'react-query';
import { fetchCategories, fetchArticles } from '../../../services/articleService';

ChartJS.register(ArcElement, Tooltip, Legend);

const ArticleParCategorie = () => {
  const { data: categories, error: categoriesError, isLoading: categoriesLoading } = useQuery('categories', fetchCategories);
  const { data: articles, error: articlesError, isLoading: articlesLoading } = useQuery('articles', fetchArticles);

  if (categoriesLoading || articlesLoading) return <p>Loading...</p>;
  if (categoriesError || articlesError) return <p>Error loading data</p>;

  const countByCategory = categories.reduce((acc, category) => {
    acc[category.id] = articles.filter(article => article.categorie_id === category.id).length;
    return acc;
  }, {});

  const data = {
    labels: categories.map(categorie => categorie.nom_categorie),
    datasets: [{
      data: categories.map(categorie => countByCategory[categorie.id] || 0),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
    }],
  };

  return (
    <div className="container my-4">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <Pie data={data} width={200} height={200} />
      </div>
      <table className="table table-striped table-bordered w-100 mt-4  centered-table">
        <thead>
          <tr>
            <th>Categorie</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(categorie => (
            <tr key={categorie.id}>
              <td>{categorie.nom_categorie}</td>
              <td>{countByCategory[categorie.id] || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArticleParCategorie;
