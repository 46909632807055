import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from 'react-router-dom';
import $ from 'jquery'; 

function AjouterPersonnel() {

    const navigate = useNavigate();
    const [nom_complet, setNomComplet] = useState('');
    const [responsabilite, setResponsabilite] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [ville, setVille] = useState('');
    const [CIN, setCIN] = useState('');
    const [photo, setPhoto] = useState(null); 
    const [selectedMagasin, setSelectedMagasin] = useState("");
    const [magasins, setMagasins] = useState([]);

    const handleFileChange = (e) => {
        setPhoto(e.target.files[0]); 
    };
    
 useEffect(() => {
        (function() {
            $('.input-file').each(function() {
                var $input = $(this),
                    $label = $input.next('.js-labelFile'),
                    labelVal = $label.html();
                
                $input.on('change', function(element) {
                    var fileName = '';
                    if (element.target.value) fileName = element.target.value.split('\\').pop();
                    fileName ? $label.addClass('has-file').find('.js-fileName').html(fileName) : $label.removeClass('has-file').html(labelVal);
                });
            });
        })();
    }, []); 

    useEffect(() => {
        axios.get("/magasins")
            .then(response => {
                setMagasins(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des magasins :", error);
            });
    }, []);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const formData = new FormData(); 
            formData.append('nom_complet', nom_complet);
            formData.append('responsabilite', responsabilite);
            formData.append('adresse', adresse);
            formData.append('telephone', telephone);
            formData.append('ville', ville);
            formData.append('CIN', CIN);
            formData.append('id_magasin', selectedMagasin); 
            formData.append('photo', photo);
            const response = await axios.post('/personnel', formData);
            Swal.fire('Success', 'Personnel added successfully', 'success');
            navigate('/personnels');
        } catch (error) {
            console.error('Error adding personnel:', error);
            if (error.response) {
                Swal.fire('Error', `Failed to add personnel: ${error.response.data.message || JSON.stringify(error.response.data.errors)}`, 'error');
            } else {
                Swal.fire('Error', 'Failed to add personnel', 'error');
            }
        }
    };
    
    return (
        <>
           
            
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter personnel</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Nom & Prenom</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nom_complet"
                                                    value={nom_complet}
                                                    onChange={(e) => setNomComplet(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Responsabilite</label>
                                                <select
                                                    className="form-select"
                                                    value={responsabilite}
                                                    onChange={(e) => setResponsabilite(e.target.value)}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="chef personnel">chef personnel</option>
                                                    <option value="Comptable">Comptable</option>
                                                    <option value="Directeur">Directeur</option>
                                                    <option value="Gérant des ventes">Gérant des ventes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Adresse</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={adresse}
                                                    onChange={(e) => setAdresse(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Téléphone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={telephone}
                                                    onChange={(e) => setTelephone(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Ville</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={ville}
                                                    onChange={(e) => setVille(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">CIN</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={CIN}
                                                    onChange={(e) => setCIN(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Magasin</label>
                                                <select
                                                    name="nom_magasin"
                                                    className="form-control"
                                                    value={selectedMagasin}
                                                    onChange={(e) => setSelectedMagasin(e.target.value)}
                                                    required
                                                >
                                                    <option key="" value=""></option>
                                                    {magasins.map(magasin => (
                                                        <option key={magasin.id} value={magasin.id}>
                                                            {magasin.nom_magasin}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-1 col-md-4">Choisir une image:</label>
                                                <input
                                                    type="file"
                                                    className="input-file form-control"
                                                    onChange={handleFileChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/personnels">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>
                                        </div>              
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default AjouterPersonnel;
