import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";

function ListerFacture() {
    const {t}=useTranslation();
    const [factures, setFactures] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredfactures, setFilteredfactures] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage,setItemsPerPage] = useState(1);
  
    //sorting
    const columns = [
        { key: 'Facture', label: 'Facture', sortable: true },
        { key: 'Client', label: 'Client', sortable: false },
        { key: 'Date', label: 'Date', sortable: true },
        { key: 'Montant', label: 'Montant', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
    ];
  
   //logique recherche 
   const handleSearchChange = (term) => {
    setSearchTerm(term);
  
    const filtered = factures.filter((commande) => {
        return Object.keys(commande).some((key) => {
            if (typeof commande[key] === 'string') {
                return commande[key].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
    });
  
    setFilteredfactures(filtered);
  };
  
  useEffect(() => {
    setFilteredfactures(factures); 
  }, [factures]);
  
    //pagination debut
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex); 
      };
      
      const offset = currentPage * itemsPerPage; 
      const currentItems = filteredfactures.slice(offset, offset + itemsPerPage); 
      console.log('Nombre de lignes affichées:', currentItems.length);
    
    //end pagination
     //temsPerPage 
     const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value)); 
        setCurrentPage(0); 
      };
      
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("/factures");
                setFactures(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des factures :", error);
            }
        };

        fetchData();
    }, []);

    
    return (
        <>
            <div className="breadcrumbbar">
                        <div className="row align-items-center">
                            <div className="col-md-8 col-lg-8">
                                <h4 className="page-title">{t('Factures')}</h4>
                                <div className="breadcrumb-list mb-3">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/home">
                                            <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                            </Link>
                                        </li>
                                            <li className="breadcrumb-item">
                                                    <Link to="/home" style={{textDecoration:'none'}}>{t('Factures')}</Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('Factures')}</li>
                                            </ol>
                                    </div>
                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                        <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                        <SortingTable
                                       data={currentItems.map((facture) => ({
                                        id:facture.id,
                                        Facture:facture.code,
                                        Client: facture.nom_client +' '+ facture.prenom_client,
                                        Date: facture.date_fac,
                                        Montant: facture.montant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                                        actions: (
                                            <div className="text-center">
                                                <Link
                                                                    to={`https://admin.mansoft.ma/api/factures/pdf/${facture.id}`}
                                                                    title="Consulter" data-toggle="tooltip" className="btn btn-danger-rgba btn-round mb-1"
                                                                    style={{ marginRight: '10px' }}
                                                                    target="_blank">
                                                                    <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "18px" }} />
                                                                </Link>
                                                                <Link
                                                                    to={`/creer-avoir/${facture.id}`} className="btn btn-warning-rgba btn-round"
                                                                    title="Créer Avoir" data-toggle="tooltip"
                                                                    style={{ marginRight: '10px' }}>
                                                                    <i className="feather icon-repeat" style={{ fontSize: "small" }}></i>
                                                                </Link>
                                            </div>
                                            ),
                                        }))}
                                    columns={columns}
                                    />
                                    <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <select
                                        className="form-control"
                                        id="items-per-page"
                                        onChange={handleItemsPerPageChange}
                                        value={itemsPerPage}
                                            >
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            </select>
                                        </div>
                                        <Pagination
                                                items={filteredfactures} 
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageChange}
                                       />

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
            
            
        </>
    );
}

export default ListerFacture;
