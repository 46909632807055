import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery'; 

function ModifierPersonnel() {

    const navigate = useNavigate();
    const { id } = useParams(); 
    const [selectedPersonnelImage, setselectedPersonnelImage] = useState(null);

    const [photo, setPhoto] = useState(null); 
    const [magasins, setMagasins] = useState([]);
    const [personnelData, setPersonnelData] = useState({
        nom_complet: "",
        adresse: "",
        telephone: "",
        ville: "",
        CIN: "",
        responsabilite: "",
        id_magasin: ""
    });

    useEffect(() => {
        (function() {
            $('.input-file').each(function() {
                var $input = $(this),
                    $label = $input.next('.js-labelFile'),
                    labelVal = $label.html();
                
                $input.on('change', function(element) {
                    var fileName = '';
                    if (element.target.value) fileName = element.target.value.split('\\').pop();
                    fileName ? $label.addClass('has-file').find('.js-fileName').html(fileName) : $label.removeClass('has-file').html(labelVal);
                });
            });
        })();
    }, []); 

    useEffect(() => {
        axios.get(`/personnel/${id}`)
            .then(response => {
                setPersonnelData(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching personnel data:", error);
            });
    }, [id]);

    useEffect(() => {
        axios.get("/magasins")
            .then(response => {
                setMagasins(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des magasins :", error);
            });
    }, []);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('nom_complet', personnelData.nom_complet);
        formData.append('adresse', personnelData.adresse);
        formData.append('telephone', personnelData.telephone);
        formData.append('ville', personnelData.ville);
        formData.append('CIN', personnelData.CIN);
        formData.append('responsabilite', personnelData.responsabilite);
        formData.append('id_magasin', personnelData.id_magasin);
        
        if (photo) {
            formData.append('photo', photo);
        }
        
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
        
        try {
            const response = await axios.put(`/personnel/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log('Personnel updated successfully:', response.data);
            Swal.fire('Success', 'Personnel updated successfully', 'success');
            navigate('/personnels');
        } catch (error) {
            console.error('Error updating personnel:', error);
            Swal.fire('Error', 'Failed to update personnel', 'error');
        }
    };
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPersonnelData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setselectedPersonnelImage(previewUrl);
            setPhoto(file); 
            console.log("Selected file:", file); 
        }
    };
    
   
    return (
        <>
       
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Modifier personnel</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Nom & Prenom</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nom_complet"
                                                    value={personnelData.nom_complet}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Responsabilite</label>
                                                <select
                                                    className="form-control"
                                                    name="responsabilite"
                                                    value={personnelData.responsabilite}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Comptable">Comptable</option>
                                                    <option value="Directeur">Directeur</option>
                                                    <option value="Gérant des ventes">Gérant des ventes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Adresse</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="adresse"
                                                    value={personnelData.adresse}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Téléphone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="telephone"
                                                    value={personnelData.telephone}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Ville</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="ville"
                                                    value={personnelData.ville}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">CIN</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="CIN"
                                                    value={personnelData.CIN}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Magasin</label>
                                                <select
                                                    className="form-control"
                                                    name="id_magasin"
                                                    value={personnelData.id_magasin}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    {magasins.map(magasin => (
                                                        <option key={magasin.id} value={magasin.id}>
                                                            {magasin.nom_magasin}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                    <label className="me-2 col-md-4">Image:</label>
                                    <input
                                        type="file"
                                        className="input-file form-control"
                                        onChange={handleFileChange}
                                    />

                                    {selectedPersonnelImage ? (
                                        <img
                                        src={selectedPersonnelImage}
                                        alt="Selected"
                                        className="image-preview"
                                        style={{ maxWidth: "100px", marginLeft: "7px" }}
                                        />
                                    ) : (
                                        <div className="col-md-3 d-flex align-items-center mb-3">
                                        {personnelData.photo && (
                                            <img
                                            src={`https://admin.mansoft.ma/${personnelData.photo}`}
                                            alt="articles"
                                            className="image-preview"
                                            style={{ maxWidth: "90px", marginLeft: "30px" }}
                                            />
                                        )}
                                        </div>
                                    )}
                                    </div>
                                    </div>

                                            
                                       
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/personnels">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>
                                        </div>              
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    );
}

export default ModifierPersonnel;
