import axios from '../api/axios';

//fournisseurs
 export const fetchFournisseurs = async ()=>{
   try {
        const response = await axios.get('/fournisseurs');
        return response.data;

      }
    catch(error){
     console.error("error feching fournisseurs:",error);
     throw error;
 }
 }
 //ligne bon entree

 export const fetchBonEntree = async ()=>{
  try {
       const response = await axios.get('/bon-entrees');
       return response.data;

     }
   catch(error){
    console.error("error feching bon-entrees:",error);
    throw error;
}
}

 //ligne bon entree
export const fetchLigneBonEntree = async ()=>{
  try {
       const response = await axios.get('/ligne-bon-entree-fr');
       return response.data;

     }
   catch(error){
    console.error("error feching bon-entrees:",error);
    throw error;
}
}
export const fetchForCommandes = async () => {
  const response = await axios.get('/ligne-commande-fors');
  return response.data;
};

