import React from 'react';

const StatCard = ({ title, value, trend, trendDirection }) => {
  return (
    <div className="card">
      <div className="card-body text-center">
        <h5 className={trendDirection === 'up' ? 'text-success' : 'text-danger'}>
          {value} <span>{trendDirection === 'up' ? '↑' : '↓'}</span>
        </h5>
        <p className="card-text">{title}</p>
        <p className={trendDirection === 'up' ? 'text-success' : 'text-danger'}>
        </p>
      </div>
    </div>
  );
};

export default StatCard;
