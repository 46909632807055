import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchArticles, fetchligneBonEntree } from '../../../services/articleService';
import * as XLSX from 'xlsx';

const AchatParArticle = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Fetch articles and article commandes using react-query
  const { data: articles, error: articlesError, isLoading: articlesLoading } = useQuery('articles', fetchArticles);
  const { data: articleCommandes, error: articleCommandesError, isLoading: articleCommandesLoading } = useQuery('articleCommandes', fetchligneBonEntree);

  const filteredData = useMemo(() => {
    if (!articles || !articleCommandes) return [];

    const filteredArticleCommandes = articleCommandes.filter(cmd => {
      const createdAt = new Date(cmd.created_at);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || createdAt >= start) && (!end || createdAt <= end);
    });

    return articles.map(article => {
      const commandes = filteredArticleCommandes.filter(cmd => cmd.id_article === article.id);
      const totalQuantity = commandes.reduce((sum, cmd) => sum + cmd.quantite, 0);
      const totalAmount = commandes.reduce((sum, cmd) => sum + (cmd.quantite * cmd.montant), 0);

      return {
        ...article,
        totalQuantity,
        totalAmount
      };
    }).filter(article => article.totalQuantity > 0); // Filter articles with quantity > 0
  }, [articles, articleCommandes, startDate, endDate]);

  const noData = filteredData.length === 0;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Achat_par_article');
    XLSX.writeFile(workbook, 'Achat_par_article.xlsx');
  };

  if (articlesLoading || articleCommandesLoading) return <p>Loading...</p>;
  if (articlesError || articleCommandesError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
    <div className="row mb-4 align-items-center">
      <div className="col-md-5 col-sm-12 mb-4">
        <label className="form-label">{t('Date de début')} :</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-md-5 col-sm-12 mb-4">
        <label className="form-label">{t('Date de fin')} :</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-md-2 col-sm-12 d-flex justify-content-end">
        <Link className="btn btn-primary" onClick={exportToExcel}>
          <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
        </Link>
      </div>
    </div>
    {noData ? (
      <div className="m-alert m-alert--outline alert alert-dismissible fade show alert-warning">
        {t('Aucune donnée disponible pour la période sélectionnée')}
      </div>
    ) : (
      <div className='table-responsive'>
        <table className="table table-striped table-bordered w-100 mt-4  centered-table">
          <thead>
            <tr>
              <th>{t('Article')}</th>
              <th>{t('Quantite')}</th>
              <th>{t('Montant HT')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((article) => (
              <tr key={article.id}>
                <td>{article.nom_article}</td>
                <td>{article.totalQuantity}</td>
                <td>{article.totalAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
           )}
         </div>
       );
     };

export default AchatParArticle;
