import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchArticleCommandes } from '../../../services/articleCommandeService';
import * as XLSX from 'xlsx';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const CommandeParMois = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data: articleCommandes, error: articleCommandesError, isLoading: articleCommandesLoading } = useQuery('articleCommandes', fetchArticleCommandes);

  const filteredData = React.useMemo(() => {
    if (!articleCommandes) return [];

    const filteredArticleCommandes = articleCommandes.filter(cmd => {
      const createdAt = new Date(cmd.created_at);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || createdAt >= start) && (!end || createdAt <= end);
    });

    const groupedData = filteredArticleCommandes.reduce((acc, cmd) => {
      const month = new Date(cmd.created_at).toISOString().slice(0, 7);
      const key = `${cmd.id_commande_cl}-${month}`; 

      if (!acc[key]) {
        acc[key] = { month, totalAmount: 0, count: 0, uniqueCommandCount: new Set() };
      }

      acc[key].totalAmount += cmd.quantite * cmd.pu_vente;
      acc[key].count += cmd.quantite;
      acc[key].uniqueCommandCount.add(cmd.id_commande_cl);

      return acc;
    }, {});

    return Object.entries(groupedData).map(([key, { month, totalAmount, count, uniqueCommandCount }]) => ({
      month,
      totalAmount,
      count,
      uniqueCommandCount: uniqueCommandCount.size,
    }));
  }, [articleCommandes, startDate, endDate]);

  const calculatedData = React.useMemo(() => {
    return filteredData.map((data, index) => {
      const previousData = filteredData[index - 1];
      const evolution = previousData ? ((data.totalAmount - previousData.totalAmount) / previousData.totalAmount) * 100 : 0;
      return { ...data, evolution: evolution.toFixed(2) };
    });
  }, [filteredData]);

  const noData = filteredData.length === 0;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(calculatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Commande_par_Mois');
    XLSX.writeFile(workbook, 'Commande_par_Mois.xlsx');
  };

  const lineChartData = {
    labels: calculatedData.map(d => d.month),
    datasets: [
      {
        label: t('Montant'),
        data: calculatedData.map(d => d.totalAmount),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t('Mois'),
        },
      },
      y: {
        title: {
          display: true,
          text: t('Montant'),
        },
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  if (articleCommandesLoading) return <p>Loading...</p>;
  if (articleCommandesError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="row mb-4 align-items-center">
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de début')} :</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de fin')} :</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-2 col-sm-12 d-flex justify-content-end">
          <Link className="btn btn-primary" onClick={exportToExcel}>
            <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
          </Link>
        </div>
      </div>

      {noData ? (
        <div className="m-alert m-alert--outline alert alert-dismissible fade show alert-warning">
          {t('Aucune donnée disponible pour la période sélectionnée')}
        </div>
      ) : (
        <div>
          <h6>{t('Évolution des commandes par Mois')}</h6>
          <div className="chart-container" style={{ width: '100%', maxWidth: '900px', height: '300px', margin: '0 auto' }}>
            <Line data={lineChartData} options={options} ref={chartRef} />
          </div>
          <div className='table-responsive'>
          <table className="table table-striped table-bordered w-100 mt-4">
            <thead>
              <tr>
                <th>{t('Mois')}</th>
                <th>{t('Quantité')}</th>
                <th>{t('Montant Total')}</th>
                <th>{t('Nombre de Commandes')}</th>
                <th>{t('Evolution (%)')}</th>
              </tr>
            </thead>
            <tbody>
              {calculatedData.map(({ month, count, totalAmount, uniqueCommandCount, evolution }) => (
                <tr key={month}>
                  <td>{month}</td>
                  <td>{count}</td>
                  <td>{totalAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                  <td>{uniqueCommandCount}</td>
                  <td><span style={{ color: evolution > 0 ? 'green' : 'red' }}>{evolution > 0 ? '+' : ''}{evolution}%</span></td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommandeParMois;
