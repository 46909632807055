import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import { useAuth } from "../../layouts/AuthContext";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import Search from "../../layouts/Search";
import { useTranslation } from "react-i18next";

function ListerInventaire() {
  const {t}=useTranslation();
  const [inventaires, setInventaires] = useState([]);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [inventaireIdToDelete, setInventaireIdToDelete] = useState(null);
  const { userName } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredinventaires, setFilteredinventaires] = useState([]); 
  const [searchTerm, setSearchTerm] = useState(''); 
  const [itemsPerPage,setItemsPerPage] = useState(1);

  //sorting
  const columns = [
      { key: 'Code', label: 'Code', sortable: true },
      { key: 'Utilisateur', label: 'Utilisateur', sortable: false },
      { key: 'Date', label: 'Date', sortable: true },
      { key: 'Description', label: 'Description', sortable: true },
      { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
  ];

 //logique recherche 
 const handleSearchChange = (term) => {
  setSearchTerm(term);

  const filtered = inventaires.filter((commande) => {
      return Object.keys(commande).some((key) => {
          if (typeof commande[key] === 'string') {
              return commande[key].toLowerCase().includes(term.toLowerCase());
          }
          return false;
      });
  });

  setFilteredinventaires(filtered);
};

useEffect(() => {
  setFilteredinventaires(inventaires); 
}, [inventaires]);

  //pagination debut
  const handlePageChange = (pageIndex) => {
      setCurrentPage(pageIndex); 
    };
    
    const offset = currentPage * itemsPerPage; 
    const currentItems = filteredinventaires.slice(offset, offset + itemsPerPage); 
    console.log('Nombre de lignes affichées:', currentItems.length);
  
  //end pagination
   //temsPerPage 
   const handleItemsPerPageChange = (event) => {
      setItemsPerPage(parseInt(event.target.value)); 
      setCurrentPage(0); 
    };
    
  useEffect(() => {
    const fetchInventaires = async () => {
      try {
        const response = await axios.get("/inventaires");
        setInventaires(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des inventaires :", error);
      }
    };

    fetchInventaires();
  }, []);

  const openDeleteConfirmBox = (inventaireId) => {
    setInventaireIdToDelete(inventaireId);
    setShowConfirmBox(true); 
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false); 
    setInventaireIdToDelete(null);
  };

  const handleDeleteInventaire = async () => {
    if (!inventaireIdToDelete) {
      console.error("Aucun ID d'inventaire à supprimer");
      return;
    }

    try {
      await axios.delete(`/inventaires/${inventaireIdToDelete}`);

      setInventaires(
        inventaires.filter(inventaire => inventaire.id !== inventaireIdToDelete)
      );

      setShowConfirmBox(false);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'inventaire :", error);
    }
  };

  return (
    <>
      
       
          <div className="breadcrumbbar">
          <div className="row align-items-center">
              <div className="col-md-8 col-lg-8">
                <h4 className="page-title">{t('Inventaires')}</h4>
                <div className="breadcrumb-list mb-3">
                         <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                </Link>
                            </li>
                                  <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Inventaires')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('Inventaires')}</li>
                                </ol>
                    </div>
              </div>
            </div> 
            <div className="row">
              <div className="col-lg-12m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                  <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajouter-inventaire" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                              </div> 
                    
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                       <SortingTable
                                    data={currentItems.map((inventaire) => ({
                                        id:inventaire.id,
                                        Code:inventaire.code,
                                        Utilisateur: userName,
                                        Date: inventaire.date,
                                        Description: inventaire.observation,
                                        actions: (
                                            <div className="text-center">
                                                <Link
                                                    to={`/modifier-inventaire/${inventaire.id}`}
                                                    className="btn btn-success-rgba btn-round mb-1"
                                                    style={{ marginRight: "10px" }}
                                                  >
                                                    <i className="feather icon-edit-2" />
                                                  </Link>
                                                  <button
                                                    onClick={() => openDeleteConfirmBox(inventaire.id)}
                                                    className="btn btn-danger-rgba btn-round"
                                                    style={{ marginRight: "10px" }}

                                                  >
                                                    <i className="feather icon-trash" />
                                                  </button>
                                            </div>
                                            ),
                                        }))}
                                    columns={columns}
                                    />
                                    <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <select
                                        className="form-control"
                                        id="items-per-page"
                                        onChange={handleItemsPerPageChange}
                                        value={itemsPerPage}
                                            >
                                            <option value={2}>2</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            </select>
                                        </div>
                                        <Pagination
                                                items={filteredinventaires} 
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageChange}
                                          />
                                     </div> 
                              </div>
                     </div>
                </div>
              </div>
            </div>
          </div>
          
          <ConfirmBox
            open={showConfirmBox}
            closeDialog={closeConfirmBox}
            title="Confirmation de suppression"
            deleteFunction={handleDeleteInventaire}
          />
      
    </>
  );
}

export default ListerInventaire;
