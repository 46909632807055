import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import Search from "../../layouts/Search";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import { useQuery } from 'react-query';
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';

function ListeFournisseur() {
    const {t}= useTranslation();
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [fournisseurIdToDelete, setFournisseurIdToDelete] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredFournisseurs, setFilteredFournisseurs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { data: fournisseurs = [], error, isLoading } = useQuery('fournisseurs', async () => {
        const response = await axios.get("/fournisseurs");
        return response.data;
    });

    useEffect(() => {
        setFilteredFournisseurs(fournisseurs);
    }, [fournisseurs]);

    useEffect(() => {
        const filtered = fournisseurs.filter((fournisseur) => {
            return columns.some((col) => {
                if (typeof fournisseur[col.key] === 'string') {
                    return fournisseur[col.key].toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });
        });

        setFilteredFournisseurs(filtered);
        setCurrentPage(0);
    }, [searchTerm, fournisseurs]);

    const handleDeleteFournisseur = async () => {
        if (fournisseurIdToDelete === undefined || fournisseurIdToDelete === null) {
            console.error("Pas de fournisseur pour le supprimer.");
            return;
        }

        try {
            await axios.delete(`/fournisseurs/${fournisseurIdToDelete}`);
            setFilteredFournisseurs((prevFournisseurs) =>
                prevFournisseurs.filter((fournisseur) => fournisseur.id !== fournisseurIdToDelete)
            );
            closeConfirmBox();
        } catch (error) {
            console.error("Erreur lors de la suppression du fournisseur:", error);
        }
    };

    const openDelete = (fourId) => {
        setFournisseurIdToDelete(fourId);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setFournisseurIdToDelete(null);
    };

    const columns = [
        { key: 'nom_fournisseur', label: 'nom_fournisseur', sortable: true },
        { key: 'email', label: 'email', sortable: false },
        { key: 'telephone', label: 'telephone', sortable: true },
        { key: 'fax', label: 'fax', sortable: true },
        { key: 'societe', label: 'societe', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
    ];

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredFournisseurs.slice(offset, offset + itemsPerPage);

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    // if (isLoading) return <p>Loading...</p>;
    // if (error) return <p>Error loading fournisseurs: {error.message}</p>;

    return (
        <>
            
                
                    <div className="breadcrumbbar">
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('Fournisseurs')}</h4>
                            <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Fournisseurs')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('Fournisseurs')}</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                      <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajouter-fournisseur" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                       </div>
                                        
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((fournisseur) => ({
                                                    id: fournisseur.id,
                                                    nom_fournisseur: fournisseur.nom_fournisseur,
                                                    email: fournisseur.email,
                                                    telephone: fournisseur.telephone,
                                                    fax: fournisseur.fax,
                                                    societe: fournisseur.societe,
                                                    actions: (
                                                        <div className="text-center">
                                                            <Link
                                                                to={`/prix_categorie_fournisseur/${fournisseur.id}`}
                                                                className="btn btn-primary-rgba btn-round mb-1"
                                                                title="prix"
                                                                style={{ color: "#f08167", marginRight: '10px', display: 'inline-block', verticalAlign: 'middle' }}
                                                            >
                                                                <FontAwesomeIcon icon={faHandHoldingDollar} />
                                                            </Link>
                                                            <Link
                                                                to={`/modifier-fournisseur/${fournisseur.id}`}
                                                                className="btn btn-success-rgba btn-round mb-1"
                                                                title="Edit"
                                                                style={{ marginRight: '10px', display: 'inline-block', verticalAlign: 'middle' }}
                                                            >
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link
                                                                onClick={() => openDelete(fournisseur.id)}
                                                                className="btn btn-danger-rgba btn-round mb-1"
                                                                style={{ marginRight: '10px', display: 'inline-block', verticalAlign: 'middle' }}

                                                            >
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredFournisseurs}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                         )}
                                         </>
                                     )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
                <ConfirmBox
                    open={showConfirmBox}
                    closeDialog={closeConfirmBox}
                    title="Fournisseur"
                    deleteFunction={handleDeleteFournisseur}
                />
           
        </>
    );
}

export default ListeFournisseur;
