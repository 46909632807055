import axios from '../api/axios'; 

export const fetchBonsLivraisons = async () => {
    try {
        const response = await axios.get('/bonslivraisons');
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des bons de livraison:', error);
        throw error;
    }
};

export const fetchClient = async (clientId) => {
    try {
        const response = await axios.get(`/clients/${clientId}`);
        return response.data;
    } catch (error) {
        console.error(`Erreur lors de la récupération du client ${clientId}:`, error);
        throw error;
    }
};

export const fetchLigneBonLiv = async (bonLivraisonId) => {
    const response = await axios.get(`/ligne-bon-liv-cls?bon_livraison_id=${bonLivraisonId}`);
    return response.data;
};

export const fetchPaiementClient = async (livraisonCode) => {
    const response = await axios.get(`/Paiementclient?livraison_code=${livraisonCode}`);
    return response.data;
};

export const deleteLigneBonLiv = async (ligneId) => {
    await axios.delete(`/ligne-bon-liv-cls/${ligneId}`);
};

export const deleteBonLivraison = async (bonLivraisonId) => {
    await axios.delete(`/bonslivraisons/${bonLivraisonId}`);
};

export const createFacture = async (bonLivraisonId, montant) => {
    const dateFac = new Date().toISOString().split('T')[0];
    const response = await axios.post('/factures', {
        bon_livraison_id: bonLivraisonId,
        date_fac: dateFac,
        montant: montant,
    });
    return response.data;
};
//bon retour jsx
export const fetchBonRetour = async () => {
    const response = await axios.get("/bon_retour_cls");
    return Promise.all(response.data.map(async bl => {
        const bonLivraisonResponse = await axios.get(`/bonslivraisons/${bl.id_bonlivraison}`);
        const bonLivraisonData = bonLivraisonResponse.data;
        const clientResponse = await axios.get(`/clients/${bonLivraisonData.id_client}`);
        const clientData = clientResponse.data;

        const LignBRResponse = await axios.get(`/ligne-bon-rcls/${bl.id}`);
        const ligneBRData = LignBRResponse.data;

        // If ligneBRData is an object, wrap it in an array
        const ligneBRArray = Array.isArray(ligneBRData) ? ligneBRData : [ligneBRData];

        const montant = ligneBRArray.reduce(
            (total, ligne) => total + ligne.montant * ligne.quantite,
            0
        );

        return {
            ...bl,
            client: `${clientData.nom_client} ${clientData.prenom_client}`,
            montant,
        };
    }));
};


export const fetchUserData = async (userId) => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
  };
  
  export const fetchPersonnelData = async (personnelId) => {
    const response = await axios.get(`/personnel/${personnelId}`);
    return response.data;
  };

  //compoennt commandeclin 

  export const fetchCommandes = async () => {
    const response = await axios.get('/commandeclient');
    const commandes = response.data;

    const clientPromises = commandes.map(async (commande) => {
        try {
            if (commande.idclient !== null) {
                const clientResponse = await axios.get(`/clients/${commande.idclient}`);
                const clientData = clientResponse.data;
                return {
                    ...commande,
                    client: `${clientData.nom_client} ${clientData.prenom_client}`,
                    societe: clientData.societe
                };
            } else {
                return {
                    ...commande,
                    client: '',
                    societe: ''
                };
            }
        } catch (clientError) {
            console.error('Erreur lors de la récupération des données du client:', clientError);
            return {
                ...commande,
                client: '',
                societe: ''
            };
        }
    });

    return Promise.all(clientPromises);
};

// Fonction pour supprimer une commande
export const deleteCommande = async (id) => {
    // Obtenir les article-commandes associés à la commande
    const lignesCommandeResponse = await axios.get(`/article-commandes?id_commande_cl=${id}`);
    const lignesCommandeIds = lignesCommandeResponse.data.map(ligne => ligne.id);

    // Supprimer chaque article-commande
    await Promise.all(lignesCommandeIds.map(async (ligneId) => {
        await axios.delete(`/article-commandes/${ligneId}`);
    }));

    // Supprimer la commande elle-même
    await axios.delete(`/commandeclient/${id}`);
};
