import React, { useState, useEffect, useRef } from "react";
import axios from "../../api/axios";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Quagga from 'quagga';

function AjouterInventaire() {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [magasins, setMagasins] = useState([]);
  const [selectedMagasin, setSelectedMagasin] = useState(""); 
  const [dateInventaire, setDateInventaire] = useState("");
  const [description, setDescription] = useState("");
  const [articleCommandes, setArticleCommande] = useState([]);
  const [quantite, setQuantite] = useState("");
  const [prix, setPrix] = useState("");
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState("");
  const [selectedArticleName, setSelectedArticleName] = useState("");
  const [choixMode, setChoixMode] = useState("manuel");
  const scannerRef = useRef(null);

  // Effect for handling Quagga initialization
  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDropdownOpen(false);
  //   }
  // };


  useEffect(() => {
    if (choixMode === "scanner" && scannerRef.current) {
      const isMobile = window.innerWidth < 768;
  
      const width = isMobile ? window.innerWidth : 440;  // Full window width for mobile
      const height = isMobile ? window.innerHeight : 230; // Full window height for mobile
    
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: scannerRef.current,
            constraints: {
              width: width, // Ensure width is set correctly
              height: height, // Ensure height is set correctly
            },
          },
          locator: {
            patchSize: 'medium',
            halfSample: true,
          },
          locate: true,
          decoder: {
            readers: ["ean_reader", "code_128_reader"],
          },
        },
        (err) => {
          if (err) {
            console.error("Erreur lors de l'initialisation de Quagga:", err);
            return;
          }
          console.log("Quagga initialisé avec succès.");
          Quagga.start();
        }
      );
  
      Quagga.onDetected((data) => {
        if (data && data.codeResult && data.codeResult.code) {
          handleScanResult(data.codeResult.code);
        } else {
          console.error("Données de détection invalides :", data);
        }
      });
  
      // Stop Quagga when component is unmounted
      return () => {
        if (typeof Quagga !== "undefined" && typeof Quagga.stop === "function") {
          Quagga.stop();  // Stop the camera and scanning
          console.log("Quagga stopped.");
        }
      };
    }
  }, [choixMode, scannerRef]);
  
  
  const handleScanResult = (scanResult) => {
    console.log("Scanned Code:", scanResult); // Log the scanned code
  
    const foundArticle = articles.find(
      (article) => article.code_barre === scanResult
    );
  
    if (foundArticle ) {
      Swal.fire({
        icon: "success",
        title: "Article scanné",
        text: `L'article "${foundArticle.nom_article}" a été scanné avec succès.`,
      confirmButtonText: "OK", 
  }).then((result) => {
    if (result.isConfirmed) {
      handleAddRow();
    }
  });
  
      
      // Réinitialiser la sélection
      setSelectedArticleId(foundArticle.id);
      setSelectedArticleName(foundArticle.nom_article);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Article non trouvé",
        text: "L'article scanné n'existe pas dans la base de données.",
      });
    }
  };
  
  
  
  const handleQuantityChange = (index, newQuantity) => {
    const updatedCommandes = [...articleCommandes];
    updatedCommandes[index].quantite = newQuantity;
    setArticleCommande(updatedCommandes);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get("/articles");
        if (response && response.data) {
          setArticles(response.data);
          setFilteredArticles(response.data);
        } else {
          console.error("Réponse API invalide pour les articles :", response);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des articles :", error);
      }
    };

    const fetchMagasins = async () => {
      try {
        const response = await axios.get("/magasins");
        setMagasins(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des magasins :", error);
      }
    };

    fetchArticles();
    fetchMagasins();
  }, []);


  
  const handleSelectArticle = (id, nom) => {
    console.log("Article sélectionné :", id, nom);
    setSelectedArticleId(id);
    setSelectedArticleName(nom);
    setDropdownOpen(false);
  };
  
  const handleAddRow = () => {
    if (!selectedArticleId) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Aucun article sélectionné pour l'ajout.",
      });
      return;
    }
  
    const selectedArticleObj = articles.find(
      (article) => article.id === parseInt(selectedArticleId, 10)
    );
  
    if (!selectedArticleObj) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "L'article sélectionné n'a pas été trouvé.",
      });
      return;
    }
  
    const alreadyAdded = articleCommandes.some(
      (commande) => commande.id_article === selectedArticleId
    );
  
    if (alreadyAdded) {
      Swal.fire({
        icon: "warning",
        title: "Article déjà ajouté",
        text: `L'article "${selectedArticleObj.nom_article}" a déjà été ajouté.`,
      });
      return;
    }
  
    setArticleCommande([
      ...articleCommandes,
      {
        id_article: selectedArticleId,
        nom_article: selectedArticleObj.nom_article,
        quantite: quantite || 1,
        prix: selectedArticleObj.prix || 0,
      },
    ]);
  
    setSelectedArticleId("");
    setSelectedArticleName("");
    setSearchTerm("");
    setDropdownOpen(false);
    setQuantite("");
    setPrix("");
  if(choixMode === "scanner"){
    Swal.fire({
      icon: "success",
      title: "Ajouté",
      text: `L'article "${selectedArticleObj.nom_article}" a été ajouté avec succès.`,
    
    })
  };
  };
  
  
  
  const deleteArticle = (index) => {
    const updatedRows = [...articleCommandes];
    updatedRows.splice(index, 1);
    setArticleCommande(updatedRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const inventaireResponse = await axios.post("/inventaires", {
        date: dateInventaire,
        observation: description,
        magasin_id: selectedMagasin, 
      });

      if (inventaireResponse.status === 201) {
        const inventaireId = inventaireResponse.data.id; 

        await Promise.all(
          articleCommandes.map(async (commande) => {
            await axios.post("/inventaire-articles", {
              inventaire_id: inventaireId,
              article_id: commande.id_article,
              quantite: commande.quantite,
            });
          })
        );

        handleSuccess(); 
      } else {
        handleError(); 
      }
    } catch (error) {
      handleError(error); 
    }
  };

  const handleSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Succès",
      text: "Inventaire ajouté avec succès!",
    });
    navigate("/inventaires");
  };

  const handleError = (error) => {
    console.error("Erreur lors de l'ajout de l'inventaire :", error);
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Échec de l'ajout de l'inventaire. Veuillez réessayer plus tard.",
    });
  };

  const handleMagasinChange = (e) => {
    setSelectedMagasin(e.target.value);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = articles.filter(article =>
      article.nom_article.toLowerCase().includes(searchTerm)
    );
    setFilteredArticles(filtered);
  };

  return (
    <div className="breadcrumbbar">
      <div className="row">
        <div className="col-lg-12 m-b-50">
          <div className="card m-b-50">
            <div className="card-header">
              <h5 className="card-title">Ajouter Inventaire</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-md-4 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={dateInventaire}
                      onChange={(e) => setDateInventaire(e.target.value)}
                    />
                  </div>

                  <div className="col-md-4 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Magasin</label>
                    <select
                      className="form-select"
                      value={selectedMagasin}
                      onChange={handleMagasinChange} 
                    >
                      <option value=""></option>
                      {magasins.map((magasin) => (
                        <option key={magasin.id} value={magasin.id}>
                          {magasin.nom_magasin}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Description</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              
                <div className="form-row">
                  <div className="col-md-4 mb-3 d-flex align-items-center">
                    <label className="me-2 col-md-4">Mode</label>
                    <select
                      className="form-select"
                      value={choixMode}
                      onChange={(e) => setChoixMode(e.target.value)}
                    >
                      <option value="manuel">Choix manuel</option>
                      <option value="scanner">Scan automatique</option>
                    </select>
                  </div>

                  {choixMode === "manuel" ? (
                    <>
                    <div className="col-md-4 mb-3 d-flex align-items-center">
                      <label className="me-2 col-md-4">Article</label>
                      <div className="custom-select-wrapper" ref={dropdownRef}>
                        <div className="form-control" onClick={handleDropdownClick}>
                          {selectedArticleName ? selectedArticleName : ""}
                        </div>
                        {dropdownOpen && (
                          <div className="autocom-box">
                            <input
                              type="search"
                              aria-label="Search"
                              className="search-box form-control"
                              placeholder="Rechercher..."
                              value={searchTerm}
                              onChange={handleSearchChange}
                              onClick={() => setDropdownOpen(true)}
                            />
                            <ul>
                              {filteredArticles.map((article) => (
                                <li
                                  key={article.id}
                                  value={article.id}
                                  onClick={() => handleSelectArticle(article.id, article.nom_article)}
                                >
                                  {article.nom_article}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 mb-3 d-flex align-items-center">
                    <label className="me-2 col-md-4">Quantité</label>
                    <input
                      type="number"
                      className="form-control"
                      value={quantite}
                      onChange={(e) => setQuantite(e.target.value)}
                      min={1}
                    />
                  </div>
                    </>
                  ) : (
                    <>
                     <div className="form-row">
                        <div className="scanner-container" ref={scannerRef}>
                      </div>
                    </div>
                     
                 </>
                )}
                </div>

              {  choixMode== "manuel" ? (
               <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                    <i
                      className="feather icon-plus-circle btn btn-primary-rgba"
                      onClick={handleAddRow}
                      style={{ cursor: 'pointer' }}
                    ></i>
                  </div>
                ):(
                   <></>
                  )}
              {articleCommandes.length > 0 && (
                <div className="form-row">
                  <div className="col-md-12">
                  <table className="display table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Article</th>
                        <th>Quantité</th>
                        <th className="text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {articleCommandes.map((commande, index) => (
                        <tr key={index}>
                          <td>{commande.nom_article}</td>
                          <td>
                            <input
                              type="number"
                              value={commande.quantite}
                              min={1}
                              style={{width:'85px'}}
                              className="form-control"
                              onChange={(e) => handleQuantityChange(index, parseInt(e.target.value, 10))}
                            />
                          </td>
                          <td className="text-end">
                            <i
                              className="feather icon-trash btn btn-danger-rgba btn-round"
                              style={{ fontSize: "small" }}
                              onClick={() => deleteArticle(index)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                </div>
              )}

              <div className="d-grid gap-2 d-md-flex justify-content-md-end ">
                  <Link className="btn btn-warning my-1" to="/inventaires">Annuler</Link>
                  <button className="btn btn-success my-1" type="submit">Ajouter</button>
              </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterInventaire;
