import React, { useState, useEffect, useTransition } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { faFilterCircleDollar } from '@fortawesome/free-solid-svg-icons';
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import Search from "../../layouts/Search";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';
import { useMemo } from "react";
import { Modal, Button } from 'react-bootstrap';


const fetchClients = async () => {
    const response = await axios.get("/clients");
    return response.data;
};

const deleteClient = async (clientId) => {
    await axios.delete(`/clients/${clientId}`);
};

function ListerClient() {
    const { t }= useTranslation();
    const queryClient = useQueryClient();
    const { data: clients = [], error, isLoading } = useQuery('clients', fetchClients);
    const mutation = useMutation(deleteClient, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients');
        },
    });
    
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [clientIdToDelete, setClientIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredclients, setFilteredclients] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        setFilteredclients(clients); 
    }, [clients]);

    const openDelete = (clientId) => {
        setClientIdToDelete(clientId);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setClientIdToDelete(null);
    };

    const handleDeleteClient = () => {
        if (clientIdToDelete === undefined || clientIdToDelete === null) {
            console.error("No client ID to delete");
            return;
        }
        mutation.mutate(clientIdToDelete);
        setShowConfirmBox(false); 
    };  

    const columns = useMemo(() => [
        { key: 'nom_client', label: t('Nom_Client'), sortable: true },
        { key: 'prenom_client', label: t('Prenom_Client'), sortable: false },
        { key: 'societe', label: t('Societe'), sortable: true },
        { key: 'ville', label: t('Ville'), sortable: true },
        { key: 'num_telephone', label: t('Telephone'), sortable: true },
        { key: 'fax', label: t('Fax'), sortable: true },
        { key: 'reste', label: t('Reste'), sortable: true },
        { key: 'avance', label: t('Avance'), sortable: true },
        { key: 'actions', label: t('Actions'), sortable: false, style: { textAlign: "center" } },
    ], [t]);

    // Search logic
    const handleSearchChange = (term) => {
        setSearchTerm(term);
        const filtered = clients.filter((client) => {
            return columns.some((col) => {
                if (col.sortable && typeof client[col.key] === 'string') {
                    return client[col.key].toLowerCase().includes(term.toLowerCase());
                }
                return false;
            });
        });
        setFilteredclients(filtered);
    };

    // Pagination logic
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex); 
    };
    
    const offset = currentPage * itemsPerPage; 
    const currentItems = filteredclients.slice(offset, offset + itemsPerPage); 
    console.log('Nombre de lignes affichées:', currentItems.length);
    
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value)); 
        setCurrentPage(0); 
    };


    const handleEventClick = () => {
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
      };

  return (
        <>
          <div className="breadcrumbbar">
                <div className="col-md-8 col-lg-8">
                    <h4 className="page-title">{t('clients')}</h4>
                    <div className="breadcrumb-list mb-3">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/home" style={{textDecoration:'none'}}>{t('clients')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('List clients')}</li>
                            </ol>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                       <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajoute_client" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((client) => ({
                                                    id: client.id_client,
                                                    nom_client: client.nom_client,
                                                    prenom_client: client.prenom_client,
                                                    societe: client.societe,
                                                    ville: client.ville,
                                                    num_telephone: client.num_telephone,
                                                    fax: client.fax,
                                                    reste: client.reste,
                                                    avance: client.avance,

                                                    actions: (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Link
                                                                to={`/clients/prix_categorie/${client.id_client}`}
                                                                className="btn btn-primary-rgba btn-round mb-1"
                                                                title="prix"
                                                                style={{ marginLeft: "10px" }}
                                                                >
                                                                <FontAwesomeIcon icon={faHandHoldingDollar} />
                                                            </Link>
                                                          
                                                            <Link
                                                                to={`/clients/modifier_client/${client.id_client}`}
                                                                className="btn btn-success-rgba btn-round mb-1"
                                                                title="Edit"
                                                                style={{ marginLeft: "10px" }}
                                                                >
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link
                                                               as="button"
                                                               onClick={handleEventClick} 
                                                                className="btn btn-info-rgba btn-round mb-1"
                                                                title="prix"
                                                                style={{ marginLeft: "10px" }}
                                                                >
                                                                 <FontAwesomeIcon icon={faFilterCircleDollar} />                                                          
                                                            </Link>
                                                            <Link
                                                                onClick={() => openDelete(client.id_client)}
                                                                className="btn btn-danger-rgba btn-round mb-1"
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredclients}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                         )}
                                         </>
                                     )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="Client"
                        deleteFunction={handleDeleteClient}
                    />
                    <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ajouter le paiement client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form >
                        <div className="form-row">
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-3">Client</label>
                                <input
                                type="text"
                                className="form-control"
                            />
                            </div>
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-4">Code Cl</label>
                                <input
                                type="text"
                                className="form-control"
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-3">Reste</label>
                                <input
                                type="text"
                                className="form-control"
                                />
                            </div>
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-4">Montant</label>
                                <input
                                type="text"
                                className="form-control"
                            />
                            </div>
                        </div>
                        <div  className="form-row">
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-3">Mode</label>
                                <select
                                className="form-control"
                                >
                                <option value=""></option>
                                <option value="espèces">Espèces</option>
                                <option value="chéque">Chèque</option>
                                <option value="tpe">TPE</option>
                                </select>
                            </div>
                            <div className="col-md-6 d-flex align-items-center mb-3">
                                <label className="me-2 col-md-4">Payé le</label>
                                <input
                                type="date"
                                className="form-control"
                                />
                            </div>
                        </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Link className="btn btn-warning my-1" to="/magasins">Annuler</Link>
                            <button className="btn btn-success my-1" type="submit">Ajouter</button>
                        </div>
                    </Modal.Footer>
                    </Modal>                
          
        </>
    );
}

export default ListerClient;
