import React, { useState, useEffect  } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate,Link } from 'react-router-dom';

function AjouterCategorie() {

    const [categories, setCategories] = useState([]);
    const [categorie, setCategorie] = useState({
        nom_categorie: "",
        parent_id: 0,
        pu_achat: "",
        pu_vente: ""
    });

    const { nom_categorie, parent_id, pu_achat, pu_vente } = categorie;
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchCategories() {
            try {
                const response = await axios.get('/categories'); 
                const categoriesData = response.data.categories;
                const filteredCategories = categoriesData.filter(category => category.parent_id === 0);
                setCategories(filteredCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }

        fetchCategories();
    }, []);


    const onInputChange = e => {
      const value = e.target.value;
      const name = e.target.name;

      const newValue = name === "parent_id" && value === "" ? 0 : value;

      setCategorie({ ...categorie, [name]: newValue });
  };

    const onSubmit = async e => {
        e.preventDefault();
        console.log("La fonction onSubmit est appelée !");

        try {
            if (nom_categorie.trim() === "") {
                Swal.fire({
                    title: "",
                    text: "Veuillez saisir les données svp",
                    icon: "error"
                });
                return;
            }
            const parentIdToSend = parent_id === "" ? 0 : parent_id;
            console.log(parentIdToSend);
            
            let requestData = {
                nom_categorie,
                pu_achat,
                pu_vente
            };
            
            if (parentIdToSend !== 0) {
                requestData.parent_id = parentIdToSend;
            }
            console.log(requestData.parent_id)

          await axios.post("/categories", requestData).catch(error => {
                console.error('Erreur lors de la requête axios :', error);
            });

            Swal.fire({
                title: "",
                text: "Les données ont été ajoutées avec succès",
                icon: "success"
            });

            navigate('/articles/categorie');
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la catégorie :', error);
            alert('Erreur lors de l\'ajout de la catégorie. Veuillez réessayer.');
        }
    };
  


    return (
    <>
		<div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter une categorie</h5>
                                </div>
                                <div className="card-body">
                                <form className="needs-validation" onSubmit={onSubmit}>
                                        <div className="form-row">
                                          <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">Nom de Catégorie</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nom_categorie"
                                                    value={nom_categorie}
                                                    onChange={onInputChange}
                                                />
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">Catégorie parent</label>
                                            <select
                                                    className="form-select"
                                                    name="parent_id"
                                                    value={parent_id}
                                                    onChange={onInputChange}
                                                >
                                                    <option value=""></option>
                                                    {categories.map((category, index) => (
                                                        <option key={index} value={category.id}>{category.nom_categorie}</option>
                                                    ))}
                                                </select>
                                        </div>
                                        </div>
                                        <div className="form-row">
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">PU Achat (DH)</label>
                                               <input
                                                    type="number"
                                                    name="pu_achat"
                                                    className="form-control"
                                                    value={pu_achat}
                                                    onChange={onInputChange}
                                                />
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center mb-4">
                                            <label className="me-2 col-md-4">PU Vente (DH)</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="pu_vente"
                                                    value={pu_vente}
                                                    onChange={onInputChange}
                                                />
                                        </div> 
                                        </div>

                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/articles/categorie">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter <i className="feather icon-arrow-right ml-2"></i></button>
                                        </div>
                                    </form>
                                </div>

             </div>
        </div>
                        
    </div>
       </div>
   </>

  );
}

export default AjouterCategorie;
