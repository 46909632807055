import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";

import { useOfflineOperations } from '../../layouts/useOfflineOperations'; 

function AjouterVersement() {
  const [personne, setPersonne] = useState("");
  const [mode, setMode] = useState("");
  const [montant, setMontant] = useState("");
  const [date, setDate] = useState("");
  const [observation, setObservation] = useState("");
  const navigate = useNavigate();
  const { saveOperationOffline } = useOfflineOperations(); 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!personne || !mode || !montant || !date) {
      Swal.fire({
        icon: "warning",
        title: "Validation",
        text: "Veuillez remplir tous les champs obligatoires.",
      });
      return;
    }

    // Création de l'objet de données à envoyer ou sauvegarder
    const formData = {
      personne,
      mode,
      montant,
      date,
      observation,
    };

    try {
      if (navigator.onLine) {
        await axios.post("/versements", formData);
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Versement ajouté avec succès !",
        });
        navigate("/liste-versement");
      } else {
        saveOperationOffline({
          type: 'CREATE',
          endpoint: '/versements', 
          data: formData, 
        });
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Versement sauvegardé localement ! Il sera envoyé lorsque vous serez en ligne.",
        });
        navigate("/liste-versement");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.response?.data?.message || "Une erreur est survenue lors de l'ajout du versement.",
      });
    }
  };

  return (
    <div className="breadcrumbbar">
      <div className="row">
        <div className="col-lg-12 m-b-50">
          <div className="card m-b-50">
            <div className="card-header">
              <h5 className="card-title">Ajouter un versement</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col-md-6 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Personne</label>
                    <input
                      name="personne"
                      className="form-control"
                      value={personne}
                      onChange={(e) => setPersonne(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Mode</label>
                    <select
                      name="mode"
                      className="form-select"
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      required
                    >
                      <option value="">Sélectionner le mode de paiement</option>
                      <option value="Espèce">Espèce</option>
                      <option value="Chèque">Chèque</option>
                      <option value="TPE">TPE</option>
                      <option value="Effet">Effet</option>
                      <option value="Versement">Versement</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Montant</label>
                    <input
                      type="text"
                      name="montant"
                      className="form-control"
                      value={montant}
                      onChange={(e) => setMontant(e.target.value)}
                      required
                    />
                  </div>

                  <div className="col-md-6 d-flex align-items-center mb-3">
                    <label className="me-2 col-md-4">Date</label>
                    <input
                      type="date"
                      name="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                  </div>

                  <div className="col-md-12 d-flex align-items-center mb-3">
                    <label className="me-0 col-md-2">Observation</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      name="observation"
                      value={observation}
                      onChange={(e) => setObservation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Link className="btn btn-warning my-1" to="/liste-versement">Annuler</Link>
                  <button className="btn btn-success my-1" type="submit">Ajouter</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterVersement;
