
import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function CreerAvoir() {
   
    const navigate = useNavigate();
    const { id } = useParams(); 

    const [articles, setArticles] = useState([]);
    const [selectedClient, setCode] = useState("");
    const [date_fac, setDate] = useState("");  
    const [description, setDescription] = useState("");  

    const [ setEditMode] = useState([]);
    const [tempArticleCommandes, setTempArticleCommandes] = useState([]);
    const [rows, setRows] = useState([{ selectedArticle: '', quantity: '', price: '' }]);
    const [rowError, setRowError] = useState({});
    const [initialQuantities, setInitialQuantities] = useState([]);


    useEffect(() => {
        const fetchArticles = async () => {
          try {
            const response = await axios.get("/articles");
            setArticles(response.data);
          } catch (error) {
            console.error("Erreur lors du chargement des articles:", error);
          }
        };
      
        fetchArticles();
      }, []);
      
      useEffect(() => {
        const fetchFactureDetails = async () => {
          try {
            const response = await axios.get(`/factures/${id}`);
            setCode(response.data.code);
            setDate(response.data.date_fac);
      
            const lignesResponse = await axios.get(`/ligne-bon-liv-cls?bon_livraison_id=${response.data.bon_livraison_id}`);
            const lignesDevisData = lignesResponse.data.map((ligne) => ({
              id: ligne.id,
              selectedArticle: ligne.id_article,
              quantity: ligne.quantite,
              price: ligne.pu_achat,
              nom_article: articles.find((article) => article.id === ligne.id_article)?.nom_article || "Article inconnu",
            }));
      
            setTempArticleCommandes(lignesDevisData);
            setRows(lignesDevisData.map((ligne) => ({
                selectedArticle: ligne.selectedArticle,
                quantity: ligne.quantity,
                price: ligne.price,
                montant: ligne.quantity * ligne.price,
            })));
          console.log("dvdfv",lignesDevisData);
            setInitialQuantities(
              lignesDevisData.map((ligne) => ligne.quantity)
            );
          } catch (error) {
            console.error("Erreur lors du chargement des détails de facture:", error);
          }
        };
      
        if (articles.length > 0) {
          fetchFactureDetails();
        }
      }, [articles, id,setEditMode]);

      useEffect(() => {
        console.log("Initial Quantities:", initialQuantities); 
    }) 
  
      const handleRowChange = (index, key, value) => {
        const updatedRows = [...rows];
        updatedRows[index][key] = value;
        setRows(updatedRows);
    };
    const handleChange = (index, key, value) => {
      const initialQuantity = initialQuantities[index];
  
      if (key === "quantity" && value > initialQuantity) {
          setRowError({ ...rowError, [index]: `La quantité ne peut pas dépasser ${initialQuantity}.` });
          handleRowChange(index, key, initialQuantity);
          return;
      }
  
      setRowError({ ...rowError, [index]: null });
      handleRowChange(index, key, value); 
  };
  const onInputChange = e => {
    const { name, value } = e.target;
    switch (name) {
        case "selectedClient":
            setCode(value);
            break;
        case "date_fac":
            setDate(value);
            break;
        case "description":
            setDescription(value);
            break;
        default:
            break;
    }
};
console.log("tempArticleCommandes:", tempArticleCommandes);

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       
        await Promise.all(rows.map(async (row) => {
          await axios.post("/avoirs", {
            facture_id: id,
            date_avoir: date_fac, 
            description: description,
              article_id: row.selectedArticle,
              quantite: row.quantity,
              montant: row.price*row.quantity,
              });
      }));
        handleSuccess();
    } catch (error) {
        handleError(error);
    }
};

const handleSuccess = () => {
  Swal.fire({
      icon: 'success',
      title: 'Succès',
      text: 'Avoir créer avec succès!',
  });
  navigate('/avoirs');
};

const handleError = (error) => {
  Swal.fire({
      icon: 'error',
      title: 'Erreur',
      text: 'Échec de l\'ajout d\'avoir. Veuillez réessayer plus tard.',
  });
};

    const handleRemoveRow = async (index) => {
        try {
          if (index < 0 || index >= tempArticleCommandes.length) {
            throw new Error("Index hors limite");
          }
    
          const row = tempArticleCommandes[index];
    
          if (!row.selectedArticle || !row.quantity || !row.price) {
            const updatedRows = [...tempArticleCommandes];
            updatedRows.splice(index, 1);
            setTempArticleCommandes(updatedRows); 
          } else {
            const confirmDelete = await Swal.fire({
              icon: "warning",
              title: "Confirmation",
              text: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
            });
    
            if (confirmDelete.isConfirmed) {
              if (row.id) {
                await axios.delete(`/ligne-bon-liv-cls/${row.id}`);
              }
    
              const updatedRows = [...tempArticleCommandes];
              updatedRows.splice(index, 1);
              setTempArticleCommandes(updatedRows); 
              window.location.reload();

            }
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la ligne:", error);
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Échec de la suppression de la ligne. Veuillez réessayer plus tard.",
          });
        }
      };
    
     return (
        <>
		  <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                               <h5 className="card-title">Creer avoir</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                        <div className="form-row">
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-2">Facture</label>
                                            <input
                                                className=" form-control"
                                                name="selectedClient"
                                                value={selectedClient}
                                                onChange={onInputChange}
                                                 >
                                                
                                            </input>
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-2">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="date_fac"
                                                    value={date_fac}
                                                    onChange={onInputChange}
                                                />
                                            
                                            </div>
                                        
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Description</label>
                                                <textarea
                                                   type="text"
                                                   rows="1"
                                                    className="form-control"
                                                    name="description"
                                                    value={description}
                                                    onChange={onInputChange}
                                                />
                                            
                                            </div>
                                           
                                        
                                        </div>
                                       < table className="display table table-striped table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Article</th>
                                                <th >Quantité</th>
                                                <th>P.U</th>
                                                <th>Total</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tempArticleCommandes.map((row, index) => (
    <tr key={index}>
        <td>{row.nom_article}</td>
        <td>
            <input
                type="number"
                value={rows[index]?.quantity}
                onChange={(e) => handleChange(index, "quantity", parseInt(e.target.value))}
                min={0} 
                style={{
                    border: 'none',  
                    outline: 'none',  
                    background: 'transparent',  
                    textAlign: 'center', 
                    width:"70px" ,
                    color: "#8A98AC",
                }}
            />
            {rowError[index] && (
                <span style={{ color: "red", fontSize: "0.8em" }}>
                    {rowError[index]}
                </span>
            )}
        </td>
        <td>{row.price}</td>
        <td>{row.quantity * row.price}</td>
        <td className="d-md-flex justify-content-md-end">
            <i
                className="feather icon-trash btn btn-danger-rgba btn-round"
                style={{ fontSize: "small" }}
                onClick={() => handleRemoveRow(index)}
            ></i>
        </td>
    </tr>
))}
                                            </tbody>
                                        </table>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/clients/bon-livraison">Annuler</Link>
                                           <button  className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Enregistrer</button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
           </div>


</>

    );
}

export default CreerAvoir;
