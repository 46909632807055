import React, { useState, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import { fetchListCategories, deleteCategory } from '../../services/articleService'; 
import { useTranslation } from "react-i18next";

function ListerCategorie() {

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: categories = [], isLoading, error, refetch } = useQuery(
    ['categories'],
    fetchListCategories, 
    {
      onError: (error) => {
        console.error("Error loading categories:", error);
      },
    }
  );

  const deleteCategoryMutation = useMutation(
    deleteCategory, 
    {
      onSuccess: () => {
       
      },
      onError: (error) => {
        console.error("Error deleting category:", error);
        alert("Error in the Code");
      },
    }
  );

  const openDelete = (categoryId) => {
    setCategoryIdToDelete(categoryId);
    setShowConfirmBox(true);
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false);
    setCategoryIdToDelete(null);
  };

  const handleDeleteCategory = () => {
    if (categoryIdToDelete) {
      deleteCategoryMutation.mutate(categoryIdToDelete);
      closeConfirmBox();
      refetch();
    }
  };

  const columns = useMemo(() => [
    { key: 'nom_categorie', label: t('categoryName'), sortable: true },
    { key: 'nom_sous_categorie', label: t('subCategoryName'), sortable: true },
    { key: 'pu_achat', label: t('purchasePrice'), sortable: true },
    { key: 'pu_vente', label: t('sellingPrice'), sortable: true },
    { key: 'actions', label: t('actions'), sortable: false, style: { textAlign: "center" } },
  ], [t]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const filteredcategories = categories.filter((category) => {
    return columns.some((col) => {
      if (col.key === 'nom_categorie' && category.nom_categorie) {
        return category.nom_categorie.toLowerCase().includes(searchTerm.toLowerCase());
      }
      if (category.sous_categories) {
        return category.sous_categories.some((subCategory) => {
          if (col.key === 'nom_sous_categorie' && subCategory.nom_categorie) {
            return subCategory.nom_categorie.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false;
        });
      }
      return false;
    });
  });

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredcategories.slice(offset, offset + itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  return (
    <>
     
          <div className="breadcrumbbar">
            <div className="row align-items-center">
              <div className="col-md-8 col-lg-8">
                <h4 className="page-title">{t('Catégories')}</h4>
                <div className="breadcrumb-list mb-3">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/home">
                          <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/home" style={{textDecoration:'none'}}>{t('Articles')}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{t('List Catégories')}</li>
                    </ol>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                        <div className="row align-items-center">
                              <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                    <div className="col-5 col-md-5 d-flex justify-content-center">
                                       <Search 
                                          placeholder={t('Search')} 
                                          onSearchChange={handleSearchChange} 
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                     <div className="widgetbar">
                                          <Link to="/articles/ajoute_categorie" className="btn btn-primary-rgba">
                                              <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                          </Link>
                                      </div>
                                </div>
                        </div>
                  
                  </div>
                  <div className="card-body">
                  <>
                    <div className="table-responsive">
                      <table id="default-datatable" className="display table table-striped table-bordered">
                        <thead>
                          <tr>
                           <th>{t('categoryName')}</th>
                            <th>{t('subCategoryName')}</th>
                            <th>{t('purchasePrice')}</th>
                            <th>{t('sellingPrice')}</th>
                            <th className="text-center">{t('actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((category, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="text-gray-800 text-hover-primary mb-1">{category.nom_categorie}</td>
                                <td></td>
                                <td>{category.pu_achat}</td>
                                <td>{category.pu_vente}</td>
                                <td style={{ textAlign: "center" }}>
                                  <Link to={`/articles/modifier_categorie/${category.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip"    
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="feather icon-edit-2" style={{ fontSize: "medium" }}></i>
                                  </Link>
                                  <Link className="btn btn-danger-rgba btn-round mb-1" title="Delete" data-toggle="tooltip"                  
                                      style={{ marginLeft: "10px" }}
                                      onClick={() => openDelete(category.id)}>
                                    <i className="feather icon-trash"></i>
                                  </Link>
                                </td>
                              </tr>
                              {category.sous_categories.map((subcategory, subIndex) => (
                                <tr key={`${index}-${subIndex}`} className="subcategory-row">
                                  <td></td>
                                  <td>{subcategory.nom_categorie}</td>
                                  <td>{subcategory.pu_achat}</td>
                                  <td>{subcategory.pu_vente}</td>
                                  <td style={{ textAlign: "center" }}>
                                    <Link to={`/articles/modifier_categorie/${subcategory.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip"                                       
                                     style={{ marginLeft: "10px" }}
                                    >
                                      <i className="feather icon-edit-2" style={{ fontSize: "medium" }}></i>
                                    </Link>
                                    <Link onClick={() => openDelete(subcategory.id)}                                                                 
                                       style={{ marginLeft: "10px" }}
                                       className="btn btn-danger-rgba btn-round mb-1">
                                      <i className="feather icon-trash"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <select
                            className="form-control"
                            id="items-per-page"
                            onChange={handleItemsPerPageChange}
                            value={itemsPerPage}
                          >
                            <option value={2}>2</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </select>
                        </div>
                        <Pagination
                          items={filteredcategories}
                          itemsPerPage={itemsPerPage}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                 
                     </>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmBox
            open={showConfirmBox}
            closeDialog={closeConfirmBox}
            title="Categorie"
            deleteFunction={handleDeleteCategory}
          />
       
    </>
  );
}

export default ListerCategorie;
