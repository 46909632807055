import React, { useState, useEffect,useRef } from "react";
import axios from "../../api/axios";
import { useNavigate,Link} from 'react-router-dom';
import Swal from "sweetalert2";


function AjouterDevis() {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedClient, setSelectedClient] = useState(""); 
    const [selectedArticle, setSelectedArticle] = useState("");
    const [date, setDate] = useState("");
    const [categories, setCategories] = useState([]);
    const [validation, setValidation] = useState("");
    const [article_commandes, setArticleCommande] = useState([]);
    const [qte, setQte] = useState("");
    const [price, setPrice] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
   

    useEffect(() => {
        fetchClients();
        fetchArticles();
        fetchCategories();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axios.get("/clients");
            setClients(response.data);
            console.log("sdfg",response.data)
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    const fetchCategories = async () => {
        try {
            const response = await axios.get("/categories"); 
            setCategories(response.data.categories);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };
    const fetchArticles = async () => {
        try {
            const response = await axios.get("/articles");
            setArticles(response.data);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };
    

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
      };
    
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
      const handleSelectArticle = (id, nom_article) => {
        setSelectedArticle(id);
        setSearchTerm(nom_article);
        setDropdownOpen(false);
      };

      
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("idclient",selectedClient)
        try {
            const response = await axios.post("/devis", {
                id_client: selectedClient,
                date_devis: date,
                validation: validation,
            });
            
            if (response.status === 201) {
                const DevisId = response.data.id; 
                
                article_commandes.forEach(async (row) => {
                    await axios.post("/article-devis", {
                        id_devis: DevisId,
                        id_article: row.id,
                        quantite: row.quantity,
                        pu_vente: row.price,
                    });
                });
    
                handleSuccess();
            } else { 
               
                handleError();
            }
        } catch (error) {
            handleError();
        }
    };
    
    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Commande ajoutée avec succès!',
        });
        navigate('/clients/devis');
    };
    
    const handleError = (error) => {
        console.error('Erreur lors de l\'ajout de devis :', error);
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Échec de l\'ajout de devis. Veuillez réessayer plus tard.',
        });
    };
    

    const handleAddRow = () => {
        const selectedArticleObj = articles.find(
          (article) => article.id === parseInt(selectedArticle, 10)
        );
      
        if (!selectedArticleObj) {
          console.error("L'article sélectionné n'a pas été trouvé");
          return;
        }
      
        const newCommande = {
          id: selectedArticle, 
          nom_article: selectedArticleObj.nom_article, 
          quantity: qte,
          price: price,
        };
      
        setArticleCommande([...article_commandes, newCommande]); 
        setSelectedArticle('')
        setPrice('')
        setQte('')
      };
  
 
    const handleRowChange = (field, value) => {
        switch (field) {
            case "selectedFr":
                setSelectedClient(value);
                break;
            case "dateCommande":
                setDate(value);
                break;
            case "selectedArticle":
                setSelectedArticle(value);
                break;
            case "qte":
                setQte(value);
                break;
            case "price":
                setPrice(value);
                break;
            default:
                console.warn("Champ inconnu:", field);
        }
    };
    const deleteArticle = (index) => {
        const updatedArticleCommandes = [...article_commandes];
        updatedArticleCommandes.splice(index, 1);
        setArticleCommande(updatedArticleCommandes);
    };
      
    return (
        <>
		
        <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter devis client</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-4 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-4">Client</label>
                                            <select
                                                className=" form-control"
                                                name="selectedClient"
                                                value={selectedClient}
                                                onChange={(e) => setSelectedClient(e.target.value)}
                                            >
                                                <option key="" value=""></option>
                                                {clients.map(client => (
                                                    <option key={client.id_client} value={client.id_client}>{client.nom_client} {client.prenom_client}</option>
                                                ))}
                                            </select>
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="adresse_magasin"
                                                    value={date}
                                                   onChange={(e) => setDate(e.target.value)}
                                                />
                                            
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Statut</label>
                                                <select
                                                className="form-control"
                                                value={validation}
                                                onChange={(e) => setValidation(e.target.value)}
                                            >
                                                <option value=""></option>
                                                <option value="valide">Valide</option>
                                                <option value="en_attente">En attente</option>
                                                <option value="annule">Annulé</option>
                                                <option value="en_cours">En cours</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                        <React.Fragment>
                                        <div className="col-md-4 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-4">Article</label>
                                            <div className="custom-select-wrapper" ref={dropdownRef}>
                                            <div className="form-control" onClick={handleDropdownClick}>
                                                {searchTerm}
                                            </div>
                                            {dropdownOpen && (
                                                <div className="autocom-box">
                                                <input
                                                    type="search"
                                                    aria-label="Search"
                                                    className="search-box form-control"
                                                    placeholder="Rechercher..."
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    onClick={() => setDropdownOpen(true)}
                                                />
                                                <ul>
                                                    {categories
                                                    .filter(category => 
                                                        articles.some(article => 
                                                        article.categorie_id === category.id && 
                                                        article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                        )
                                                    )
                                                    .map(category => (
                                                        <React.Fragment key={category.id}>
                                                        <li className="category-label text-bold">{category.nom_categorie}</li>
                                                        {articles
                                                            .filter(article => 
                                                            article.categorie_id === category.id && 
                                                            article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                            )
                                                            .map(filteredArticle => (
                                                            <li
                                                                key={filteredArticle.id}
                                                                className="article-item"
                                                                onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                                            >
                                                                {filteredArticle.nom_article}
                                                            </li>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Quantité</label>
                                                <input
                                                 type="number"
                                                 className="form-control"
                                                 value={qte}
                                                 onChange={(e) => handleRowChange('qte', e.target.value)}
                                                 min={0}
                                                />
                                            </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Prix</label>
                                                <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                value={price}
                                                onChange={(e) => handleRowChange( 'price', e.target.value)}
                                                />
                                            </div>
                                           
                                   </React.Fragment>
                                   <div className="col-md-12 mb-3">
                                            <div className="row g-3">
                                                    <div className="col-md-4 offset-md-8 text-end ">
                                                     <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                                   </div>
                                            </div>
                                    </div>
                                         </div>
                                         <table className="display table table-striped table-bordered">
                                           <thead>
                                             <tr>
                                               <th>Article</th>
                                               <th>Quantité</th>
                                               <th>P.U</th>
                                               <th>Total</th>
                                               <th>Action</th>
                                             </tr>
                                           </thead>
                                           <tbody>
                                           {article_commandes.map((commande, index) => (
                                             <tr key={index}>
                                               <td>{commande.nom_article}</td>
                                               <td>{commande.quantity}</td>
                                               <td>{commande.price}</td>
                                               <td>{commande.quantity * commande.price}</td>
                                               <td className="d-md-flex justify-content-md-end">
                                                 <i
                                                   className="feather icon-trash btn btn-danger-rgba btn-round"
                                                   style={{ fontSize: "small" }}
                                                   onClick={() => deleteArticle(index)}
                                                 ></i>
                                               </td>
                                             </tr>
                   
                   
                                             
                                             ))}
                                           </tbody>
                                         </table>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/clients/devis">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
        </div>


</>

    );
}

export default AjouterDevis;
