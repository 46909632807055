import React, { useEffect,useCallback,useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import logoImage from '../assets/images/Logo_mansoft.png';
import horizontalIcon from '../assets/images/svg-icon/horizontal.svg';
import verticalIcon from '../assets/images/svg-icon/verticle.svg';
import menuIcon from '../assets/images/svg-icon/menu.svg';
import closeIcon from '../assets/images/svg-icon/close.svg';
import searchIcon from '../assets/images/svg-icon/search.svg';
import notificationsIcon from '../assets/images/svg-icon/notifications.svg';
import crmIcon from '../assets/images/svg-icon/crm.svg';
import emailIcon from '../assets/images/svg-icon/email.svg';
import logoutIcon from '../assets/images/svg-icon/logout.svg';
import dropdownMenu from '../assets/js/dropdownMenu';
import { useAuth } from '../../src/layouts/AuthContext';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import $ from 'jquery';
import LanguageContext from './LanguageContext';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useContext(LanguageContext);
  const { logout } = useAuth();
  const location = useLocation();

  const userName = location.state?.userName || localStorage.getItem('userName');
  const userPhoto = location.state?.userPhoto || localStorage.getItem('userPhoto');
  
  const handleLogout = useCallback(() => {
    logout();
    window.location.href = "/";
  }, [logout]);

  const handleHamburgerClick = useCallback((e) => {
    e.preventDefault();
    $("body").toggleClass("toggle-menu");
    $(".menu-hamburger img.menu-hamburger-collapse").toggle();
    $(".menu-hamburger img.menu-hamburger-close").toggle();
  }, []);

  const handleTopbarHamburgerClick = useCallback((e) => {
    e.preventDefault();
    $("body").toggleClass("topbar-toggle-menu");
    $(".menu-hamburger-horizontal").toggle();
    $(".menu-hamburger-vertical").toggle();
  }, []);

  const mediaSize = useCallback(() => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      $("body").removeClass("toggle-menu topbar-toggle-menu");

      $(".menu-hamburger img.menu-hamburger-close").hide();
      $(".menu-hamburger img.menu-hamburger-collapse").show();
      $(".menu-hamburger-horizontal").hide();
      $(".menu-hamburger-vertical").show();
    }
  }, []);

  useEffect(() => {
    mediaSize();
    window.addEventListener('resize', mediaSize);
    $(".menu-hamburger").on("click", handleHamburgerClick);
    $(".topbar-toggle-hamburger").on("click", handleTopbarHamburgerClick);

    const cleanupDropdowns = [
      dropdownMenu('.menu-hamburger', '.mobile-logobar .dropdown-menu'),
      dropdownMenu('.menu-hamburger', '.togglebar .dropdown-menu'),
      dropdownMenu('#profileDropdownTrigger', '#profileDropdownMenu'),
      dropdownMenu('#languageLink', '#languageDropdownMenu'),
      dropdownMenu('#notoficationlink', '#notificationDropdownMenu'),
    ];

    return () => {
      $(".menu-hamburger").off("click", handleHamburgerClick);
      $(".topbar-toggle-hamburger").off("click", handleTopbarHamburgerClick);
      window.removeEventListener('resize', mediaSize);
      cleanupDropdowns.forEach(cleanup => cleanup());
    };
  }, [handleHamburgerClick, handleTopbarHamburgerClick, mediaSize]);
  
  return (
    <>
      <div className="topbar-mobile">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="mobile-logobar">
              <Link href="index.html" className="mobile-logo"><img src={logoImage} className="img-fluid" alt="logo" /></Link>
            </div>
            <div className="mobile-togglebar">
              <ul className="list-inline mb-0">
              <li className="list-inline-item">
                  <div className="menubar">
                    <Link className="menu-hamburger">
                      <img src={menuIcon} className="img-fluid menu-hamburger-collapse" alt="collapse" />
                      <img src={closeIcon} className="img-fluid menu-hamburger-close" alt="close" />
                    </Link>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="topbar-toggle-icon">
                    <Link className="topbar-toggle-hamburger">
                      <img src={horizontalIcon} className="img-fluid menu-hamburger-horizontal" alt="horizontal" />
                      <img src={verticalIcon} className="img-fluid menu-hamburger-vertical" alt="vertical" />
                    </Link>
                  </div>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="topbar">
        <div className="row align-items-center">
          <div className="col-md-12 align-self-center">
            <div className="togglebar">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                 
                </li>
                <li className="list-inline-item">
                  <div className="searchbar">
                    <form>
                      <div className="input-group">
                        <div className="input-group-append">
                          <button className="btn" type="submit" id="button-addonSearch"><img src={searchIcon} className="img-fluid" alt="search" /></button>
                        </div>
                        <input type="search" className="form-control" placeholder="Search" aria-label="Search" />
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
            <div className="infobar">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <div className="languagebar">
                    <div className="dropdown">
                      <Link
                        id="languageLink"
                        to="#"
                        role="button"
                        style={{ textDecoration: 'none' }}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="live-icon">{t('login.language')}</span>
                        <span className="feather icon-chevron-down live-icon"></span>
                      </Link>
                      <div
                        id="languageDropdownMenu"
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="languageLink"
                      >
                        <Link className="dropdown-item" to="#" onClick={() => changeLanguage('en')}>
                          <i className="flag flag-icon-us flag-icon-squared"></i>{t('login.english')} 
                        </Link>
                        <Link className="dropdown-item" to="#" onClick={() => changeLanguage('fr')}>
                          <i className="flag flag-icon-bl flag-icon-squared"></i>{t('login.french')} 
                        </Link>
                        <Link className="dropdown-item" to="#" onClick={() => changeLanguage('ar')}>
                          <i className="flag flag-icon-ru flag-icon-squared"></i>{t('login.arabic')} 
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="notifybar">
                    <div className="dropdown">
                      <Link className="dropdown-toggle infobar-icon" to="#" role="button" id="notoficationlink" style={{ textDecoration: 'none' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={notificationsIcon} className="img-fluid" alt="notifications" />
                        <span className="live-icon">8</span></Link>
                      <div className="dropdown-menu dropdown-menu-right" id="notificationDropdownMenu" aria-labelledby="notoficationlink">
                        <div className="notification-dropdown-title">
                          <h4>Notifications</h4>
                        </div>
                        <ul className="list-unstyled">
                          <li className="media dropdown-item">
                            <span className="action-icon badge badge-success-inverse">1</span>
                            <div className="media-body">
                              <h5 className="action-title">chèque à encaisser</h5>
                            </div>
                          </li>
                          <li className="media dropdown-item">
                            <span className="action-icon badge badge-primary-inverse">3</span>
                            <div className="media-body">
                              <h5 className="action-title">produits en rupture  à commander</h5>
                            </div>
                          </li>
                          <li className="media dropdown-item">
                            <span className="action-icon badge badge-warning-inverse">4</span>
                            <div className="media-body">
                              <h5 className="action-title"> factures arrivant à échéance <br/> à  relancer</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="profilebar">
                    <div className="dropdown">
                      <Link
                        id="profileDropdownTrigger"
                        to="#"
                        className="dropdown-toggle"
                        role="button"
                        style={{ textDecoration: 'none' }}
                      >
                        <img
                          src={userPhoto}
                          className="img-fluid"
                          alt="profile"
                          style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                        />
                        <span>{userName}</span>
                        <span className="feather icon-chevron-down"></span>
                      </Link>
                      <div
                        id="profileDropdownMenu"
                        className="dropdown-menu dropdown-menu-right"
                      >
                        <div className="dropdown-item">
                          <div className="profilename">
                            <h5>{userName}</h5>
                          </div>
                        </div>
                        <div className="userbox">
                          <ul className="list-unstyled mb-0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <li className="media dropdown-item">
                              <Link to="/profil" className="profile-icon" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                                <img src={crmIcon} className="img-fluid" alt="user" style={{ marginRight: '8px' }} />
                                Profil
                              </Link>
                            </li>
                            <li className="media dropdown-item">
                              <Link to="#" className="profile-icon" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                                <img src={emailIcon} className="img-fluid" alt="email" style={{ marginRight: '8px' }} />
                                Email
                              </Link>
                            </li>
                            <li className="media dropdown-item">
                              <Link onClick={handleLogout} className="profile-icon" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                                <img src={logoutIcon} className="img-fluid" alt="logout" style={{ marginRight: '8px' }} />
                                se déconnecter
                              </Link>
                            </li>             
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> 
              </ul> 
            </div>
           </div>
        </div>
      </div>
    </>
  );
};

export default Header;