import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function ModifierPaiementFr() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [bonEntree, setBonEntree] = useState(null);
    const [date_paiement, setDatePaiement] = useState("");
    const [montant, setMontant] = useState("");
    const [mode_paiement, setModePaiement] = useState("");
    const [date_echeance, setDateEcheance] = useState("");

    useEffect(() => {
        const fetchPaiement = async () => {
            try {
                const paiementResponse = await axios.get(`/PaiementFr/${id}`);
                const paiementData = paiementResponse.data;

                setBonEntree({
                    code_bon_entree: paiementData.code_bon_entree,
                    id_fournisseur: paiementData.id_fournisseur,
                    montant: paiementData.montant,
                    montant_paye: paiementData.montant_paye,
                    reste: paiementData.reste,
                });

                setDatePaiement(paiementData.date_paiement);
                setMontant(paiementData.montant);
                setModePaiement(paiementData.mode_paiement);
                setDateEcheance(paiementData.date_echeance);
            } catch (error) {
                console.error("Erreur lors de la récupération du paiement:", error);
            }
        };

        fetchPaiement();
    }, [id]);

    const handleModePaiementChange = (e) => {
        const newModePaiement = e.target.value;
        setModePaiement(newModePaiement);

        if (newModePaiement !== "chèque") {
            setDateEcheance("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const pcResponse = await axios.put(`/PaiementFr/${id}`, {
                date_paiement: date_paiement,
                montant: montant,
                mode_paiement: mode_paiement,
                date_echeance: date_echeance,
            });

            if (pcResponse.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Succès',
                    text: 'Paiement modifié avec succès!',
                });
                navigate('/paiements-fournisseurs');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Erreur lors de la modification du paiement. Veuillez réessayer plus tard.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Erreur lors de la modification du paiement. Veuillez réessayer plus tard.',
            });
        }
    };

    return (
        <div className="breadcrumbbar">
            <div className="row">
                <div className="col-lg-12 m-b-50">
                    <div className="card m-b-50">
                        <div className="card-header">
                            <h5 className="card-title">Modifier paiement fournisseur</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                        <label className="me-2 col-md-3">Code BE</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={bonEntree ? bonEntree.code_bon_entree : ""}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                        <label className="me-2 col-md-4">Montant Total</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={bonEntree ? bonEntree.montant : ""}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                        <label className="me-2 col-md-3">Date de Paiement</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date_paiement}
                                            onChange={(e) => setDatePaiement(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                        <label className="me-2 col-md-4">Montant</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={montant}
                                            onChange={(e) => setMontant(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                        <label className="me-2 col-md-3">Mode de Paiement</label>
                                        <select
                                            className="form-control"
                                            value={mode_paiement}
                                            onChange={handleModePaiementChange}
                                        >
                                            <option value=""></option>
                                            <option value="espèces">Espèces</option>
                                            <option value="chèque">Chèque</option>
                                            <option value="tpe">TPE</option>
                                        </select>
                                    </div>
                                    {mode_paiement === "chèque" && (
                                        <div className="col-md-6 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-4">Date d'Échéance</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={date_echeance}
                                                onChange={(e) => setDateEcheance(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Link className="btn btn-primary-rgba my-1" to="/paiements-fournisseurs">Annuler</Link>
                                    <button className="btn btn-primary-rgba my-1" type="submit">
                                        Modifier <i className="feather icon-arrow-right ml-2"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModifierPaiementFr;
