import React, { useState, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchClients } from '../../../services/clientService';
import { fetchArticles, fetchCommandeData, fetchArticleCommandes } from '../../../services/articleService';

const VenteParClient = () => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [years, setYears] = useState([]);

  const { data: articles, error: articlesError, isLoading: articlesLoading } = useQuery('articles', fetchArticles);
  const { data: clients, error: clientsError, isLoading: clientsLoading } = useQuery('clients', fetchClients);

  const { data: filteredData, error: filteredDataError, isLoading: filteredDataLoading } = useQuery(
    ['filteredData', selectedClient, selectedYear],
    () => fetchCommandeData(selectedClient, selectedYear).then(async (commandeData) => {
      const articleCommandes = await Promise.all(
        commandeData.map(async (commande) => fetchArticleCommandes(commande.num_commande))
      );

      const articlesMap = {};
      articles?.forEach((article) => {
        articlesMap[article.id] = article.nom_article;
      });

      const dataByArticle = {};

      articleCommandes.forEach((item) => {
        item.forEach((article) => {
          if (!dataByArticle[article.id_article]) {
            dataByArticle[article.id_article] = {
              article: articlesMap[article.id_article] || 'Nom non disponible',
              monthlyData: Array(12).fill(0),
              total: 0
            };
          }
          dataByArticle[article.id_article].monthlyData[new Date(article.created_at).getMonth()] += article.quantite;
          dataByArticle[article.id_article].total += article.quantite;
        });
      });

      return Object.values(dataByArticle);
    }),
    {
      enabled: !!selectedClient && !!selectedYear, // Only run query if both client and year are selected
    }
  );

  // Generate a list of years from the current year to 2020
  useMemo(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let year = currentYear; year >= 2020; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, []);

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData || []);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'VenteParClient');
    XLSX.writeFile(workbook, 'VenteParClient.xlsx');
  };

  if (articlesLoading || clientsLoading || filteredDataLoading) return <p>Loading...</p>;
  if (articlesError || clientsError || filteredDataError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="form-group d-flex flex-column flex-md-row align-items-center">
              <select
                className="form-control mb-2 mb-md-0 me-md-2"
                value={selectedClient}
                onChange={handleClientChange}
              >
                <option value="">{t('----- Sélectionner un client -----')}</option>
                {clients?.map((client) => (
                  <option key={client.id_client} value={client.id_client}>
                    {client.nom_client} {client.prenom_client}
                  </option>
                ))}
              </select>
              <select
                className="form-control mb-2 mb-md-0 me-md-2"
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="">{t('Année')}</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <div className="d-flex flex-column flex-md-row">
                
                <Link className="btn btn-primary-rgba btn-export " onClick={exportToExcel}>
                  <i className="fa fa-cloud-download mr-4 ml-4 "></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(!selectedClient || !selectedYear) && (
        <p className="alert alert-warning">{t('Veuillez sélectionner un client et une année')}</p>
      )}

      {selectedClient && selectedYear && (
        <table className="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th>{t('Article')}</th>
              {[...Array(12).keys()].map(month => (
                <th key={month + 1}>{t(`Mois ${month + 1}`)}</th>
              ))}
              <th>{t('Total')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((data) => (
              <tr key={data.article}>
                <td>{data.article}</td>
                {[...Array(12).keys()].map(month => (
                  <td key={month + 1}>
                    {data.monthlyData[month] || 0}
                  </td>
                ))}
                <td>{data.total}</td>
              </tr>
            )) || (
              <tr>
                <td colSpan="14">Aucune donnée disponible</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default VenteParClient;
