import React, { useState, useTransition } from "react";
import { useQuery } from 'react-query';
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';

// Function to fetch paiements
const fetchPaiements = async () => {
    const response = await axios.get('/PaiementFr');
    const paiements = response.data;

    return Promise.all(
        paiements.map(async (paiement) => {
            const clientResponse = await axios.get(`/fournisseurs/${paiement.id_fournisseur}`);
            const clientData = clientResponse.data;

            return {
                id: paiement.id,
                montant: Number(paiement.montant).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                mode_paiement: paiement.mode_paiement,
                date_paiement: paiement.date_paiement,
                date_echeance: paiement.date_echeance,
                nom_fournisseur: clientData.nom_fournisseur,
            };
        })
    );
};

const columns = [
    { key: 'fournisseur', label: 'fournisseur', sortable: true },
    { key: 'montant', label: 'montant', sortable: true },
    { key: 'mode_paiement', label: 'mode_paiement', sortable: false },
    { key: 'date_paiement', label: 'date_paiement', sortable: true },
    { key: 'date_echeance', label: 'date_echeance', sortable: true },
    { key: 'actions', label: 'Actions', sortable: false, className: 'text-center' }
];

function ListerPaimentFr() {
    const {t}=useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);

    // Use react-query to fetch data
    const { data: paiements = [], isLoading, isError } = useQuery(['paiements'], fetchPaiements, {
      
    });

    // Filtered paiements based on search term
    const filteredpaiements = paiements.filter((paiement) => {
        return Object.keys(paiement).some((key) => {
            if (typeof paiement[key] === 'string') {
                return paiement[key].toLowerCase().includes(searchTerm.toLowerCase());
            }
            return false;
        });
    });

    // Pagination logic
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredpaiements.slice(offset, offset + itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    // if (isLoading) return <p>Loading...</p>;
    // if (isError) return <p>Error loading data</p>;

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('paiement fournisseur')}</h4>
                            <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Fournisseurs')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('List')} {t('paiement Fournisseurs')}</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                    <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {isError ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((paiement) => ({
                                                    id: paiement.id,
                                                    fournisseur: paiement.nom_fournisseur,
                                                    montant: paiement.montant,
                                                    mode_paiement: paiement.mode_paiement,
                                                    date_paiement: paiement.date_paiement,
                                                    date_echeance: paiement.date_echeance,
                                                    actions: (
                                                        <div className="text-center">
                                                            <Link to={`/modifier-paiement-fournisseur/${paiement.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link className="btn btn-danger-rgba btn-round mb-1" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredpaiements}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                        )}
                                        </>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
                
        </>
    );
}

export default ListerPaimentFr;
