import React, { Component } from 'react';
import axios from "../../../api/axios";
import ReactApexChart from 'react-apexcharts';
class ColumnChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'pie',
                    height: 300
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',  
                        endingShape: 'rounded',
                        distributed: true   
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Nombre d\'articles'
                    }
                },
                fill: {
                    opacity: 1,
                    colors: []  
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return `${val} articles`;
                        }
                    }
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: [],
                    markers: {
                        fillColors: []
                    }
                }
            },
            series: [{
                name: 'Articles',
                data: []
            }]
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            const [articlesResponse, categoriesResponse] = await Promise.all([
                axios.get('/articles'),
                axios.get('/categories')
            ]);
    
            const categoryNamesMap = {};
            categoriesResponse.data.categories.forEach(category => {
                categoryNamesMap[category.id] = category.nom_categorie;
                if (category.children && category.children.length > 0) {
                    category.children.forEach(child => {
                        categoryNamesMap[child.id] = child.nom_categorie;
                    });
                }
            });
    
            const categoryTotals = articlesResponse.data.reduce((acc, article) => {
                const categoryName = categoryNamesMap[article.categorie_id];
                acc[categoryName] = (acc[categoryName] || 0) + 1;
                return acc;
            }, {});
    
            const categories = Object.keys(categoryTotals);
            const data = Object.values(categoryTotals);
    
            const predefinedColors = ['#008FFB', '#92e5a2', '#FFB4A3', '#775DD0', '#3F51B5', '#546E7A', '#D4526E', '#8D5B4C', '#F86624', '#D7263D', '#1B998B'];
            const colors = categories.map((category, index) => predefinedColors[index % predefinedColors.length]);
    
            this.setState({
                options: {
                    ...this.state.options,
                    xaxis: {
                        categories: categories,
                    },
                    fill: {
                        ...this.state.options.fill,
                        colors: colors
                    },
                    legend: {
                        ...this.state.options.legend,
                        customLegendItems: categories,
                        markers: {
                            fillColors: colors
                        }
                    }
                },
                series: [{
                    name: 'Articles',
                    data: data
                }]
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="250"
            />
        );
    }
}

export default ColumnChart;
