import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function AjouterPaiFr() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [bonEntree, setbonEntree] = useState(null); 
    const [date_paiement, setDatePaiement] = useState(""); 
    const [montant, setMontant] = useState("");
    const [mode_paiement, setModePaiement] = useState("");
    const [date_echeance, setDateEcheance] = useState(""); 

    useEffect(() => {
    const fetchbonEntree = async () => {
        try {
            const response = await axios.get(`/bon-entrees/${id}`);
            const bonEntreeData = response.data;

            const lignesResponse = await axios.get(`/ligne-bon-entree-fr?bon_entree_id=${id}`);
            const lignesData = lignesResponse.data;

            const montantTotal = lignesData.reduce(
                (total, ligne) => total + (ligne.montant * ligne.quantite),
                0
            );

            const montantPaye = 0; 
            const montantReste = montantTotal - montantPaye;

            setbonEntree({
                id_fournisseur: bonEntreeData.id_fournisseur, 
                code: bonEntreeData.code,
                montant: montantTotal.toFixed(2),
                montant_paye: montantPaye.toFixed(2), 
                reste: montantReste.toFixed(2), 
            });
        } catch (error) {
            console.error("Erreur lors de la récupération du bon de livraison:", error);
        }
    };

  
        fetchbonEntree(); 
    }, [id]);
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Check if the record already exists
            const checkResponse = await axios.get(`/PaiementFr?code_bon_entree=${bonEntree.code}`);
            
            if (checkResponse.data.length > 0) {
                // Record exists, perform update
                const existingRecordId = checkResponse.data[0].id; // Assuming the record has an 'id' field
                const updateResponse = await axios.put(`/PaiementFr/${existingRecordId}`, {
                    date_paiement: date_paiement,
                    id_fournisseur: bonEntree.id_fournisseur,
                    montant: montant,
                    mode_paiement: mode_paiement,
                    date_echeance: date_echeance,
                });
    
                if (updateResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Paiement mis à jour avec succès!',
                    });
                    navigate('/paiements-fournisseurs');
                } else {
                    throw new Error('Erreur lors de la mise à jour du paiement.');
                }
            } else {
                // Record does not exist, perform create
                const createResponse = await axios.post("/PaiementFr", {
                    code_bon_entree: bonEntree.code,
                    date_paiement: date_paiement,
                    id_fournisseur: bonEntree.id_fournisseur,
                    montant: montant,
                    mode_paiement: mode_paiement,
                    date_echeance: date_echeance,
                });
    
                if (createResponse.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Paiement ajouté avec succès!',
                    });
                    navigate('/paiements-fournisseurs');
                } else {
                    throw new Error('Erreur lors de l\'ajout du paiement.');
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Erreur lors du traitement du paiement. Veuillez réessayer plus tard.',
            });
        }
    };
    

    return (
        
           <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Ajouter un paiement fournisseur</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Code BE</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonEntree ? bonEntree.code : ""}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Total TTC</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonEntree ? bonEntree.montant : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Payé</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonEntree ? bonEntree.montant_paye : ""}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Reste</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={bonEntree ? bonEntree.reste : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                      
                                             <div  className="form-row">
                                               <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Payé le</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={date_paiement}
                                                        onChange={(e) => setDatePaiement(e.target.value)}
                                                    />
                                                    
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Montant</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={montant}
                                                        onChange={(e) => setMontant(e.target.value)}
                                                        maxLength={undefined}
                                                    />
                                                </div>
                                            
                                                </div>
                                                <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-3">Mode paiement</label>
                                                     <select
                                                        className="form-control"
                                                        value={mode_paiement}
                                                        onChange={(e) => setModePaiement(e.target.value)}
                                                    >
                                                        <option value=""></option>
                                                        <option value="espèces">Espèces</option>
                                                            <option value="chèque">Chèque</option>
                                                            <option value="tpe">TPE</option>
                                                    </select>
                                                </div>
                                                {mode_paiement === "chéque" && (
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Date d'échéance</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date_echeance}
                                                    onChange={(e) => setDateEcheance(e.target.value)}
                                                />
                                            </div>
                                             )}
                                              </div>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-primary-rgba my-1" to="/paiements-fournisseurs">Annuler</Link>
                                                <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
                
        
    );
}

export default AjouterPaiFr;
