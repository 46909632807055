import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchDevis } from '../../../services/clientService';
ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const DevisParMois = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const chartRef = useRef(null);
  const [data, setData] = useState({ labels: [], datasets: [] });

  const { data: Devis, error: DevisError, isLoading: DevisLoading } = useQuery('Devis', fetchDevis);

  useEffect(() => {
    if (Devis) {
      updateChartData(Devis);
    }
  }, [Devis]);

  useEffect(() => {
    if (Devis) {
      const filtered = filterData(Devis, startDate, endDate);
      setFilteredData(filtered);
    }
  }, [startDate, endDate, Devis]);

  const extractMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}`;
  };

  const aggregateDataByMonth = (data) => {
    return data.reduce((acc, item) => {
      const monthYear = extractMonthYear(item.created_at);
      if (!acc[monthYear]) {
        acc[monthYear] = { quantite: 0, montant: 0 };
      }
      acc[monthYear].quantite += item.quantite;
      acc[monthYear].montant += item.quantite * item.pu_vente;
      return acc;
    }, {});
  };

  const updateChartData = (Devis) => {
    const DevisDataByMonth = aggregateDataByMonth(Devis);

    const months = [...new Set([...Object.keys(DevisDataByMonth)])];
    const DevisQuantities = months.map((month) => DevisDataByMonth[month]?.quantite || 0);

    setData({
      labels: months,
      datasets: [
        {
          label: 'Devis',
          data: DevisQuantities,
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: false,
        },
      ],
    });
  };

  const filterData = (Devis, startDate, endDate) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filteredDevis = Devis.filter((Devis) => {
      const DevisDate = new Date(Devis.created_at);
      return (!start || DevisDate >= start) && (!end || DevisDate <= end);
    });

    const months = [...new Set([...filteredDevis.map((Devis) => extractMonthYear(Devis.created_at))])];
    const aggregatedDevis = aggregateDataByMonth(filteredDevis);

    return months.map((month, index) => {
      const previousMonth = index > 0 ? months[index - 1] : null;
      const evolution = previousMonth ? ((aggregatedDevis[month].quantite - aggregatedDevis[previousMonth].quantite) / aggregatedDevis[previousMonth].quantite) * 100 : 0;
      return {
        mois: month,
        quantite: aggregatedDevis[month].quantite || 0,
        montant: aggregatedDevis[month].montant || 0,
        evolution: evolution.toFixed(2),
      };
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mois',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Quantité',
        },
      },
    },
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Devis');
    XLSX.writeFile(workbook, 'Devis.xlsx');
  };

  if (DevisLoading) return <p>Loading...</p>;
  if (DevisError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="row mb-4 align-items-center">
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de début')} :</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="form-control"
          />
        </div>
        <div className="col-md-5 col-sm-12 mb-4">
          <label className="form-label">{t('Date de fin')} :</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="form-control"
          />
        </div>
        <div className="col-md-2 col-sm-12 d-flex justify-content-end">
          <Link className="btn btn-primary" onClick={exportToExcel}>
            <i className="fa fa-cloud-download me-2"></i> {t('Exporter')}
          </Link>
        </div>
      </div>
      <h6>{t('Évolution des Devis par Mois')}</h6>

      <div className="chart-container" style={{ width: '100%', height: '300px' }}>
          <Line data={data} options={options} ref={chartRef} />
        </div>
        <table className="table table-striped table-bordered w-100 mt-4 centered-table">
          <thead>
            <tr>
              <th>{t('Mois')}</th>
              <th>{t('Qte')}</th>
              <th>{t('Montant HT')}</th>
              <th>{t('Evolution (%)')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.mois}</td>
                <td>{item.quantite}</td>
                <td>{item.montant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                <td><span style={{color: item.evolution > 0 ? 'green':'red' }}>{item.evolution > 0 ? '+':''}{item.evolution}{'%'}</span></td>
              </tr>
            )) : (
              <tr>
                <td colSpan="4">{t('Aucune donnée disponible pour la période sélectionnée')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
  );
};

export default DevisParMois;
