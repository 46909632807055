import React, { useState, useRef, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';

const Rapport = () => {
  const { t } = useTranslation();
  const [selectedMainCase, setSelectedMainCase] = useState('comptes');
  const [selectedSubCaseIndex, setSelectedSubCaseIndex] = useState(0);
  const printRef = useRef();  // Reference for the content to print
  const navigate = useNavigate();
  const location = useLocation();

  const mainCases = [
    { id: 'comptes', label: 'Comptes' },
    { id: 'catalogue', label: 'Catalogue' },
    { id: 'achats', label: 'Achats' },
    { id: 'ventes', label: 'Ventes' },
    { id: 'tresorerie', label: 'Trésorerie' },
    { id: 'affaires', label: 'Affaires' },
  ];

  const subCases = {
    comptes: [
      { label: 'Comptes par type', path: 'comptes-par-type' },
      { label: 'CA par client', path: 'ca-par-client' },
      { label: 'CA par ville', path: 'ca-par-ville' },
      { label: 'CA par catégorie', path: 'ca-par-categorie' },
      { label: 'Évolution des comptes', path: 'evolution-des-comptes' },
    ],
    catalogue: [
      { label: 'Article par Fournisseur', path: 'article-par-fournisseur' },
      { label: 'Article par Catégorie', path: 'article-par-categorie' },
      { label: 'Vente par Client', path: 'vente-par-client' },
      { label: 'Vente par Article', path: 'vente-par-article' },
      { label: 'Achat par Article', path: 'achat-par-article' },
    ],
    achats: [
      { label: 'Factures', path: 'factures' },
      { label: 'BC', path: 'bc' },
      { label: 'Achat par Fournisseur', path: 'achat-par-fournisseur' },
    ],
    ventes: [
      { label: 'Vente par Mois', path: 'vente-par-mois' },
      { label: 'Devis', path: 'devis' },
      { label: 'Commandes', path: 'commandes' },
      { label: 'Bon Livraison', path: 'bl-par-mois' },
    ],
  };

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const mainCaseId = Object.keys(subCases).find((key) =>
      subCases[key].some((subCase) => subCase.path === path)
    );
    if (mainCaseId) {
      setSelectedMainCase(mainCaseId);
      setSelectedSubCaseIndex(
        subCases[mainCaseId].findIndex((subCase) => subCase.path === path)
      );
    }
  }, [location]);

  const handleMainCaseClick = (id) => {
    if (id !== selectedMainCase) {
      setSelectedMainCase(id);
      setSelectedSubCaseIndex(0);
      navigate(`/rapport/${subCases[id][0].path}`);
    }
  };

  const handlePreviousSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex - 1 + subCaseList.length) % subCaseList.length;
      navigate(`/rapport/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const handleNextSubCase = () => {
    setSelectedSubCaseIndex((prevIndex) => {
      const subCaseList = subCases[selectedMainCase];
      const newIndex = (prevIndex + 1) % subCaseList.length;
      navigate(`/rapport/${subCaseList[newIndex].path}`);
      return newIndex;
    });
  };

  const selectedSubCase = subCases[selectedMainCase]?.[selectedSubCaseIndex];

  return (
    <div className="breadcrumbbar">
      <div className="row align-items-center mb-5">
        <div className="col-md-8 col-lg-12 d-md-flex align-items-center justify-content-between mb-3">
          <div className="breadcrumb-list mb-3">
            <ol className="breadcrumb d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to="/home">
                  <i
                    className="fa fa-home img-fluid"
                    style={{
                      fontSize: '15px',
                      width: '20px',
                      marginRight: '3px',
                    }}
                  ></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/home" style={{ textDecoration: 'none' }}>
                  Compte
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t('Rapports')}
              </li>
            </ol>
          </div>
          <div className="widgetbar">
            <button
              className="btn btn-danger-rgba mr-2"
              onClick={handlePreviousSubCase}
            >
              <i className="fa fa-chevron-left"></i>
            </button>
            <button
              className="btn btn-success-rgba mr-2"
              onClick={handleNextSubCase}
            >
              <i className="fa fa-chevron-right"></i>
            </button>
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-primary-rgba">
                  <i className="fa fa-print mr-2"></i>
                  {t('Imprimer')}
                </button>
              )}
              content={() => printRef.current} // Specifies the ref of the content to print
            />
          </div>
        </div>
        <div className="col-12">
          <div className="content-container">
            <div className="right-panel">
              <div className="subcase-container m-b-50 "  ref={printRef}> {/* This div and its content will be printed */}
                <div className="card m-b-50">
                  <div className="card-header text-center mt-2">
                    <h6>
                      {mainCases.find((mc) => mc.id === selectedMainCase)?.label}
                      {'  -->  '} {selectedSubCase?.label}
                    </h6>
                  </div>
                  <div
                    className="card-body text-center chart-container"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rapport;
