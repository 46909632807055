import React, { useState, useEffect, useRef} from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import ArticleTable from '../../layouts/TableArticle';


function ModifierDevis() {

    const navigate = useNavigate();
    const { id } = useParams(); 
    const [clients, setClients] = useState([]);
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [date_devis, setDateDevis] = useState("");
    const [validation, setValidation] = useState("");
    const [price, setPrice] = useState("");
    const [qte, setQte] = useState("");
    const [selectedArticle, setSelectedArticle] = useState("");
    const [editMode, setEditMode] = useState([]);
    const [tempArticleCommandes, setTempArticleCommandes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [selectedArticleId, setSelectedArticleId] = useState("");  // Used for storing the article ID


    useEffect(() => {
        const fetchArticlesAndCategories = async () => {
          try {
            const categoriesResponse = await axios.get("/categories");
            setCategories(categoriesResponse.data.categories);
            console.log('Categories:', categoriesResponse.data.categories);
      
            const articlesResponse = await axios.get("/articles");
            setArticles(articlesResponse.data);
            console.log('Articles:', articlesResponse.data); 
          } catch (error) {
            console.error("Error fetching articles or categories:", error);
          }
        };
      
        fetchArticlesAndCategories();
      }, []);
      
      
      useEffect(() => {
        const fetchClients = async () => {
          try {
            const response = await axios.get('/clients');
            setClients(response.data);
          } catch (error) {
            console.error("Error fetching clients:", error);
          }
        };
    
        fetchClients();
      }, []);

      useEffect(() => {
        if (articles.length === 0) {
            return; 
        }
        const fetchDevisDetails = async () => {
            try {
                const devisResponse = await axios.get(`/devis/${id}`);
                console.log("cfgh", devisResponse.data);
                const { id_client, date_devis, validation } = devisResponse.data;
                setSelectedClient(id_client);
                setDateDevis(date_devis);
                setValidation(validation);
    
                const allLignesDevisResponse = await axios.get(`/article-devis?id_devis=${id}`);
                const lignesDevisData = allLignesDevisResponse.data.map(ligne => ({
                    id: ligne.id,
                    selectedArticle: ligne.id_article,
                    quantity: ligne.quantite,
                    price: ligne.pu_vente,
                    nom_article: articles.find((article) => article.id === ligne.id_article)?.nom_article || "Article inconnu",
                    isDeleted: false, // Nouvelle propriété pour marquer les lignes supprimées
                }));
                setEditMode(lignesDevisData.map(() => false));
                setTempArticleCommandes(lignesDevisData);
            } catch (error) {
                console.error('Error fetching devis details:', error);
            }
        };
    
        fetchDevisDetails(); 
    }, [id, articles]);
    
    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
      };
    
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
  
      const handleSelectArticle = (id, nom_article) => {
        setSelectedArticleId(id);
        setSelectedArticle(nom_article);  
        setSearchTerm(nom_article);
        setDropdownOpen(false);
      };
    
    const handleAddRow = () => {
        const articleObj = articles.find(
          (article) => article.id === parseInt(selectedArticleId, 10)
        );
    
        if (!articleObj) {
          console.error("Article introuvable pour l'identifiant :", selectedArticleId);
          return;
        }
    
        const newCommande = {
          selectedArticle: articleObj.id,
          nom_article: articleObj.nom_article,
          quantity: parseFloat(qte),
          price: parseFloat(price),
        };
    
        setTempArticleCommandes([...tempArticleCommandes, newCommande]);
        setEditMode([...editMode, false]);
        setSelectedArticle("");
        setPrice("");
        setQte("");
      };
      const toggleEditMode = (index) => {
          setEditMode((prev) => prev.map((val, i) => (i === index ? !val : val)));
        };
        const handleTempRowChange = (index, field, value) => {
          const updatedRows = [...tempArticleCommandes];
      
          if (index >= 0 && index < updatedRows.length) {
            updatedRows[index][field] = value;
      
            if (field === "selectedArticle") {
              const article = articles.find(
                (article) => article.id === parseInt(value, 10)
              );
              updatedRows[index].nom_article = article ? article.nom_article : "Article inconnu";
            }
      
            setTempArticleCommandes(updatedRows);
          }
        };
      const saveChanges = () => {
        setEditMode((prev) => prev.map(() => false));
      };
    const onInputChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case "selectedClient":
                setSelectedClient(value);
                break;
            case "date_devis":
                setDateDevis(value);
                break;
            case "validation":
                setValidation(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          await axios.put(`/devis/${id}`, {
              id_client: selectedClient,
              date_devis: date_devis,
              validation: validation,
          });
  
          await Promise.all(
              tempArticleCommandes
                  .filter(row => !row.isDeleted) // Exclure les lignes marquées comme supprimées
                  .map(async (row) => {
                      if (row.id) {
                          await axios.put(`/article-devis/${row.id}`, {
                              id_devis: id,
                              id_article: row.selectedArticle,
                              quantite: row.quantity,
                              pu_vente: row.price
                          });
                      } else {
                          await axios.post("/article-devis", {
                              id_devis: id,
                              id_article: row.selectedArticle,
                              quantite: row.quantity,
                              pu_vente: row.price
                          });
                      }
                  })
          );
  
          handleSuccess();
      } catch (error) {
          handleError(error);
      }
  };
  
 

    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Devis modifié avec succès!',
        });
        navigate('/clients/devis');
    };

    const handleError = (error) => {
        console.error('Erreur lors de la modification du devis :', error);
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Échec de la modification du devis. Veuillez réessayer plus tard.',
        });
    };

    const handleRemoveRow = async (index) => {
      try {
          if (index < 0 || index >= tempArticleCommandes.length) {
              throw new Error("Index hors limite");
          }
  
          const row = tempArticleCommandes[index];
  
          if (!row.selectedArticle || !row.quantity || !row.price) {
              const updatedRows = [...tempArticleCommandes];
              updatedRows.splice(index, 1);
              setTempArticleCommandes(updatedRows); 
          } else {
              const confirmDelete = await Swal.fire({
                  icon: "warning",
                  title: "Confirmation",
                  text: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
                  showCancelButton: true,
                  confirmButtonText: "Oui",
                  cancelButtonText: "Non",
              });
  
              if (confirmDelete.isConfirmed) {
                  if (row.id) {
                      await axios.delete(`/article-devis/${row.id}`);
                  }
  
                  const updatedRows = [...tempArticleCommandes];
                  updatedRows.splice(index, 1);
                  setTempArticleCommandes(updatedRows);
              }
          }
      } catch (error) {
          console.error("Erreur lors de la suppression de la ligne:", error);
          Swal.fire({
              icon: "error",
              title: "Erreur",
              text: "Échec de la suppression de la ligne. Veuillez réessayer plus tard.",
          });
      }
  };
  
    
   
    
    return (
    <>
		
        <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Modifier devis client</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-4 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-4">Client</label>
                                            <select
                                                className=" form-control"
                                                name="selectedClient"
                                                value={selectedClient}
                                                onChange={onInputChange}
                                            >
                                                <option key="" value=""></option>
                                                {clients.map(client => (
                                                    <option key={client.id_client} value={client.id_client}>{client.nom_client} {client.prenom_client}</option>
                                                ))}
                                            </select>
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="date_devis"
                                                    value={date_devis}
                                                    onChange={onInputChange}
                                                />
                                            
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Statut</label>
                                                <select
                                                className="form-control"
                                                name="validation"
                                                value={validation}
                                                onChange={onInputChange}
                                            >
                                                <option value=""></option>
                                                <option value="valide">Valide</option>
                                                <option value="en_attente">En attente</option>
                                                <option value="annule">Annulé</option>
                                                <option value="en_cours">En cours</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                           <React.Fragment >
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                              <label className="me-2 col-md-4">Article</label>
                                                <div className="custom-select-wrapper" ref={dropdownRef}>
                                                    <div onClick={handleDropdownClick}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="nom_article"
                                                            value={selectedArticle} 
                                                            onChange={(e) => setSelectedArticle(e.target.value)} 
                                                        />
                                                    </div>
                                                        {dropdownOpen && (
                                                        <div className="autocom-box">
                                                            <input
                                                            type="search"
                                                            aria-label="Search"
                                                            className="search-box form-control"
                                                            placeholder="Rechercher..."
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                            onClick={() => setDropdownOpen(true)}
                                                            />
                                                            <ul>
                                                        {categories
                                                            .filter(category => 
                                                            articles.some(article => 
                                                                article.categorie_id === category.id && 
                                                                article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                            )
                                                            )
                                                            .map(category => (
                                                            <React.Fragment key={category.id}>
                                                                <li className="category-label text-bold">{category.nom_categorie}</li>
                                                                {articles
                                                                .filter(article => 
                                                                    article.categorie_id === category.id && 
                                                                    article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                                )
                                                                .map(filteredArticle => (
                                                                    <li
                                                                    key={filteredArticle.id}
                                                                    className="article-item"
                                                                    onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                                                    >
                                                                    {filteredArticle.nom_article}
                                                                    </li>
                                                                ))}
                                                            </React.Fragment>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Quantité</label>
                                                <input
                                                        type="number"
                                                        className="form-control"
                                                        value={qte}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            setQte(isNaN(newValue) ? 0 : newValue); 
                                                        }}
                                                        min={0}
                                                />
                                            </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Prix</label>
                                                <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                value={price}
                                                onChange={(e) => setPrice(  e.target.value)}                                           

                                                />
                                            </div>
                                            </React.Fragment>
                                            <div className="col-md-12 mb-3">
                                                <div className="row g-3">
                                                    <div className="col-md-4 offset-md-8 text-end ">
                                                        <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ArticleTable 
                                            tempArticleCommandes={tempArticleCommandes} 
                                            editMode={editMode} 
                                            handleTempRowChange={handleTempRowChange} 
                                            saveChanges={saveChanges} 
                                            handleRemoveRow={handleRemoveRow} 
                                            toggleEditMode={toggleEditMode}

                                        />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/clients/devis">Annuler</Link>
                                            <button  className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
        </div>
    </>

    );
}

export default ModifierDevis;
