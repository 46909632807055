import React, { useState } from "react";
import { useQuery,refetch } from 'react-query';
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import Search from "../../layouts/Search";
import { TailSpin } from 'react-loader-spinner';
import { useTranslation } from "react-i18next";
import { useQueryClient } from 'react-query';

// Fonction pour récupérer les bons d'entrée
const fetchBonEntrees = async () => {
    const response = await axios.get("/bon-entrees");
    return response.data;
};

// Fonction pour récupérer les fournisseurs
const fetchFournisseur = async (id) => {
    const response = await axios.get(`/fournisseurs/${id}`);
    return response.data;
};

// Fonction pour récupérer les commandes
const fetchCommande = async (id) => {
    const response = await axios.get(`/commandeFr/${id}`);
    return response.data.code;
};

// Fonction pour récupérer les lignes de bon d'entrée
const fetchLignesBonEntree = async (id) => {
    const response = await axios.get(`/ligne-bon-entree-fr?bon_entree_id=${id}`);
    return response.data;
};

// Fonction pour récupérer les paiements
const fetchPaiements = async (code) => {
    const response = await axios.get(`/PaiementFr?code_bon_entree=${code}`);
    return response.data;
};

// Fonction pour combiner toutes les données
const fetchBonEntreesWithDetails = async () => {
    const bonEntrees = await fetchBonEntrees();
    const bonEntreesWithData = await Promise.all(
        bonEntrees.map(async (bon) => {
            const fournisseurData = await fetchFournisseur(bon.id_fournisseur);
            let commandeCode = null;
            if (bon.id_cf) {
                commandeCode = await fetchCommande(bon.id_cf);
            }

            // Fetching lines and calculating total
            const lignesData = await fetchLignesBonEntree(bon.id);
            const montantTotalBonLivraison = lignesData.reduce(
                (total, ligne) => total + (ligne.montant * ligne.quantite),
                0
            );

            // Fetching payments and calculating total paid
            const paiementsData = await fetchPaiements(bon.code);
            const montantTotalPaiements = paiementsData.reduce(
                (total, paiement) => total + paiement.montant,
                0
            );

            const montantRestant = montantTotalBonLivraison - montantTotalPaiements;

            return {
                ...bon,
                fournisseur: fournisseurData.code,
                nom_fr: fournisseurData.nom_fournisseur,
                codeCommande: commandeCode,
                montant: montantTotalBonLivraison.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                reste : (
                    <span
                      style={{
                        color: montantRestant < 0 ? 'red' : 'green',
                      }}
                    >
                      {montantRestant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                    </span>
                  )
            };
        })
    );
    return bonEntreesWithData;
};

function ListerBonEntree() {
    const queryClient = useQueryClient();
    const {t}=useTranslation();
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [beIdToDelete, setBeIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(''); 

    const { data: bonEntrees, error, isLoading } = useQuery({
        queryKey: ['bonEntreesWithDetails'],
        queryFn: fetchBonEntreesWithDetails,
        staleTime: 60000, 
    });

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const filteredbonEntrees = bonEntrees?.filter((bon) => {
        return Object.keys(bon).some((key) => {
            if (typeof bon[key] === 'string') {
                return bon[key].toLowerCase().includes(searchTerm.toLowerCase());
            }
            return false;
        });
    }) || [];

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredbonEntrees.slice(offset, offset + itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const openDelete = (id) => {
        if (!id) {
            console.error("ID invalide pour la suppression");
            return;
        }
        setBeIdToDelete(id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setBeIdToDelete(null);
    };

    const handleDeleteBe = async () => {
        if (!beIdToDelete) {
            console.error("Pas de bon d'entrée pour le supprimer");
            return;
        }
        try {
            const lignesCommandeResponse = await axios.get(`/ligne-bon-entree-fr?bon_entree_id=${beIdToDelete}`);
            const lignesCommandeIds = lignesCommandeResponse.data.map((ligne) => ligne.id);
    
            if (lignesCommandeIds.length > 0) {
                await Promise.all(
                    lignesCommandeIds.map((ligneId) => axios.delete(`/ligne-bon-entree-fr/${ligneId}`))
                );
            }
    
            await axios.delete(`/bon-entrees/${beIdToDelete}`);
    
            setShowConfirmBox(false);
    
            queryClient.invalidateQueries('bonEntreesWithDetails');
    
            console.log("Bon d'entrée supprimé avec succès");
        } catch (error) {
            console.error("Erreur lors de la suppression du bon d'entrée :", error);
        }
    };
    

    const columns = [
        { key: 'Code_be', label: 'Code be', sortable: true },
        { key: 'code_F', label: 'code F', sortable: false },
        { key: 'Fournisseur', label: 'Fournisseur', sortable: true },
        { key: 'Code_CF', label: 'Code CF', sortable: true },
        { key: 'date', label: 'date', sortable: true },
        { key: 'Montant', label: 'Montant', sortable: true },
        { key: 'Reste', label: 'Reste', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false  }
    ];

    // if (isLoading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            
                    <div className="breadcrumbbar">
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('bon d\'entrée de fournisseurs')}</h4>
                            <div className="breadcrumb-list mb-3">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                    <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/home" style={{textDecoration:'none'}}>{t('Fournisseurs')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> {t('Liste')} {t(' bon d\'entrée Fournisseurs')}</li>
                                </ol>
                                </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                   <div className="card-header">
                                      <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/fournisseur/creer-bon-entree" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                       </div>
                                       
                                      </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((bonentree) => ({
                                                    id: bonentree.id,
                                                    Code_be: bonentree.code,
                                                    code_F: bonentree.fournisseur,
                                                    Fournisseur: bonentree.nom_fr,
                                                    Code_CF: bonentree.codeCommande,
                                                    date: bonentree.date_entree,
                                                    Montant: bonentree.montant,
                                                    Reste:bonentree.reste,                                                    
                                                      actions: (
                                                        <div style={{ textAlign: "center" }}> 
                                                            <Link to={`/fournisseur/modifier-bon-entree/${bonentree.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip"                                                                   
                                                             style={{ marginLeft: "10px" }}

                                                            >
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(bonentree.id)} 
                                                                style={{ marginLeft: "10px" }}
                                                                className="btn btn-danger-rgba btn-round mb-1">
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link to={`/ajouter-paiement-fournisseur/${bonentree.id}`} className="btn btn-info-rgba btn-round mb-1" title="ajouter paiement" data-toggle="tooltip" style={{ marginLeft: "10px" }}>
                                                                <span className="material-icons" style={{ fontSize: "small" }}>{t('payment')}</span>
                                                            </Link>
                                                            <Link to={`https://admin.mansoft.ma/api/bon-entrees/pdf/${bonentree.id}`}     
                                                            style={{ marginLeft: "10px" }}
                                                               title="Consulter" className="btn btn-danger-rgba btn-round"
                                                               target="_blank">
                                                                <i className="fa fa-file-pdf-o" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select className="form-control" id="items-per-page" onChange={handleItemsPerPageChange} value={itemsPerPage}>
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredbonEntrees}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                         )}
                                         </>
                                     )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="Bon entree fournisseur"
                        deleteFunction={handleDeleteBe}
                    />
               
        </>
    );
}

export default ListerBonEntree;
