import React, { useEffect } from 'react';
import { sendOfflineOperations } from './layouts/useOfflineOperations';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/js/vertical-menu';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LanguageProvider } from './layouts/LanguageContext';
import { Provider } from 'react-redux';
import store, { persistor } from './layouts/store/store'; 
import { PersistGate } from 'redux-persist/integration/react'; 


const queryClient = new QueryClient();

const RootComponent = () => {
  useEffect(() => {
    function handleOnline() {
      console.log('Vous êtes en ligne');
      sendOfflineOperations();
    }

    window.addEventListener('online', handleOnline);

    if (navigator.onLine) {
      sendOfflineOperations();
    }

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    <LanguageProvider>
      <React.StrictMode>
        <Router>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </PersistGate>
          </Provider>
        </Router>
      </React.StrictMode>
    </LanguageProvider>
  );
};

const root = document.getElementById('root');
const appRoot = createRoot(root);

appRoot.render(<RootComponent />);
serviceWorkerRegistration.register();
