import React, { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ConfirmBox from '../../../layouts/ConfirmBox';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

const GererTaches = () => {
  const { t } = useTranslation();
  const [personnels, setPersonnels] = useState([]);
  const [personnelId, setPersonnelId] = useState('');
  const [tasks, setTasks] = useState([]);
  const [message, setMessage] = useState('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [tacheIdToDelete, settacheIdToDelete] = useState();

  const [selectedTask, setSelectedTask] = useState({
    id: '',
    titre: '',
    duree: '0h0m',
    personnel_id: '',
    date: '',
    heures: '0',
    minutes: '0'
  });

  const [showModal, setShowModal] = useState(false);

  const handleEditClick = (task) => {
    if (task) {
      const [heures, minutes] = task.duree.split('h').join('m').split('m');
      setSelectedTask({
        id: task.id,
        titre: task.titre,
        heures: heures || '0',
        minutes: minutes || '0',
        personnel_id: task.personnel_id,
        date: task.date || ''
      });
      setShowModal(true);
    }
  };

  const fetchTasks = async (id) => {
    try {
      const response = await axios.get(`/taches?personnel_id=${id}`);
      console.log(response.data);
      setTasks(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des tâches:', error);
    }
  };

  const fetchPersonnels = async () => {
    try {
      const response = await axios.get('/personnel');
      console.log(response.data);
      setPersonnels(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des personnes:', error);
    }
  };

  useEffect(() => {
    fetchPersonnels();
  }, []);

  useEffect(() => {
    if (personnelId) {
      fetchTasks(personnelId);
    }
  }, [personnelId]);

  const handleTitleChange = (e) => {
    setSelectedTask({ ...selectedTask, titre: e.target.value });
  };

  const handleDateChange = (e) => {
    setSelectedTask({ ...selectedTask, date: e.target.value });
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setSelectedTask({
      ...selectedTask,
      [name]: value,
      duree: `${selectedTask.heures}h${selectedTask.minutes}m`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTask.id) return;

    try {
      const updatedTask = {
        personnel_id: selectedTask.personnel_id,
        titre: selectedTask.titre,
        duree: selectedTask.duree,
        date: selectedTask.date,
      };

      await axios.put(`/taches/${selectedTask.id}`, updatedTask);
      setMessage('Tâche modifiée avec succès');
      fetchTasks(personnelId);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  };

  const openDelete = (tacheId) => {
    settacheIdToDelete(tacheId);
    setShowConfirmBox(true);
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false);
    settacheIdToDelete(null);
  };

  const handleDeleteTache = async () => {
    if (tacheIdToDelete === null || tacheIdToDelete === undefined) {
      console.error("No tache ID to delete");
      return;
    }
    try {
      await axios.delete(`/taches/${tacheIdToDelete}`);
      setShowConfirmBox(false);
      fetchTasks(personnelId);
    } catch (error) {
      console.error("Error deleting tache:", error);
    }
  };

  return (
    <div className="agenda-container mb-5">
      <div className="row mb-3">
        <label className="col-12 col-md-3 col-form-label">
          {t('Personnel ')} 
          <span className="required" aria-required="true"> &nbsp; &nbsp;* </span>&nbsp;: 
        </label>
        <div className="col-12 col-md-9">
          <select
            required
            name="departmentid"
            className="form-control"
            aria-required="true"
            value={personnelId}
            onChange={(e) => setPersonnelId(e.target.value)}
          >
            <option value="">---</option>
            {personnels.map(personnel => (
              <option key={personnel.id} value={personnel.id}>
                {personnel.nom_complet}
              </option>
            ))}
          </select>
        </div>
      </div>

      <table className='table table-striped table-bordered w-100 mt-4'>
        <thead>
          <tr>
            <th>{t('Taches')}</th>
            <th>{t('Date')}</th>
            <th>{t('Durée')}</th>
            <th>{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td>{task.titre}</td>
              <td>{task.date}</td>
              <td>{task.duree}</td>
              <td>
                <div style={{ textAlign: "center" }}>
                  <Link
                    className="btn btn-success-rgba btn-round mb-1"
                    title="Edit"
                    onClick={() => handleEditClick(task)}
                  >
                    <i className="feather icon-edit-2" style={{ fontSize: "small" }} ></i>
                  </Link>
                  <Link                                                      
                    onClick={() => openDelete(task.id)}
                    className="btn btn-danger-rgba btn-round mb-1" title="Delete"  style={{ marginLeft: "10px" }}>
                    <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Modifier la tâche')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formPersonnel">
              <Form.Label>
                {t('Personnel')} 
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                as="select"
                required
                name="departmentid"
                value={selectedTask.personnel_id}
                onChange={(e) => setSelectedTask({ ...selectedTask, personnel_id: e.target.value })}
              >
                <option value="">---</option>
                {personnels.map(personnel => (
                  <option key={personnel.id} value={personnel.id}>
                    {personnel.nom_complet}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>
                {t('Titre')}
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                type="text"
                value={selectedTask.titre}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDate">
              <Form.Label>
                {t('Date')}
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <Form.Control
                type="date"
                value={selectedTask.date}
                onChange={handleDateChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDuration">
              <Form.Label>
                Durée
                <span className="required" aria-required="true"> * </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  name="heures"
                  value={selectedTask.heures}
                  onChange={handleDurationChange}
                  placeholder="Heures"
                  step="1"
                  required
                />
                <InputGroup.Text>h</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="minutes"
                  value={selectedTask.minutes}
                  onChange={handleDurationChange}
                  placeholder="Minutes"
                  step="1"
                  required
                />
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            {message && <div className="alert alert-info">{message}</div>}

            <Modal.Footer>
              <Button variant="success" type="submit">{t('Modifier')}</Button>
              <Button variant="danger" onClick={() => setShowModal(false)}>{t('Fermer')}</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <ConfirmBox
        open={showConfirmBox}
        closeDialog={closeConfirmBox}
        title="Tache"
        deleteFunction={handleDeleteTache}
      />
    </div>
  );
};

export default GererTaches;
