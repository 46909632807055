import React, { useState, useEffect, useRef } from "react";
import axios from "../../api/axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function AjouterCommandeCl() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [clients, setClients] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [date_commande, setDateCommande] = useState("");
  const [statut, setStatut] = useState("");
  const [selectedArticle, setSelectedArticle] = useState("");
  const [price, setPrice] = useState("");
  const [qte, setQte] = useState("");
  const [categories, setCategories] = useState([]);
  const [article_commandes, setArticleCommande] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  function formatNumber(number) {
    return number.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("/clients");
        setClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get("/categories");
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchArticles = async () => {
      try {
        const response = await axios.get("/articles");
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };
    fetchClients();
    fetchCategories();
    fetchArticles();
  }, []);

  useEffect(() => {
    if (!id) return;
    if (articles.length > 0) {
      const fetchDevisDetails = async () => {
        try {
          const devisResponse = await axios.get(`/devis/${id}`);
          const { id_client, date_devis, validation } = devisResponse.data;
          setSelectedClient(id_client);
          setDateCommande(date_devis);
          setStatut(validation);
  
          const allLignesDevisResponse = await axios.get(`/article-devis?id_devis=${id}`);
          const lignesDevisData = allLignesDevisResponse.data.map(ligne => ({
            id: ligne.id,
            id_article: ligne.id_article,
            nom_article: articles.find(article => article.id === ligne.id_article)?.nom_article || "Nom inconnu",
            quantity: ligne.quantite,
            price: ligne.pu_vente,
            isDeleted: false,
          }));
          setArticleCommande(lignesDevisData);
        } catch (error) {
          console.error('Error fetching devis details:', error);
        }
      };
  
      fetchDevisDetails();
    }
  }, [id, articles]);
  
  useEffect(() => {
    const filtered = articles.filter(article =>
      article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredArticles(filtered);
  }, [searchTerm, articles]);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectArticle = (id, nom_article) => {
    setSelectedArticle(id);
    setSearchTerm(nom_article);
    setDropdownOpen(false);
  };

  const handleAddRow = () => {
    const selectedArticleObj = articles.find(
      (article) => article.id === parseInt(selectedArticle, 10)
    );

    if (!selectedArticleObj) {
      console.error("L'article sélectionné n'a pas été trouvé");
      return;
    }

    const newCommande = {
      id_article: selectedArticle,
      nom_article: selectedArticleObj.nom_article,
      quantity: qte,
      price: price,
    };

    setArticleCommande([...article_commandes, newCommande]);
    setSelectedArticle('');
    setSearchTerm('');
    setPrice('');
    setQte('');
  };

  const handleChange = (field, value) => {
    switch (field) {
      case "selectedClient":
        setSelectedClient(value);
        break;
      case "date_commande":
        setDateCommande(value);
        break;
      case "qte":
        setQte(value);
        break;
      case "price":
        setPrice(value);
        break;
      default:
        console.warn("Champ inconnu:", field);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Selected Client:', selectedClient);
    console.log('Date Commande:', date_commande);
    console.log('Statut:', statut);
  
    // if (!selectedClient || !selectedClient.trim() || !date_commande || !date_commande.trim() || !statut || !statut.trim()) {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'Attention',
    //     text: 'Veuillez remplir tous les champs requis.',
    //   });
    //   return;
    // }
  
    try {
      const commandeClientResponse = await axios.post("/commandeclient", {
        idclient: selectedClient,
        date_commande: date_commande,
        statut: statut,
      });
  
      if (commandeClientResponse.status === 201) {
        const commandeClientId = commandeClientResponse.data.num_commande;
  
        await Promise.all(
          article_commandes
            .filter(commande => !commande.isDeleted)
            .map(async (commande) => {
              await axios.post("/article-commandes", {
                id_commande_cl: commandeClientId,
                id_article: commande.id_article,
                quantite: commande.quantity,
                pu_vente: commande.price,
              });
            })
        );
  
        handleSuccess();
      } else {
        handleError();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccess = () => {
    Swal.fire({
      icon: 'success',
      title: 'Succès',
      text: 'Commande client ajoutée avec succès!',
    });
    navigate('/clients/commandes');
  };

  const handleError = (error) => {
    console.error('Erreur lors de l\'ajout de la commande client:', error);
    Swal.fire({
      icon: 'error',
      title: 'Erreur',
      text: 'Échec de l\'ajout de la commande client. Veuillez réessayer plus tard.',
    });
  };

  

  const deleteArticle = (index) => {
    const updatedArticleCommandes = [...article_commandes];
    updatedArticleCommandes.splice(index, 1);
    setArticleCommande(updatedArticleCommandes);
  };

  return (
    <>
     <div className="breadcrumbbar">
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                    <h5 className="card-title">Ajouter commande client</h5>
                  </div>
                  <div className="card-body">
                    <form className="needs-validation" onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="col-md-4 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Client</label>
                          <select
                            className="form-control"
                            value={selectedClient}
                            onChange={(e) => setSelectedClient(e.target.value)}
                            required
                          >
                            <option key="" value=""></option>
                            {clients.map((client) => (
                              <option key={client.id_client} value={client.id_client}>
                                {client.nom_client} {client.prenom_client}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-md-4 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_commande"
                            value={date_commande}
                            onChange={(e) => setDateCommande(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-4 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Statut</label>
                          <select
                            className="form-control"
                            name="statut"
                            value={statut}
                            onChange={(e) => setStatut(e.target.value)}
                            required
                          >
                            <option value=""></option>
                            <option value="valide">Validé</option>
                            <option value="en_attente">En attente</option>
                            <option value="annule">Annulé</option>
                            <option value="en_cours">En cours</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <React.Fragment>
                          <div className="col-md-4 d-flex align-items-center mb-3">
                            <label className="me-2 col-md-4">Article</label>
                            <div className="custom-select-wrapper" ref={dropdownRef}>
                              <div className="form-control" onClick={handleDropdownClick}>
                                {searchTerm}
                              </div>
                              {dropdownOpen && (
                                <div className="autocom-box">
                                  <input
                                    type="search"
                                    aria-label="Search"
                                    className="search-box form-control"
                                    placeholder="Rechercher..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onClick={() => setDropdownOpen(true)}
                                  />
                                  <ul>
                                    {categories
                                      .filter(category => 
                                        articles.some(article => 
                                          article.categorie_id === category.id && 
                                          article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                        )
                                      )
                                      .map(category => (
                                        <React.Fragment key={category.id}>
                                          <li className="category-label text-bold">{category.nom_categorie}</li>
                                          {articles
                                            .filter(article => 
                                              article.categorie_id === category.id && 
                                              article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                            )
                                            .map(filteredArticle => (
                                              <li
                                                key={filteredArticle.id}
                                                className="article-item"
                                                onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                              >
                                                {filteredArticle.nom_article}
                                              </li>
                                            ))}
                                        </React.Fragment>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 d-flex align-items-center mb-3">
                            <label className="me-2 col-md-4">Quantité</label>
                            <input
                              type="number"
                              className="form-control"
                              value={qte}
                              onChange={(e) => handleChange('qte', e.target.value)}
                              min={0}
                              
                            />
                          </div>
                          <div className="col-md-4 d-flex align-items-center mb-3">
                            <label className="me-2 col-md-4">Prix</label>
                            <input
                              type="number"
                              className="form-control form-control-solid"
                              value={price}
                              onChange={(e) => handleChange('price', e.target.value)}
                              
                            />
                          </div>
                        </React.Fragment>
                        <div className="col-md-12 mb-3">
                          <div className="row g-3">
                            <div className="col-md-4 offset-md-8 text-end">
                              <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table className="display table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Article</th>
                            <th>Quantité</th>
                            <th>P.U</th>
                            <th>Total</th>
                            <th className="d-md-flex justify-content-md-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {article_commandes.map((commande, index) => (
                            <tr key={index}>
                              <td>{commande.nom_article}</td>
                              <td>{commande.quantity}</td>
                              <td>{formatNumber(parseFloat(commande.price))}</td>
                              <td>{formatNumber(parseFloat(commande.quantity * commande.price))}</td>
                              <td className="d-md-flex justify-content-md-end">
                                <i
                                  className="feather icon-trash btn btn-danger-rgba btn-round"
                                  style={{ fontSize: "small" }}
                                  onClick={() => deleteArticle(index)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Link className="btn btn-primary-rgba my-1" to="/clients/commandes">
                          Annuler
                        </Link>
                        <button className="btn btn-primary-rgba my-1" type="submit">
                          Ajouter<i className="feather icon-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </>
  );
}

export default AjouterCommandeCl;
