import React, { useState, useMemo } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import 'material-icons/iconfont/material-icons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useQuery } from 'react-query';
import { TailSpin } from 'react-loader-spinner';
import { useTranslation } from "react-i18next";
import { fetchBonsLivraisons, fetchClient, fetchLigneBonLiv, fetchPaiementClient } from "../../services/apiService";

function ListerBonLiv() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [BonLivIdToDelete, seBonLivIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [searchTerm, setSearchTerm] = useState('');
    const [setBonsLivraisons]=useState('');

    const fetchBonsLivraisonsData = async () => {
        const data = await fetchBonsLivraisons();
        const enrichedData = await Promise.all(data.map(async (bl) => {
            const clientData = await fetchClient(bl.id_client);
            const lignesData = await fetchLigneBonLiv(bl.id);

            const montantTotalBonLivraison = lignesData.reduce(
                (total, ligne) => total + ligne.pu_achat * ligne.quantite,
                0
            );

            const paiementsData = await fetchPaiementClient(bl.code);
            const montantTotalPaiements = paiementsData.reduce(
                (total, paiement) => total + paiement.montant,
                0
            );

            const montantRestant = montantTotalBonLivraison - montantTotalPaiements;

            return {
                ...bl,
                client: `${clientData.nom_client} ${clientData.prenom_client}`,
                montant: montantTotalBonLivraison.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                reste:(
                    <span
                      style={{
                        color: montantRestant < 0 ? 'red' : 'green',
                      }}
                    >
                      {montantRestant.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                    </span>
                  )
            };
        }));

        return enrichedData;
    };

    const { data: bonsLivraisons = [], isLoading, error } = useQuery('bonsLivraisons', fetchBonsLivraisonsData);

    const filteredbonsLivraisons = useMemo(() => {
        return bonsLivraisons.filter((commande) => {
            return Object.keys(commande).some((key) => {
                if (typeof commande[key] === 'string') {
                    return commande[key].toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });
        });
    }, [bonsLivraisons, searchTerm]);

    const currentItems = useMemo(() => {
        const offset = currentPage * itemsPerPage;
        return filteredbonsLivraisons.slice(offset, offset + itemsPerPage);
    }, [filteredbonsLivraisons, currentPage, itemsPerPage]);

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const createFacture = async (bonLivraisonId, montant) => {
        const dateFac = new Date().toISOString().split('T')[0];

        try {
            const response = await axios.post('/factures', {
                bon_livraison_id: bonLivraisonId,
                date_fac: dateFac,
                montant: montant,
            });

            console.log('Facture créée avec succès:', response.data);
            handleSuccess(response.data.id);
        } catch (error) {
            console.error('Erreur lors de la création de la facture:', error);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'La création de la facture a échoué. Veuillez réessayer.',
            });
        }
    };

    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Facture créée avec succès!',
        }).then(() => {
            navigate(`/factures`);
        });
    };

    const openDelete = (Id) => {
        seBonLivIdToDelete(Id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        seBonLivIdToDelete(null);
    };

    const handleDeleteBonLiv = async () => {
        if (BonLivIdToDelete === undefined || BonLivIdToDelete === null) {
            console.error("No bon de livraison ID to delete");
            return;
        }

        try {
            const lignesBonLivResponse = await axios.get(`/ligne-bon-liv-cls?bonLivraisonId=${BonLivIdToDelete}`);
            const lignesBonLivIds = lignesBonLivResponse.data.map(ligne => ligne.id);

            await Promise.all(lignesBonLivIds.map(async ligneId => {
                await axios.delete(`/ligne-bon-liv-cls/${ligneId}`);
            }));

            await axios.delete(`/bonslivraisons/${BonLivIdToDelete}`);

            setBonsLivraisons(bonsLivraisons.filter(bonLiv => bonLiv.id !== BonLivIdToDelete));
            setShowConfirmBox(false);

            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Bon de livraison supprimé avec succès!',
            });

        } catch (error) {
            console.error("Error deleting bon de livraison:", error);

            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'La suppression du bon de livraison a échoué. Veuillez réessayer.',
            });
        }
    };
    const columns = [
        { key: 'Bon', label: 'Code', sortable: true },
        { key: 'Client', label: 'Client', sortable: true },
        { key: 'Date', label: 'Date', sortable: false },
        { key: 'Montant', label: 'Montant', sortable: true },
        { key: 'Reste', label: 'Reste', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false  }
    ];

    return (
        <>
		
        
        <div className="breadcrumbbar">
                <div className="row align-items-center">
                    <div className="col-md-8 col-lg-8">
                        <h4 className="page-title">{t('Liste de bon livraison')}</h4>
                        <div className="breadcrumb-list mb-3">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                 </li>
                                <li className="breadcrumb-item">
                                    <Link to="/home" style={{textDecoration:'none'}}>{t('clients')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('List bon livraison')}</li>
                            </ol>
                        </div>
                    </div>
                   
                </div>                   
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-30">
                           <div className="card-header">
                                <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/clients/ajoute-bon-livraison" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                </div>
                            </div>
                            
                            <div className="card-body">
                            {isLoading ? (
                                <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                            <div className="table-responsive">
                            <SortingTable  
                              data={currentItems.map((bonLivraison) => ({
                                   id:bonLivraison.id,
                                    Bon: bonLivraison.code,
                                    Client: bonLivraison.client,
                                    Date: bonLivraison.date_livraison,
                                    Montant:bonLivraison.montant ,
                                    Reste:bonLivraison.reste,
                                  
                                    actions: (
                                    <div style={{ textAlign: "center" } }>
                                         <Link to={`/clients/modifie-bon-livraison/${bonLivraison.id}`} 
                                         className="btn btn-success-rgba btn-round mb-1" 
                                         style={{ marginLeft: "10px" }}

                                         >
                                        <i className="feather icon-edit-2" style={{fontSize:"medium"}}></i>
                                        </Link>
                                        <Link onClick={() => openDelete(bonLivraison.id)}
                                        style={{ marginLeft: "10px" }}
                                         className="btn btn-danger-rgba btn-round mb-1">
                                        <i className="feather icon-trash" ></i>
                                       </Link>
                                        <Link  
                                        to={`/clients/ajouter-bon-retour/${bonLivraison.id}`} className="btn btn-warning-rgba btn-round mb-1"
                                        title="Créer Bon de retour" data-toggle="tooltip"  
                                        style={{ marginLeft: "10px" }}>
                                        <i className="feather icon-repeat" style={{fontSize: "small"}}></i>
                                        </Link>
                                        <Link  
                                        to={`/ajouter-paiement-client/${bonLivraison.id}`} className="btn btn-info-rgba btn-round mb-1"
                                        title="ajouter paiment " data-toggle="tooltip"  
                                        style={{ marginLeft: "10px" }}
                                        >
                                        <span className="material-icons" style={{fontSize: "small"}}>payment</span>
                                       </Link>
                                       <button
                                       to={`/bonslivraisons/pdf/${bonLivraison.id}`}
                                        onClick={() => createFacture(bonLivraison.id, bonLivraison.montant)}
                                                                className="btn btn-primary-rgba btn-round "
                                                                title="générer la facture"
                                                                data-toggle="tooltip"
                                                                style={{ marginLeft: "10px" }}
>
                                                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                                                </button>
                                    </div>
                                    ),
                                }))}
                              columns={columns}
                            />
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <select
                                  className="form-control"
                                  id="items-per-page"
                                  onChange={handleItemsPerPageChange}
                                  value={itemsPerPage}
                                    >
                                      <option value={2}>2</option>
                                      <option value={5}>5</option>
                                      <option value={10}>10</option>
                                      <option value={20}>20</option>
                                    </select>
                                  </div>
                                  <Pagination
                                          items={filteredbonsLivraisons} 
                                          itemsPerPage={itemsPerPage}
                                          onPageChange={handlePageChange}
                                  />
                            </div>
                                    
                                </div>
                                 )}
                                 </>
                             )}
                            </div>
                        </div>
                    </div>
                </div>
        </div>

            <ConfirmBox
                open={showConfirmBox}
                closeDialog={closeConfirmBox}
                title="Bon Livraison"
                deleteFunction={handleDeleteBonLiv}
           />	
   
   

</>
    );
}

export default ListerBonLiv;
