import React from 'react';
import { useTranslation } from 'react-i18next'; 

function Search({ onSearchChange }) {
  const { t } = useTranslation(); 
  const handleChange = (event) => {
    onSearchChange(event.target.value); 
  };

  return (
    <div className="input-group">
      <input 
        type="search" 
        className="form-control" 
        placeholder={t('Chercher...')} 
        aria-label="Search" 
        onChange={handleChange}
      />
    </div>
  );
}

export default Search;
