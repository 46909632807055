import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate,Link } from 'react-router-dom';
import Swal from "sweetalert2";

function AjouterUtilisateur() {
    const navigate = useNavigate();

    const [personnels, setPersonnels] = useState([]);
    const [magasins, setMagasins] = useState([]);
    const [selectedPersonnel, setSelectedPersonnel] = useState("");

    const [selectedMagasin, setSelectedMagasin] = useState("");
    const [formData, setFormData] = useState({
        role: "",
        nomUtilisateur: "",
        password: "",
        confirmPassword: "",
        email:"",
    });

    useEffect(() => {
        axios.get("/personnel")
            .then(response => {
                setPersonnels(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des personnels :", error);
            });

        axios.get("/magasins")
            .then(response => {
                setMagasins(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des magasins :", error);
            });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const selectedPersonnelId = personnels.find(personnel => personnel.nom_complet === selectedPersonnel)?.id;
    
        if (!selectedPersonnelId) {
            console.error("ID du personnel non trouvé.");
            return;
        }
    
        try {
            const userData = {
            personnel_id: selectedPersonnelId,
            role: formData.role,
            name: formData.nomUtilisateur,
            password: formData.password,
            password_confirmation: formData.confirmPassword,
            email: formData.email
        };

        try {
            const userResponse = await axios.post("/register", userData);
    
            if (userResponse.status === 201) {
                const userId = userResponse.data.id;
                console.log("ID du dernier utilisateur ajouté :", userId);
    
                const selectedMagasinId = magasins.find(magasin => magasin.nom_magasin === selectedMagasin)?.id;

                if (!selectedMagasinId) {
                    console.error("ID du magasin non trouvé.");
                    return;
                }
                    const magasinUserData = {
                        id_utilisateur: userId,
                        id_magasin: selectedMagasinId
                            };
                
                    await axios.post("/utilisateur-magasin", magasinUserData);
                            Swal.fire('Success', 'user added successfully', 'success');
                            navigate('/utilisateurs');
                        } else {
                            console.log("Erreur lors de la création de l'utilisateur :", userResponse.statusText);
                        }
                    } catch (error) {
                        console.error("Erreur lors de la création de l'utilisateur :", error);
                    }            
        }catch (error) {
            console.error("Erreur lors de la création de l'utilisateur :", error);
        }
    }
     const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'magasins') {
            setSelectedMagasin(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }
    

    return (
        <>
           
                    <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Ajouter un utilisateur</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} className="needs-validation">
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Choisir un Personnel</label>
                                                    <select
                                                        className="form-control"
                                                        value={selectedPersonnel}
                                                        onChange={(e) => setSelectedPersonnel(e.target.value)}
                                                    >
                                                        <option value=""></option>
                                                        {personnels.map(personnel => (
                                                            <option key={personnel.id} value={personnel.nom_complet}>
                                                                {personnel.nom_complet}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Magasin</label>
                                                    <select
                                                        name="magasins"
                                                        className="form-control"
                                                        value={selectedMagasin}
                                                        onChange={(e) => setSelectedMagasin(e.target.value)}
                                                    >


                                                        <option key="" value=""></option>
                                                        {magasins.map(magasin => (
                                                            <option key={magasin.id} value={magasin.nom_magasin}>
                                                                {magasin.nom_magasin}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Nom utilisateur</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="nomUtilisateur"
                                                        value={formData.nomUtilisateur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                
                                                </div>
                                                <div className="form-row">

                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Nouveau Mot de passe</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Confirmer le mot de passe</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="confirmPassword"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Role</label>
                                                    <select
                                                        className="form-control"
                                                        name="role"
                                                        value={formData.role}
                                                        onChange={handleChange}

                                                    >
                                                        <option value=""></option>
                                                        <option value="chef personnel">chef personnel</option>
                                                        <option value="Super Admin">Super Admin</option>
                                                        <option value="Comptable admin">Comptable admin</option>
                                                        <option value="controleur">Controleur</option>
                                                        <option value="livraison admin">Livraison admin</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-primary-rgba my-1" to="/utilisateurs">Annuler</Link>
                                                <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
        </>
    );
}

export default AjouterUtilisateur;
