import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';

function ListerDevis() {
     const {t}= useTranslation();
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [devisIdToDelete, setdevisIdToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [itemsPerPage, setItemsPerPage] = useState(2);

    const { data: devis, isLoading, error } = useQuery('devis', async () => {
        const response = await axios.get("/devis");
        const devisWithData = response.data.map(async devis => {
            const clientResponse = await axios.get(`/clients/${devis.id_client}`);
            const clientData = clientResponse.data;
            return {
                ...devis,
                client: clientData.nom_client + ' ' + clientData.prenom_client,
                societe: clientData.societe
            };
        });

        return await Promise.all(devisWithData);
    });

    const [filteredDevis, setFilteredDevis] = useState([]);

    useEffect(() => {
        if (devis) {
            setFilteredDevis(devis);
        }
    }, [devis]);

    const handleSearchChange = (term) => {
        setSearchTerm(term);
        const filtered = devis.filter((commande) => {
            return Object.keys(commande).some((key) => {
                if (typeof commande[key] === 'string') {
                    return commande[key].toLowerCase().includes(term.toLowerCase());
                }
                return false;
            });
        });
        setFilteredDevis(filtered);
    };

    // Pagination
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredDevis.slice(offset, offset + itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const fetchDevisDetails = async (Numdevis) => {
        try {
            const allLignesDevisResponse = await axios.get(`/article-devis?id_devis_cl=${Numdevis}`);
            const allLignesDevisData = allLignesDevisResponse.data;
            const lignesDevisData = allLignesDevisData.filter(ligne => ligne.id_devis_cl === Numdevis);
            return lignesDevisData.map(ligne => ligne.id);
        } catch (error) {
            console.error('Error fetching devis details:', error);
            throw error;
        }
    };

    const openDelete = (Id) => {
        setdevisIdToDelete(Id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setdevisIdToDelete(null);
    };

    const handleDeleteDevis = async () => {
        if (!devisIdToDelete) {
            console.error("No devis ID to delete");
            return;
        }

        try {
            const lignesDevisIds = await fetchDevisDetails(devisIdToDelete);
            for (const ligneId of lignesDevisIds) {
                await axios.delete(`/article-devis/${ligneId}`);
            }

            if (devisIdToDelete) {
                axios
                    .delete(`/devis/${devisIdToDelete}`)
                    .then((result) => {
                        setFilteredDevis(prevDevis => prevDevis.filter(devis => devis.id !== devisIdToDelete));
                    })
                    .catch((error) => {
                        console.error("Error deleting category:", error);
                        alert("Error in the Code");
                    })
                    .finally(() => {
                        closeConfirmBox();
                    });
            }
        } catch (error) {
            console.error("Error deleting devis:", error);
        }
    }

   

    const columns = [
        { key: 'Devis', label: t('Devis'), sortable: true },
        { key: 'Client', label: t('Client'), sortable: true },
        { key: 'Societe', label: t('Societe'), sortable: true },
        { key: 'Date', label: t('Date'), sortable: true },
        { key: 'Statut', label: t('Statut'), sortable: true },
        { key: 'actions', label: t('Actions'), sortable: false }
      ];
    

    return (
        <>
           
                    <div className="breadcrumbbar">
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('Liste devis de clients')}</h4>
                            <div className="breadcrumb-list mb-3">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/home">
                                <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                    </Link>
                                 </li>
                                <li className="breadcrumb-item">
                                    <Link to="/home" style={{textDecoration:'none'}}>{t('clients')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('List devis')}</li>
                            </ol>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                       <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/clients/ajoute-devis" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                       </div>
                                        
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {error ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((devis) => ({
                                                    id: devis.id,
                                                    Devis: devis.code,
                                                    Client: devis.client,
                                                    Societe: devis.societe,
                                                    Date: devis.date_devis,
                                                    Statut: devis.validation === 'valide' ? (
                                                        <span className="badge badge-success font-14">Valide</span>
                                                    ) : devis.validation === 'en_cours' ? (
                                                        <span className="badge badge-info font-14">En cours</span>
                                                    ) : devis.validation === 'en_attente' ? (
                                                        <span className="badge badge-warning font-14">En attente</span>
                                                    ) : (
                                                        <span className="badge badge-danger font-14">Non valide</span>
                                                    ),
                                                    actions: (
                                                        <div className="text-center">
                                                            <Link to={`/clients/modifie-devis/${devis.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit"  style={{ marginRight: '10px'}}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(devis.id)} className="btn btn-danger-rgba btn-round mb-1" style={{ marginRight: '10px'}}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link
                                                                to={`/clients/ajoute_commande/${devis.id}`}
                                                                title="Créer bon de commande" data-toggle="tooltip" className="btn btn-primary-rgba btn-round mb-1" style={{ marginRight: '10px' }} >
                                                                <i className="feather icon-shopping-bag" style={{ fontSize: "small" }} />
                                                            </Link>
                                                            <Link
                                                                to={`/clients/ajoute-bon-livraison/${devis.id}`}
                                                                style={{ marginRight: '10px' }}
                                                                title="Créer Bon de Livraison" data-toggle="tooltip" className="btn btn-warning-rgba btn-round">
                                                                <i className="feather icon-truck" style={{ fontSize: "small" }} />
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredDevis}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                         )}
                                         </>
                                     )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="devis"
                        deleteFunction={handleDeleteDevis}
                    />
                
        </>
    );
}

export default ListerDevis;
