import React, { useState } from 'react';
import { useAuth } from '../../layouts/AuthContext';
import { useQuery } from 'react-query';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { useTranslation } from 'react-i18next';
import { fetchUserData, fetchPersonnelData } from '../../services/apiService';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from '../../api/axios';
function Profil() {
  const { t } = useTranslation();
  const { userId, userPassword } = useAuth();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({}); 
  const [isLoading, setIsLoading] = useState(false);
 

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible); 
  };

  const handleShowModal = () => {
    setFormData({
      nom_complet: personnel?.nom_complet || '',
      CIN: personnel?.CIN || '',
      ville: personnel?.ville || '',
      adresse: personnel?.adresse || '',
      responsabilite: personnel?.responsabilite || '',
      telephone: personnel?.telephone || '',
      email: user?.email || '',
    });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`/personnel/${user?.personnel_id}`, formData);
  
      console.log('Les modifications ont été sauvegardées avec succès.');
  
      handleCloseModal();
      setIsLoading(true);


    } catch (error) {
      console.error('Une erreur est survenue lors de la sauvegarde des modifications.');
      console.error("Erreur de sauvegarde:", error);
    }
  };

  const { data: user } = useQuery(['user', userId], () => fetchUserData(userId), { enabled: !!userId });
  const { data: personnel } = useQuery(['personnel', user?.personnel_id], () => fetchPersonnelData(user?.personnel_id), { enabled: !!user?.personnel_id });

  return (
    <div className="breadcrumbbar mb-5">
      <div className="row">
        <div className="col-lg-12 m-b-50">
          <div className="card m-b-50">
            <div className="card-body">
            <div className="row align-items-center text-lg-start">
              <div className="col-12 col-lg-6 mb-3 mb-lg-0 d-flex justify-content-center justify-content-lg-start"> 
                <div style={{ width: "100px", height: "100px", overflow: "hidden", borderRadius: "8px" }}> 
                  <img 
                    src={`https://admin.mansoft.ma/${personnel?.photo}`} 
                    className="img-fluid"
                    alt="user"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                <button type="button" className="btn btn-success-rgba" onClick={handleShowModal}>
                  <i className="feather icon-edit mr-2"></i>{t('Modifier Profil')}
                </button>
              </div>
            </div>

            <div className="button-list mt-4 mb-2">
                <div className="card-header">
                  <h5 className="card-title">{t('Mes Informations')}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Nom Complet')} </label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.nom_complet || ''}
                    
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('CIN')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.CIN || ''}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Ville')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.ville || ''}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Adresse')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.adresse || ''}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Responsabilité')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.responsabilite || ''}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Password')}</label>
                  <div className="input-group"> 
                    <input
                      type={isPasswordVisible ? "text" : "password"} 
                      className="form-control"
                      defaultValue={userPassword} 
                    
                    />
                    <span className="input-group-text" onClick={togglePasswordVisibility}> 
                      {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="button-list mt-4 mb-1">
                <div className="card-header">
                  <h5 className="card-title">{t('Information de contact')}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Téléphone')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={personnel?.telephone || ''}
                    
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center mb-3">
                  <label className="me-2 col-md-4">{t('Email')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={user?.email || ''}
                  />
                </div>
              </div>          
                </div>
          </div>  
        </div>
      </div>

      {/* Modal for editing profile */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Modifier Profil')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNomComplet">
              <Form.Label>{t('Nom Complet')}</Form.Label>
              <Form.Control
                type="text"
                name="nom_complet"
                value={formData.nom_complet}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCIN">
              <Form.Label>{t('CIN')}</Form.Label>
              <Form.Control
                type="text"
                name="CIN"
                value={formData.CIN}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formVille">
              <Form.Label>{t('Ville')}</Form.Label>
              <Form.Control
                type="text"
                name="ville"
                value={formData.ville}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formAdresse">
              <Form.Label>{t('Adresse')}</Form.Label>
              <Form.Control
                type="text"
                name="adresse"
                value={formData.adresse}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formResponsabilite">
              <Form.Label>{t('Responsabilité')}</Form.Label>
              <Form.Control
                type="text"
                name="responsabilite"
                value={formData.responsabilite}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formTelephone">
              <Form.Label>{t('Téléphone')}</Form.Label>
              <Form.Control
                type="text"
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('Email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('Annuler')}
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            {t('Sauvegarder')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Profil;
