import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link, useNavigate, useParams } from "react-router-dom";

function ModifierTypeCharge() {
  const { id } = useParams(); 
  const [typeCharge, setTypeCharge] = useState(""); 
  const [parentId, setParentId] = useState(""); 
  const [allCharges, setAllCharges] = useState([]); 
  const navigate = useNavigate(); 


  useEffect(() => {
    const fetchChargeData = async () => {
      try {
        const response = await axios.get(`/type-charges/${id}`);
        setTypeCharge(response.data.type_charge); 
console.log("df",response.data.type_charge)      
  setParentId(response.data.parent_id || "");
      } catch (error) {
        console.error("Erreur lors de la récupération du type de charge :", error);
      }

      try {
        const response = await axios.get("/type-charges"); 
        setAllCharges(response.data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des charges :", error);
      }
    };

    fetchChargeData(); 
  }, [id]);

  const onInputChange = (e) => {
    setTypeCharge(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault(); 

    try {
      const response = await axios.put(`/type-charges/${id}`, {
        type_charge: typeCharge,
        parent_id: parentId === "" ? null : parentId, 
      });

      if (response.status === 200) { 
        navigate("/types-charges");
      }
    } catch (error) {
      console.error("Erreur lors de la modification du type de charge :", error);
    }
  };

  return (
    <>
     
          <div className="breadcrumbbar">
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                    <h5 className="card-title">Modifier le type de charge</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={onSubmit}>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Type de charge</label>
                          <input
                            type="text"
                            className="form-control"
                            value={typeCharge}
                            onChange={onInputChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Charge parent</label>
                          <select
                            className="form-select"
                            value={parentId}
                            onChange={(e) => setParentId(e.target.value)}
                          >
                            <option value="">Aucun</option> 
                            {allCharges.map((charge) => (
                              <option key={charge.id} value={charge.id}>
                                {charge.type_charge}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Link className="btn btn-primary-rgba my-1" to="/types-charges">
                          Annuler
                        </Link>
                        <button className="btn btn-primary-rgba my-1" type="submit">
                          <i className="feather icon-save mr-2"></i>Modifier
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </>
  );
}

export default ModifierTypeCharge;
