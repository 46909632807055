import React, { useState,useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate,Link, useParams } from 'react-router-dom';


function ModifierFournisseur() {

    const navigate = useNavigate();
    const { id } = useParams(); 
    const [error, setError]=useState('');
    const [telephone, setTelephone] = useState("");

    const [FournisseurData, setFournisseurData] = useState({
        nom_fournisseur: "",
        email: "",
        telephone: "",
        fax: "",
        societe: ""
    });
    useEffect(() => {
    const getFournisseurData = async () => {
        try {
            const response = await axios.get(`/fournisseurs/${id}`);
            setFournisseurData(response.data);
            console.log(response.data )
        } catch (error) {
            console.error("Erreur lors de la récupération des données du fournisseur:", error);
        }
    }
    getFournisseurData();
}, [id]);

    
 


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFournisseurData({
            ...FournisseurData,
            [name]: value
        });
        setTelephone(value);
    
        const phonePattern = /^[0-9\s()+-]*$/; 
        if (!phonePattern.test(value)) {
          setError('Veuillez entrer un numéro de téléphone valide.');
        } else {
          setError('');
        } 
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`/fournisseurs/${id}`, FournisseurData);
            if (response.status === 200) {
                Swal.fire("Success", "Fournisseur modifié avec succès", "success");
                navigate("/fournisseurs");
            }
        } catch (error) {
            console.error("Error updating supplier:", error);
            Swal.fire("Error", "Une erreur s'est produite lors de la modification du fournisseur", "error");
        }
    };


    return (
        <>
		<div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Modifier fournisseur</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleFormSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-4">Nom de fournisseur</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="nom_fournisseur"
                                                value={FournisseurData.nom_fournisseur}
                                                onChange={handleInputChange}
                                           
                                            />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Email</label>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    value={FournisseurData.email}
                                                    onChange={handleInputChange}
                                                />
                                            
                                            </div>
                                        </div>
                                   
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Téléphone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="telephone"
                                                    value={FournisseurData.telephone}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Fax</label>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    name="fax"
                                                    value={FournisseurData.fax}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            {error && <span className="text-danger mt-2 d-block">{error}</span>}

                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                               <label className="me-2 col-md-4" >Societe</label>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    name="societe"
                                                    value={FournisseurData.societe}
                                                    onChange={handleInputChange}
                                                />
                                        
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/fournisseurs">Annuler</Link>
                                            <button  className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
        </div>
       </>

  );
}

export default ModifierFournisseur;
