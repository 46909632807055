import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [userName, setUserName] = useState(localStorage.getItem('userName') || "");
  const [userPassword, setUserPassword] = useState(localStorage.getItem('userPassword') || ""); 
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }

    if (userName) {
      localStorage.setItem('userName', userName);
    } else {
      localStorage.removeItem('userName');
    }
    if (userPassword) {
      localStorage.setItem('userPassword', userPassword); 
    } else {
      localStorage.removeItem('userPassword');
    }
    
    localStorage.setItem('language', language);
  }, [userId, userName, userPassword, language]);

  const logout = () => {
    setUserId(null);
    setUserName("");
    setUserPassword("");
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userPassword');
  };
  return (
    <AuthContext.Provider value={{ userId, setUserId, userName, setUserName, userPassword, setUserPassword ,language, setLanguage,logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
