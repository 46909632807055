import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import axios from '../../../api/axios';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const Taches = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [personnels, setPersonnels] = useState([]);
  const [personnelId, setPersonnelId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchPersonnels();
  }, []);

  useEffect(() => {
    if (personnelId) {
      fetchTasks(personnelId);
    }
  }, [personnelId]);

  const fetchTasks = async (id) => {
    try {
      const response = await axios.get(`/taches?personnel_id=${id}`);
      const tasks = response.data;

      const calendarEvents = tasks.map(task => {
        const start = new Date(task.created_at); 
        const [hours, minutes] = parseDuration(task.duree);
        const end = new Date(start);
        end.setHours(start.getHours() + hours);
        end.setMinutes(start.getMinutes() + minutes);

        return {
          id: task.id,
          title: task.titre,
          start: start.toISOString(),
          end: end.toISOString(),
          duree: task.duree 
        };
      });

      setEvents(calendarEvents);
    } catch (error) {
      console.error('Erreur lors de la récupération des tâches:', error);
    }
  };

  const parseDuration = (duration) => {
    const hoursMatch = duration.match(/(\d+)h/);
    const minutesMatch = duration.match(/(\d+)m/);

    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    return [hours, minutes];
  };

  const fetchPersonnels = async () => {
    try {
      const response = await axios.get('/personnel');
      setPersonnels(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des personnes:', error);
    }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedTask(clickInfo.event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTask(null);
  };

  return (
    <div className="agenda-container mb-5">
     <div className="row mb-3">
        <div className="col-md-5 d-flex align-items-center mb-2 mb-md-0">
          <label className="d-flex me-4">{t('Personnel ')}
            <span className="required" aria-required="true"> &nbsp; * </span>&nbsp;:
          </label>
        </div>
        <div className="col-12 col-md-8">
          <select
            required
            name="departmentid"
            className="form-control"
            aria-required="true"
            value={personnelId}
            onChange={(e) => setPersonnelId(e.target.value)}
          >
            <option value="">---</option>
            {personnels.map(personnel => (
              <option key={personnel.id} value={personnel.id}>
                {personnel.nom_complet}
              </option>
            ))}
          </select>
        </div>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={frLocale}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        events={events}
        selectable={true}
        editable={true}
        eventClick={handleEventClick} 
      />

      {selectedTask && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedTask.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Date de début:</strong> {new Date(selectedTask.start).toLocaleString()}</p>
            <p><strong>Date de fin:</strong> {new Date(selectedTask.end).toLocaleString()}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Taches;
