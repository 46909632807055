import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useOfflineOperations } from '../../layouts/useOfflineOperations'; 
import 'bootstrap/dist/css/bootstrap.min.css';

function ModifierMagasin() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [depot, setDepot] = useState(false); 
    const [magasin, setMagasin] = useState({
        nom_magasin: "",
        adresse_magasin: "",
        telephone: "",
        ville: "",
        depot: ""
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { saveOperationOffline } = useOfflineOperations(); 

    useEffect(() => {
        const fetchMagasin = async () => {
            try {
                const response = await axios.get(`/magasins/${id}`);
                setMagasin(response.data);
                setDepot(response.data.depot === 1);
            } catch (error) {
                console.error("Erreur lors de la récupération des données du magasin:", error);
                setError("Erreur lors de la récupération des données.");
            } finally {
                setLoading(false);
            }
        };

        fetchMagasin();
    }, [id]);

    const handleRadioChange = (event) => {
        setDepot(event.target.checked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMagasin({ ...magasin, [name]: value });

        const phonePattern = /^[0-9\s()+-]*$/; 
        if (!phonePattern.test(value)) {
          setError('Veuillez entrer un numéro de téléphone valide.');
        } else {
          setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedMagasin = { ...magasin, depot: depot ? 1 : 0 };

        try {
            if (navigator.onLine) {
                await axios.put(`/magasins/${id}`, updatedMagasin);
                Swal.fire({
                    title: "Succès",
                    text: "Le magasin a été modifié avec succès",
                    icon: "success",
                });
                navigate('/magasins');
            } else {
                saveOperationOffline({
                    type: 'UPDATE',
                    endpoint: `/magasins/${id}`,
                    data: updatedMagasin,
                });
                Swal.fire({
                    icon: "success",
                    title: "Succès",
                    text: "Magasin sauvegardé localement ! Il sera envoyé lorsque vous serez en ligne.",
                });
            }
        } catch (error) {
            console.error("Erreur lors de la modification du magasin:", error);
            Swal.fire({
                title: "Erreur",
                text: "Une erreur s'est produite lors de la modification du magasin",
                icon: "error",
            });
        }
    };


    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>{error}</p>;

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">Modifier magasin</h5>
                                    </div>
                                    <div className="card-body">
                                        <form className="needs-validation" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Nom de Magasin</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="nom_magasin"
                                                        value={magasin.nom_magasin}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Adresse</label>
                                                    <input
                                                        className="form-control"
                                                        name="adresse_magasin"
                                                        value={magasin.adresse_magasin}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Téléphone</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="telephone"
                                                        value={magasin.telephone}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Ville</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="ville"
                                                        value={magasin.ville}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                             {error && <span className="text-danger mt-2 d-block">{error}</span>}

                                             <div className="form-row">
                                               <div className="form-check col-6 d-flex d-md-block flex-row-reverse flex-md-row align-items-center">
                                                    <label className="me-4 col-md-4" htmlFor="depotCheckbox">Dépôt</label>
                                                    
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="depotCheckbox"
                                                    checked={depot}
                                                    onChange={handleRadioChange}
                                                />
                                            </div>
                                            </div>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-light me-0" to="/magasins">Annuler</Link>
                                                <button className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden w-90">Modifier Magasin</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
           </div>
               
        </>
    );
}

export default ModifierMagasin;
