import React, { useState, useEffect,useRef} from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import ArticleTable from '../../layouts/TableArticle';


function ModifierCommandeFr() {
   
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [fournisseurs, setFournisseurs] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedFr, setSelectedFr] = useState("");
    const [date_commande, setDateCommande] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState("");
    const [editMode, setEditMode] = useState([]);
    const [tempArticleCommandes, setTempArticleCommandes] = useState([]);
    const [qte, setQte] = useState("");
    const [price, setPrice] = useState("");    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
   

    useEffect(() => {
      const fetchInitialData = async () => {
          try {
              const [categoriesResponse, articlesResponse, fournisseursResponse] = await Promise.all([
                  axios.get("/categories"),
                  axios.get("/articles"),
                  axios.get("/fournisseurs"),
              ]);
              setCategories(categoriesResponse.data.categories);
              setArticles(articlesResponse.data);
              setFournisseurs(fournisseursResponse.data);
          } catch (error) {
              console.error("Error fetching initial data:", error);
          }
      };
      fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchCommandeFrDetails = async () => {
        try {
            const [commandeFrResponse, ligneCommandeFrResponse] = await Promise.all([
                axios.get(`/commandeFr/${id}`),
                axios.get(`/ligne-commande-fors?id_commande_fr=${id}`)
            ]);

            const { id_fournisseur, date_commande } = commandeFrResponse.data;
            setSelectedFr(id_fournisseur);
            setDateCommande(date_commande);

            const lignesCommandeFr = ligneCommandeFrResponse.data.map(ligne => ({
                id: ligne.id,
                selectedArticle: ligne.id_article,
                quantity: ligne.quantite,
                price: ligne.pu_vente,
                nom_article: articles.find((article) => article.id === ligne.id_article)?.nom_article || "Article inconnu",
            }));
            setEditMode(lignesCommandeFr.map(() => false));
            setTempArticleCommandes(lignesCommandeFr);
        } catch (error) {
            console.error('Error fetching commande fournisseur details:', error);
        }
    };

    if (articles.length > 0) {
        fetchCommandeFrDetails();
    }
}, [id, articles]);
    
const onInputChange = (e) => {
  const { name, value } = e.target;
  switch (name) {
      case "selectedFr":
          setSelectedFr(value);
          break;
      case "date_commande":
          setDateCommande(value);
          break;
      case "qte":
          setQte(value);
          break;
      case "price":
          setPrice(value);
          break;
      default:
          break;
  }
};

    
    const handleDropdownClick = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleSelectArticle = (id, nom_article) => {
      setSelectedArticle(id);
      setSearchTerm(nom_article);
      setDropdownOpen(false);
    }; 
    const toggleEditMode = (index) => {
      setEditMode((prev) => prev.map((val, i) => (i === index ? !val : val)));
    };
    const saveChanges = () => {
      setEditMode((prev) => prev.map(() => false));
    };

      const handleTempRowChange = (index, field, value) => {
        const updatedRows = [...tempArticleCommandes];
    
        if (index >= 0 && index < updatedRows.length) {
          updatedRows[index][field] = value;
    
          if (field === "selectedArticle") {
            const article = articles.find(
              (article) => article.id === parseInt(value, 10)
            );
            updatedRows[index].nom_article = article ? article.nom_article : "Article inconnu";
          }
    
          setTempArticleCommandes(updatedRows);
        }
      };
    
      const handleAddRow = () => {
        const articleObj = articles.find(
          (article) => article.id === parseInt(selectedArticle, 10)
        );
    
        if (!articleObj) {
          console.error("Article introuvable pour l'identifiant :", selectedArticle);
          return;
        }
    
        const newCommande = {
          selectedArticle: articleObj.id,
          nom_article: articleObj.nom_article,
          quantity: parseFloat(qte),
          price: parseFloat(price),
        };
    
        setTempArticleCommandes([...tempArticleCommandes, newCommande]);
        setEditMode([...editMode, false]);
        setSelectedArticle("");
        setPrice("");
        setQte("");
      };
    
   
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.put(`/commandeFr/${id}`, {
            id_fournisseur: selectedFr,
                date_commande: date_commande
          });
    
          await Promise.all(
            tempArticleCommandes.map(async (row) => {
              if (row.id) {
                await axios.put(`/ligne-commande-fors/${row.id}`, {
                    id_commande_fr: id,
                    id_article: row.selectedArticle,
                    quantite: row.quantity,
                    pu_vente: row.price
                });
              } else {
                await axios.post("/ligne-commande-fors", {
                    id_commande_fr: id,
                    id_article: row.selectedArticle,
                    quantite: row.quantity,
                    pu_vente: row.price
                });
              }
            })
          );
    
          handleSuccess();
        } catch (error) {
          handleError(error);
        }
      };
    
    
   
    
   
    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Commande modifiée avec succès!',
        });
        navigate('/commande-fournisseur/commandes');
    };

    const handleError = (error) => {
        console.error('Erreur lors de la modification de la commande :', error);
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Échec de la modification de la commande. Veuillez réessayer plus tard.',
        });
    };
   
    
    const handleRemoveRow = async (index) => {
        try {
          if (index < 0 || index >= tempArticleCommandes.length) {
            throw new Error("Index hors limite");
          }
    
          const row = tempArticleCommandes[index];
    
          if (!row.selectedArticle || !row.quantity || !row.price) {
            const updatedRows = [...tempArticleCommandes];
            updatedRows.splice(index, 1);
            setTempArticleCommandes(updatedRows); 
          } else {
            const confirmDelete = await Swal.fire({
              icon: "warning",
              title: "Confirmation",
              text: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
            });
    
            if (confirmDelete.isConfirmed) {
              if (row.id) {
                await axios.delete(`/ligne-commande-fors/${row.id}`);
              }
    
              const updatedRows = [...tempArticleCommandes];
              updatedRows.splice(index, 1);
              setTempArticleCommandes(updatedRows); 
            }
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la ligne:", error);
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Échec de la suppression de la ligne. Veuillez réessayer plus tard.",
          });
        }
      };
    
    
    
    return (
        <>
		
          <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Modifier commande fournisseur</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                         <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-3">Fournisseur</label>
                                            <select
                                                className=" form-control"
                                                name="selectedFr"
                                                value={selectedFr}
                                                onChange={onInputChange}
                                            >
                                                <option key="" value=""></option>
                                                {fournisseurs.map(fournisseur => (
                                                    <option key={fournisseur.id} value={fournisseur.id}>{fournisseur.nom_fournisseur} {fournisseur.prenom_fournisseur}</option>
                                                ))}
                                            </select>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-3">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="date_commande"
                                                    value={date_commande}
                                                    onChange={onInputChange}
                                                />
                                            
                                            </div>
                                           
                                        </div>
                                        <div className="form-row">
                                          <React.Fragment >
                                            
                                          <div className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-4 col-md-4">Article</label>
                                                <div className="custom-select-wrapper" ref={dropdownRef}>
                                                <div className="form-control" onClick={handleDropdownClick}>
                                                    {searchTerm}
                                                </div>
                                                {dropdownOpen && (
                                                    <div className="autocom-box">
                                                    <input
                                                        type="search"
                                                        aria-label="Search"
                                                        className="search-box form-control"
                                                        placeholder="Rechercher..."
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                        onClick={() => setDropdownOpen(true)}
                                                    />
                                                        <ul>
                                                    {categories
                                                    .filter(category => 
                                                        articles.some(article => 
                                                        article.categorie_id === category.id && 
                                                        article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                        )
                                                    )
                                                    .map(category => (
                                                        <React.Fragment key={category.id}>
                                                        <li className="category-label text-bold">{category.nom_categorie}</li>
                                                        {articles
                                                            .filter(article => 
                                                            article.categorie_id === category.id && 
                                                            article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                            )
                                                            .map(filteredArticle => (
                                                            <li
                                                                key={filteredArticle.id}
                                                                className="article-item"
                                                                onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                                            >
                                                                {filteredArticle.nom_article}
                                                            </li>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>

                                                </div>
                                                )}
                                                </div>
                                            </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-4 col-md-4">Quantité</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="qte"
                                                    value={qte}
                                                    onChange={onInputChange}
                                                    min={0}
                                                />
                                            </div>
                                            <div  className="col-md-4 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-4">Prix</label>
                                                <input
                                                  type="text"
                                                  className="form-control form-control-solid"
                                                  name="price"
                                                  value={price}
                                                  onChange={onInputChange}
                                              />
                                             </div>
                                            </React.Fragment>
                                             <div className="col-md-12 mb-3">
                                                <div className="row g-3">
                                                    <div className="col-md-4 offset-md-8 text-end ">
                                                        <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                                        </div>
                                                </div>
                                            </div>
                                          </div>
                                        
                                         <ArticleTable 
                                            tempArticleCommandes={tempArticleCommandes} 
                                            editMode={editMode} 
                                            handleTempRowChange={handleTempRowChange} 
                                            saveChanges={saveChanges} 
                                            handleRemoveRow={handleRemoveRow} 
                                            toggleEditMode={toggleEditMode}

                                          />

                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/commande-fournisseur/commandes">Annuler</Link>
                                            <button  className="btn btn-primary-rgba my-1"><i className="feather icon-save mr-2"></i>Modifier</button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
          </div>

</>

    );
}

export default ModifierCommandeFr;
