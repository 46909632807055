import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../../api/axios';
import logo from '../../assets/images/Logo_mansoft.png';
import auth from '../../assets/images/auth-screens.png';
import '../../assets/css/login.css';
import { useAuth } from '../../layouts/AuthContext';
import dropdownMenu from '../../assets/js/dropdownMenu';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); 
  const navigate = useNavigate();
  const { setUserName, setUserId, setUserPassword, setLanguage } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log("Submitting login ...");
  
    try {

    // await axios.post('/sanctum/csrf-cookie'); 
  const loginResponse = await axios.post('/login', { name, password });
  
      const userId = loginResponse.data?.user?.id;
  
      if (userId) {
        setUserId(userId);
        console.log("User ID:", userId);
        setUserName(name);
        setUserPassword(password);
        console.log("Login successful!");
        setName("");
        setPassword("");
        setError("");
  
        const userResponse = await axios.get(`/users/${userId}`);
        const userData = userResponse.data;
        const personnelId = userData?.personnel_id;
  
        if (personnelId) {
          const personnelResponse = await axios.get(`/personnel/${personnelId}`);
          const personnelData = personnelResponse.data;
           const userPhoto=`https://admin.mansoft.ma/${personnelData.photo}`;
          localStorage.setItem('userPhoto',userPhoto );
          navigate("/home", { state: { userPhoto, userName: name } });
        } else {
          navigate("/home", { state: { userName: name } });
        }
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError(t('login.error'));
    }
  };
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    dropdownMenu('#languageLink', '#languageDropdownMenu');

    return () => {
      $(document).off('click', '#languageLink');
      $(document).off('click', '#languageDropdownMenu');
    };
  }, []);

  return (
    <div className="login-container">
      <div className="form-section pT-3">
        <div className="form-head">
          <a href="/" className="logo">
            <img
              src={logo}
              alt="logo"
              style={{ width: '100%', maxWidth: '270px', padding: '13px' }}
            />
          </a>
        </div>
        <form onSubmit={handleLogin} className="form w-100">
          <div className="form-group mb-3">
            <input
              type="text"
              placeholder={t('login.username')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control bg-transparent"
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="password"
              placeholder={t('login.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control bg-transparent"
            />
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="d-grid mb-3">
            <button type="submit" className="btn btn-primary">
              <span className="text-white fw-semibold fs-6">{t('login.loginButton')}</span>
            </button>
          </div>
        </form>
        <div className="col-12 d-flex">
          <div className="col-6 mb-3">
            <div className="languagebar">
              <div className="dropdown">
                <Link
                  id="languageLink"
                  to="#"
                  role="button"
                  style={{ textDecoration: 'none' }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="live-icon">{t('login.language')}</span>
                  <span className="feather icon-chevron-down live-icon"></span>
                </Link>
                <div
                  id="languageDropdownMenu"
                  className="dropdown-menu dropdown-menu-left"
                  aria-labelledby="languageLink"
                >
                  <Link className="dropdown-item" to="#" onClick={() => changeLanguage('en')}>
                    {t('login.english')}
                  </Link>
                  <Link className="dropdown-item" to="#" onClick={() => changeLanguage('fr')}>
                    {t('login.french')}
                  </Link>
                  <Link className="dropdown-item" to="#" onClick={() => changeLanguage('ar')}>
                    {t('login.arabic')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 text-end">
            <Link to="https://www.njt-group.com/en/index.html" target="_blank" style={{ textDecoration: 'none' }}>@NJT GROUP</Link>
          </div>
        </div>
      </div>
      <div className="background-section">
        <div className="content-wrapper">
          <img src={auth} alt="auth-screens" style={{ width: '70%' }} />
          <div className="description-text">
            {t('auth.description')}
          </div>
          <span className="white-text">Brian Tracy</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
