import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import axios from '../../../api/axios';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const fetchClients = async () => {
  const response = await axios.get('/clients');
  return response.data;
};

const fetchFournisseurs = async () => {
  const response = await axios.get('/fournisseurs');
  return response.data;
};

const EvolutionCompte = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const chartRef = useRef(null);
  const [data, setData] = useState({ labels: [], datasets: [] });

  const { data: clients, error: clientsError, isLoading: clientsLoading } = useQuery('clients', fetchClients);
  const { data: fournisseurs, error: fournisseursError, isLoading: fournisseursLoading } = useQuery('fournisseurs', fetchFournisseurs);

  useEffect(() => {
    if (clients && fournisseurs) {
      updateChartData(clients, fournisseurs);
    }
  }, [clients, fournisseurs]);

  useEffect(() => {
    if (clients && fournisseurs) {
      const filtered = filterData(clients, fournisseurs, startDate, endDate);
      setFilteredData(filtered);
    }
  }, [startDate, endDate, clients, fournisseurs]);

  const updateChartData = (clients, fournisseurs) => {
    const extractMonthYear = (date) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}`;
    };

    const aggregateDataByMonth = (data) => {
      return data.reduce((acc, item) => {
        const monthYear = extractMonthYear(item.created_at);
        if (!acc[monthYear]) {
          acc[monthYear] = 0;
        }
        acc[monthYear]++;
        return acc;
      }, {});
    };

    const clientsDataByMonth = aggregateDataByMonth(clients);
    const fournisseursDataByMonth = aggregateDataByMonth(fournisseurs);

    const months = [...new Set([...Object.keys(clientsDataByMonth), ...Object.keys(fournisseursDataByMonth)])];
    const clientCounts = months.map(month => clientsDataByMonth[month] || 0);
    const fournisseurCounts = months.map(month => fournisseursDataByMonth[month] || 0);

    setData({
      labels: months,
      datasets: [
        {
          label: 'Clients',
          data: clientCounts,
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: false,
        },
        {
          label: 'Fournisseurs',
          data: fournisseurCounts,
          borderColor: '#FF7F50',
          backgroundColor: 'rgba(255, 127, 80, 0.2)',
          fill: false,
        },
      ],
    });
  };

  const aggregateFilteredData = (clients, fournisseurs, months) => {
    const data = months.map(monthYear => {
      const clientCount = clients.filter(client => extractMonthYear(client.created_at) === monthYear).length;
      const fournisseurCount = fournisseurs.filter(fournisseur => extractMonthYear(fournisseur.created_at) === monthYear).length;
      return { mois: monthYear, clientCount, fournisseurCount };
    });
    return data;
  };

  const extractMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}`;
  };

  const filterData = (clients, fournisseurs, startDate, endDate) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filteredClients = clients.filter(client => {
      const clientDate = new Date(client.created_at);
      return (!start || clientDate >= start) && (!end || clientDate <= end);
    });

    const filteredFournisseurs = fournisseurs.filter(fournisseur => {
      const fournisseurDate = new Date(fournisseur.created_at);
      return (!start || fournisseurDate >= start) && (!end || fournisseurDate <= end);
    });

    const months = [...new Set([...filteredClients.map(client => extractMonthYear(client.created_at)), ...filteredFournisseurs.map(fournisseur => extractMonthYear(fournisseur.created_at))])];
    return aggregateFilteredData(filteredClients, filteredFournisseurs, months);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mois',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Nombre',
        },
      },
    },
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'EvolutionCompte');
    XLSX.writeFile(workbook, 'EvolutionCompte.xlsx');
  };

  if (clientsLoading || fournisseursLoading) return <p>Loading...</p>;
  if (clientsError || fournisseursError) return <p>Error loading data</p>;

  return (
    <div className="container my-4">
      <div className="row mb-4 align-items-center">
          <div className="col-md-5 col-sm-12 mb-4">
            <label className="form-label">{t('Date de début')} :</label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="form-control"
            />
          </div>
          <div className="col-md-5 col-sm-12 mb-4">
            <label className="form-label">{t('Date de fin')} :</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="form-control"
            />
          </div>
          <div className="col-md-2 col-sm-12 d-flex justify-content-end">
            <Link className="btn btn-primary" onClick={exportToExcel}>
              <i className="fa fa-cloud-download me-2"></i>{t('Exporter')}
            </Link>
          </div>
        </div>


      <div className="mb-4">
        <h5>{t('Évolution des Clients et Fournisseurs')}</h5>
        <div className="chart-container" style={{ width: '80%', height: '300px' }}>
          <Line data={data} options={options} ref={chartRef} />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>{t('Mois')}</th>
              <th>{t('Nombre de Clients')}</th>
              <th>{t('Nombre de Fournisseurs')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.mois}</td>
                <td>{item.clientCount}</td>
                <td>{item.fournisseurCount}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan="3">{t('Aucune donnée disponible pour la période sélectionnée')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EvolutionCompte;
