import React, { useState, useEffect, useMemo } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import SortingTable from "../../layouts/SortingTable";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from "react-i18next";
import { useOfflineOperations } from '../../layouts/useOfflineOperations'; 
import Swal from "sweetalert2";

function ListeVersement() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [versementIdToDelete, setVersementIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);  
  const [searchTerm, setSearchTerm] = useState('');
  const { saveOperationOffline } = useOfflineOperations(); 


  const { data: versements = [], refetch } = useQuery(
    ['versements'],
    async () => {
      const [versementsResponse] = await Promise.all([
        axios.get("/versements"),
      ]);

      return versementsResponse.data.map(versement => {
        return {
          ...versement,
        };
      });
    }
  );

  const deleteVersementMutation = useMutation(
    async (versementId) => {
      if(navigator.onLine){
      await axios.delete(`/versements/${versementId}`);
    }else {
        saveOperationOffline({
          type: 'DELETE',
          endpoint: `/versements/${versementId}`, 
        });
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Versement sauvegardé localement ! Il sera envoyé lorsque vous serez en ligne.",
        });
    }
    },
    {
      onSuccess: () => {
        refetch(); 
        closeConfirmBox();
      },
      onError: (error) => {
        console.error("Erreur lors de la suppression du versement :", error);
      },
    }
  );

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const filteredVersements = useMemo(() => {
    return versements.filter((versement) => {
      const lowercaseVersement = Object.fromEntries(
        Object.entries(versement).map(([key, value]) => [
          key,
          typeof value === "string" ? value.toLowerCase() : (value && value.toString().toLowerCase()) || '',
        ])
      );

      return Object.values(lowercaseVersement).some((value) =>
        typeof value === "string" && value.includes(searchTerm.toLowerCase())
      );
    });
  }, [versements, searchTerm]);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredVersements.slice(offset, offset + itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const openDelete = (versementId) => {
    setVersementIdToDelete(versementId);
    setShowConfirmBox(true);
  };

  const closeConfirmBox = () => {
    setShowConfirmBox(false);
    setVersementIdToDelete(null);
  };

  const handleDeleteVersement = () => {
    if (versementIdToDelete) {
      deleteVersementMutation.mutate(versementIdToDelete);
    }
  };

  const columns = useMemo(() => [
    { key: 'personne', label: t('personne'), sortable: true },
    { key: 'montant', label: t('Montant'), sortable: true },
    { key: 'date', label: t('Date'), sortable: true },
    { key: 'mode', label: t('Mode'), sortable: true },
    { key: 'actions', label: t('Actions'), sortable: false, className: 'text-center' }
  ], [t]);

  return (
    <>
      <div className="breadcrumbbar">
        <div className="row align-items-center">
          <div className="col-md-8 col-lg-8">
            <h4 className="page-title">{t('Liste des versements')}</h4>
            <div className="breadcrumb-list mb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <i className="fa fa-home img-fluid" style={{ fontSize: "15px", width: "20px", marginRight: "3px" }}></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/home" style={{ textDecoration: 'none' }}>{t('Versements')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">{t('List')} {t('Versements')}</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 m-b-50">
            <div className="card m-b-50">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                    <div className="col-5 col-md-5 d-flex justify-content-center">
                      <Search
                        placeholder={t('Search')}
                        onSearchChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                    <div className="widgetbar">
                      <Link to="/ajouter-versement" className="btn btn-primary-rgba">
                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <SortingTable
                    data={currentItems.map((versement) => ({
                      id: versement.id,
                      personne: versement.personne,
                      montant: Number(versement.montant).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                      date: versement.date,
                      mode: versement.mode,
                      actions: (
                        <div className="text-center">
                          <Link
                            to={`/modifier-versement/${versement.id}`}
                            className="btn btn-success-rgba btn-round mb-1"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                          </Link>
                          <Link
                            onClick={() => openDelete(versement.id)}
                            className="btn btn-danger-rgba btn-round"
                            style={{ marginRight: '10px' }}
                          >
                            <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                          </Link>
                        </div>
                      ),
                    }))}
                    columns={columns}
                  />
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <select
                        className="form-control"
                        id="items-per-page"
                        onChange={handleItemsPerPageChange}
                        value={itemsPerPage}
                      >
                        <option value={2}>2</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                    <Pagination
                      items={filteredVersements}
                      itemsPerPage={itemsPerPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmBox
        open={showConfirmBox}
        closeDialog={closeConfirmBox}
        title={t("Supprimer Versement")}
        deleteFunction={handleDeleteVersement}
      />
    </>
  );
}

export default ListeVersement;
