import React, { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import { TailSpin } from 'react-loader-spinner';

const ClientParVille = () => {
  const [clientsData, setClientsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/clients')
      .then(response => {
        const data = response.data;
        const clientsByCity = data.reduce((acc, client) => {
          acc[client.ville] = (acc[client.ville] || 0) + 1;
          return acc;
        }, {});
        const formattedData = Object.entries(clientsByCity).map(([ville, count]) => ({
          ville,
          count
        }));
        setClientsData(formattedData);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the clients data!", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <TailSpin color="#00BFFF" height={80} width={80} />;
  }

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Ville</th>
          <th>Nombre de Clients</th>
        </tr>
      </thead>
      <tbody>
        {clientsData.map(({ ville, count }) => (
          <tr key={ville}>
            <td>{ville}</td>
            <td>{count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClientParVille;
