import React from 'react';


function ArticleTable({ tempArticleCommandes, editMode, handleTempRowChange, saveChanges, handleRemoveRow,toggleEditMode }) {

    
    return (
        <table className="display table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Article</th>
                    <th>Quantité</th>
                    <th>P.U</th>
                    <th>Total</th>
                    <th className="d-md-flex justify-content-md-end">Action</th>
                </tr>
            </thead>
            <tbody>
                {tempArticleCommandes.map((commande, index) => {
                    return (
                        <tr key={index}>
                            {editMode[index] ? (
                                <>
                                    <td>
                                        <input
                                            className="form-control"
                                            value={commande.nom_article}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={commande.quantity}
                                            onChange={(e) => handleTempRowChange(index, 'quantity', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={commande.price}
                                            onChange={(e) => handleTempRowChange(index, 'price', e.target.value)}
                                        />
                                    </td>
                                    <td>{commande.quantity * commande.price}</td>
                                    <td className="d-md-flex justify-content-md-end">
                                        <i
                                            className="feather icon-save btn btn-success-rgba btn-round"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => saveChanges()}
                                        ></i>
                                        <i
                                            className="feather icon-trash btn btn-danger-rgba btn-round"
                                            onClick={() => handleRemoveRow(index)}
                                        ></i>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{commande.nom_article}</td>
                                    <td>{commande.quantity}</td>
                                    <td>{commande.price}</td>
                                    <td>{commande.quantity * commande.price}</td>
                                    <td className="d-md-flex justify-content-md-end">
                                        <i
                                            className="feather icon-edit-2 btn btn-primary-rgba btn-round"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => toggleEditMode(index)}
                                        ></i>
                                        <i
                                            className="feather icon-trash btn btn-danger-rgba btn-round"
                                            onClick={() => handleRemoveRow(index)}
                                        ></i>
                                    </td>
                                </>
                            )}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default ArticleTable;
