
import React from 'react';
import {Routes, Route ,useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import '@fortawesome/fontawesome-svg-core/styles.css'; 
import MainLayout from "../src/layouts/MainLayout";

import Footer from './layouts/footer';
import { AuthProvider } from './layouts/AuthContext'; 
import RequireAuth from './layouts/RequireAuth';
import ListerArticles from './Components/Article/ListerArticle';
import AjouterArticle from './Components/Article/AjouterArticle';
import ModifierArticle from './Components/Article/ModifierArticle';
import PlusDetailArticle from './Components/Article/PlusDetailsArticle'
import AjoutCategorie from './Components/CategorieArticle/AjoutCategorie';
import ListerCategorie from './Components/CategorieArticle/ListerCategorie';
import ModifierCategorie from  './Components/CategorieArticle/ModifierCategorie';

import ListerMagasin from './Components/Magasin/listerMagasin';
import AjouterMagasin from './Components/Magasin/AjouterMagasin';
import ModifierMagasin from './Components/Magasin/ModifierMagasin';

import ListerClient from './Components/Clients/ListerClient';
import AjouterClient from './Components/Clients/AjouterClient';
import ModifierClient from './Components/Clients/ModifierClient';
import PrixParCategorie from './Components/Clients/PrixParCategorie';

import ListeFournisseur from './Components/Fourinsseurs/ListeFournisseur';
import AjouterFournisseur from './Components/Fourinsseurs/AjouterFournisseur';
import ModifierFournisseur from './Components/Fourinsseurs/ModifierFournisseur';
import CataloguePrix from './Components/Fourinsseurs/CataloguePrix';

import ListerPersonnel from './Components/Personnel/ListerPersonnel';
import AjouterPersonnel from './Components/Personnel/AjouterPersonnel';
import ModifierPersonnel from './Components/Personnel/ModifierPersonnel';

import ListerCommandeCl from './Components/CommandeClient/ListerCommandeCl';
import AjouterCommandeCl from './Components/CommandeClient/AjouterCommandeCl';
import ModifierCommandeCl from './Components/CommandeClient/ModifierCommandeCl';

import ListerBonLiv from './Components/BonLivraison/ListerBonLiv';
import AjouterBonLiv from './Components/BonLivraison/AjouterBonLiv';
import ModifierBonLiv from './Components/BonLivraison/ModifierBonLiv';

import ListerDevis from './Components/Devis/ListerDevis';
import AjouterDevis from './Components/Devis/AjouterDevis';
import ModifierDevis from './Components/Devis/ModifierDevis';

import ListerBonRetour from './Components/BonRetour/ListerBonRetour';
import AjouterBonRetour from './Components/BonRetour/AjouterBonRetour';
import ModifierBonRetour from './Components/BonRetour/ModifierBonRetour';

import ListerCommandeFr from './Components/CommandeFr/ListerCommandeFr';
import AjouterCommandeFr from './Components/CommandeFr/AjouterCommandeFr';
import ModifierCommandeFr from './Components/CommandeFr/ModifierCommandeFr';

import ListerBonEntree from './Components/BonEntree/ListerBonEntree';
import AjouterBonEntree from './Components/BonEntree/AjouterBonEntree';
import ModifierBonEntree from './Components/BonEntree/ModifierBonEntree';

import AjouterPaiementClient from './Components/PaimentClient/AjouterPaiementClient';
import ListerPaiementCl from './Components/PaimentClient/ListerPaimentCl';
import AjouterPaiementFr from './Components/PaiementFournisseur/AjouterPaiementFr';
import ListerPaiementFr from './Components/PaiementFournisseur/ListerPaiementFr';
import ModifierPaiementFr from './Components/PaiementFournisseur/ModifierPaiementFr';

import ModifierPaiementClient from './Components/PaimentClient/ModiferPaiementClient';
import Profil from './Components/Profil/Profil' ;
import AjouterCharge from './Components/Charges/AjouterCharge';
import ListerCharge from './Components/Charges/ListerCharge';
import ModifierCharge from './Components/Charges/ModifierCharge';

import CreerTypeCharge from './Components/TypeCharge/CreerTypeCharge';
import ListerTypeCharge from './Components/TypeCharge/ListerTypeCharge';
import ModifierTypeCharge from './Components/TypeCharge/ModifierTypeCharge';

import ListerInventaire from './Components/Inventaire/ListerInventaire';

import AjouterInventaire from './Components/Inventaire/AjouterInventaire';
import ModifierInventaire from './Components/Inventaire/ModifierInventaire';
import ListerFacture from './Components/Factures/ListerFacture';

import Avoirs from './Components/Factures/Avoirs';
import CreerAvoir from './Components/Factures/CreerAvoir';
import ListeUtilisateur from './Components/Utilisateurs/ListeUtilisateur';
import ModifierUtilisateur from './Components/Utilisateurs/ModiferUtilisateur';
import AjouterUtilisateur from './Components/Utilisateurs/AjouterUtilisateur';

import InfoSociete from './Components/Settings/SocieteInfo';
import Verrouiller from './Components/Settings/Verrouiller';

import AjouterPaiementPersonnel from './Components/PaiementPersonnel/AjouterPaiementPersonnel';
import ListePaiementPersonnel from './Components/PaiementPersonnel/ListePaiementPersonnel';
import ModifierPaiementPersonnel from './Components/PaiementPersonnel/ModifierPaiementPersonnel';
// routes rapports 
import CompteParType from './Components/Rapports/Compte/CompteParType';
import CAparClient from './Components/Rapports/Compte/CAparClient';
import EvolutionCompte from './Components/Rapports/Compte/EvolutionCompte';
import ArticleparFournisseur from './Components/Rapports/Catalogue/ArticleparFournisseur';
import ArticleParCategorie from './Components/Rapports/Catalogue/ArticleParCategorie';
import VenteParClient from './Components/Rapports/Catalogue/VenteParClient';
import VenteParArticle from './Components/Rapports/Catalogue/VenteParArticle';
import VenteParMois from './Components/Rapports/Ventes/VenteParMois';
import AchatParArticle from './Components/Rapports/Catalogue/AchatParArticle';
import Devis from './Components/Rapports/Ventes/DevisParMois';
import CAparCatégorie from './Components/Rapports/Compte/CAparCatégorie';
import Facture from './Components/Rapports/Achats/Facture';
import BC from './Components/Rapports/Achats/BC';
import AchatParFournisseur from './Components/Rapports/Achats/AchatParFournisseur';
import CommandeParMois from './Components/Rapports/Ventes/CommandeParVente';

import Rapport from './Components/Rapports/Rapport';
import Agenda from './Components/Settings/Agenda/Agenda';
import GererTaches from './Components/Settings/Agenda/GererTaches';
import Taches from './Components/Settings/Agenda/AgendaTaches';
import EnTeteFacture from './Components/Settings/Factures/Entete';
import EnPiedFacture from './Components/Settings/Factures/enPied';
import PersonaliserFacture from './Components/Settings/Factures/PersonaliserFacture';
import BLParMois from './Components/Rapports/Ventes/BLParMois';
import AjouterVersement from './Components/Versement/AjouterVersement';
import ListeVersement from './Components/Versement/ListeVersement';
import ModifierVersement from './Components/Versement/ModifierVersement';
import CAparVille from './Components/Rapports/Compte/CAparVille';

const App = () => {
  const location = useLocation(); 

  const shouldShowFooter = location.pathname !== "/" ;
  const isMainLayout = location.pathname !== "/";

  
  return (
    <div>
      <AuthProvider>
      {isMainLayout ? (
      <MainLayout>

            <Routes>

              <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
              <Route path="/articles" element={<RequireAuth><ListerArticles/></RequireAuth>} />
              <Route path="/ajoute_article" element={<RequireAuth><AjouterArticle/></RequireAuth>} />
              <Route path="/ListerArticles/ModifierArticle/:id" element={<RequireAuth><ModifierArticle/></RequireAuth>} />
              <Route path="/articles/categorie" element={<RequireAuth><ListerCategorie/></RequireAuth>} />
              <Route path="/articles/ajoute_categorie" element={<RequireAuth><AjoutCategorie/></RequireAuth>} />
              <Route path="/articles/modifier_categorie/:categoryId" element={<RequireAuth><ModifierCategorie/></RequireAuth>} />
              <Route path="/article/detail/:id" element={<RequireAuth><PlusDetailArticle/></RequireAuth>} />

              <Route path="/magasins" element={<RequireAuth><ListerMagasin/></RequireAuth>} />
              <Route path="/ajoute-magasin" element={<RequireAuth><AjouterMagasin/></RequireAuth>} />
              <Route path="/modifier-magasin/:id" element={<RequireAuth><ModifierMagasin/></RequireAuth>} />

              <Route path="/clients" element={<RequireAuth><ListerClient/></RequireAuth>} />
              <Route path="/ajoute_client" element={<RequireAuth><AjouterClient/></RequireAuth>} />
              <Route path="clients/modifier_client/:idClient" element={<RequireAuth><ModifierClient/></RequireAuth>} />
              <Route path="clients/prix_categorie/:clientId" element={<RequireAuth><PrixParCategorie/></RequireAuth>} />

              <Route path="/fournisseurs" element={<RequireAuth><ListeFournisseur/></RequireAuth>} />
              <Route path="/ajouter-fournisseur" element={<RequireAuth><AjouterFournisseur/></RequireAuth>} />
              <Route path="/modifier-fournisseur/:id" element={<RequireAuth><ModifierFournisseur/></RequireAuth>} />
              <Route path="/prix_categorie_fournisseur/:fourniId" element={<RequireAuth><CataloguePrix/></RequireAuth>} />

              <Route path="/personnels" element={<RequireAuth><ListerPersonnel/></RequireAuth>} />
              <Route path="/ajoute-personnel" element={<RequireAuth><AjouterPersonnel/></RequireAuth>} />
              <Route path="/modifie-personnel/:id" element={<RequireAuth><ModifierPersonnel/></RequireAuth>} />

              <Route path="/clients/commandes" element={<RequireAuth><ListerCommandeCl/></RequireAuth>} />
              <Route path="/clients/ajoute_commande/:id" element={<RequireAuth><AjouterCommandeCl/></RequireAuth>} />
              <Route path="/clients/ajoute_commande" element={<RequireAuth><AjouterCommandeCl/></RequireAuth>} />
              <Route path="/clients/modifie-commande/:num_commande" element={<RequireAuth><ModifierCommandeCl/></RequireAuth>} />
              <Route path="/clients/bon-livraison" element={<RequireAuth><ListerBonLiv/></RequireAuth>} />
              <Route path="/clients/ajoute-bon-livraison/:id" element={<RequireAuth><AjouterBonLiv/></RequireAuth>} />
              <Route path="/clients/ajoute-bon-livraison" element={<RequireAuth><AjouterBonLiv/></RequireAuth>} />
              <Route path="/clients/modifie-bon-livraison/:id" element={<RequireAuth><ModifierBonLiv/></RequireAuth>} />

              <Route path="/clients/devis" element={<RequireAuth><ListerDevis/></RequireAuth>} />
              <Route path="/clients/ajoute-devis" element={<RequireAuth><AjouterDevis/></RequireAuth>} />
              <Route path="/clients/modifie-devis/:id" element={<RequireAuth><ModifierDevis/></RequireAuth>} />

              <Route path="/clients/bon-retour" element={<RequireAuth><ListerBonRetour/></RequireAuth>} />
              <Route path="/clients/ajouter-bon-retour/:id" element={<RequireAuth><AjouterBonRetour/></RequireAuth>} />
              <Route path="/clients/modifer-bon-retour/:id" element={<RequireAuth><ModifierBonRetour/></RequireAuth>} />

              <Route path="/commande-fournisseur/commandes" element={<RequireAuth><ListerCommandeFr/></RequireAuth>} />
              <Route path="/commande-fournisseur" element={<RequireAuth><AjouterCommandeFr/></RequireAuth>} />
              <Route path="/modifier-commande-fournisseur/:id" element={<RequireAuth><ModifierCommandeFr/></RequireAuth>} />

              <Route path="/fournisseur/bon-entree" element={<RequireAuth><ListerBonEntree/></RequireAuth>} />
              <Route path="/fournisseur/creer-bon-entree/:id" element={<RequireAuth><AjouterBonEntree/></RequireAuth>} />
              <Route path="/fournisseur/creer-bon-entree" element={<RequireAuth><AjouterBonEntree/></RequireAuth>} />
              <Route path="/fournisseur/modifier-bon-entree/:id" element={<RequireAuth><ModifierBonEntree/></RequireAuth>} />

              <Route path="/ajouter-paiement-client/:id" element={<RequireAuth><AjouterPaiementClient/></RequireAuth>} />
              <Route path="/paiements-clients" element={<RequireAuth><ListerPaiementCl/></RequireAuth>} />
              <Route path="/modifier-paiement-client/:id" element={<RequireAuth><ModifierPaiementClient/></RequireAuth>} />

              <Route path="/ajouter-paiement-fournisseur/:id" element={<RequireAuth><AjouterPaiementFr/></RequireAuth>} />
              <Route path="/paiements-fournisseurs" element={<RequireAuth><ListerPaiementFr/></RequireAuth>} />
              <Route path="/modifier-paiement-fournisseur/:id" element={<RequireAuth><ModifierPaiementFr/></RequireAuth>} />

              <Route path="/charges" element={<RequireAuth><ListerCharge/></RequireAuth>} />
              <Route path="/ajouter-charge" element={<RequireAuth><AjouterCharge/></RequireAuth>} />
              <Route path="/modifier-charge/:id" element={<RequireAuth><ModifierCharge/></RequireAuth>} />


              <Route path="/ajouter-type-charges" element={<RequireAuth><CreerTypeCharge/></RequireAuth>} />
              <Route path="/types-charges" element={<RequireAuth><ListerTypeCharge/></RequireAuth>} />
              <Route path="/modifier-type-charge/:id" element={<RequireAuth><ModifierTypeCharge/></RequireAuth>} />
              <Route path="/factures" element={<RequireAuth><ListerFacture/></RequireAuth>} />
              <Route path="/creer-avoir/:id" element={<RequireAuth><CreerAvoir/></RequireAuth>} />

              <Route path="/avoirs" element={<RequireAuth><Avoirs/></RequireAuth>} />

              <Route path="/inventaires" element={<RequireAuth><ListerInventaire/></RequireAuth>} />

              <Route path="/ajouter-inventaire" element={<RequireAuth><AjouterInventaire/></RequireAuth>} />
              <Route path="/modifier-inventaire/:id" element={<RequireAuth><ModifierInventaire/></RequireAuth>} />

              <Route path="/profil" element={<RequireAuth><Profil/></RequireAuth>} />
              <Route path="/utilisateurs" element={<RequireAuth><ListeUtilisateur/></RequireAuth>} />
              <Route path="/ajoute-utilisateur" element={<RequireAuth><AjouterUtilisateur/></RequireAuth>} />
              <Route path="/modifier-utilisateur/:id" element={<RequireAuth><ModifierUtilisateur/></RequireAuth>} />

              <Route path="/infos-societe" element={<RequireAuth><InfoSociete/></RequireAuth>} />
              <Route path="/verrouiller-acces" element={<RequireAuth><Verrouiller/></RequireAuth>} />
              <Route path="/paiement/personnel/:id" element={<RequireAuth><AjouterPaiementPersonnel/></RequireAuth>} />
              <Route path="/paiement/personnel" element={<RequireAuth><ListePaiementPersonnel/></RequireAuth>} />
              <Route path="/modifier-paiement-personnel/:id" element={<RequireAuth><ModifierPaiementPersonnel/></RequireAuth>} />
              {/* Partie Rapports*/}
              <Route path="/rapport" element={<RequireAuth><Rapport/></RequireAuth>}>
                <Route path="comptes-par-type" element={<RequireAuth><CompteParType/></RequireAuth>}/>
                <Route path="ca-par-client" element={<RequireAuth><CAparClient/></RequireAuth>} />
                <Route path="ca-par-ville" element={<RequireAuth><CAparVille/></RequireAuth>} />
                <Route path="evolution-des-comptes" element={<RequireAuth><EvolutionCompte/></RequireAuth>} />
                <Route path="article-par-fournisseur" element={<RequireAuth><ArticleparFournisseur/></RequireAuth>} />
                <Route path="article-par-categorie" element={<RequireAuth><ArticleParCategorie/></RequireAuth>} />
                <Route path="vente-par-client" element={<RequireAuth><VenteParClient/></RequireAuth>} />
                <Route path="vente-par-article" element={<RequireAuth><VenteParArticle/></RequireAuth>} />
                <Route path="vente-par-mois" element={<RequireAuth><VenteParMois/></RequireAuth>} />
                <Route path="achat-par-article" element={<RequireAuth><AchatParArticle/></RequireAuth>} />
                <Route path="devis" element={<RequireAuth><Devis/></RequireAuth>} />
                <Route path="ca-par-categorie" element={<RequireAuth><CAparCatégorie/></RequireAuth>} />
                <Route path="factures" element={<RequireAuth><Facture/></RequireAuth>} />
                <Route path="bc" element={<RequireAuth><BC/></RequireAuth>} />
                <Route path="achat-par-fournisseur" element={<RequireAuth><AchatParFournisseur/></RequireAuth>} />
                <Route path="commandes" element={<RequireAuth><CommandeParMois/></RequireAuth>} />
                <Route path="bl-par-mois" element={<RequireAuth><BLParMois/></RequireAuth>} />

           </Route>
          {/* Partie settings*/}
          <Route path="/agenda" element={<RequireAuth><Agenda/></RequireAuth>} >
              <Route path="taches" element={<RequireAuth><Taches/></RequireAuth>} />
              <Route path="gerer-taches" element={<RequireAuth><GererTaches/></RequireAuth>} />
          </Route>
          <Route path="/PersonaliserFacture" element={<RequireAuth><PersonaliserFacture/></RequireAuth>} >
              <Route path="enTete" element={<RequireAuth><EnTeteFacture/></RequireAuth>} />
              <Route path="enPied" element={<RequireAuth><EnPiedFacture/></RequireAuth>} />
          </Route>
          {/* Partie versement*/}
          <Route path='/liste-versement' element={<RequireAuth><ListeVersement/></RequireAuth>}></Route>
          <Route path='/ajouter-versement' element={<RequireAuth><AjouterVersement/></RequireAuth>}></Route>
          <Route path='/modifier-versement/:id' element={<RequireAuth><ModifierVersement/></RequireAuth>}></Route>


           </Routes>
           </MainLayout>
           ) : (
            <Routes>
              <Route path="/" element={<Login/>} />
            </Routes>
          )}
        </AuthProvider>
            {shouldShowFooter && <Footer />}

   
    </div>
  );
}

export default App;

