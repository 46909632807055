import React, { useState, useEffect,useMemo } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import Search from "../../layouts/Search";
import Pagination from "../../layouts/Pagination";
import Lightbox from "../../layouts/Lightbox";
import SortingTable from "../../layouts/SortingTable";
import { useTranslation } from "react-i18next";

function ListerPersonnel() {
    const {t}=useTranslation();
    const [personnels, setPersonnels] = useState([]);
    const [magasins, setMagasins] = useState([]);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [PersonnelIdToDelete, setPersonnelIdToDelete] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false); 
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [itemsPerPage,setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [filteredpersonnels, setFilteredpersonnels] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [personnelsResponse, magasinsResponse] = await Promise.all([
                    axios.get("/personnel"),
                    axios.get("/magasins"),
                ]);

                const personnelsData = personnelsResponse.data;
                const magasinsData = magasinsResponse.data;

                const personnelsWithMagasin = personnelsData.map(personnel => {
                    const magasin = magasinsData.find(m => m.id === personnel.id_magasin);
                    return {
                        ...personnel,
                        magasin: magasin ? magasin.nom_magasin : '',
                    };
                });

                setPersonnels(personnelsWithMagasin);
                setMagasins(magasinsData);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        };

        fetchData();
    }, []); 

    const openDelete = (personnelId) => {
        setPersonnelIdToDelete(personnelId);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        setPersonnelIdToDelete(null);
    };

    const handleDeletPersonnel = async () => {
        try {
            await axios.delete(`/personnel/${PersonnelIdToDelete}`);
            setPersonnels(personnels.filter(personnel => personnel.id !== PersonnelIdToDelete));
            setShowConfirmBox(false); 
        } catch (error) {
            console.error("Error deleting type:", error);
        }
    };

    const columns = useMemo(() => [
        { key: 'Photo', label: t('Photo'), sortable: false },
        { key: 'Nom_Complet', label: t('Nom_Complet'), sortable: true },
        { key: 'Responsabilite', label: t('Responsabilite'), sortable: true },
        { key: 'Adresse', label: t('Adresse'), sortable: true },
        { key: 'Telephone', label: t('Telephone'), sortable: true },
        { key: 'Ville', label: t('Ville'), sortable: true },
        { key: 'CIN', label: t('CIN'), sortable: true },
        { key: 'Magasin', label: t('Magasin'), sortable: true },
        { key: 'actions', label: t('actions'), sortable: false },
    ], [t]);

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage; 
    const currentItems = filteredpersonnels.slice(offset, offset + itemsPerPage); 

    const openLightbox = (imageUrl) => {
        setCurrentImageUrl(imageUrl); 
        setLightboxOpen(true); 
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    const handleSearchChange = (term) => {
      setSearchTerm(term);
  };
  
  useEffect(() => {
      const filtered = personnels.filter(personnel => 
          Object.values(personnel).some(value =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
      setFilteredpersonnels(filtered);
      setCurrentPage(0); 
  }, [searchTerm, personnels]);
  

  const handleItemsPerPageChange = (event) => {
      setItemsPerPage(parseInt(event.target.value)); 
      setCurrentPage(0);
  };
    return (
        <>
           <div className="breadcrumbbar">  
                        <div className="col-md-8 col-lg-8">
                            <h4 className="page-title">{t('Personnels')}</h4>
                            <div className="breadcrumb-list mb-3">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                        <Link to="/home">
                                        <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                        </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/home" style={{textDecoration:'none'}}>{t('magasins')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('List Personnels')}</li>
                                    </ol>
                            </div>
                        </div>              
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    
                                    <div className="card-header">                                
                                    <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/ajoute-personnel" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((personnel) => ({
                                                    id: personnel.id,
                                                    Nom_Complet: personnel.nom_complet,
                                                    Photo: (
                                                        <img
                                                            src={`https://admin.mansoft.ma/${personnel.photo}`}
                                                            alt={personnel.nom_complet}
                                                            style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                                            onClick={() => openLightbox(`https://admin.mansoft.ma/${personnel.photo}`)}
                                                        />
                                                    ),
                                                    Responsabilite: personnel.responsabilite,
                                                    Adresse: personnel.adresse,
                                                    Telephone: personnel.telephone,
                                                    Ville: personnel.ville,
                                                    CIN: personnel.CIN,
                                                    Magasin: personnel.magasin, 
                                                    actions: (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Link to={`/modifie-personnel/${personnel.id}`} className="btn btn-success-rgba btn-round mb-1" title="Edit" data-toggle="tooltip" style={{ marginLeft: "10px" }}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(personnel.id)} className="btn btn-danger-rgba btn-round mb-1" style={{ marginLeft: "10px" }}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link to={`/paiement/personnel/${personnel.id}`} className="btn btn-primary-rgba btn-round mb-1" title="prix" style={{ marginLeft: "10px" }}>
                                                                <FontAwesomeIcon icon={faHandHoldingDollar} />
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredpersonnels} 
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                            <Lightbox
                                                isOpen={lightboxOpen} 
                                                onClose={closeLightbox} 
                                                imageUrl={currentImageUrl} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        open={showConfirmBox}
                        closeDialog={closeConfirmBox}
                        title="personnel"
                        deleteFunction={handleDeletPersonnel}
                    />
               
        </>
    );
}

export default ListerPersonnel;
