import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";


function AjouterBonR() {
   
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [clients, setClients] = useState([]);
    const [articles, setArticles] = useState([]);
    const [magasins, setMagasin] = useState([]);
    const [selectedMagasin, setSelectedMagasin] = useState(""); 
    const [selectedClient, setSelectedClient] = useState("");
    const [soldeClient, setSoldeClient] = useState("");
    const [date_br, setDate] = useState("");
    const [description, setDescription] = useState("");
    const [personnel, setPersonnel] = useState("");
    const [selectedPersonnel, setSelectedPersonnel] = useState(""); 
    const [ setCategories] = useState([]);
    const [rows, setRows] = useState([{ selectedArticle: '', quantity: '', price: '' }]);
    const [rowError, setRowError] = useState({});
    const [initialQuantities, setInitialQuantities] = useState([]);

    const formatNumber = (number) => {
        return number.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get("/clients");
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
    
        // const fetchCategories = async () => {
        //     try {
        //         const response = await axios.get("/categories");
        //         setCategories(response.data.categories);
        //     } catch (error) {
        //         console.error("Error fetching categories:", error);
        //     }
        // };
    
        const fetchArticles = async () => {
            try {
                const response = await axios.get("/articles");
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };
    
        const fetchMagasin = async () => {
            try {
                const response = await axios.get("/magasins");
                setMagasin(response.data);
            } catch (error) {
                console.error('Error fetching magasins:', error);
                setMagasin([]);
            }
        };
    
        const fetchPersonnel = async () => {
            try {
                const response = await axios.get("/personnel");
                setPersonnel(response.data);
            } catch (error) {
                console.error('Error fetching personnel:', error);
                setPersonnel([]);
            }
        };
        const fetchDevisDetails = async () => {
            try {

                const devisResponse = await axios.get(`/bonslivraisons/${id}`);
                console.log("cfgh", devisResponse.data);
                const { id_client, date_devis} = devisResponse.data;
                setSelectedClient(id_client);
                setDate(date_devis);
              const allLignesDevisResponse = await axios.get(`/ligne-bon-liv-cls?bon_livraison_id=${id}`);
              const lignesDevisData = allLignesDevisResponse.data;
      
              setRows(
                lignesDevisData.map((ligne, index) => ({
                  id: ligne.id,
                  selectedArticle: ligne.id_article,
                  quantity: ligne.quantite,
                  price: ligne.pu_achat,
                }))
              );
      
            setInitialQuantities(
                lignesDevisData.map((ligne) => ligne.quantite)
              );
            } catch (error) {
              console.error("Error fetching devis details:", error);
            }
          };
    
        
        fetchDevisDetails();
        fetchClients();
        fetchArticles();
        // fetchCategories();
        fetchMagasin();
        fetchPersonnel();
    }, [id, setClients, setArticles, setMagasin, setSelectedClient, setRows, setPersonnel]);
    
  
    
    const getArticleName = (articleId, articles) => {
        const article = articles.find((a) => a.id === articleId);
        return article ? article.nom_article : "";
      }; 
      const onInputChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case "selectedClient":
                setSelectedClient(value);
                break;
            case "date_br":
                setDate(value);
                break;
           
        }
    };
  
      const handleRowChange = (index, key, value) => {
        const updatedRows = [...rows];
        updatedRows[index][key] = value;
        setRows(updatedRows);
    };
    
    const handleChange = (index, key, value) => {
        const initialQuantity = initialQuantities[index];
    
        if (key === "quantity" && value > initialQuantity) {
            setRowError({ ...rowError, [index]: `La quantité ne peut pas dépasser ${initialQuantity}.` });
            handleRowChange(index, key, initialQuantity);
            return;
        }
    
        setRowError({ ...rowError, [index]: null });
        handleRowChange(index, key, value); 
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData ={
            date_br: date_br,
            id_bonlivraison:id,
            magasin:selectedMagasin,
            personnel:selectedPersonnel,
            description :description,
            solde_client: soldeClient,
        }
        console.log('avant envoi ',formData);
        try {
            const brResponse = await axios.post("/bon_retour_cls",formData);
            
            if (brResponse.status === 201) {
                const bonRetourId = brResponse.data.id; 
                
                await Promise.all(rows.map(async (row) => {
                    await axios.post("/ligne-bon-rcls", {
                        bon_retour_id: bonRetourId,
                        id_article: row.selectedArticle,
                        quantite: row.quantity,
                        montant: row.price*row.quantity,
                        });
                }));
                
    
                handleSuccess();
            } else { 
                handleError();
            }
        } catch (error) {
            handleError(error);
        }
    };
    
    
    

    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Bon de retour ajoute avec succès!',
        });
        navigate('/clients/bon-retour');
    };

    const handleError = (error) => {
        if (error.response.status === 401) {
            Swal.fire({
                icon: 'error',
                title: 'Unauthorized',
                text: 'Vous n\'êtes pas autorisé à effectuer cette action. Veuillez vous connecter à nouveau.',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Échec de l\'ajout. Veuillez réessayer plus tard.',
            });
        }
    };
    

    
 
    
    const handleRemoveRow = async (index) => {
        try {
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
        } catch (error) {
            console.error('Erreur lors de la suppression de la ligne :', error);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Échec de la suppression de la ligne. Veuillez réessayer plus tard.',
            });
        }
    };
    
    
    return (
<>
	<div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter bon retour de client</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                            <div className="form-row">
                                                <div className="col-md-4 d-flex align-items-center mb-3 ">
                                                    <label className="me-2 col-md-4">Client</label>
                                                    <select
                                                className=" form-control"
                                                name="selectedClient"
                                                value={selectedClient}
                                                onChange={onInputChange}
                                            >
                                                <option key="" value=""></option>
                                                {clients.map(client => (
                                                    <option key={client.id_client} value={client.id_client}>{client.nom_client} {client.prenom_client}</option>
                                                ))}
                                            </select>
                                                </div>
                                                <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Date</label>
                                                    <input
                                                    type="date"
                                                        className="form-control"
                                                        value={date_br ||""}
                                                        onChange={(e) => setDate(e.target.value)}
                                                    />
                                                
                                                </div>
                                           
                                                <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Magasin</label>
                                                    <select
                                                        name="magasins"
                                                       className="form-control "
                                                        value={selectedMagasin}
                                                        onChange={(e) =>setSelectedMagasin(e.target.value)}
                                                    >
                                                        <option key="" value=""></option>
                                                        {Array.isArray(magasins) &&
                                                        magasins.map((magasin) => (
                                                            <option key={magasin.id} value={magasin.nom_magasin}>
                                                            {magasin.nom_magasin}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                </div>
                                                <div className="form-row">

                                                <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">Personne</label>
                                                    <select
                                                        name="magasins"
                                                    className="form-control "
                                                    value={selectedPersonnel}
                                                    onChange={(e) =>setSelectedPersonnel(e.target.value)}
                                                  >
                                                    <option key="" value=""></option>
                                                    {Array.isArray(personnel) &&
                                                    personnel.map((p) => (
                                                        <option key={p.id} value={p.nom_complet}>
                                                        {p.nom_complet}
                                                        </option>
                                                    ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-4 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">soldeClient </label>
                                                    <select
                                                        name="magasins"
                                                        className="form-control "
                                                        value={soldeClient}
                                                       onChange={(e) =>setSoldeClient(e.target.value)}
                                                  >
                                                   <option key="" value="">-- pouvant être utilisé soit----</option>
                                                    <option key="avance" value="Avance ">Avance</option>
                                                    <option key="argent" value="Argent">Argent</option>

                                                    
                                                    </select>
                                                </div>
                                                <div className="col-md-4 d-flex align-items-center mb-3">
                                                   <label className="me-2 col-md-4">Description</label>
                                                    <textarea className="form-control " rows="1" name="description" 
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}

                                                    >

                                                    </textarea>
                                                </div>
                                           </div>
                                        
                                        <table className="display table table-striped table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Article</th>
                                                <th >Quantité</th>
                                                <th>P.U</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{getArticleName(row.selectedArticle, articles)}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        
                                                        value={rows[index]?.quantity}
                                                        onChange={(e) => handleChange(index, "quantity", parseInt(e.target.value))}
                                                        min={0} 
                                                        style={{
                                                            border: 'none',  
                                                            outline: 'none',  
                                                            background: 'transparent',  
                                                            textAlign: 'center', 
                                                            width:"70 px" ,
                                                            color: "#8A98AC",
                                                        }}
                                                    />{rowError[index] && (
                                                        <span style={{ color: "red", fontSize: "0.8em" }}>
                                                          {rowError[index]}
                                                        </span>
                                                      )}</td>
                                                <td>{formatNumber(parseFloat(row.price))}</td>
                                                <td>{formatNumber(parseFloat(row.quantity * row.price))}</td>
                                                <td className="d-md-flex justify-content-md-end">
                                                <i
                                                    className="feather icon-trash btn btn-danger-rgba btn-round"
                                                    style={{ fontSize: "small" }}
                                                    onClick={() => handleRemoveRow(index)}
                                                ></i>
                                                </td>
                                            </tr>


                                            
                                            ))}
                                            </tbody>
                                        </table>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/clients/bon-retour">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
    </div>


</>

    );
}

export default AjouterBonR;
