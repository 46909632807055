import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import Search from "../../layouts/Search";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Pagination from "../../layouts/Pagination";

function PrixparCategorie() {
    const { clientId } = useParams();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [editedPrices, setEditedPrices] = useState({});
    const [editMode, setEditMode] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);

    const loadCategories = useCallback(async () => {
        // Load categories and subcategories with their prices
        const loadCategoryPrices = async (categoryId, subcategoryId = null) => {
            try {
                let result;
                if (subcategoryId) {
                    result = await axios.get(`/categorie-prix-clients/${clientId}/${subcategoryId}`);
                } else {
                    result = await axios.get(`/categorie-prix-clients/${clientId}/${categoryId}`);
                }
                const categoryPrice = result.data.pu_vente;
                if (categoryPrice !== null && categoryPrice !== undefined) {
                    setEditedPrices(prevPrices => ({
                        ...prevPrices,
                        [subcategoryId ? subcategoryId : categoryId]: categoryPrice
                    }));
                }
            } catch (error) {
                console.error("Error loading category prices:", error);
            }
        };

        try {
            const result = await axios.get("categories/listcategories");
            const sousCategories = result.data.sous_categories;
            const categoriesWithData = await Promise.all(
                sousCategories.map(async (category) => {
                    const subcategoriesResult = await axios.get(`/categories/${category.id}/sous-categories`);
                    let categoryWithSubcategories = {
                        ...category,
                        sous_categories: subcategoriesResult.data,
                    };

                    const loadPricesPromises = [loadCategoryPrices(category.id)];
                    categoryWithSubcategories.sous_categories.forEach((subcategory) => {
                        loadPricesPromises.push(loadCategoryPrices(category.id, subcategory.id));
                    });

                    await Promise.all(loadPricesPromises);

                    return categoryWithSubcategories;
                })
            );
            setCategories(categoriesWithData.reverse());
            setEditMode(new Array(categoriesWithData.length).fill(false));
        } catch (error) {
            console.error("Error loading categories:", error);
        }
    }, [clientId, setCategories, setEditMode, setEditedPrices]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    
    const handlePriceChange = (id, value) => {
        setEditedPrices((prevPrices) => ({
            ...prevPrices,
            [id]: value,
        }));
    };


    const toggleEditModeCategory = (categoryId) => {
        setEditMode((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId],
        }));
    };

    const toggleEditModeSubcategory = (subcategoryId) => {
        setEditMode((prev) => ({
            ...prev,
            [subcategoryId]: !prev[subcategoryId],
        }));
    };

    const toggleEditMode = (categoryId, subcategoryId) => {
        if (subcategoryId) {
            toggleEditModeSubcategory(subcategoryId);
        } else {
            toggleEditModeCategory(categoryId);
        }
    };
    const handleSave = async (categoryId) => {
        try {
            if (editedPrices[categoryId] !== undefined) {
                await savePricesToDatabase(categoryId, editedPrices[categoryId]);
            }
            setEditedPrices({});
            setEditMode((prev) => ({
                ...prev,
                [categoryId]: false,
            }));
            await loadCategories();
        } catch (error) {
            console.error("Error saving prices:", error);
        }
    };

    const savePricesToDatabase = async (categoryId, price) => {
        try {
            await axios.post(`/categorie-prix-clients/create-or-update`, {
                client_id: clientId,
                categorie_id: categoryId,
                pu_vente: price,
            });
            console.log(`Price for category ${categoryId} saved to database successfully!`);
        } catch (error) {
            console.error("Error saving price to database:", error);
            alert("Erreur lors de l'enregistrement des prix dans la base de données. Veuillez réessayer.");
        }
    };

    const columns = useMemo(() => [
        { key: 'nom_categorie', label: t('categoryName'), sortable: true },
        { key: 'nom_sous_categorie', label: t('subCategoryName'), sortable: true },
        { key: 'pu_vente', label: t('sellingPrice'), sortable: true },
        { key: 'actions', label: t('actions'), sortable: false, style: { textAlign: "center" } },
    ], [t]);

    const handleSearchChange = (value) => {
        setSearchTerm(value);
    };

    const filteredCategories = categories.filter((category) => {
        return columns.some((col) => {
            if (col.key === 'nom_categorie' && category.nom_categorie) {
                return category.nom_categorie.toLowerCase().includes(searchTerm.toLowerCase());
            }
            if (category.sous_categories) {
                return category.sous_categories.some((subCategory) => {
                    if (col.key === 'nom_sous_categorie' && subCategory.nom_categorie) {
                        return subCategory.nom_categorie.toLowerCase().includes(searchTerm.toLowerCase());
                    }
                    return false;
                });
            }
            return false;
        });
    });

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
      };
    
      const offset = currentPage * itemsPerPage;
      const currentItems = filteredCategories.slice(offset, offset + itemsPerPage);
    
      const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
      };
    

    const deleteCategoryPriceFromDatabase = async (categoryId) => {
        try {
            await axios.delete(`/categorie-prix-clients/${clientId}/${categoryId}`);
            Swal.fire({
                icon: "success",
                title: "Succès",
                text: `Price for category ${categoryId} deleted from database successfully!`,
            });

            const categoryResponse = await axios.get(`/categories/${categoryId}`);
            const initialPrice = categoryResponse.data.pu_vente;

            setEditedPrices(prevPrices => ({
                ...prevPrices,
                [categoryId]: initialPrice
            }));

            await loadCategories();
        } catch (error) {
            console.error("Error deleting price from database:", error);
            Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Erreur lors de la suppression du prix de la base de données. Veuillez réessayer.",
            });
        }
    };

    return (
        <>
            <div className="breadcrumbbar">
                <div className="col-md-8 col-lg-8">
                    <h4 className="page-title">{t('Catalogues prix par client')}</h4>
                    <div className="breadcrumb-list mb-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/home">
                                    <i className="fa fa-home img-fluid" style={{ fontSize: "15px", width: "20px", marginRight: "3px" }}></i>
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/home" style={{ textDecoration: 'none' }}>{t('clients')}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{t('Prix Par Client')}</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                        <div className="card m-b-50">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                        <div className="col-5 col-md-5 d-flex justify-content-center">
                                            <Search
                                                placeholder={t('Search')}
                                                onSearchChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table id="default-datatable" className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Nom de catégorie</th>
                                                    <th>Nom de sous catégorie</th>
                                                    <th>PU. Vente (DH)</th>
                                                    <th style={{ textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((category, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td className="text-gray-800 text-hover-primary mb-1">{category.nom_categorie}</td>
                                                            <td></td>
                                                            <td>
                                                                    {editMode[category.id] ? (
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={editedPrices[category.id] !== undefined ? editedPrices[category.id] : category.pu_vente}
                                                                            onChange={(e) => handlePriceChange(category.id, e.target.value)}
                                                                        />
                                                                    ) : (
                                                                        editedPrices[category.id] !== undefined ? editedPrices[category.id] : category.pu_vente
                                                                    )}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                   {editMode[category.id] ? (
                                                            <i
                                                                className="feather icon-save btn btn-success-rgba btn-round"
                                                                onClick={() => handleSave(category.id)}
                                                                style={{ marginRight: '10px'}}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="feather icon-edit-2 btn btn-primary-rgba btn-round"
                                                                onClick={() => toggleEditMode(category.id)}
                                                                style={{ marginRight: '10px'}}
                                                            ></i>
                                                        )}
                                                        <button
                                                            className="btn btn-danger-rgba btn-round"
                                                            onClick={() => deleteCategoryPriceFromDatabase(category.id)}
                                                        >
                                                            <i className="feather icon-trash" style={{ fontSize: "small" }} ></i>
                                                        </button>

                                                                </td>
                                                        </tr>
                                                        {category.sous_categories.map((subcategory, subIndex) => (
                                                            <tr key={subIndex}>
                                                                <td></td>
                                                                <td className="text-gray-800 text-hover-primary mb-1">{subcategory.nom_categorie}</td>
                                                                <td>
                                                                        {editMode[subcategory.id] ? (
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={editedPrices[subcategory.id] !== undefined ? editedPrices[subcategory.id] : subcategory.pu_vente}
                                                                                onChange={(e) => handlePriceChange(subcategory.id, e.target.value)}
                                                                            />
                                                                        ) : (
                                                                            editedPrices[subcategory.id] !== undefined ? editedPrices[subcategory.id] : subcategory.pu_vente
                                                                        )}

                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {editMode[subcategory.id] ? (
                                                                            <i
                                                                                className="feather icon-save btn btn-success-rgba btn-round"
                                                                                onClick={() => handleSave(subcategory.id)}
                                                                                style={{ marginRight: '10px'}}
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                className="feather icon-edit-2 btn btn-primary-rgba btn-round"
                                                                                onClick={() => toggleEditMode(subcategory.id)}
                                                                                style={{ marginRight: '10px'}}

                                                                            ></i>
                                                                        )}
                                                                        <button
                                                                            className="btn btn-danger-rgba btn-round"
                                                                            onClick={() => deleteCategoryPriceFromDatabase(subcategory.id)}
                                                                        >
                                                                            <i className="feather icon-trash" style={{ fontSize: "small" }} ></i>
                                                                        </button>
                                                                    </td>
                                                            </tr>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                <select
                                                    className="form-control"
                                                    id="items-per-page"
                                                    onChange={handleItemsPerPageChange}
                                                    value={itemsPerPage}
                                                >
                                                    <option value={2}>2</option>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                </select>
                                                </div>
                                        <div className="col-6">
                                        <Pagination
                                            items={filteredCategories}
                                            itemsPerPage={itemsPerPage}
                                            onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrixparCategorie;
