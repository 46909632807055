import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import axios from '../../../api/axios';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fetchClients } from '../../../services/clientService';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const fetchData = async () => {
  const [clientsData, bonsLivraisonsData, facturesData, avoirsData] = await Promise.all([
    fetchClients(),
    axios.get('/bonslivraisons').then(response => response.data),
    axios.get('/factures').then(response => response.data),
    axios.get('/avoirs').then(response => response.data),
  ]);

  return {
    clients: clientsData || [],
    bonsLivraisons: bonsLivraisonsData || [],
    factures: facturesData || [],
    avoirs: avoirsData || [],
  };
};

const CAparVille = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const chartRef = useRef(null);

  const { data, error, isLoading } = useQuery(['data'], fetchData);

  useEffect(() => {
    if (data) {
      const { clients, bonsLivraisons, factures, avoirs } = data;
      const filtered = filterData(clients, bonsLivraisons, factures, avoirs, startDate, endDate);
      setFilteredData(filtered);
    }
  }, [data, startDate, endDate]);

  const filterData = (clients, bonLivraisons, factures, avoirs, startDate, endDate) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return clients.map(client => {
      const clientBonLivraisons = bonLivraisons.filter(bon =>
        bon.id_client === client.id_client &&
        (!start || new Date(bon.date_livraison) >= start) &&
        (!end || new Date(bon.date_livraison) <= end)
      );

      const factureGroups = factures.reduce((groups, facture) => {
        const bonLivraison = clientBonLivraisons.find(bon => bon.id === facture.bon_livraison_id);
        if (bonLivraison) {
          const key = facture.id;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(facture);
        }
        return groups;
      }, {});

      const clientFactures = Object.values(factureGroups).map(group => ({
        ...group[0],
        montant: group.reduce((total, facture) => total + parseFloat(facture.montant), 0)
      }));

      const totalFacturesAmount = clientFactures.reduce((total, facture) => total + facture.montant, 0);

      const totalAvoirsAmount = avoirs.reduce((total, avoir) => {
        const facture = clientFactures.find(facture => facture.id === avoir.facture_id);
        return facture ? total + parseFloat(avoir.montant) : total;
      }, 0);

      const balance = totalFacturesAmount - totalAvoirsAmount;

      return {
        ...client,
        factures: clientFactures.length,
        avoirs: totalAvoirsAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
        chiffreAffaires: totalFacturesAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
        balance: balance.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      };
    }).filter(client => client.factures > 0 || parseFloat(client.avoirs) > 0 || parseFloat(client.chiffreAffaires) > 0 || parseFloat(client.balance) > 0);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CAparVille');
    XLSX.writeFile(workbook, 'CAparVille.xlsx');
  };

  const pieChartData = {
    labels: filteredData.map(client => client.ville),
    datasets: [{
      data: filteredData.map(client => parseFloat(client.chiffreAffaires.replace(/\s/g, '').replace(',', '.'))),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  if (isLoading) return <p>{t('Chargement...')}</p>;
  if (error) return <p>{t('Erreur lors de la récupération des données')}</p>;

  return (
    <div className="container my-4">
      <div className="container mb-5">
        <div className="row mb-3">
          <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
            <label className="me-2">{t('Date de début')} :</label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="form-control"
            />
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
            <label className="me-2">{t('Date de fin')} :</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="form-control"
            />
          </div>
          <div className="col-lg-2 col-md-12 d-flex justify-content-end align-items-center mb-3">
            <Link className="btn btn-primary btn-export" onClick={exportToExcel}>
              <i className="fa fa-cloud-download mr-2"></i>{t('Exporter')}
            </Link>
          </div>
        </div>
      </div>

      <div className="chart-container" style={{ width: '100%', height: '300px' }}>
        <Pie data={pieChartData} options={options} ref={chartRef} />
      </div>

      {filteredData.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-bordered w-100 mt-4">
            <thead>
              <tr>
                <th>{t('Ville')}</th>
                <th>{t('Factures')}</th>
                <th>{t('Avoirs')}</th>
                <th>{t('Chiffre d\'affaires')}</th>
                <th>{t('Balance')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((client, index) => (
                <tr key={index}>
                  <td>{client.ville}</td>
                  <td>{client.factures}</td>
                  <td>{client.avoirs}</td>
                  <td>{client.chiffreAffaires}</td>
                  <td>{client.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="m-alert m-alert--outline alert alert-dismissible fade show alert-warning">
          {t('Aucune donnée disponible pour la période sélectionnée')}
        </div>
      )}
    </div>
  );
};

export default CAparVille;
