import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AjouterClient() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [nom_client, setNomClient] = useState("");
    const [prenom_client, setPrenomClient] = useState("");
    const [societe, setSociete] = useState("");
    const [num_telephone, setNumTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [adresse, setAdresse] = useState("");
    const [ville, setVille] = useState("");
    const [fax, setFax] = useState("");
    const [type_client, setTypeClient] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/clients", {
                nom_client,
                prenom_client,
                societe,
                num_telephone,
                email,
                adresse,
                ville,
                fax,
                type_client
            });
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    text: t('client_added_successfully'),
                });
                navigate('/clients');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: t('client_add_failed'),
                });
            }
        } catch (error) {
            console.error('Erreur lors de l\'ajout du client:', error);
            Swal.fire({
                icon: 'error',
                title: t('error'),
                text: t('client_add_failed'),
            });
        }
    };

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                        <h5 className="card-title">{t('Ajouter un client')}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form className="needs-validation" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Nom Client')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={nom_client}
                                                        onChange={(e) => setNomClient(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Prénom Client')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={prenom_client}
                                                        onChange={(e) => setPrenomClient(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Téléphone')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={num_telephone}
                                                        onChange={(e) => setNumTelephone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Email')}</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Adresse')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={adresse}
                                                        onChange={(e) => setAdresse(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Fax')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={fax}
                                                        onChange={(e) => setFax(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row">

                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('Ville')}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={ville}
                                                        onChange={(e) => setVille(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex align-items-center mb-3">
                                                    <label className="me-2 col-md-4">{t('type_client')}</label>
                                                    <select
                                                        className="form-select"
                                                        value={type_client}
                                                        onChange={(e) => setTypeClient(e.target.value)}
                                                    >
                                                        <option value="">{t('select_type')}</option>
                                                        <option value="moral">{t('moral')}</option>
                                                        <option value="physique">{t('Physique')}</option>
                                                    </select>
                                                </div>

                                                {type_client === "moral" && (
                                                    <div className="col-md-6 d-flex align-items-center mb-3">
                                                        <label className="me-2 col-md-4">{t('Société')}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={societe}
                                                            onChange={(e) => setSociete(e.target.value)}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Link className="btn btn-warning my-1" to="/clients">Annuler</Link>
                                                <button className="btn btn-success my-1" type="submit">Ajouter</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
                
        </>
    );
}

export default AjouterClient;
