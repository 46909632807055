import React from 'react';
import { useQuery } from 'react-query';
import axios from "../../api/axios";
import frIcon from '../../assets/images/svg-icon/crm.svg';
import crmIcon from '../../assets/images/svg-icon/customers.svg';
import magasinIcon from '../../assets/images/svg-icon/frontend.svg';
import ecommerceIcon from '../../assets/images/svg-icon/ecommerce.svg';
import AreaChart from '../../assets/js/statistiques/AreaChart';
import ColumnChart from '../../assets/js/statistiques/ColumnChart';
import { useTranslation } from 'react-i18next';
import PieChart from '../../assets/js/statistiques/PieChart';
import BarChart from '../../assets/js/statistiques/BarChart'; 
import SuiviVenteDashboard from '../../assets/js/statistiques/SuiviVente';
import { fetchArticlePlusVendu, fetchArticles ,fetchMagasin } from '../../services/articleService';
import { fetchFournisseurs } from '../../services/fournisseurService';
import { fetchClients, fetchCommandeCl } from '../../services/clientService';
import { SuiviVente } from '../../services/suivVenteService';
import { TailSpin } from 'react-loader-spinner'; 
import ClientParVille from '../../assets/js/statistiques/ClientParVille';

function Home() {
  const { t } = useTranslation();

  const { data: salesData, isLoading: salesLoading } = useQuery('salesData', SuiviVente);
  const { data: articlesResponse, isLoading: articlesLoading } = useQuery('articles', fetchArticles);
  const { data: fournisseursResponse, isLoading: fournisseursLoading } = useQuery('fournisseurs', fetchFournisseurs);
  const { data: clientsResponse, isLoading: clientsLoading } = useQuery('clients', fetchClients);
  const { data: magasinsResponse, isLoading: magasinsLoading } = useQuery('magasins',fetchMagasin);
  const { data: articleCommandesResponse, isLoading: articleCommandesLoading } = useQuery('article-commandes', fetchArticlePlusVendu);
  const { data: CommandeClResponse, isLoading: CommandeClLoading } = useQuery('commandeclient', fetchCommandeCl);
  function formatNumber(number) {
    return number.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}


  const [articlesData, setArticlesData] = React.useState([]);
  const [articlesLabels, setArticlesLabels] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const [clientLabels, setClientLabels] = React.useState([]);

  React.useEffect(() => {
    if (articlesResponse && articleCommandesResponse) {
      const salesData = articleCommandesResponse.reduce((acc, line) => {
        if (line.id_article) {
          acc[line.id_article] = acc[line.id_article] ? acc[line.id_article] + 1 : 1;
        }
        return acc;
      }, {});

      const sortedSalesData = Object.entries(salesData).sort(([, a], [, b]) => b - a).slice(0, 5);
      const articleIds = sortedSalesData.map(([id]) => id);

      Promise.all(articleIds.map(id => axios.get(`/articles/${id}`).catch(error => null)))
        .then(articlesResponses => {
          const articlesData = articlesResponses
            .filter(response => response && response.data)
            .map(response => response.data);

          const labels = articlesData.map(article => article.nom_article);
          const data = articlesData.map(article => salesData[article.id]);

          setArticlesLabels(labels);
          setArticlesData(data);
        });

      if (CommandeClResponse) {
        const clientSalesData = CommandeClResponse.reduce((acc, line) => {
          if (line.idclient) {
            acc[line.idclient] = acc[line.idclient] ? acc[line.idclient] + 1 : 1;
          }
          return acc;
        }, {});

        const clientIds = Object.keys(clientSalesData);

        Promise.all(clientIds.map(id => axios.get(`/clients/${id}`).catch(error => null)))
          .then(clientsResponses => {
            const clientsData = clientsResponses
              .filter(response => response && response.data)
              .map(response => response.data);

            const clientNames = clientsData.map(client => `${client.nom_client} ${client.prenom_client}`);
            const clientPurchases = clientsData.map(client => clientSalesData[client.id_client] || 0);

            setClientLabels(clientNames);
            setClientData(clientPurchases);
          });
      }
    }
  }, [articlesResponse, articleCommandesResponse, CommandeClResponse]);

  const cardStyle = {
    marginBottom: '30px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    color: 'white',
    textAlign: 'center',
    padding: '15px'
  };

  const cards = [
    {
      total: articlesResponse?.length || 0,
      title: t('Articles'),
      color: '#00FA9A',
      icon: ecommerceIcon,
    },
    {
      total: clientsResponse?.length || 0,
      title: t('Clients'),
      color: '#007bff',
      icon: crmIcon,
    },
    {
      total: fournisseursResponse?.length || 0,
      title: t('Fournisseurs'),
      color: '#ff5761',
      icon: frIcon,
    },
    {
      total: magasinsResponse?.length || 0,
      title: t('Magasins'),
      color: '#ffc107',
      icon: magasinIcon,
    }
  ];

  return (
    <>
      
          <div className="breadcrumbbar" />
          <div className="contentbar">
            <div className="row">
              {cards.map((card, index) => (
                <div className="col-lg-3" key={index}>
                  <div style={{ ...cardStyle, backgroundColor: card.color }}>
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-center">
                          <h5 className="mb-1" style={{ color: 'white' }}>{card.total}</h5>
                          <span style={{ color: 'white' }}>{card.title}</span>
                        </div>
                        <img src={card.icon} style={{ width: "30px" }} alt={card.title} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-lg-9">
                <div className="card m-b-30">
                  <div className="card-header text-center">
                    <h5 className="card-subtitle">{t('les ventes par rapport au retours')}</h5>
                  </div>
                  <div className="card-body">
                     <AreaChart />
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card m-b-30">
                  <div className="card-header text-center">
                    <h5 className="card-subtitle">{t('Suivi des ventes')}</h5>
                  </div>
                  <div className="card-body">
                    {salesLoading ? <TailSpin color="#00BFFF" height={80} width={80} /> : (
                      <SuiviVenteDashboard
                      facturesTotal={formatNumber(parseFloat(salesData?.totalFactures || 0))}
                      avoirsTotal={formatNumber(parseFloat(salesData?.totalAvoirs || 0))}
                      chiffreAffaires={formatNumber(parseFloat(salesData?.CA || 0))}
                    />
                    
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-30">
                  <div className="card-header text-center">
                    <h4 className="card-subtitle">{t('Nombre d\'articles par catégorie')}</h4>
                  </div>
                  <div className="card-body">
                    {articlesLoading ? <TailSpin color="#00BFFF" height={80} width={80} /> : <ColumnChart />}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-30">
                  <div className="card-header text-center">
                    <h5 className="card-subtitle">{t('Les articles les plus vendus')}</h5>
                  </div>
                  <div className="card-body text-center">
                    {articleCommandesLoading ? <TailSpin color="#00BFFF" height={80} width={80} /> : (
                      <PieChart data={articlesData} labels={articlesLabels} />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-30">
                  <div className="card-header text-center mt-2">
                    <h5 className="card-subtitle">{t('Les clients qui effectuent plus achats')}</h5>
                  </div>
                  <div className="card-body text-center">
                    {CommandeClLoading ? <TailSpin color="#00BFFF" height={80} width={80} /> : (
                      <BarChart
                        data={clientData}
                        labels={clientLabels}
                        title={t('Nombre d\'achats')}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-50">
                  <div className="card-header text-center">
                    <h5 className="card-subtitle">{t('Les clients par villes')}</h5>
                  </div>
                  <div className="card-body text-center">
                      <ClientParVille/>
                   
                  </div>
                </div>
              </div>

            </div>
         
      </div>
    </>
  );
}

export default Home;
