import React, { useState, useEffect,useMemo } from "react";
import { useQuery, useMutation } from 'react-query';
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import ConfirmBox from "../../layouts/ConfirmBox";
import Search from "../../layouts/Search";
import SortingTable from "../../layouts/SortingTable";
import Pagination from "../../layouts/Pagination";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';
import { fetchCommandes, deleteCommande } from '../../services/apiService';


function ListerCommandeCl() {

    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [commandeIdToDelete, setCommandeIdToDelete] = useState(null);
    const [showConfirmBox, setShowConfirmBox] = useState(false);

    const columns = useMemo(() => [
        { key: 'Commande', label: t('Commande'), sortable: true },
        { key: 'Client', label: t('Client'), sortable: false },
        { key: 'Date', label: t('Date'), sortable: true },
        { key: 'Statut', label: t('Statut'), sortable: true },
        { key: 'actions', label: t('Actions'), sortable: false, className: 'text-center' }
    ], [t]);

    const { data: commandes = [], isLoading, isError } = useQuery('commandes', fetchCommandes);

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const filteredcommandes = commandes?.filter(commande => {
        return Object.values(commande).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }) || [];
   

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = filteredcommandes.slice(offset, offset + itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const openDelete = (Id) => {
        setCommandeIdToDelete(Id);
        setShowConfirmBox(true);
    };

    const closeConfirmBox = () => {
        setShowConfirmBox(false);
        // setCommandeIdToDelete(null);
    };

    const { mutate: handleDeleteCommande } = useMutation(deleteCommande, {
        onSuccess: () => {
            setCommandeIdToDelete(null);
            setShowConfirmBox(false);
            fetchCommandes();
        },
        onError: (error) => {
            console.error('Error deleting commande:', error);
        }
    });
    

    return (
        <>
           <div className="breadcrumbbar">
                        <div className="row align-items-center">
                            <div className="col-md-8 col-lg-8">
                                <h4 className="page-title">{t('Liste commandes')}</h4>
                                <div className="breadcrumb-list mb-3">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                        <Link to="/home">
                                        <i className="fa fa-home img-fluid" style={{fontSize:"15px", width:"20px", marginRight:"3px"}}></i>
                                        </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/home" style={{textDecoration:'none'}}>{t('clients')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('List commandes')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 m-b-50">
                                <div className="card m-b-50">
                                    <div className="card-header">
                                       <div className="row align-items-center">
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                                                <div className="col-5 col-md-5 d-flex justify-content-center">
                                                    <Search 
                                                        placeholder={t('Search')} 
                                                        onSearchChange={handleSearchChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                                                <div className="widgetbar">
                                                    <Link to="/clients/ajoute_commande" className="btn btn-primary-rgba">
                                                        <i className="feather icon-plus mr-2"></i>{t('Ajouter')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className="card-body">
                                    {isLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <TailSpin
                                                    height="80"
                                                    width="80"
                                                    color="#0000ff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {isError ? (
                                                    <div>{t('Error loading articles')}</div>
                                                ) : (
                                        <div className="table-responsive">
                                            <SortingTable
                                                data={currentItems.map((commande) => ({
                                                    key :commande.id,
                                                    id: commande.id,
                                                    Commande: commande.code,
                                                    Client: commande.client,
                                                    Société: commande.societe,
                                                    Date: commande.date_commande,
                                                    Statut: commande.statut === 'valide' ? (
                                                        <span className="badge badge-success font-14">Valide</span>
                                                    ) : commande.statut === 'en_cours' ? (
                                                        <span className="badge badge-info font-14">En cours</span>
                                                    ) : commande.statut === 'en_attente' ? (
                                                        <span className="badge badge-warning font-14">En attente</span>
                                                    ) : (
                                                        <span className="badge badge-danger font-14">Annulé</span>
                                                    ),
                                                    actions: (
                                                        <div>
                                                            <Link to={`/clients/modifie-commande/${commande.num_commande}`} className="btn btn-success-rgba btn-round mb-1" title="Modifier" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-edit-2" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link onClick={() => openDelete(commande.num_commande)} title="Supprimer" className="btn btn-danger-rgba btn-round mb-1" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-trash" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                            <Link to={`/clients/ajoute-bon-livraison/${commande.num_commande}`} title="Créer Bon de Livraison" className="btn btn-primary-rgba btn-round mb-1" style={{ marginRight: '10px' }}>
                                                                <i className="feather icon-truck" style={{ fontSize: "small" }} />
                                                            </Link>
                                                            <Link to={`https://admin.mansoft.ma/api/commandeclient/pdf/${commande.num_commande}`} title="Consulter" className="btn btn-danger-rgba btn-round" style={{ marginRight: '10px' }}>
                                                                <i className="fa fa-file-pdf-o" style={{ fontSize: "small" }}></i>
                                                            </Link>
                                                        </div>
                                                    ),
                                                }))}
                                                columns={columns}
                                            />
                                            <div className="d-flex justify-content-between mb-3">
                                                <div>
                                                    <select
                                                        className="form-control"
                                                        id="items-per-page"
                                                        onChange={handleItemsPerPageChange}
                                                        value={itemsPerPage}
                                                    >
                                                        <option value={2}>2</option>
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                </div>
                                                <Pagination
                                                    items={filteredcommandes.length ? filteredcommandes : []}
                                                    itemsPerPage={itemsPerPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                        )}
                                        </>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
           
            <ConfirmBox
                open={showConfirmBox}
                closeDialog={closeConfirmBox}
                title="Commande Client"
                deleteFunction={() => handleDeleteCommande(commandeIdToDelete)}
            />
        </>
    );
}

export default ListerCommandeCl;
