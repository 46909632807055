import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";

function ModifierClient() {
  const navigate = useNavigate();
  const { idClient } = useParams();

  const [clientData, setClientData] = useState({
    nom_client: "",
    prenom_client: "",
    societe: "",
    num_telephone: "",
    email: "",
    adresse: "",
    fax: "",
    ville:"",
    type_client:"",
  });

  useEffect(() => {
    const getClientData = async () => {
      try {
        const response = await axios.get(`/clients/${idClient}`);
        const data = response.data;

        const updatedData = {
          nom_client: data.nom_client || "",
          prenom_client: data.prenom_client || "",
          societe: data.societe || "",
          num_telephone: data.num_telephone || "",
          email: data.email || "",
          adresse: data.adresse || "",
          fax: data.fax || "",
          ville: data.ville || "",
          type_client: data.type_client || "",

        };

        setClientData(updatedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données du client:", error);
      }
    };

    getClientData(); 
  }, [idClient]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/clients/${idClient}`, clientData);
      Swal.fire("Succès", "Client modifié avec succès", "success");
      navigate("/clients");
    } catch (error) {
      console.error("Erreur lors de la modification du client:", error);
      Swal.fire("Erreur", "Erreur lors de la modification du client", "error");
    }
  };

  const handleChange = (e) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
     <div className="breadcrumbbar">
            <div className="row">
              <div className="col-lg-12 m-b-50">
                <div className="card m-b-50">
                  <div className="card-header">
                    <h5 className="card-title">Modifier client</h5>
                  </div>
                  <div className="card-body">
                    <form className="needs-validation" onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Nom Client</label>
                          <input
                            type="text"
                            className="form-control"
                            name="nom_client"
                            value={clientData.nom_client}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Prenom Client</label>
                          <input
                            type="text"
                            className="form-control"
                            name="prenom_client"
                            value={clientData.prenom_client}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Société</label>
                          <input
                            type="text"
                            className="form-control"
                            name="societe"
                            value={clientData.societe}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Téléphone</label>
                          <input
                            type="text"
                            className="form-control"
                            name="num_telephone"
                            value={clientData.num_telephone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={clientData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Adresse</label>
                          <input
                            type="text"
                            className="form-control"
                            name="adresse"
                            value={clientData.adresse}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Fax</label>
                          <input
                            type="text"
                            className="form-control"
                            name="fax"
                            value={clientData.fax}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">Ville</label>
                          <input
                            type="text"
                            className="form-control"
                            name="ville"
                            value={clientData.ville}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6 d-flex align-items-center mb-3">
                          <label className="me-2 col-md-4">type_client</label>
                          <input
                            type="text"
                            className="form-control"
                            name="type_client"
                            value={clientData.type_client}
                            onChange={handleChange}
                          />
                        </div>
                    </div>

                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Link className="btn btn-primary-rgba my-1" to="/clients">Annuler</Link>
                        <button
                          className="btn btn-primary-rgba my-1"
                          type="submit"
                        >
                          <i className="feather icon-save mr-2"></i>Modifier
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
      </div>
        
    </>
  );
}

export default ModifierClient;
