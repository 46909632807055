import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faPrint } from "@fortawesome/free-solid-svg-icons";
import { TailSpin } from 'react-loader-spinner'; 

const fetchArticleDetails = async (id) => {

  const articleResponse = await axios.get(`/articles/${id}`);
  const articleData = articleResponse.data;

  const typeResponse = await axios.get(`/types_articles/${articleData.id_type}`);
  const type = typeResponse.data;

  return {
    ...articleData,
    type
  };
};

const fetchCategories = async () => {
  const categoryResponse = await axios.get('/categories');
  return categoryResponse.data.categories;
};

function ArticleDetails() {
  const { id } = useParams();
  
 
  const handlePrint = () => {
    window.print();
  };

  const { data: article, isLoading: isArticleLoading, error: articleError } = useQuery(['articleDetails', id], () => fetchArticleDetails(id), {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useQuery('categories', fetchCategories, {
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
  

 

  return (
   
        <div className="breadcrumbbar">
          <div className="row align-items-center">
            <h4 className="page-title">Détails du produit</h4>
          </div>
          <div className="row m-b-50">
            <div className="card m-b-50">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6 d-flex justify-content-between">
                    <div className="col-5">
                      <button className="btn btn-primary-rgba" onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint} style={{ marginRight: '8px' }} />
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                  {isArticleLoading || isCategoriesLoading ? (
                    <div className="d-flex justify-content-center">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#0000ff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  ):(
                  <>
                  {articleError ||categoriesError ? (
                      <div>{'Error loading articles'}</div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="border-right align-middle">Article</td>
                                <td>{article.nom_article || ' '}</td>
                              </tr>
                              <tr>
                                <td className="border-right align-middle">
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={faBarcode} style={{ marginRight: '8px' }} />
                                    Bar Code
                                  </div>
                                </td>
                                <td>{article.code_barre || ' '}</td>
                              </tr>
                              <tr>
                                <td className="border-right align-middle">Prix Achat</td>
                                <td>{article.pu_achat || ' '}</td>
                              </tr>
                              <tr>
                                <td className="border-right align-middle">Prix Vente</td>
                                <td>{article.pu_vente || ' '}</td>
                              </tr>
                              <tr>
                                <td className="border-right align-middle">Category</td>
                                <td>{article.nom_categorie || ' '}</td>
                              </tr>
                              <tr>
                                <td className="border-right align-middle">Type</td>
                                <td>{article.type.type || ' '}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-4 image-container">
                        {article && (
                          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                              src={`https://admin.mansoft.ma/${article.photo}`}
                              alt={article.nom_article}
                              style={{ maxWidth: '100%', height: 'auto' }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md">
                        <Link className="btn btn-primary-rgba my-1" to="/articles">
                          <i className="feather icon-arrow-left ml-2"></i> Retour
                        </Link>
                      </div>
                    </div>
                  )}
                  </>
                  )}
                </div>
            </div>
          </div>
        </div>
     
  );
}

export default ArticleDetails;
