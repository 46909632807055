import React, { useState, useEffect,useRef } from "react";
import axios from "../../api/axios";
import { useNavigate,Link,useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function AjouterBonLiv() {
   
    const navigate = useNavigate();
    const { id } = useParams(); 

    const [clients, setClients] = useState([]);
    const [articles, setArticles] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [date, setDateBl] = useState("");

    const [categories, setCategories] = useState([]);
    const [article_commandes, setArticleCommande] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState("");
    const [price, setPrice] = useState("");
    const [qte, setQte] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
          const fetchClients = async () => {
      try {
        const response = await axios.get("/clients");
        setClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    
       
        const fetchCategories = async () => {
          try {
            const response = await axios.get("/categories");
            setCategories(response.data.categories);
          } catch (error) {
            console.error("Error fetching categories:", error);
          }
        };
    
        fetchClients();
        fetchCategories();
      }, []);
     
      useEffect(() => {
        if (!id) return;
        
        const fetchDevisDetails = async () => {
            try {
                const devisResponse = await axios.get(`/devis/${id}`);
                const { id_client, date_devis } = devisResponse.data;
                setSelectedClient(id_client);

                setDateBl(date_devis);
    
                const allLignesDevisResponse = await axios.get(`/article-devis?id_devis=${id}`);
                const lignesDevisData = allLignesDevisResponse.data;
    
                const articlesResponse = await axios.get("/articles");
                const allArticles = articlesResponse.data;
    
                const articlesDevis = lignesDevisData.map(ligne => {
                    const article = allArticles.find(a => a.id === ligne.id_article);
                    return {
                        id: ligne.id,
                        nom_article: article ? article.nom_article : "Nom inconnu",
                        quantity: ligne.quantite,
                        price: ligne.pu_vente,
                    };
                });
    
                setArticleCommande(articlesDevis);
            } catch (error) {
                console.error('Error fetching devis details:', error);
            }
        };
    
        fetchDevisDetails();
    }, [id]);
    useEffect(() => {
      const fetchCommandeClinetDetails = async () => {
          try {
              const CommandeResponse = await axios.get(`/commandeclient/${id}`);
              const { idclient, date_commande } = CommandeResponse.data;
              setSelectedClient(idclient);
              setDateBl(date_commande);
  
              const allLignesCommandeResponse = await axios.get(`/article-commandes?id_commande_cl=${id}`);
              const lignesDevisData = allLignesCommandeResponse.data;
  
              const articlesResponse = await axios.get("/articles");
              const allArticles = articlesResponse.data;
  
              const articlesDevis = lignesDevisData.map(ligne => {
                  const article = allArticles.find(a => a.id === ligne.id_article);
                  return {
                      id: ligne.id,
                      nom_article: article ? article.nom_article : "Nom inconnu",
                      quantity: ligne.quantite,
                      price: ligne.pu_vente,
                  };
              });
  
              setArticleCommande(articlesDevis);
          } catch (error) {
              console.error('Error fetching devis details:', error);
          }
      };
  
      fetchCommandeClinetDetails();
  }, [id]);
    useEffect(() => {
      const fetchArticles = async () => {
        try {
          const response = await axios.get("/articles");
          setArticles(response.data);
        } catch (error) {
          console.error("Error fetching articles:", error);
        }
      };
    
      fetchArticles();
    }, []);
    const handleAddRow = () => {
      const selectedArticleObj = articles.find(
        (article) => article.id === parseInt(selectedArticle, 10)
      );
    
      if (!selectedArticleObj) {
        console.error("L'article sélectionné n'a pas été trouvé");
        return;
      }
    
      const existingCommandeIndex = article_commandes.findIndex(
        (commande) => commande.id === selectedArticle
      );
    
      if (existingCommandeIndex !== -1) {
        const updatedCommandes = [...article_commandes];
        updatedCommandes[existingCommandeIndex].quantity += parseInt(qte);
        setArticleCommande(updatedCommandes);
      } else {
        const newCommande = {
         id_article: selectedArticle, 
          nom_article: selectedArticleObj.nom_article, 
          quantity: parseInt(qte),
          price: price,
        };
        setArticleCommande([...article_commandes, newCommande]);
      }
  
      setSelectedArticle('');
      setPrice('');
      setQte('');
  };
  
      const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
      };
    
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleSelectArticle = (id, nom_article) => {
        setSelectedArticle(id);
        setSearchTerm(nom_article);
        setDropdownOpen(false);
      };
      const handleRowChange = (field, value) => {
        switch (field) {
          case "selectedClient":
            setSelectedClient(value);
            break;
         
          case "selectedArticle":
            setSelectedArticle(value);
            break;
          case "qte":
            setQte(value);
            break;
          case "price":
            setPrice(value);
            break;
          default:
            console.warn("Champ inconnu:", field);
        }
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!selectedClient) {
            Swal.fire({
                icon: 'warning',
                title: 'Attention',
                text: 'Veuillez sélectionner un client.',
            });
            return;
        }
    
        if (article_commandes.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Attention',
                text: 'Veuillez ajouter au moins un article.',
            });
            return;
        }
    
        console.log("Submitting data:", {
            id_client: selectedClient,
            date_livraison: date,
        });
    
        try {
            const bonLivraisonResponse = await axios.post("/bonslivraisons", {
                id_client: selectedClient,
                date_livraison: date,
            });
    
            if (bonLivraisonResponse.status === 201) {
                const bonLivraisonId = bonLivraisonResponse.data.id;
    
                await Promise.all(
                    article_commandes.map(async (commande) => {
                        await axios.post("/ligne-bon-liv-cls", {
                            bon_livraison_id: bonLivraisonId,
                            id_article: commande.id_article, 
                            quantite: commande.quantity,
                            pu_achat: commande.price,
                        });
                    })
                );
    
                handleSuccess();
            } else {
                handleError();
            }
        } catch (error) {
            console.error("Error during submission:", error);
            handleError(error);
        }
    };
    

    
    const handleSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'bon livraison ajouté avec succès!',
        });
        navigate('/clients/bon-livraison');
    };

    const handleError = (error) => {
        console.error('Erreur lors de l\'ajout  de bon livraison :', error);
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Échec de l\'ajout  de bon livraison. Veuillez réessayer plus tard.',
        });
    };

    const deleteArticle = (index) => {
        const updatedRows = [...article_commandes];
        updatedRows.splice(index, 1);
        setArticleCommande(updatedRows);
      };
    
    return (
        <>
		
      
        <div className="breadcrumbbar">
                <div className="row">
                    <div className="col-lg-12 m-b-50">
                            <div className="card m-b-50">
                                <div className="card-header">
                                    <h5 className="card-title">Ajouter bon livraison de client</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation" onSubmit={handleSubmit} >
                                        <div className="form-row">
                                            <div className="col-md-6 d-flex align-items-center mb-3 ">
                                            <label className="me-2 col-md-2">Client</label>
                                              <select
                                                  className="form-control"
                                                  name="selectedClient"
                                                  value={selectedClient}
                                                  onChange={(e) => setSelectedClient(e.target.value)}
                                              >
                                                  <option key="" value=""></option>
                                                  {clients.map(client => (
                                                      <option key={client.id_client} value={client.id_client}>
                                                          {client.nom_client} {client.prenom_client}
                                                      </option>
                                                  ))}
                                              </select>

                                            </div>
                                            <div className="col-md-6 d-flex align-items-center mb-3">
                                                <label className="me-2 col-md-2">Date</label>
                                                <input
                                                   type="date"
                                                    className="form-control"
                                                    name="date"
                                                    value={date}
                                                    onChange={(e) => setDateBl(e.target.value)}
                                                />
                                            
                                            </div>
                                           
                                        </div>
                                        <div className="form-row">
                                        <React.Fragment>
                                        <div className="col-md-4 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-3">Article</label>
                                            <div className="custom-select-wrapper" ref={dropdownRef}>
                                              <div className="form-control" onClick={handleDropdownClick}>
                                                {searchTerm}
                                              </div>
                                              {dropdownOpen && (
                                                <div className="autocom-box">
                                                  <input
                                                    type="search"
                                                    aria-label="Search"
                                                    className="search-box form-control"
                                                    placeholder="Rechercher..."
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    onClick={() => setDropdownOpen(true)}
                                                  />
                                                    <ul>
                                                {categories
                                                  .filter(category => 
                                                    articles.some(article => 
                                                      article.categorie_id === category.id && 
                                                      article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                    )
                                                  )
                                                  .map(category => (
                                                    <React.Fragment key={category.id}>
                                                      <li className="category-label text-bold">{category.nom_categorie}</li>
                                                      {articles
                                                        .filter(article => 
                                                          article.categorie_id === category.id && 
                                                          article.nom_article.toLowerCase().includes(searchTerm.toLowerCase())
                                                        )
                                                        .map(filteredArticle => (
                                                          <li
                                                            key={filteredArticle.id}
                                                            className="article-item"
                                                            onClick={() => handleSelectArticle(filteredArticle.id, filteredArticle.nom_article)}
                                                          >
                                                            {filteredArticle.nom_article}
                                                          </li>
                                                        ))}
                                                    </React.Fragment>
                                                  ))}
                                              </ul>

                                            </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-4 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-4 ">Quantité</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={qte}
                                                onChange={(e) => handleRowChange('qte', e.target.value)}
                                                min={0}
                                            />
                                            </div>


                                            <div className="col-md-4 d-flex align-items-center mb-3">
                                            <label className="me-2 col-md-4">Prix</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={price}
                                                onChange={(e) => handleRowChange('price', e.target.value)}
                                            />
                                            </div>
                                       </React.Fragment>
                                            
                                       
                                        </div>

                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                             <i className="feather icon-plus-circle btn btn-primary-rgba ms-1 mb-3 pr-0" onClick={handleAddRow}></i>
                                        </div>
                                        <table className= "display table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Article</th>
                            <th>Quantité</th>
                            <th>P.U</th>
                            <th>Total</th>
                            <th className="d-md-flex justify-content-md-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {article_commandes.map((commande, index) => (
                          <tr key={index}>
                            <td>{commande.nom_article}</td>
                            <td>{commande.quantity}</td>
                            <td>{commande.price}</td>
                            <td>{commande.quantity * commande.price}</td>
                            <td className="d-md-flex justify-content-md-end">
                              <i
                                className="feather icon-trash btn btn-danger-rgba btn-round"
                                style={{ fontSize: "small" }}
                                onClick={() => deleteArticle(index)}
                              ></i>
                            </td>
                          </tr>


                          
                          ))}
                        </tbody>
                                        </table>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link className="btn btn-primary-rgba my-1" to="/clients/bon-livraison">Annuler</Link>
                                            <button className="btn btn-primary-rgba my-1">Ajouter<i className="feather icon-arrow-right ml-2"></i></button>

                                        </div>              
                                    
                                </form>
                </div>
             </div>
        </div>
                        
    </div>
        </div>


</>

    );
}

export default AjouterBonLiv;
